<template>
  <div
    ref="scroll"
    class="scroll-area"
    :class="$mq"
    @wheel="onWheel"
    @scroll="onScroll">
    <slot></slot>
  </div>
</template>

<script>
import { WheelUpThreshold } from '@/config'

export default {
  name: 'vue-y-scroll-area',
  data () {
    return {
      totalDeltaY: 0
    }
  },
  methods: {
    scrollTo (position) {
      this.$refs.scroll.scrollTo({
        top: position,
        smooth: true
      })
    },
    onWheel (e) {
      if (e.deltaY < 0) {
        this.totalDeltaY++
        if (this.totalDeltaY > WheelUpThreshold) {
          this.$emit('top')
          this.totalDeltaY = 0
        }
      } else {
        this.totalDeltaY = 0
      }
    },
    onScroll (e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target
      if (scrollTop === 0) {
        this.$emit('top')
      } else if (scrollTop === scrollHeight - clientHeight) {
        this.$emit('bottom')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .scroll-area{
   overflow-y: scroll;
   height: 100%;

   &::-webkit-scrollbar {
     width: $scroll_bar_width;
   }

   &::-webkit-scrollbar-track {
     border-radius: $scroll_bar_width;
     box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
   }

   &::-webkit-scrollbar-thumb {
     background-color: rgba(0, 60, 70, .5);
     border-radius: $scroll_bar_width;
     box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
   }
 }
</style>
