
import {
  defineComponent,
  computed
} from 'vue'
import ListRowItemText from '@/components/atoms/list/listRowItemText.vue'
import ListRowItemPermission from '@/components/atoms/list/listRowItemPermission.vue'
import ListRowItemButton from '@/components/atoms/list/listRowItemButton.vue'
import ListRowItemMultiButton from '@/components/atoms/list/listRowItemMultiButton.vue'
import ListRowItemEmphasizedText from '@/components/atoms/list/listRowItemEmphasizedText.vue'
import ListRowItemTextMultiline from '@/components/atoms/list/listRowItemTextMultiline.vue'
import ListRowItemEmphasizedTextMultiline from '@/components/atoms/list/listRowItemEmphasizedTextMultiline.vue'
import ListRowItemTextMultilineLink from '@/components/atoms/list/listRowItemTextMultilineLink.vue'
import ListRowItemSelectBox from '@/components/atoms/list/listRowItemSelectBox.vue'
import ListRowItemEditBox from '@/components/atoms/list/listRowItemEditBox.vue'
import ListRowItemMultiIconButton from '@/components/atoms/list/listRowItemMultiIconButton.vue'
import ListRowItemCircleMarks from '@/components/atoms/list/listRowItemCircleMarks.vue'
import ListRowItemKartes from '@/components/atoms/list/listRowItemKartes.vue'
import ListRowItemBookmark from '@/components/atoms/list/listRowItemBookmark.vue'
import ListRowItemCheckbox from '@/components/atoms/list/listRowItemCheckBox.vue'

type RowItem = {
  value?: string
  values?: string[]
  width: number
  type: string
  center?: boolean
  state?: string
  emitType?: string
  buttons?: {
    value: string
    state: string
    emitType: string
  }[]
}

export default defineComponent({
  name: 'list-row',
  components: {
    ListRowItemCheckbox,
    ListRowItemBookmark,
    ListRowItemKartes,
    ListRowItemCircleMarks,
    ListRowItemMultiIconButton,
    ListRowItemEditBox,
    ListRowItemSelectBox,
    ListRowItemTextMultilineLink,
    ListRowItemEmphasizedTextMultiline,
    ListRowItemTextMultiline,
    ListRowItemEmphasizedText,
    listRowItemText: ListRowItemText,
    listRowItemPermission: ListRowItemPermission,
    listRowItemButton: ListRowItemButton,
    listRowItemMultiButton: ListRowItemMultiButton
  },
  props: {
    rowId: String,
    columns: Array,
    searchWord: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'row', 'bookmark', 'up', 'down'],
  setup (props) {
    const styles = computed(() => {
      return (props.columns as RowItem[]).map(column => {
        const s = [`flex: ${column.width}`]
        if (column.center) {
          s.push('text-align: center')
        }
        return s.join(';')
      })
    })

    return {
      styles
    }
  }
})
