
import {
  defineComponent,
  ref
} from 'vue'

export default defineComponent({
  name: 'drop-down-frame',
  setup () {
    const show = ref(false)

    const open = () => {
      show.value = true
      document.body.classList.add('open-modal')
    }

    const dismiss = () => {
      show.value = false
      document.body.classList.remove('open-modal')
    }

    return {
      show,
      open,
      dismiss
    }
  }
})
