<template>
  <div class="title" :class="$mq">
    <div
      v-for="(title, index) in titles"
      :key="'title-'+index"
      :style="styles[index]">{{ title.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-title',
  props: {
    titles: Array
  },
  computed: {
    styles () {
      return this.titles.map(title => {
        const s = ['flex:' + title.width]
        if (title.center) {
          s.push('text-align: center')
        }
        return s.join(';')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid $base_color_blue_dark;
  padding-right: $scroll_bar_width;
  gap: 4px;

  &.laptop, &.tablet, &.laptop {
    font-size: 0.9em;
  }
}

.title-center {
  text-align: center;
}
</style>
