
import {
  defineComponent,
  computed
} from 'vue'

type Props = {
  icon?: string
  disabled: boolean
  state: string
}

export default defineComponent({
  name: 'icon-button-large',
  props: {
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      required: true,
      default: 'normal',
      validator (value: string) {
        return ['normal', 'normal-green', 'normal-gray', 'warning', 'delete', 'confirm'].indexOf(value) !== -1
      }
    }
  },
  emits: ['click'],
  setup (props: Props, context) {
    const buttonClass = computed(() => {
      return [
        'normal-button',
        props.disabled ? 'button-disable-' + props.state : 'button-enable-' + props.state
      ].join(' ')
    })

    const onClickIcon = (event: MouseEvent) => {
      context.emit('click', {
        pageX: event.pageX - 17,
        pageY: event.pageY - 14,
        offsetX: event.offsetX,
        offsetY: event.offsetY
      })
    }

    return {
      buttonClass,
      onClickIcon
    }
  }
})
