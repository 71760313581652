import { InjectionKey } from 'vue'
import { AnswerStatus } from '@/API'
import { API, graphqlOperation } from 'aws-amplify'
import { updateInquiryStatus as updateInquiryStatusMutation } from '@/graphql/mutations'

export const useInquiryAnswerMutation = () => {
  const updateInquiryStatus = async ({ id, answerStatus }: {
    id: string,
    answerStatus: AnswerStatus
  }) => {
    try {
      await API.graphql(graphqlOperation(updateInquiryStatusMutation, { id, answerStatus }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    updateInquiryStatus
  }
}

export type UseInquiryAnswerMutationType = ReturnType<typeof useInquiryAnswerMutation>
export const UseInquiryAnswerMutationKey: InjectionKey<UseInquiryAnswerMutationType> = Symbol('InquiryAnswerMutation')
