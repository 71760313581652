
import {
  defineComponent,
  ref,
  watchEffect
} from 'vue'
import QRCode from 'qrcode'

type Props = {
  url: string
}

export default defineComponent({
  name: 'qrcode',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  setup (props: Props) {
    const qrcode = ref('')

    watchEffect(async () => {
      if (props.url === '') {
        qrcode.value = ''
        return
      }

      try {
        const opt = {
          width: 300,
          color: {
            dark: '#2f3432',
            light: '#FFFFFF'
          }
        }
        qrcode.value = await QRCode.toDataURL(props.url, opt)
      } catch (e) {
        console.log(e)
        qrcode.value = ''
      }
    })

    return {
      qrcode
    }
  }
})
