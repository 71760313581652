import { computed, inject, InjectionKey, ref } from 'vue'
import { Karte as KarteModel } from '@/models'
import { useRoute, useRouter } from 'vue-router'
import {
  UseFavoriteKarteSubscriptionKey,
  UseFavoriteKarteSubscriptionType
} from '@/composables/karte/useFavoriteKarteSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useKarte } from '@/composables/karte/useKarte'
import { API, graphqlOperation } from 'aws-amplify'
import { getKarte } from '@/graphql/queries'

interface GetKarteResult {
  data?: {
    getKarte: KarteModel
  }
}

export const useFavoriteList = () => {
  const route = useRoute()
  const router = useRouter()
  const patientId = ref<string>(route.query.p as string || '')
  const { favoriteMap } = inject(UseFavoriteKarteSubscriptionKey) as UseFavoriteKarteSubscriptionType
  const { clinicId } = inject(UseClinicKey) as UseClinicType
  const { setKarte } = useKarte()

  const favoriteRows = computed(() => {
    if (!favoriteMap.value[patientId.value]) {
      return []
    }

    return favoriteMap.value[patientId.value].map(favo => {
      return {
        id: favo.id,
        columns: [
          {
            value: {
              id: favo.karteId,
              number: favo.karteNumber,
              title: favo.title === '' ? 'タイトルなし' : favo.title,
              accountName: favo.karteAccountName,
              karteDate: favo.karteDate,
              async cb () {
                try {
                  const res = await API.graphql(graphqlOperation(getKarte, { id: favo.karteId })) as GetKarteResult
                  setKarte(res.data!.getKarte)
                  await router.push(`/w/${clinicId.value}/karte?p=${patientId.value}`)
                } catch (e) {
                  console.log(e)
                }
              }
            },
            width: 3,
            type: 'bookmark'
          }
        ]
      }
    })
  })

  return {
    favoriteRows
  }
}

export type UseFavoriteListType = ReturnType<typeof useFavoriteList>
export const UseFavoriteListKey: InjectionKey<UseFavoriteListType> = Symbol('FavoriteList')
