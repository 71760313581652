
import { computed, defineComponent, inject } from 'vue'
import { VueMqType } from 'vue3-mq'

type Props = {
  disabled: boolean
  state: string
  buttonType: string
  large: boolean
}

export default defineComponent({
  name: 'NormalButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      required: true,
      default: 'normal',
      validator (value: string): boolean {
        return ['normal', 'normal-green', 'normal-gray', 'warning', 'delete', 'confirm'].indexOf(value) !== -1
      }
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    large: Boolean
  },
  setup (props: Props) {
    const mq = inject('mq') as VueMqType
    const buttonClass = computed<string>(() => {
      return [
        'normal-button',
        props.large ? 'normal-button-large' : '',
        props.disabled ? 'button-disable-' + props.state : 'button-enable-' + props.state,
        mq.value
      ].join(' ')
    })

    return {
      buttonClass
    }
  }
})
