<template>
  <div class="text-column">
    <transition-group>
      <div
        v-for="(value, index) in values"
        :key="value.id"
        class="karte-row">
        <fa-icon class="karte-icon" :icon="value.icon"/>
        <div class="karte-number">{{ `No. ${value.number}` }}</div>
        <div class="karte-title" @click="value.cb(value)">{{ value.title }}</div>
        <div class="karte-account">{{ `担当: ${value.accountName}` }}</div>
        <fa-icon
          v-if="value.bookmarked"
          class="karte-bookmark-marked"
          icon="star" @click="$emit('bookmark', value)"/>
        <fa-icon
          v-else
          class="karte-bookmark"
          :icon="['far', 'star']" @click="$emit('bookmark', value)"/>
        <template v-if="value.icon!=='cloud'">
          <fa-icon
            v-if="index===0"
            class="karte-arrow-disable"
            icon="arrow-up"/>
          <fa-icon
            v-else
            class="karte-arrow"
            icon="arrow-up" @click="$emit('up', value)"/>
          <fa-icon
            v-if="index===values.length-1"
            class="karte-arrow-disable"
            icon="arrow-down"/>
          <fa-icon
            v-else
            class="karte-arrow"
            icon="arrow-down" @click="$emit('down', value)"/>
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-kartes',
  props: {
    values: Array,
    columnId: String
  },
  emits: ['bookmark', 'up', 'down']
}
</script>

<style lang="scss" scoped>
.text-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .karte-row {
    display: grid;
    grid-template-columns: 40px 150px 250px 150px 30px 30px 30px;
    align-items: center;

    .karte-icon {
      color: $base_color_blue_light;
    }

    .karte-title {
      word-break: break-all;
      text-decoration: underline;
      cursor: pointer;
    }

    .karte-bookmark-marked {
      font-size: 1.2em;
      color: $base_color_blue_light;
      cursor: pointer;
    }

    .karte-bookmark {
      font-size: 1.2em;
      color: $base_color_gray_dark;
      cursor: pointer;
    }

    .karte-arrow-disable {
      font-size: 1.2em;
      color: $base_color_gray_light;
    }

    .karte-arrow {
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}

.v-move {
  transition: 0.2s;
}

.v-leave-active {
  display: none;
}
</style>
