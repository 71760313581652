<template>
  <div v-show="show">
    <div class="backdrop">
    </div>
    <transition
      name="custom-classes-transition"
      :duration="100"
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut">
      <slot></slot>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'white-backdrop',
  props: {
    show: Boolean
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $base_color_white;
  opacity: 0.7;
}
</style>
