<template>
  <div class="radio-group-frame">
    <div class="control-content" :class="$mq">
      <radio-button
        v-for="(radio, index) in radios"
        :key="'radio-group-'+groupId+'-'+index"
        :checked="radio.value===modelValue"
        @click="$emit('update:modelValue', radio.value)">{{ radio.name }}
      </radio-button>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/atoms/radioButton.vue'

export default {
  name: 'no-label-radio-button-group',
  components: {
    RadioButton
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    radios: Array,
    modelValue: String
  }
}
</script>

<style lang="scss" scoped>
.radio-group-frame {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.control-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  &.mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
