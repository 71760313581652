
import {
  defineComponent,
  inject,
  onMounted, provide
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import Calendar from '@/components/organisms/calendar.vue'
import DoctorTag from '@/components/molecules/doctorTag.vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useCalendar, UseCalendarKey } from '@/composables/useCalendar'
import { useAppointmentList } from '@/composables/appointment/useAppintmentList'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'karte-by-appointment',
  components: {
    DoctorTag,
    Calendar,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const router = useRouter()
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { calendarStartDayNumber } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    const calendar = useCalendar(calendarStartDayNumber)
    const {
      year,
      month
    } = calendar
    provide(UseCalendarKey, calendar)

    const { appointmentList } = useAppointmentList(year, month)

    onMounted(async () => {
      intoDashboard()
    })

    const onSelectAppointment = async (day:number, accountId: string, count: number) => {
      if (count === 0) {
        return
      }
      await router.push(`/w/${clinicId.value}/karte_select_appointment?y=${year.value}&m=${month.value}&d=${day}&a=${accountId}`)
    }

    return {
      year,
      month,
      onSelectAppointment,
      appointmentList
    }
  }
})
