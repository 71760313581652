import { computed, InjectionKey, ref, watch } from 'vue'
import { AppendFunc } from '@/composables/useError'
import { DropAreaFileType } from '@/composables/types'
import { DBKarteFileType, SyncStatusType, SyncStatusTypes } from '@/composables/karte/types'
import { FileSyncFailedIntervalSec } from '@/config'
import { API, graphqlOperation } from 'aws-amplify'
import {
  uploadFile as uploadFileMutation,
  downloadFile as downloadFileMutation,
  deleteFile as deleteFileMutation
} from '@/graphql/mutations'
import { DownloadFile as DownloadFileModel } from '@/models'

type DownloadFileResult = {
  data?: {
    downloadFile: DownloadFileModel
  }
}

const FileDatabaseName = 'ekarte_files'
const FileDatabaseVersion = 1
const FileStoreName = 'files'

let _timer: NodeJS.Timer | null = null

export const useFileDB = (append: AppendFunc) => {
  const files = ref<DBKarteFileType[]>([])
  const uploadQue = ref<string[]>([])
  const downloadQue = ref<string[]>([])
  const deleteQue = ref<string[]>([])
  const syncing = ref<boolean>(false)

  const startSync = async () => {
    syncing.value = true
    if (_timer === null) {
      _timer = setTimeout(async () => {
        await sync()
      }, 100)
    }
  }

  const stopSync = async () => {
    syncing.value = false
    if (_timer !== null) {
      clearTimeout(_timer)
      _timer = null
    }
  }

  const sync = async () => {
    try {
      if (uploadQue.value.length > 0) {
        await _syncUpload()
      } else if (deleteQue.value.length > 0) {
        await _syncDelete()
      } else if (downloadQue.value.length > 0) {
        await _syncDownload()
      } else {
        _timer = null
        return
      }

      // 成功した場合はすぐに次の同期を始める
      if (syncing.value) {
        _timer = setTimeout(async () => {
          await sync()
        }, 500)
      }
    } catch (e) {
      console.log(e)

      // 失敗の場合はおそらくオフラインかサーバダウンなので、しばらく置いてから再開
      if (syncing.value) {
        _timer = setTimeout(async () => {
          await sync()
        }, FileSyncFailedIntervalSec)
      }
    }
  }

  const _syncUpload = async () => {
    try {
      const targetId = uploadQue.value[0]
      const file = await _getFileFromDB(targetId)
      if (file === undefined) {
        // アプロードするはずのデータが何故かindexedDBになかった
        // とりあえずアップロードキューから消す。
        // 最終的に画面には、「クラウドにも無いのでもう一度アップロードしてください」と表示する。
        const [, ...rest] = uploadQue.value
        uploadQue.value = rest
        return true
      }

      // 既に同期済みのものはスキップ
      if (file.syncStatus === SyncStatusTypes.Synced) {
        const [, ...rest] = uploadQue.value
        uploadQue.value = rest
        return true
      }

      const input = {
        id: file.id,
        fileType: file.fileType,
        name: file.name,
        base64: file.base64,
        hash: file.hash
      }
      await API.graphql(graphqlOperation(uploadFileMutation, { input }))
      const [, ...rest] = uploadQue.value
      uploadQue.value = rest

      // 同期状態に書き換え
      await putFile({
        ...file,
        syncStatus: SyncStatusTypes.Synced
      })
      return true
    } catch (e) {
      const targetId = uploadQue.value[0]
      const file = await _getFileFromDB(targetId)
      await putFile({
        ...file!,
        syncStatus: SyncStatusTypes.Retrying
      })

      return Promise.reject(e)
    }
  }

  const _syncDelete = async () => {
    try {
      const targetId = deleteQue.value[0]
      await API.graphql(graphqlOperation(deleteFileMutation, { id: targetId }))
      const [, ...rest] = deleteQue.value
      deleteQue.value = rest
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _syncDownload = async () => {
    try {
      const targetId = downloadQue.value[0]
      const res = await API.graphql(graphqlOperation(downloadFileMutation, { id: targetId })) as DownloadFileResult
      if (!res.data || !res.data!.downloadFile) {
        // サーバにファイルが無かった場合、
        const notFound = {
          id: targetId,
          name: '',
          fileType: '',
          base64: '',
          hash: '',
          syncStatus: SyncStatusTypes.NotFoundOnCloud
        }
        await putFile(notFound)
      } else {
        const file = res.data.downloadFile
        await putFile({
          ...file,
          syncStatus: SyncStatusTypes.Synced
        })
      }
      const [, ...rest] = downloadQue.value
      downloadQue.value = rest
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // db, tableの初期化
  const initializeDatabase = async () => {
    return new Promise<boolean>((resolve, reject) => {
      const init = indexedDB.open(FileDatabaseName, FileDatabaseVersion)

      init.onupgradeneeded = (event) => {
        const db = (<IDBRequest>event.target).result
        db.createObjectStore(FileStoreName, { keyPath: 'id' })
      }

      init.onsuccess = (event) => {
        const db = (<IDBRequest>event.target).result
        db.close()
        resolve(true)
      }

      init.onerror = async (event: Event) => {
        console.log(event)
        await append('error', 'ファイルDBの初期化に失敗しました')
        reject(event)
      }
    })
  }

  // db, tableの初期化
  const deleteDatabase = async () => {
    files.value = []
    return new Promise((resolve, reject) => {
      const del = indexedDB.deleteDatabase(FileDatabaseName)

      del.onsuccess = () => {
        resolve(true)
      }

      del.onerror = async (event: Event) => {
        console.log(event)
        reject(event)
      }
    })
  }

  const putFile = async (file: DBKarteFileType) => {
    return new Promise((resolve, reject) => {
      const put = indexedDB.open(FileDatabaseName, FileDatabaseVersion)

      put.onsuccess = (event) => {
        const db = (<IDBRequest>event.target).result
        const transaction = db.transaction(FileStoreName, 'readwrite')
        const store = transaction.objectStore(FileStoreName)
        const putReq = store.put(file)

        putReq.onsuccess = () => {
          const index = files.value.findIndex(f => f.id === file.id)
          if (index >= 0) {
            const newFiles = [...files.value]
            newFiles.splice(index, 1, file)
            files.value = newFiles
          } else {
            files.value = [...files.value, file]
          }
          resolve(true)
        }

        putReq.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの保存に失敗しました'))
        }

        transaction.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの保存に失敗しました'))
        }
      }

      put.onerror = async (event: Event) => {
        console.log(event)
        reject(new Error('ファイルDBのオープンに失敗しました'))
      }
    })
  }

  const getFile = async (id: string) => {
    if (files.value.find(f => f.id === id)) {
      return true
    }
    try {
      const file = await _getFileFromDB(id)
      if (file) {
        files.value = [...files.value, file]
      } else {
        downloadQue.value = [...new Set([...downloadQue.value, id])]
        await startSync()
      }
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _getFileFromDB = async (id: string): Promise<DBKarteFileType|undefined> => {
    return new Promise((resolve, reject) => {
      const get = indexedDB.open(FileDatabaseName, FileDatabaseVersion)

      get.onsuccess = (event) => {
        const db = (<IDBRequest>event.target).result
        const transaction = db.transaction(FileStoreName, 'readonly')
        const store = transaction.objectStore(FileStoreName)
        const getReq = store.get(id)

        getReq.onsuccess = (event: Event) => {
          const file = <DBKarteFileType>(<IDBRequest>event.target).result
          resolve(file)
        }

        getReq.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの取得に失敗しました'))
        }

        transaction.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの取得に失敗しました'))
        }
      }

      get.onerror = async (event) => {
        console.log(event)
        reject(new Error('ファイルDBのオープンに失敗しました'))
      }
    })
  }

  (async () => {
    const data = localStorage.getItem('ekarte-file-que')
    if (data) {
      const parsed = JSON.parse(data) as {
        uploadQue: string[],
        downloadQue: string[],
        deleteQue: string[]
      }
      uploadQue.value = parsed.uploadQue
      downloadQue.value = parsed.downloadQue
      deleteQue.value = parsed.deleteQue

      if (uploadQue.value.length > 0 || downloadQue.value.length > 0 || deleteQue.value.length > 0) {
        await startSync()
      }
    }
  })()

  watch(
    [uploadQue, downloadQue, deleteQue],
    (curr) => {
      const data = {
        uploadQue: curr[0],
        downloadQue: curr[1],
        deleteQue: curr[2]
      }
      localStorage.setItem('ekarte-file-que', JSON.stringify(data))
    },
    { deep: true }
  )

  const deleteFile = async (id: string) => {
    return new Promise((resolve, reject) => {
      const del = indexedDB.open(FileDatabaseName, FileDatabaseVersion)

      del.onsuccess = (event) => {
        const db = (<IDBRequest>event.target).result
        const transaction = db.transaction(FileStoreName, 'readwrite')
        const store = transaction.objectStore(FileStoreName)
        const deleteReq = store.delete(id)

        deleteReq.onsuccess = () => {
          files.value = files.value.filter(f => f.id !== id)
          resolve(true)
        }

        deleteReq.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの削除に失敗しました'))
        }

        transaction.onerror = async (event: Event) => {
          console.log(event)
          reject(new Error('ファイルの削除に失敗しました'))
        }
      }

      del.onerror = async (event) => {
        console.log(event)
        reject(new Error('ファイルDBのオープンに失敗しました'))
      }
    })
  }

  const addUploadQue = async (uploadFileIds: string[]) => {
    uploadQue.value = [...uploadQue.value, ...uploadFileIds]
    await startSync()
  }

  const addDeleteQue = async (deleteFileIds: string[]) => {
    deleteQue.value = [...deleteQue.value, ...deleteFileIds]
    await startSync()
  }

  const fileMap = computed<{ [id: string]: DropAreaFileType }>(() => {
    const newMap:{ [id: string]: DropAreaFileType } = {}
    files.value.forEach(f => {
      newMap[f.id] = f
    })
    return newMap
  })

  const syncStatus = computed<SyncStatusType>(() => {
    if (uploadQue.value.length === 0 &&
        downloadQue.value.length === 0 &&
        deleteQue.value.length === 0) {
      return SyncStatusTypes.Synced
    }
    return files.value.some(f => f.syncStatus === SyncStatusTypes.Retrying)
      ? SyncStatusTypes.Retrying
      : SyncStatusTypes.Syncing
  })

  return {
    startSync,
    stopSync,
    initializeDatabase,
    deleteDatabase,
    putFile,
    getFile,
    deleteFile,
    addUploadQue,
    addDeleteQue,
    fileMap,
    syncStatus
  }
}

export type UseFileDBType = ReturnType<typeof useFileDB>
export const UseFileDBKey: InjectionKey<UseFileDBType> = Symbol('FileDB')
