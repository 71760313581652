
import {
  computed,
  defineComponent, inject
} from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import SelectBox from '@/components/atoms/selectBox.vue'
import { UsePatientListKey, UsePatientListType } from '@/composables/patient/usePatientList'
import {
  UseTemporaryPatientListKey,
  UseTemporaryPatientListType
} from '@/composables/appointment/useTemporaryPatientList'
import { UseAppointmentKey, UseAppointmentType } from '@/composables/appointment/useAppointment'
import {
  UseAccountAppointmentKey,
  UseAccountAppointmentType
} from '@/composables/appointment/useAccountAppointment'

export default defineComponent({
  name: 'patient-time-form',
  components: {
    SelectBox,
    VueContent
  },
  setup () {
    const { patientInfo: selectedPatientInfo } = inject(UsePatientListKey) as UsePatientListType
    const { patientInfo: temporaryPatientInfo } = inject(UseTemporaryPatientListKey) as UseTemporaryPatientListType
    const {
      patientType,
      baseDateString
    } = inject(UseAppointmentKey) as UseAppointmentType
    const {
      accounts,
      selectedAccount
    } = inject(UseAccountAppointmentKey) as UseAccountAppointmentType

    const patientInfo = computed(() => {
      return patientType.value === 'sel' ? selectedPatientInfo.value : temporaryPatientInfo.value
    })

    return {
      baseDateString,
      patientInfo,
      accounts,
      selectedAccount
    }
  }
})
