/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onAnswerInquiry = /* GraphQL */ `
  subscription OnAnswerInquiry($clinicId: String!) {
    onAnswerInquiry(clinicId: $clinicId) {
      id
      clinicId
      owner
      inquiryId
      patientId
      answer
      answerStatus
      createdAt
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($id: String!) {
    onUpdateAccount(id: $id) {
      id
      name
      permissions
      acceptAppointment
      myself
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient($clinicId: String!) {
    onCreatePatient(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePatient = /* GraphQL */ `
  subscription OnUpdatePatient($clinicId: String!) {
    onUpdatePatient(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePatient = /* GraphQL */ `
  subscription OnDeletePatient($clinicId: String!) {
    onDeletePatient(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemporaryPatient = /* GraphQL */ `
  subscription OnCreateTemporaryPatient($clinicId: String!) {
    onCreateTemporaryPatient(clinicId: $clinicId) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTemporaryPatient = /* GraphQL */ `
  subscription OnUpdateTemporaryPatient($clinicId: String!) {
    onUpdateTemporaryPatient(clinicId: $clinicId) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTemporaryPatient = /* GraphQL */ `
  subscription OnDeleteTemporaryPatient($clinicId: String!) {
    onDeleteTemporaryPatient(clinicId: $clinicId) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePatientNumber = /* GraphQL */ `
  subscription OnCreatePatientNumber($clinicId: String!) {
    onCreatePatientNumber(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePatientNumber = /* GraphQL */ `
  subscription OnUpdatePatientNumber($clinicId: String!) {
    onUpdatePatientNumber(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePatientNumber = /* GraphQL */ `
  subscription OnDeletePatientNumber($clinicId: String!) {
    onDeletePatientNumber(clinicId: $clinicId) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBusinessHour = /* GraphQL */ `
  subscription OnCreateBusinessHour($clinicId: String!) {
    onCreateBusinessHour(clinicId: $clinicId) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBusinessHour = /* GraphQL */ `
  subscription OnUpdateBusinessHour($clinicId: String!) {
    onUpdateBusinessHour(clinicId: $clinicId) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBusinessHour = /* GraphQL */ `
  subscription OnDeleteBusinessHour($clinicId: String!) {
    onDeleteBusinessHour(clinicId: $clinicId) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemporaryClose = /* GraphQL */ `
  subscription OnCreateTemporaryClose($clinicId: String!) {
    onCreateTemporaryClose(clinicId: $clinicId) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTemporaryClose = /* GraphQL */ `
  subscription OnUpdateTemporaryClose($clinicId: String!) {
    onUpdateTemporaryClose(clinicId: $clinicId) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTemporaryClose = /* GraphQL */ `
  subscription OnDeleteTemporaryClose($clinicId: String!) {
    onDeleteTemporaryClose(clinicId: $clinicId) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAppointment = /* GraphQL */ `
  subscription OnCreateAppointment($clinicId: String!) {
    onCreateAppointment(clinicId: $clinicId) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAppointment = /* GraphQL */ `
  subscription OnUpdateAppointment($clinicId: String!) {
    onUpdateAppointment(clinicId: $clinicId) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAppointment = /* GraphQL */ `
  subscription OnDeleteAppointment($clinicId: String!) {
    onDeleteAppointment(clinicId: $clinicId) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKarteItem = /* GraphQL */ `
  subscription OnCreateKarteItem($clinicId: String!) {
    onCreateKarteItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKarteItem = /* GraphQL */ `
  subscription OnUpdateKarteItem($clinicId: String!) {
    onUpdateKarteItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKarteItem = /* GraphQL */ `
  subscription OnDeleteKarteItem($clinicId: String!) {
    onDeleteKarteItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAcuItem = /* GraphQL */ `
  subscription OnCreateAcuItem($clinicId: String!) {
    onCreateAcuItem(clinicId: $clinicId) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAcuItem = /* GraphQL */ `
  subscription OnUpdateAcuItem($clinicId: String!) {
    onUpdateAcuItem(clinicId: $clinicId) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAcuItem = /* GraphQL */ `
  subscription OnDeleteAcuItem($clinicId: String!) {
    onDeleteAcuItem(clinicId: $clinicId) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKarteTemplate = /* GraphQL */ `
  subscription OnCreateKarteTemplate($clinicId: String!) {
    onCreateKarteTemplate(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKarteTemplate = /* GraphQL */ `
  subscription OnUpdateKarteTemplate($clinicId: String!) {
    onUpdateKarteTemplate(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKarteTemplate = /* GraphQL */ `
  subscription OnDeleteKarteTemplate($clinicId: String!) {
    onDeleteKarteTemplate(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKarte = /* GraphQL */ `
  subscription OnCreateKarte($clinicId: String!) {
    onCreateKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKarte = /* GraphQL */ `
  subscription OnUpdateKarte($clinicId: String!) {
    onUpdateKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKarte = /* GraphQL */ `
  subscription OnDeleteKarte($clinicId: String!) {
    onDeleteKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateKarteNumber = /* GraphQL */ `
  subscription OnCreateKarteNumber($clinicId: String!) {
    onCreateKarteNumber(clinicId: $clinicId) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKarteNumber = /* GraphQL */ `
  subscription OnUpdateKarteNumber($clinicId: String!) {
    onUpdateKarteNumber(clinicId: $clinicId) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKarteNumber = /* GraphQL */ `
  subscription OnDeleteKarteNumber($clinicId: String!) {
    onDeleteKarteNumber(clinicId: $clinicId) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFavoriteKarte = /* GraphQL */ `
  subscription OnCreateFavoriteKarte($clinicId: String!) {
    onCreateFavoriteKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFavoriteKarte = /* GraphQL */ `
  subscription OnUpdateFavoriteKarte($clinicId: String!) {
    onUpdateFavoriteKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFavoriteKarte = /* GraphQL */ `
  subscription OnDeleteFavoriteKarte($clinicId: String!) {
    onDeleteFavoriteKarte(clinicId: $clinicId) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInquiryItem = /* GraphQL */ `
  subscription OnCreateInquiryItem($clinicId: String!) {
    onCreateInquiryItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInquiryItem = /* GraphQL */ `
  subscription OnUpdateInquiryItem($clinicId: String!) {
    onUpdateInquiryItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInquiryItem = /* GraphQL */ `
  subscription OnDeleteInquiryItem($clinicId: String!) {
    onDeleteInquiryItem(clinicId: $clinicId) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSetting = /* GraphQL */ `
  subscription OnCreateSetting($clinicId: String!) {
    onCreateSetting(clinicId: $clinicId) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSetting = /* GraphQL */ `
  subscription OnUpdateSetting($clinicId: String!) {
    onUpdateSetting(clinicId: $clinicId) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSetting = /* GraphQL */ `
  subscription OnDeleteSetting($clinicId: String!) {
    onDeleteSetting(clinicId: $clinicId) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFileHash = /* GraphQL */ `
  subscription OnCreateFileHash($clinicId: String!) {
    onCreateFileHash(clinicId: $clinicId) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFileHash = /* GraphQL */ `
  subscription OnUpdateFileHash($clinicId: String!) {
    onUpdateFileHash(clinicId: $clinicId) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFileHash = /* GraphQL */ `
  subscription OnDeleteFileHash($clinicId: String!) {
    onDeleteFileHash(clinicId: $clinicId) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
