import { computed, inject, InjectionKey, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { v4 as uuid } from 'uuid'
import { addTime } from '@/plugins/functions'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import { hhmmValidation } from '@/plugins/validator'
import {
  UseKarteTemplateSubscriptionKey,
  UseKarteTemplateSubscriptionType
} from '@/composables/karteData/useKarteTemplateSubscription'
import { ListBoxItemType } from '@/composables/types'
import {
  UseKarteItemSubscriptionKey,
  UseKarteItemSubscriptionType
} from '@/composables/karteData/useKarteItemSubscription'
import { KarteResult, PartsName } from '@/models'

export const useConsultationInfo = () => {
  const route = useRoute()

  const now = dayjs()

  const { karteTemplates } = inject(UseKarteTemplateSubscriptionKey) as UseKarteTemplateSubscriptionType
  const { karteItems } = inject(UseKarteItemSubscriptionKey) as UseKarteItemSubscriptionType

  // 予約一覧からカルテ一覧に遷移した場合、どの予約を選択したのかの情報をqueryから取得する
  const appointmentId = ref<string>(route.query.ap as string || '')
  const year = ref<number>(route.query.y ? parseInt(route.query.y as string) : now.year())
  const month = ref<number>(route.query.m ? parseInt(route.query.m as string) : now.month())
  const day = ref<number>(route.query.d ? parseInt(route.query.d as string) : now.date())
  const startTime = ref<string>(route.query.st as string || '0900')
  const endTime = ref<string>(route.query.ed as string || '1000')

  const enableTime = ref<boolean>(appointmentId.value !== '')
  const editYear = ref<number>(year.value)
  const editMonth = ref<number>(month.value)
  const editDay = ref<number>(day.value)
  const editStartTime = ref<string>(startTime.value)
  const editEndTime = ref<string>(endTime.value)
  const selectedTemplateId = ref<string>('none')

  const { setting: { appointmentTimeUnit } } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

  const resetAppointment = () => {
    enableTime.value = appointmentId.value !== ''
    editYear.value = year.value
    editMonth.value = month.value
    editDay.value = day.value
    editStartTime.value = startTime.value
    editEndTime.value = endTime.value
    selectedTemplateId.value = 'none'
  }

  const errorEditStartTime = computed(() => {
    if (!enableTime.value) {
      return undefined
    }

    return editStartTime.value === ''
      ? '開始時刻が設定されていません'
      : hhmmValidation(editStartTime.value)
  })

  const errorEditEndTime = computed(() => {
    if (!enableTime.value) {
      return undefined
    }

    return editEndTime.value === ''
      ? '終了時刻が設定されていません'
      : hhmmValidation(editEndTime.value)
  })

  const editDateString = computed(() => {
    return dayjs(new Date(editYear.value, editMonth.value, editDay.value)).format('YYYY年M月D日(ddd)')
  })

  const editDate = computed(() => {
    const d = dayjs(new Date(editYear.value, editMonth.value, editDay.value))
    return d.format('YYYY-MM-DD')
  })

  const karteTemplateListItems = computed<ListBoxItemType[]>(() => {
    // 先頭に選択肢なしを追加している
    return [
      {
        name: 'なし',
        value: 'none'
      },
      ...karteTemplates.value
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(temp => {
          return {
            name: temp.title,
            value: temp.id
          }
        })
    ]
  })

  const selectedTemplateItems = computed<KarteResult[]>(() => {
    const template = karteTemplates.value.find(temp => temp.id === selectedTemplateId.value)
    if (!template) {
      return []
    }
    const karteResult: KarteResult[] = []
    template.karteItemIds.forEach(itemId => {
      const karteItem = karteItems.value.find(item => item.id === itemId.karteItemId)
      if (!karteItem) {
        return
      }
      karteResult.push({
        id: uuid(),
        karteItemId: karteItem.id,
        title: karteItem.title,
        kartePartsResult: karteItem.karteParts.map(parts => {
          return {
            id: uuid(),
            partsName: parts.partsName as PartsName,
            title: parts.title,
            options: parts.options
          }
        })
      })
    })
    return karteResult
  })

  watch(
    () => editStartTime.value,
    to => {
      if (to.length === 4 && to >= editEndTime.value) {
        editEndTime.value = addTime(to, appointmentTimeUnit.value)
      }
    },
    { deep: true }
  )

  watch(
    () => editEndTime.value,
    to => {
      if (to.length === 4 && to <= editStartTime.value) {
        editStartTime.value = addTime(to, -appointmentTimeUnit.value)
      }
    },
    { deep: true }
  )

  return {
    appointmentId,
    year,
    month,
    day,
    startTime,
    endTime,
    enableTime,
    editYear,
    editMonth,
    editDay,
    editStartTime,
    errorEditStartTime,
    editEndTime,
    errorEditEndTime,
    selectedTemplateId,
    resetAppointment,
    editDateString,
    editDate,
    karteTemplateListItems,
    selectedTemplateItems
  }
}

export type UseConsultationInfoType = ReturnType<typeof useConsultationInfo>
export const UseConsultationInfoKey: InjectionKey<UseConsultationInfoType> = Symbol('ConsultationInfo')
