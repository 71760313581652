<template>
  <div class="icon-frame">
    <fa-icon
      class="menu-icon"
      icon="cloud"></fa-icon>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated bounceIn"
      leave-active-class="">
      <fa-icon
        v-if="syncStatus==='synced'"
        key="cloud-icon-synced"
        icon="check-circle"
        class="mini-icon mini-icon-synced"
      ></fa-icon>
    </transition>
    <fa-icon
      v-if="syncStatus==='syncing'"
      key="cloud-icon-syncing"
      icon="sync"
      class="mini-icon mini-icon-syncing rotating"
    ></fa-icon>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated bounceIn"
      leave-active-class="">
      <fa-icon
        v-if="syncStatus==='retrying'"
        key="cloud-icon-retrying"
        icon="exclamation-circle"
        class="mini-icon  mini-icon-retrying animate__animated animate__bounceIn"
      ></fa-icon>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'cloud-icon',
  props: {
    syncStatus: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-frame {
  position: relative;
  width: $navbar_height / 2;

  .menu-icon {
    width: $navbar_height / 2;
    height: $navbar_height;
    line-height: $navbar_height;
    color: $font_color;
    transition: 0.3s;
  }

  .mini-icon {
    position: absolute;
    top: $navbar_height / 2;
    left: $navbar_height / 3;
    width: $navbar_height / 3;
  }

  .mini-icon-synced {
    color: $base-color-green;
  }

  .mini-icon-syncing{
    color: $base-color-green;
  }

  .mini-icon-retrying {
    color: $base-color-red-dark;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation-delay: 0s;
  animation: rotating 1s linear infinite;
}

</style>
