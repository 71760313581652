
import {
  defineComponent,
  inject,
  provide,
  onMounted,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseSettingSubscriptionKey, UseSettingSubscriptionType } from '@/composables/setting/useSettingSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useCalendar, UseCalendarKey } from '@/composables/useCalendar'
import { useBusinessHourMyList } from '@/composables/businessHour/useBusinessHourMyList'
import { useMakeCalender } from '@/composables/businessHour/makeCalendar'
import { useBusinessHourMutation } from '@/composables/businessHour/useBusinessHourMutation'
import { formatYMD } from '@/plugins/functions'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import Calendar from '@/components/organisms/calendar.vue'
import HourTag from '@/components/molecules/hourTag.vue'
import DeleteBusinessHour from '@/views/businessHour/calendar/DeleteBusinessHoue.vue'

export default defineComponent({
  name: 'business-hour',
  components: {
    DeleteBusinessHour,
    HourTag,
    BackButton,
    VueTitle,
    VueFrame,
    Calendar
  },
  setup () {
    const router = useRouter()
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const { calendarStartDayNumber } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    const calendar = useCalendar(calendarStartDayNumber)
    const { year, month } = calendar
    provide(UseCalendarKey, calendar)

    const {
      name,
      dayList,
      selectedHour,
      selectHour
    } = useBusinessHourMyList(calendar.year, calendar.month)
    const { businessHourIntervalString } = useMakeCalender()

    const {
      deleteBusinessHour,
      registerTemporaryClose,
      deleteTemporaryClose
    } = useBusinessHourMutation()

    const mouseOverId = ref<string>('')
    const deleteRef = ref<InstanceType<typeof DeleteBusinessHour>>()

    onMounted(() => {
      intoDashboard()
    })

    const onAdd = async ({ day }:{ day:number }) => {
      await router.push(`/w/${clinicId.value}/register_business_hour?y=${year.value}&m=${month.value}&d=${day}`)
    }

    const onDelete = async (dayIndex:number, hourIndex: number) => {
      await selectHour(year.value, month.value, dayIndex, hourIndex)
      deleteRef.value?.open()
    }

    const onDeleteAll = async () => {
      try {
        await deleteBusinessHour(selectedHour.value!.businessHour.id)
        await append('notify', '営業時間を削除しました')
      } catch (e) {
        console.log(e)
        await append('error', '営業時間の削除に失敗しました')
      }
    }

    const onCreateTemporaryClose = async () => {
      try {
        await registerTemporaryClose(selectedHour.value!.businessHour.id, selectedHour.value!.date)
        const formatDate = formatYMD(selectedHour.value!.date)
        await append('notify', `${formatDate}を臨時休業にしました`)
      } catch (e) {
        console.log(e)
        await append('error', '臨時休業への変更に失敗しました')
      }
    }

    const onDeleteTemporaryClose = async () => {
      try {
        await deleteTemporaryClose(selectedHour.value!.temporaryClose!.id)
        const formatDate = formatYMD(selectedHour.value!.date)
        await append('notify', `${formatDate}を営業日に戻しました`)
      } catch (e) {
        console.log(e)
        await append('error', '営業日への変更に失敗しました')
      }
    }

    return {
      year,
      month,
      name,
      dayList,
      mouseOverId,
      deleteRef,
      onAdd,
      onDelete,
      selectedHour,
      businessHourIntervalString,
      onDeleteAll,
      onCreateTemporaryClose,
      onDeleteTemporaryClose,
      formatYMD
    }
  }
})
