
import {
  defineComponent, inject, onMounted, provide
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientTimeForm from '@/views/karte/KarteByAppointment/PatientTimeForm.vue'
import PatientTimeTable from '@/views/karte/KarteByAppointment/PatientTimeTable.vue'
import { usePatientList, UsePatientListKey } from '@/composables/patient/usePatientList'
import {
  useTemporaryPatientList,
  UseTemporaryPatientListKey
} from '@/composables/appointment/useTemporaryPatientList'
import {
  useAccountAppointment,
  UseAccountAppointmentKey
} from '@/composables/appointment/useAccountAppointment'
import { useAppointment, UseAppointmentKey } from '@/composables/appointment/useAppointment'
import { useRouter } from 'vue-router'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import BackButton from '@/components/atoms/button/backButton.vue'

export default defineComponent({
  name: 'karte-select-appointment',
  components: {
    BackButton,
    PatientTimeTable,
    PatientTimeForm,
    VueTwoColumnContent,
    VueContentRemain,
    NormalButton,
    VueButtonFrame,
    VueTitle,
    VueFrame
  },
  setup () {
    const router = useRouter()
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    const patientList = usePatientList()
    const { selectedPatient } = patientList
    provide(UsePatientListKey, patientList)

    const temporaryPatientList = useTemporaryPatientList()
    const { selectedTemporaryPatient } = temporaryPatientList
    provide(UseTemporaryPatientListKey, temporaryPatientList)

    const appointment = useAppointment(selectedPatient)
    const {
      year,
      month,
      day,
      appointmentId,
      startTime,
      endTime
    } = appointment
    provide(UseAppointmentKey, appointment)

    const accountAppointment = useAccountAppointment()
    provide(UseAccountAppointmentKey, accountAppointment)

    onMounted(async () => {
      await accountAppointment.listAccountAppointment()
    })

    const onUpdateTemporaryPatient = async () => {
      await router.push(`/w/${clinicId.value}/temporary_patient_list`)
    }

    const onKarte = async () => {
      const params = {
        y: year.value,
        m: month.value,
        d: day.value,
        ap: appointmentId.value,
        st: startTime.value,
        ed: endTime.value,
        p: selectedPatient.value?.id
      }
      const query = Object.entries(params)
        .map(([k, v]) => {
          return `${k}=${v}`
        })
        .join('&')
      await router.push(`/w/${clinicId.value}/karte_list?${query}`)
    }

    return {
      onUpdateTemporaryPatient,
      onKarte,
      selectedPatient,
      selectedTemporaryPatient
    }
  }
})
