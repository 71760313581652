
import { defineComponent, ref, onMounted, inject, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import VueLoginFrame from '@/components/layout/vueLoginFrame.vue'
import VueLoginForm from '@/components/layout/vueLoginForm.vue'
import Logo from '@/components/atoms/logo.vue'
import SixDigits from '@/components/molecules/SixDigits.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import LinkText from '@/components/atoms/linkText.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { passwordValidation } from '@/plugins/validator'
import { useSubmit } from '@/composables/useSubmit'
import { UseErrorKey, UseErrorType } from '@/composables/useError'

export default defineComponent({
  name: 'confirm-remind',
  components: {
    NormalButton,
    IconEditBox,
    Confirm,
    LinkText,
    PageDescription,
    SixDigits,
    Logo,
    VueLoginForm,
    VueLoginFrame
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { submitted, submit } = useSubmit()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { forgetPasswordSubmit } = inject(UseAuthenticationKey) as UseAuthenticationType

    const sixDigitRef = ref<InstanceType<typeof SixDigits>>()
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const passwordRef = ref<InstanceType<typeof IconEditBox>>()
    const digits = ref<string[]>(['', '', '', '', '', ''])
    const requesting = ref(false)
    const password = ref('')
    const confirmedPassword = ref('')

    const passwordError = computed(() => {
      return passwordValidation(password.value)
    })

    const confirmedPasswordError = computed(() => {
      if (password.value !== confirmedPassword.value) {
        return '確認用のパスワードが異なっています'
      }
      return undefined
    })

    const onEnter = async () => {
      if (!digits.value.every(v => v.length === 1)) {
        return
      }
      passwordRef.value?.focus()
    }

    const onSubmit = async () => {
      submit()
      if (!digits.value.every(v => v.length === 1)) {
        return
      }
      if (passwordError.value !== undefined || confirmedPasswordError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await forgetPasswordSubmit({
          userId: route.query.uid as string,
          verificationCode: digits.value.join(''),
          newPassword: password.value
        })
        confirmRef.value?.open()
      } catch (e) {
        console.log(e)
        await append('error', 'パスワードの再設定に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    const yes = async () => {
      await router.push('/login')
    }

    onMounted(() => {
      sixDigitRef.value?.focus(0)
    })

    return {
      submitted,
      requesting,
      digits,
      password,
      confirmedPassword,
      passwordError,
      confirmedPasswordError,
      onEnter,
      onSubmit,
      yes,
      sixDigitRef,
      confirmRef,
      passwordRef,
      confirmedPasswordRef: ref(null),
      submitRef: ref(null)
    }
  }
})
