import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const toHalf = (value: string):string => {
  return value.split('').map(s => {
    return s.charCodeAt(0) > 0xFEE0 ? String.fromCharCode(s.charCodeAt(0) - 0xFEE0) : s
  }).join('')
}

export const includes = (str: string, searchWord: string):boolean => {
  let index = 0
  return searchWord.split('').every(w => {
    const searched = (str || '').indexOf(w, index)

    if (searched < 0) {
      return false
    }
    index = searched + 1
    return true
  })
}

export const timeRound = (timeStr: string, interval: number):string => {
  let hour = parseInt(timeStr.substr(0, 2))
  let minute = parseInt(timeStr.substr(2, 2))
  while (hour >= 24) {
    hour -= 24
  }
  if (minute >= 60) {
    minute = 59
  }
  const newMinute = minute - (minute % interval)

  return (hour < 10 ? '0' + hour : '' + hour) + (newMinute < 10 ? '0' + newMinute : '' + newMinute)
}

export const addTime = (timeStr: string, value: number):string => {
  let hour = parseInt(timeStr.substr(0, 2))
  let minute = parseInt(timeStr.substr(2, 2)) + value

  if (minute < 0) {
    hour--
    minute = 60 + minute
  } else if (minute >= 60) {
    hour++
    minute = minute - 60
  }
  if (hour < 0) {
    hour = 23
  } else if (hour > 24 || (hour === 24 && minute > 0)) {
    hour = 0
  }

  return (hour < 10 ? '0' + hour : '' + hour) + (minute < 10 ? '0' + minute : '' + minute)
}

export const expandHourRange = (hourRange: number[], startTime: string, endTime: string):number[] => {
  let minHour = hourRange.length === 0 ? 23 : hourRange[0] + 1
  let maxHour = hourRange.length === 0 ? 0 : hourRange[hourRange.length - 1]

  const s = parseInt(startTime.substr(0, 2))
  const e = parseFloat(endTime.substr(0, 2)) + parseFloat(endTime.substr(2, 2)) / 60
  if (minHour > s) {
    minHour = s
  }
  if (maxHour < e) {
    maxHour = e
  }
  minHour = Math.max(minHour - 1, 0)
  maxHour = Math.min(Math.ceil(maxHour), 23)

  const newRange = []
  for (let h = minHour; h <= maxHour; h++) {
    newRange.push(h)
  }

  return newRange
}

export const formatYMD = (date: string):string => {
  return dayjs(date, 'YYYY-MM-DD', true).format('YYYY年M月D日')
}

export const formatYMDD = (date: string):string => {
  dayjs.locale('ja')
  return dayjs(date, 'YYYY-MM-DD', true).format('YYYY年M月D日(ddd)')
}

export const formatHM = (tm: string):string => {
  return tm.substr(0, 2) + ':' + tm.substr(2, 2)
}

export const parseYMD = (date: string): { year:number, month:number, day:number } => {
  const d = dayjs(date, 'YYYY-MM-DD', true)
  return {
    year: d.year(),
    month: d.month(),
    day: d.date()
  }
}

export const parseHM = (tm: string): string => {
  return tm.substr(0, 2) + tm.substr(3, 2)
}

export const Complementary = (color: string):string => {
  const r = parseInt(color.substr(1, 2), 16)
  const g = parseInt(color.substr(3, 2), 16)
  const b = parseInt(color.substr(5, 2), 16)

  const sum = Math.max(r, g, b) + Math.min(r, g, b)

  const newR = sum - r
  const newG = sum - g
  const newB = sum - b

  return `rgb(${newR},${newG},${newB})`
}

export const age = (date: string): number => {
  return dayjs().diff(dayjs(date, 'YYYY-MM-DD', true), 'years')
}
