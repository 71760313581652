<template>
  <div class="circle-mark">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'circle-mark'
}
</script>

<style lang="scss" scoped>
  $circle_size: 52px;

  .circle-mark {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: $circle_size;
    padding: 0 6px;
    border-radius: $circle_size;
    border: 6px solid $base_color_gray;
    box-sizing: border-box;
    color: $base_color_gray;
    font-size: 1.5em;
    font-weight: bold;
  }
</style>
