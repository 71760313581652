
import {
  defineComponent,
  inject,
  PropType,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import DropDownFrame from '@/components/molecules/dropDownFrame.vue'
import DropDownItem from '@/components/atoms/dropDownItem.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import CloudIcon from '@/components/molecules/CloudIcon.vue'
import { SyncStatusType } from '@/composables/karte/types'
import { useLogout } from '@/composables/useLogout'

export default defineComponent({
  name: 'app-header',
  components: {
    CloudIcon,
    DropDownFrame,
    DropDownItem,
    Confirm
  },
  props: {
    syncStatus: {
      type: String as PropType<SyncStatusType>,
      required: true
    }
  },
  setup () {
    const router = useRouter()
    const accountMenuRef = ref<InstanceType<typeof DropDownFrame>>()
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const {
      authenticated
    } = inject(UseAuthenticationKey) as UseAuthenticationType
    const {
      clinicId,
      clinicName
    } = inject(UseClinicKey) as UseClinicType
    const { append } = inject(UseErrorKey) as UseErrorType
    const { dashboard, logout, changeClinic } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType
    const { exitClinicAll, signOutAll } = useLogout()

    const openAccountMenu = () => {
      accountMenuRef.value?.open()
    }

    const onAccount = async () => {
      accountMenuRef.value?.dismiss()
      await router.push('/account')
    }

    const onExitClinic = async () => {
      accountMenuRef.value?.dismiss()
      try {
        await lockScreen()
        await exitClinicAll()
        await changeClinic()
      } catch (e) {
        console.log(e)
        await append('error', '施設を変更できませんでした')
      } finally {
        await unlockScreen()
      }
    }

    const onLogout = () => {
      accountMenuRef.value?.dismiss()
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        await lockScreen()
        await signOutAll()
        await logout()
      } catch (e) {
        console.log(e)
      } finally {
        await unlockScreen()
      }
    }

    return {
      authenticated,
      clinicName,
      clinicId,
      dashboard,
      openAccountMenu,
      onAccount,
      onExitClinic,
      onLogout,
      yes,
      accountMenuRef,
      confirmRef
    }
  }
})
