import { InjectionKey, ref } from 'vue'
import { CreatePatientInput, Display, Gender, SNSType } from '@/API'
import { base64Decode, stringToCsv, validateHeader } from '@/plugins/csvUtils'
import { emailValidation, numberValidation, yyyymmddValidation, zipCodeValidation } from '@/plugins/validator'
import { toHalf } from '@/plugins/functions'
import { DropAreaFileType } from '@/composables/types'

export const useBulkPatient = () => {
  const patients = ref<CreatePatientInput[]>([])
  const errors = ref<string[]>([])

  const headers = [
    'お名前',
    'よみがな',
    '生年月日(YYYYMMDD 8桁)',
    '性別(男性または女性)',
    '郵便番号',
    '都道府県',
    '市区町村',
    '町名以降',
    '電話番号1',
    '電話番号2',
    '電話番号3',
    'email',
    'コメント'
  ]

  const parseCsv = async (dropFileType: DropAreaFileType) => {
    const decoded = base64Decode(dropFileType.base64)
    const csv = stringToCsv(decoded)
    if (!validateHeader(csv, headers)) {
      throw new Error('ファイルの1行目が患者登録csvテンプレートと異なります')
    }

    patients.value = []
    errors.value = []
    csv.data.forEach((data, i) => {
      if (data.length !== headers.length) {
        errors.value.push(`${i + 2}行目の項目数が合っていません`)
      } else {
        const { patient, err } = _csvToPatient(i + 2, data)
        if (err.length > 0) {
          errors.value.push(...err)
        } else {
          patients.value.push(patient!)
        }
      }
    })
    if (errors.value.length > 0) {
      throw new Error('csvファイルエラー')
    }
  }

  const _csvToPatient = (line: number, data: string[]): {
    patient?: CreatePatientInput,
    err: string[]
  } => {
    const err: string[] = []

    if (data[0] === '') {
      err.push(`${line}行目のお名前が入力されていません`)
    }
    if (data[1] === '') {
      err.push(`${line}行目のよみがなが入力されていません`)
    }

    let birthDate = ''
    const birthDateValid = yyyymmddValidation(data[2])
    if (birthDateValid !== undefined) {
      err.push(`${line}行目の生年月日：${birthDateValid}`)
    } else {
      const d = toHalf(data[2])
      birthDate = `${d.substr(0, 4)}-${d.substr(4, 2)}-${d.substr(6, 2)}`
    }

    let gender = 'other'
    if (data[3].includes('男')) {
      gender = 'male'
    } else if (data[3].includes('女')) {
      gender = 'female'
    }

    const zipValidation = zipCodeValidation(data[4])
    if (zipValidation !== undefined) {
      err.push(`${line}行目の郵便番号：${zipValidation}`)
    }

    if (data[5] === '') {
      err.push(`${line}行目の都道府県が入力されていません`)
    }
    if (data[6] === '') {
      err.push(`${line}行目の市区町村が入力されていません`)
    }

    const phoneNumber: string[] = []
    for (let i = 8; i <= 10; i++) {
      if (data[i] === '') {
        continue
      }
      if (numberValidation(data[i]) !== undefined) {
        err.push(`${line}行目の電話番号${i - 7}が数字ではありません`)
      } else {
        phoneNumber.push(toHalf(data[i]))
      }
    }

    if (data[11] !== '') {
      if (emailValidation(data[11]) !== undefined) {
        err.push(`${line}行目のemailが不正です`)
      }
    }

    return {
      patient: err.length === 0
        ? {
          patientNumber: '',
          name: data[0],
          read: data[1],
          birthDate,
          gender: gender as Gender,
          private: {
            zipCode: data[4],
            pref: data[5],
            city: data[6],
            address: data[7],
            phoneNumber,
            sns: data[11] !== ''
              ? [{
                snsType: SNSType.email,
                accountId: data[11],
                access: false
              }]
              : []
          },
          comment: data[12],
          display: Display.show
        }
        : undefined,
      err
    }
  }

  return {
    patients,
    errors,
    parseCsv
  }
}

export type UseBulkPatientType = ReturnType<typeof useBulkPatient>
export const UseBulkPatientKey: InjectionKey<UseBulkPatientType> = Symbol('BulkPatient')
