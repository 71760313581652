import { Auth } from 'aws-amplify'
import { NavigationGuard } from 'vue-router'
import { useAuthentication } from '@/composables/useAuthentication'
import { useClinic } from '@/composables/useClinic'

const isSessionValid = async () => {
  try {
    const session = await Auth.currentSession()
    return session.isValid()
  } catch (e) {
    return false
  }
}

export const guardLogin:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const valid = await isSessionValid()
  if (authenticated.value && !valid) {
    next('/timeout')
    return
  } else if (authenticated.value && valid) {
    next('/dashboard')
    return
  }
  next()
}

export const guardDashboard:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value !== '') {
      next(`/w/${clinicId.value}`)
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardWorkSpace:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardPatientWrite:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, patientWrite } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!patientWrite) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardPatientRead:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, patientWrite, patientRead } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!patientWrite && !patientRead) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardCalendarWrite:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, calendarWrite } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!calendarWrite) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardCalendarRead:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, calendarWrite, calendarRead } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!calendarWrite && !calendarRead) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardAcceptAppointment:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, acceptAppointment } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!acceptAppointment) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardDataWrite:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, dataWrite } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!dataWrite) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardAccountWrite:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, clinicOwner, accountWrite } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!clinicOwner && !accountWrite) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardClinicOwner:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const { clinicId, clinicOwner } = useClinic([])
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    if (clinicId.value === '') {
      next('/dashboard')
      return
    }
    if (!clinicOwner) {
      next('/dashboard')
      return
    }
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}

export const guardNothing:NavigationGuard = async (to, from, next) => {
  const { authenticated } = useAuthentication()
  const valid = await isSessionValid()
  if (authenticated.value && valid) {
    next()
    return
  } else if (authenticated.value && !valid) {
    next('/timeout')
  }
  next('/login')
}
