// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Permission = {
  "CLINIC_OWNER": "clinicOwner",
  "ACCOUNT_WRITE": "accountWrite",
  "PATIENT_WRITE": "patientWrite",
  "PATIENT_READ": "patientRead",
  "CALENDAR_WRITE": "calendarWrite",
  "CALENDAR_READ": "calendarRead",
  "DATA_WRITE": "dataWrite",
  "DATA_READ": "dataRead",
  "SETTING_WRITE": "settingWrite"
};

const AnswerStatus = {
  "UNPROCESSED": "unprocessed",
  "REGISTERED": "registered",
  "TO_BE_DELETED": "toBeDeleted"
};

const Gender = {
  "MALE": "male",
  "FEMALE": "female",
  "OTHER": "other"
};

const SnsType = {
  "EMAIL": "email",
  "LINE": "line",
  "FACEBOOK": "facebook"
};

const Display = {
  "SHOW": "show",
  "HIDDEN": "hidden"
};

const IntervalType = {
  "DAY": "day",
  "WEEK": "week",
  "MONTH": "month",
  "YEAR": "year",
  "NTH_WEEK_DAY": "nthWeekDay",
  "ONCE": "once"
};

const AppointmentStatus = {
  "REGISTERED": "registered",
  "CANCELED": "canceled",
  "NO_SHOW": "noShow",
  "CONSULTED": "consulted"
};

const PartsName = {
  "TIME_RANGE": "timeRange",
  "SINGLE_LINE": "singleLine",
  "MULTI_LINE": "multiLine",
  "IMAGES": "images",
  "IMAGE_AND_TEXT": "imageAndText",
  "ATTACH_FILES": "attachFiles",
  "SINGLE_CHOICE": "singleChoice",
  "MULTI_CHOICE": "multiChoice",
  "ACU": "acu"
};

const Direction = {
  "FRONT": "front",
  "LEFT": "left",
  "BACK": "back",
  "RIGHT": "right"
};

const BodyParts = {
  "HEAD": "head",
  "BODY": "body",
  "BACK": "back",
  "RIGHT_UPPER_ARM": "rightUpperArm",
  "RIGHT_LOWER_ARM": "rightLowerArm",
  "LEFT_UPPER_ARM": "leftUpperArm",
  "LEFT_LOWER_ARM": "leftLowerArm",
  "RIGHT_UPPER_LEG": "rightUpperLeg",
  "RIGHT_LOWER_LEG": "rightLowerLeg",
  "LEFT_UPPER_LEG": "leftUpperLeg",
  "LEFT_LOWER_LEG": "leftLowerLeg"
};

const InquiryType = {
  "TEXT": "text",
  "NUMBER": "number",
  "NUMBER_ARRAY": "numberArray",
  "DATE": "date",
  "TIME": "time",
  "SINGLE_CHOICE": "singleChoice",
  "MULTI_CHOICE": "multiChoice"
};

const WeekDayShort = {
  "SUN": "sun",
  "MON": "mon",
  "TUE": "tue",
  "WED": "wed",
  "THU": "thu",
  "FRI": "fri",
  "SAT": "sat"
};

const { Patient, TemporaryPatient, PatientNumber, BusinessHour, TemporaryClose, Appointment, KarteItem, AcuItem, KarteTemplate, Karte, KarteNumber, FavoriteKarte, InquiryItem, Setting, FileHash, ClinicListData, ListAccountResult, AccountClinic, ListAccountInvitationResult, AccountInvitation, ListAccountAppointmentResult, AccountAppointment, ListInquiryAnswerResult, InquiryAnswer, Holiday, Zip, Clinic, DownloadFile, PatientPrivate, SNS, KarteParts, AcuParts, KarteItemId, KarteResult, KartePartsResult, KarteFileType, InquiryPage, InquiryPageItem } = initSchema(schema);

export {
  Patient,
  TemporaryPatient,
  PatientNumber,
  BusinessHour,
  TemporaryClose,
  Appointment,
  KarteItem,
  AcuItem,
  KarteTemplate,
  Karte,
  KarteNumber,
  FavoriteKarte,
  InquiryItem,
  Setting,
  FileHash,
  Permission,
  AnswerStatus,
  Gender,
  SnsType,
  Display,
  IntervalType,
  AppointmentStatus,
  PartsName,
  Direction,
  BodyParts,
  InquiryType,
  WeekDayShort,
  ClinicListData,
  ListAccountResult,
  AccountClinic,
  ListAccountInvitationResult,
  AccountInvitation,
  ListAccountAppointmentResult,
  AccountAppointment,
  ListInquiryAnswerResult,
  InquiryAnswer,
  Holiday,
  Zip,
  Clinic,
  DownloadFile,
  PatientPrivate,
  SNS,
  KarteParts,
  AcuParts,
  KarteItemId,
  KarteResult,
  KartePartsResult,
  KarteFileType,
  InquiryPage,
  InquiryPageItem
};