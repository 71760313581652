
import { computed, defineComponent, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import VueLoginFrame from '@/components/layout/vueLoginFrame.vue'
import VueLoginForm from '@/components/layout/vueLoginForm.vue'
import Logo from '@/components/atoms/logo.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import LinkText from '@/components/atoms/linkText.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { useSubmit } from '@/composables/useSubmit'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { emailValidation } from '@/plugins/validator'

export default defineComponent({
  name: 'PasswordReminder',
  components: {
    NormalButton,
    LinkText,
    IconEditBox,
    PageDescription,
    VueLoginFrame,
    VueLoginForm,
    Logo
  },
  setup () {
    const router = useRouter()
    const { submitted, submit } = useSubmit()
    const { forgetPassword } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { append } = inject(UseErrorKey) as UseErrorType

    const requesting = ref(false)
    const userId = ref('')

    const userIdError = computed(() => {
      return emailValidation(userId.value)
    })

    const onSubmit = async () => {
      submit()
      if (userIdError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await forgetPassword({ userId: userId.value })
        const encodeUserId = encodeURIComponent(userId.value)
        await router.push(`/confirmRemind?uid=${encodeUserId}`)
      } catch (e) {
        console.log(e)
        await append('error', 'パスワード再設定のメール送信に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      submitted,
      requesting,
      userId,
      userIdError,
      onSubmit,
      userIdRef: ref(null)
    }
  }
})
