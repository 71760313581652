<template>
  <div class="patient-text-frame">
    <div
      class="patient-text-name"
      :class="{'temporary': temporaryPatient}">{{ patientName }}
    </div>
    <div
      class="patient-text-gender"
      :class="patientGender">{{ genderText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'patient-name-text',
  components: {},
  props: {
    patientName: String,
    patientGender: String,
    temporaryPatient: Boolean
  },
  computed: {
    genderText () {
      const genderString = {
        male: '男',
        female: '女',
        other: '?'
      }
      return genderString[this.patientGender]
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-text-frame {
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 2px;
  height: 28px;
  line-height: 28px;
  font-size: 0.8em;

  .patient-text-name {
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;

    &.temporary {
      background-color: $base_color_yellow_light;
      border-radius: $border_radius;
    }
  }

  .patient-text-gender {
    &.male {
      color: $base_color_blue;
    }

    &.female {
      color: $base_color_red_dark;
    }

    &.other {
      color: $base_color_black;
    }
  }
}
</style>
