import { computed, inject, InjectionKey, ref } from 'vue'
import { API, graphqlOperation } from 'aws-amplify'
import { listKarteByOrder } from '@/graphql/queries'
import { ModelSortDirection } from '@/API'
import { FetchKarteNumber } from '@/config'
import { DateKarte, SimpleKarteByOrder } from '@/composables/karte/types'
import {
  UseKarteSubscriptionKey,
  UseKarteSubscriptionType
} from '@/composables/karte/useKarteSubscription'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'

interface ListSimpleKarteByOrderResult {
  data?: {
    listKarteByOrder: {
      items: SimpleKarteByOrder[]
      nextToken: string|null
    }
  }
}

// useKarteSubscription.tsでは過去２カ月のデータのみを扱っているが、
// 患者画面ではそれよりも古いカルテの取得が必要なので、それらを以下でサポートする
export const useOldPatientKarte = () => {
  const route = useRoute()
  const patientId = ref<string>(route.query.p as string || '')
  const kartes = ref<SimpleKarteByOrder[]>([])
  const nextToken = ref<string|null>(null)
  const noMoreData = ref<boolean>(false)

  const { lastMonth } = inject(UseKarteSubscriptionKey) as UseKarteSubscriptionType

  const fetchOldKarte = async () => {
    if (noMoreData.value) {
      return true
    }

    try {
      const variables = {
        patientId: patientId.value,
        sortDirection: ModelSortDirection.DESC,
        limit: FetchKarteNumber,
        filter: {
          karteDate: { le: lastMonth.value }
        }
      }
      const res = await API.graphql(graphqlOperation(listKarteByOrder, variables)) as ListSimpleKarteByOrderResult
      if (res.data?.listKarteByOrder && res.data!.listKarteByOrder.items.length > 0) {
        kartes.value = [
          ...kartes.value,
          ...res.data!.listKarteByOrder.items
        ]
      }
      nextToken.value = res.data!.listKarteByOrder.nextToken
      if (nextToken.value === null) {
        noMoreData.value = true
      }
      return noMoreData.value
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const dateKarteMap = computed(() => {
    const newMap: DateKarte = {}

    kartes.value.forEach(k => {
      const karteDate = k.karteDate

      if (!Object.prototype.hasOwnProperty.call(newMap, karteDate)) {
        newMap[karteDate] = {
          kartes: [],
          date: karteDate,
          dateString: dayjs(karteDate, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
        }
      }
      newMap[karteDate].kartes.push(k)
    })

    Object.keys(newMap).forEach(karteDate => {
      newMap[karteDate].kartes.sort((a, b) => a.displayOrder - b.displayOrder)
    })

    return newMap
  })

  return {
    dateKarteMap,
    fetchOldKarte
  }
}

export type UseOldPatientKarteType = ReturnType<typeof useOldPatientKarte>
export const UseOldPatientKarteKey: InjectionKey<UseOldPatientKarteType> = Symbol('OldPatientKarte')
