<template>
  <div class="key-value-frame">
    <div class="key-value-title">
      <slot></slot>
    </div>
    <div
      class="key-value-item-frame"
      :class="$mq"
      v-for="(item, index) in items"
      :key="index">
      <div class="key-value-key">{{ item.key }} :</div>
      <div class="key-value-value" :class="wide ? 'key-value-value-wide' : ''">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'key-value-frame',
  props: {
    items: {
      type: Array,
      required: true
    },
    wide: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.key-value-frame {
  display: flex;
  flex-direction: column;

  .key-value-title {
    margin-bottom: 5px;
  }

  .key-value-item-frame {
    display: flex;
    flex-direction: row;
    min-height: $form_item_height_small;

    .key-value-key {
      flex: 2;
      text-align: right;
      padding-right: 20px;
    }

    .key-value-value {
      flex: 3;
    }

    &.mobile {
      font-size: 0.9em;

      .key-value-key {
        flex: 1;
      }

      .key-value-value {
        flex: 1;
      }

      .key-value-value-wide {
        flex: 2;
      }
    }
  }
}
</style>
