
import {
  defineComponent,
  inject,
  ref,
  onMounted
} from 'vue'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { today } from '@/plugins/computed'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'

export default defineComponent({
  name: 'dashboard',
  components: {
    VueTitle,
    VueFrame,
    NormalButton,
    Confirm
  },
  setup () {
    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const { signOutAuth } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { signOutClinic } = inject(UseClinicKey) as UseClinicType
    const { intoDashboard, logout } = inject(RouterViewTransitionKey) as RouterViewTransitionType

    onMounted(() => {
      intoDashboard()
    })

    const onLogout = () => {
      confirmRef.value?.open()
    }
    const yes = async () => {
      try {
        await signOutAuth()
        await signOutClinic()
        await logout()
      } catch (e) {
        console.log(e)
      }
    }

    return {
      today,
      onLogout,
      yes,
      confirmRef
    }
  }
})
