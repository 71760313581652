<template>
  <div
    class="vue-flex-row"
    :class="['toggle-switch', disabled ? 'toggle-switch-disable' : '', $mq]">
    <fa-icon
      class="toggle-switch-icon"
      v-if="checked"
      icon="check-circle"/>
    <fa-icon
      class="toggle-switch-icon"
      v-else
      :icon="['far', 'circle']"/>
    <span class="toggle-title">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'radio-button',
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-switch {
  cursor: pointer;
  padding: 2px 5px 5px 0;
  border-radius: $border_radius;

  .toggle-switch-icon {
    color: $base_color_water_dark;
    font-size: 1.4em;
    padding: 0 10px;
    position: relative;
    user-select: none;
  }

  .toggle-title {
    position: relative;
    user-select: none;
  }

  &.mobile {
    cursor: default;
    padding: 3px 2px;

    .toggle-switch-icon {
      padding: 0 4px 0 0;
      top: 0.1em;
    }
  }
}

.toggle-switch-disable {
  cursor: default;

  &:hover {
    background: transparent;
  }

  + span {
    color: $base_color_gray_dark;
  }

  .toggle-switch-icon {
    color: $base_color_gray_dark;
  }
}
</style>
