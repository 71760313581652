
import {
  defineComponent,
  provide
} from 'vue'
import AppHeader from '@/components/organisms/AppHeader.vue'
import Alert from '@/components/organisms/Alert.vue'
import AppFooter from '@/components/organisms/AppFooter.vue'
import ScreenLock from '@/components/molecules/ScreenLock.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import Loading from '@/components/atoms/Loading.vue'
import { useRouterViewTransition, RouterViewTransitionKey } from '@/composables/useRouterViewTransition'
import { useAuthentication, UseAuthenticationKey } from '@/composables/useAuthentication'
import { ClinicSubscription, useClinic, UseClinicKey } from '@/composables/useClinic'
import {
  useInquiryAnswerSubscription,
  UseInquiryAnswerSubscriptionKey
} from '@/composables/inquieryAnswer/useInquiryAnswerSubscription'
import {
  usePatientSubscription,
  UsePatientSubscriptionKey
} from '@/composables/patient/usePatientSubscription'
import {
  useBusinessHourSubscription,
  UseBusinessHourSubscriptionKey
} from '@/composables/businessHour/useBusinessHourSubscription'
import {
  useTemporaryCloseSubscription,
  UseTemporaryCloseSubscriptionKey
} from '@/composables/businessHour/useTemporaryCloseSubscription'
import {
  useAppointmentSubscription,
  UseAppointmentSubscriptionKey
} from '@/composables/appointment/useAppointmentSubsucription'
import {
  useTemporaryPatientSubscription,
  UseTemporaryPatientSubscriptionKey
} from '@/composables/appointment/useTemporaryPatientSubscription'
import {
  useKarteItemSubscription,
  UseKarteItemSubscriptionKey
} from '@/composables/karteData/useKarteItemSubscription'
import {
  useAcuItemSubscription,
  UseAcuItemSubscriptionKey
} from '@/composables/karteData/useAcuItemSubscription'
import {
  useSettingSubscription,
  UseSettingSubscriptionKey
} from '@/composables/setting/useSettingSubscription'
import { useHoliday, UseHolidayKey } from '@/composables/useHoliday'
import { useError, UseErrorKey } from '@/composables/useError'
import { useLockScreen, UseLockScreenKey } from '@/composables/useLockScreen'
import {
  useKarteTemplateSubscription,
  UseKarteTemplateSubscriptionKey
} from '@/composables/karteData/useKarteTemplateSubscription'
import { useKarteSubscription, UseKarteSubscriptionKey } from '@/composables/karte/useKarteSubscription'
import { useFileDB, UseFileDBKey } from '@/composables/karte/useFileDB'
import {
  useFavoriteKarteSubscription,
  UseFavoriteKarteSubscriptionKey
} from '@/composables/karte/useFavoriteKarteSubscription'
import {
  useInquiryItemSubscription,
  UseInquiryItemSubscriptionKey
} from '@/composables/karteData/useInquiryItemSubscription'

export default defineComponent({
  name: 'app',
  components: {
    Loading,
    LoadingMessage,
    ScreenLock,
    AppFooter,
    AppHeader,
    Alert
  },
  setup () {
    const auth = useAuthentication()
    provide(UseAuthenticationKey, auth)

    const routeTransition = useRouterViewTransition()
    provide(RouterViewTransitionKey, routeTransition)

    const error = useError()
    const { append } = error
    provide(UseErrorKey, error)

    const lockScreen = useLockScreen()
    const { lock } = lockScreen
    provide(UseLockScreenKey, lockScreen)

    const answer = useInquiryAnswerSubscription()
    provide(UseInquiryAnswerSubscriptionKey, answer)

    const patient = usePatientSubscription()
    provide(UsePatientSubscriptionKey, patient)

    const businessHour = useBusinessHourSubscription()
    provide(UseBusinessHourSubscriptionKey, businessHour)

    const temporaryClose = useTemporaryCloseSubscription()
    provide(UseTemporaryCloseSubscriptionKey, temporaryClose)

    const temporaryPatient = useTemporaryPatientSubscription()
    provide(UseTemporaryPatientSubscriptionKey, temporaryPatient)

    const appointment = useAppointmentSubscription({
      getPatient: patient.getPatient,
      getTemporaryPatient: temporaryPatient.getTemporaryPatient
    })
    provide(UseAppointmentSubscriptionKey, appointment)

    const karte = useKarteSubscription()
    provide(UseKarteSubscriptionKey, karte)

    const favorite = useFavoriteKarteSubscription(auth.id)
    provide(UseFavoriteKarteSubscriptionKey, favorite)

    const karteItem = useKarteItemSubscription()
    provide(UseKarteItemSubscriptionKey, karteItem)

    const acuItem = useAcuItemSubscription()
    provide(UseAcuItemSubscriptionKey, acuItem)

    const karteTemplate = useKarteTemplateSubscription()
    provide(UseKarteTemplateSubscriptionKey, karteTemplate)

    const InquiryItem = useInquiryItemSubscription()
    provide(UseInquiryItemSubscriptionKey, InquiryItem)

    const setting = useSettingSubscription()
    provide(UseSettingSubscriptionKey, setting)

    const holiday = useHoliday()
    provide(UseHolidayKey, holiday)

    const clinicSubscriptions: ClinicSubscription[] = [
      { subscription: answer.subscribe },
      { subscription: patient.subscribe },
      { subscription: businessHour.subscribe },
      { subscription: temporaryClose.subscribe },
      { subscription: appointment.subscribe, syncExpression: appointment.expression },
      { subscription: temporaryPatient.subscribe },
      { subscription: karte.subscribe, syncExpression: karte.expression },
      { subscription: favorite.subscribe, syncExpression: favorite.expression },
      { subscription: karteItem.subscribe },
      { subscription: acuItem.subscribe },
      { subscription: karteTemplate.subscribe },
      { subscription: InquiryItem.subscribe },
      { subscription: setting.subscribe },
      { subscription: holiday.subscribe }
    ]

    const fileDB = useFileDB(append)
    const {
      initializeDatabase,
      syncStatus
    } = fileDB
    provide(UseFileDBKey, fileDB)

    const clinic = useClinic(
      clinicSubscriptions,
      initializeDatabase
    )
    const { entering } = clinic
    provide(UseClinicKey, clinic)

    return {
      transition: routeTransition.transition,
      entering,
      lock,
      syncStatus
    }
  }
})
