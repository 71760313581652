import { computed, InjectionKey, ref } from 'vue'
import { TemporaryPatient as TemporaryPatientModel } from '@/models'
import { DataStore } from 'aws-amplify'
import { KeyValue } from '@/composables/commonTypes'
import { GenderKey, genderString } from '@/composables/patient/types'

export interface TemporaryPatientInfo {
  temporary: TemporaryPatientModel
  info: KeyValue[]
}

export const useTemporaryPatientSubscription = () => {
  const temporaryPatients = ref<TemporaryPatientModel[]>([])
  const subscribing = ref(false)

  const fetchTemporaryPatient = async () => {
    try {
      temporaryPatients.value = await DataStore.query(TemporaryPatientModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe patient without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('patient already subscribing'))
    }

    try {
      await fetchTemporaryPatient()
      const subscription = DataStore.observe(TemporaryPatientModel).subscribe(async () => {
        await fetchTemporaryPatient()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const getTemporaryPatient = (id: string) => {
    return temporaryPatients.value.find(p => p.id === id)
  }

  const temporaryPatientInfos = computed<TemporaryPatientInfo[]>(() => {
    return temporaryPatients.value.map(temp => {
      return {
        temporary: temp,
        info: [
          { key: '名前', value: temp.name },
          { key: '性別', value: genderString(temp.gender as GenderKey) },
          { key: '電話番号', value: temp.phoneNumber || '' }
        ]
      }
    })
  })

  return {
    temporaryPatients,
    fetchTemporaryPatient,
    subscribe,
    getTemporaryPatient,
    temporaryPatientInfos
  }
}

export type UseTemporaryPatientSubscriptionType = ReturnType<typeof useTemporaryPatientSubscription>
export const UseTemporaryPatientSubscriptionKey: InjectionKey<UseTemporaryPatientSubscriptionType> = Symbol('TemporaryPatientSubscription')
