
import { computed, defineComponent, inject } from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import { UseKartePatientKey, UseKartePatientType } from '@/composables/karte/useKartePatient'
import { UseConsultationListKey, UseConsultationListType } from '@/composables/karte/useConsultationList'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'patient-info',
  components: { VueContent },
  setup () {
    const {
      patientInfo,
      phoneNumber,
      firstDateString
    } = inject(UseKartePatientKey) as UseKartePatientType
    const { consultationRows } = inject(UseConsultationListKey) as UseConsultationListType

    // 初回診察日はローカルのDataStoreに含めれていない可能性の方が高いのでusePatientInfoでfetchして取得している。
    // 最終診察日はローカルのDataStoreの最新の日付になるので、useConsultationListで整形した表示用一覧の最後の日付を以下で計差している
    const lastDateString = computed(() => {
      if (consultationRows.value.length === 0) {
        return '???'
      }
      return dayjs(consultationRows.value[consultationRows.value.length - 1].id, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
    })

    return {
      patientInfo,
      phoneNumber,
      firstDateString,
      lastDateString
    }
  }
})
