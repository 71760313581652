
import { defineComponent, watchEffect } from 'vue'
import { useArrayValue } from '@/composables/useArrayValue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ValidateEditBox from '@/components/molecules/editbox/validateEditBox.vue'
import IconButtonLarge from '@/components/atoms/button/iconButtonLarge.vue'

export default defineComponent({
  name: 'number-list-inquiry',
  components: {
    IconButtonLarge,
    ValidateEditBox,
    NormalButton
  },
  props: {
    title: String,
    placeholder: String,
    keyValue: String,
    required: Boolean,
    modelValue: String,
    validation: Array
  },
  setup (props, content) {
    const {
      values,
      addValue,
      deleteValue
    } = useArrayValue()

    values.value = JSON.parse(props.modelValue!)

    watchEffect(() => {
      content.emit('update:modelValue', JSON.stringify(values.value))
    })

    return {
      values,
      addValue,
      deleteValue
    }
  }
})
