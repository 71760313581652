import { ref, InjectionKey } from 'vue'
import { ClinicListData } from '@/API'
import { API, graphqlOperation } from 'aws-amplify'
import { listAccountClinics } from '@/graphql/queries'
import { FetchStatus } from '@/composables/commonTypes'

interface ListResult {
  data?: {
    listAccountClinics: ClinicListData[]
  }
}

export const useClinicAccount = () => {
  const fetchStatus = ref<FetchStatus>(FetchStatus.BeforeFetch)
  const clinicList = ref<ClinicListData[]>([])

  const fetchClinicList = async (): Promise<boolean> => {
    try {
      fetchStatus.value = FetchStatus.Fetching
      const res = await API.graphql(graphqlOperation(listAccountClinics)) as ListResult
      clinicList.value = res.data?.listAccountClinics || []
      fetchStatus.value = FetchStatus.Fetched
      return true
    } catch (e) {
      fetchStatus.value = FetchStatus.Failed
      return Promise.reject(e)
    }
  }

  return {
    fetchStatus,
    clinicList,
    fetchClinicList
  }
}

export type UseClinicAccountType = ReturnType<typeof useClinicAccount>
export const UseClinicAccountKey: InjectionKey<UseClinicAccountType> = Symbol('ClinicAccount')
