import { computed, inject, InjectionKey, onMounted, Ref } from 'vue'
import dayjs from 'dayjs'
import {
  UseAppointmentSubscriptionKey,
  UseAppointmentSubscriptionType
} from '@/composables/appointment/useAppointmentSubsucription'
import {
  UseBusinessHourSubscriptionKey,
  UseBusinessHourSubscriptionType
} from '@/composables/businessHour/useBusinessHourSubscription'
import { useMakeCalender } from '@/composables/businessHour/makeCalendar'
import { useAccountAppointment } from '@/composables/appointment/useAccountAppointment'
import { Day } from '@/composables/businessHour/types'
import { CalenderAppointment } from '@/composables/appointment/types'

export const useAppointmentList = (year: Ref<number>, month: Ref<number>) => {
  const { appointmentMap } = inject(UseAppointmentSubscriptionKey) as UseAppointmentSubscriptionType
  const { businessHourMap } = inject(UseBusinessHourSubscriptionKey) as UseBusinessHourSubscriptionType
  const { makeCalendar } = useMakeCalender()

  const {
    accountAppointmentList,
    listAccountAppointment
  } = useAccountAppointment()

  onMounted(async () => {
    await listAccountAppointment()
  })

  const appointmentList = computed(() => {
    const y = `${year.value}`
    const m = ('0' + (month.value + 1)).slice(-2)
    const maxDays = dayjs(new Date(year.value, month.value, 1)).daysInMonth()
    const monthAppointment = appointmentMap.value[y]
      ? appointmentMap.value[y][m]
        ? appointmentMap.value[y][m]
        : {}
      : {}

    const accountCalenderMap: { [key:string]: Day[] } = {}
    Object.keys(businessHourMap.value).forEach(accountId => {
      accountCalenderMap[accountId] = makeCalendar(year.value, month.value, businessHourMap.value[accountId])
    })

    const list: CalenderAppointment[] = []
    for (let d = 1; d <= maxDays; d++) {
      const dd = ('0' + d).slice(-2)
      const dayAppointment: CalenderAppointment = {
        day: d,
        accountList: []
      }

      accountAppointmentList.value.forEach(account => {
        if (
          (accountCalenderMap[account.userId] && accountCalenderMap[account.userId].find(cal => cal.day === d && !cal.closeDay)) ||
          (monthAppointment[dd] && Object.prototype.hasOwnProperty.call(monthAppointment[dd], account.userId))
        ) {
          dayAppointment.accountList.push({
            accountId: account.userId,
            accountName: account.name,
            appointmentList: monthAppointment[dd] && monthAppointment[dd][account.userId]
              ? monthAppointment[dd][account.userId]
              : []
          })
        }
      })

      if (dayAppointment.accountList.length > 0) {
        list.push(dayAppointment)
      }
    }
    return list
  })

  return {
    appointmentList
  }
}

export type UseAppointmentListType = ReturnType<typeof useAppointmentList>
export const UseAppointmentListKey: InjectionKey<UseAppointmentListType> = Symbol('AppointmentList')
