<template>
  <div class="content">
    <div class="inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue-content-remain'
}
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  flex-grow: 1;

  .inner {
    overflow: hidden;
    height: 100%;
  }
}
</style>
