<template>
  <div class="text-column">
    <div
      class="text"
      v-for="(char, index) in chars"
      :key="columnId+'-'+index">
      <span
        class="span-text"
        v-for="(c, i) in char"
        :key="columnId+'-'+index+'-'+i"
        :class="c.emphasized ? 'text-emphasized' : ''">{{ c.char }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-emphasized-text-multiline',
  props: {
    values: Array,
    searchWord: String,
    columnId: String
  },
  computed: {
    chars () {
      if (this.searchWord.length === 0) {
        return this.values.map(value => {
          return [
            {
              char: value,
              emphasized: false
            }
          ]
        })
      }

      let index
      const searchChars = this.searchWord.split('')
      return this.values.map(value => {
        index = 0
        return (value || '').split('').map(c => {
          if (index >= searchChars.length) {
            return {
              char: c,
              emphasized: false
            }
          }

          if (c === searchChars[index]) {
            index++
            return {
              char: c,
              emphasized: true
            }
          }
          return {
            char: c,
            emphasized: false
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-column {
  display: flex;
  flex-direction: column;
}

.text {
  word-break: break-all;
  overflow: auto;
  width: 100%;
}

.span-text {
  display: inline-block;
}

.text-emphasized {
  color: $base_color_blue_dark;
  font-weight: bold;
}
</style>
