
import { defineComponent, PropType } from 'vue'
import DigitEditbox from '@/components/atoms/DigitEditbox.vue'

export default defineComponent({
  name: 'six-digits',
  components: {
    DigitEditbox
  },
  props: {
    modelValue: Array as PropType<string[]>
  },
  setup (props, context) {
    const focus = (index: number) => {
      // eslint-disable-next-line no-undef
      const digitElements = document.getElementsByClassName('digit') as HTMLCollectionOf<HTMLInputElement>
      digitElements[index].focus()
    }

    const onInput = (index: number, value: string) => {
      if (!props.modelValue) {
        return
      }

      const newValue = [...props.modelValue]
      newValue.splice(index, 1, value)
      context.emit('update:modelValue', newValue)

      if (value.length === 1 && index < 5) {
        focus(index + 1)
      } else if (value.length === 0 && index > 0) {
        focus(index - 1)
      }

      if (newValue.every(m => m.length === 1)) {
        context.emit('enter')
      }
    }

    const onEnter = () => {
      // 全ての数字が入力されていた時だけenterキーのイベントを発生させる
      if (props.modelValue?.every(m => m.length === 1)) {
        context.emit('enter')
      }
    }

    return {
      focus,
      onInput,
      onEnter
    }
  }
})
