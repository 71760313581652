
import { defineComponent, inject } from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import RadioButtonCard from '@/components/molecules/radioButtonCard.vue'
import KeyValueFrame from '@/components/molecules/keyValueFrame.vue'
import {
  UseTemporaryPatientSubscriptionKey,
  UseTemporaryPatientSubscriptionType
} from '@/composables/appointment/useTemporaryPatientSubscription'
import { UsePatientMatchKey, UsePatientMatchType } from '@/composables/patient/usePatientMatch'

export default defineComponent({
  name: 'temporary-patient-list',
  components: { KeyValueFrame, RadioButtonCard, VueYScrollArea, VueContent },
  setup () {
    const {
      temporaryPatientInfos
    } = inject(UseTemporaryPatientSubscriptionKey) as UseTemporaryPatientSubscriptionType
    const {
      selectedTemporaryId
    } = inject(UsePatientMatchKey) as UsePatientMatchType

    return {
      temporaryPatientInfos,
      selectedTemporaryId
    }
  }
})
