
import {
  defineComponent,
  provide
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientSearchForm from '@/views/karte/KarteByPatientList/PatientSearchForm.vue'
import PatientList from '@/views/karte/KarteByPatientList/PatientList.vue'
import { usePatientList, UsePatientListKey } from '@/composables/patient/usePatientList'

export default defineComponent({
  name: 'karte-by-patient-list',
  components: {
    PatientList,
    PatientSearchForm,
    VueTwoColumnContent,
    VueContentRemain,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const patientList = usePatientList()
    provide(UsePatientListKey, patientList)

    return {
    }
  }
})
