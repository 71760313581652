
import { defineComponent } from 'vue'
import { useModal } from '@/composables/useModal'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default defineComponent({
  name: 'appointment-type-selector',
  components: {
    NormalButton,
    WhiteBackdrop
  },
  setup (props, context) {
    const {
      show,
      open,
      dismiss
    } = useModal()

    const onTemporaryPatient = () => {
      dismiss()
      context.emit('temporaryPatient')
    }
    const onSelectPatient = () => {
      dismiss()
      context.emit('selectPatient')
    }
    const onCancel = () => {
      dismiss()
      context.emit('cancel')
    }

    return {
      show,
      open,
      dismiss,
      onTemporaryPatient,
      onSelectPatient,
      onCancel
    }
  }
})
