import { InjectionKey, ref } from 'vue'
import { TemporaryClose as TemporaryCloseModel } from '@/models'
import { DataStore } from 'aws-amplify'

export const useTemporaryCloseSubscription = () => {
  // subscriptionの段階でbusinessHourIdごとに営業時間のデータを持つようにする
  const temporaryCloseMap = ref<{[key:string]: TemporaryCloseModel}>({})
  const subscribing = ref(false)

  const fetchTemporaryClose = async () => {
    try {
      const temporary = await DataStore.query(TemporaryCloseModel)
      const newMap: { [key: string]: TemporaryCloseModel } = {}

      temporary.forEach(t => {
        newMap[t.businessHourId] = t
      })
      temporaryCloseMap.value = newMap
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('temporary close already subscribing'))
    }

    try {
      await fetchTemporaryClose()
      const subscription = DataStore.observe(TemporaryCloseModel).subscribe(async () => {
        await fetchTemporaryClose()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    temporaryCloseMap,
    fetchTemporaryClose,
    subscribe
  }
}

export type UseTemporaryCloseSubscriptionType = ReturnType<typeof useTemporaryCloseSubscription>
export const UseTemporaryCloseSubscriptionKey: InjectionKey<UseTemporaryCloseSubscriptionType> = Symbol('TemporaryCloseSubscription')
