<template>
  <div class="frame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-frame'
}
</script>

<style lang="scss" scoped>
  .frame {
    width: 98vw;
    margin: 10px 1vw $footer_height + 10px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    gap: 8px;
  }
</style>
