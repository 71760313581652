import { InjectionKey, ref } from 'vue'

export const useModal = () => {
  const show = ref<boolean>(false)

  const open = async () => {
    show.value = true
    document.body.classList.add('open-modal')
  }

  const dismiss = async () => {
    show.value = false
    document.body.classList.remove('open-modal')
  }

  return {
    show,
    open,
    dismiss
  }
}

export type UseModalType = ReturnType<typeof useModal>
export const UseModalKey: InjectionKey<UseModalType> = Symbol('Modal')
