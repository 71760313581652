
import {
  defineComponent,
  ref,
  provide,
  inject
} from 'vue'
import { usePatientMutation } from '@/composables/patient/usePatientMutation'
import { usePatient, UsePatientKey } from '@/composables/patient/usePatient'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { today } from '@/plugins/computed'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ConfirmPage from '@/components/pages/ConfirmPage.vue'
import CompletePage from '@/components/pages/CompletePage.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import PatientPage from '@/views/patient/register/PatientPage.vue'
import InquiryPage from '@/views/patient/InquiryPage.vue'
import { useAppointmentMutation } from '@/composables/appointment/useAppointmentMutation'

export default defineComponent({
  name: 'register-patient',
  components: {
    PatientPage,
    VueButtonFrame,
    VueContent,
    VueTitle,
    VueFrame,
    NormalButton,
    ConfirmPage,
    CompletePage,
    Confirm
  },
  setup () {
    const page = ref(0)
    const pageRef = ref<InstanceType<typeof InquiryPage>[]>([])
    const setPageRef = (el: InstanceType<typeof InquiryPage>) => {
      if (el) {
        pageRef.value.push(el)
      }
    }

    const {
      createPatient,
      updatePatient
    } = usePatientMutation()
    const {
      getAppointmentByPatientId,
      clearAppointmentTemporary,
      deleteTemporaryPatient
    } = useAppointmentMutation()
    const pat = usePatient()
    const {
      temporary,
      inquiry,
      maxPage,
      validationErrors,
      confirmItems,
      patient,
      clearPatient
    } = pat
    provide(UsePatientKey, pat)

    const confirmRef = ref<InstanceType<typeof Confirm>>()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { back, dashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType

    const onPrev = async () => {
      if (page.value === 0) {
        await back()
      } else {
        page.value--
        if (inquiry.inquiryPages.value[page.value].inquiries.find(inq => inq.valueType === 'addressSelector')) {
          page.value--
        }
      }
    }

    const onNext = () => {
      pageRef.value[page.value].submit()
      const result = validationErrors.value[page.value].every(err => {
        if (Array.isArray(err)) {
          return err.every(err => err === undefined)
        }
        return err === undefined
      })
      if (result) {
        page.value++
      }
    }

    const onSubmit = () => {
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        if (temporary.value) {
          const appointments = await getAppointmentByPatientId(patient.value.id)
          await Promise.all(appointments
            .filter(a => !a._deleted)
            .map(async a => {
              return await clearAppointmentTemporary(a.id)
            })
          )
          await deleteTemporaryPatient(patient.value.id)
          await createPatient({
            patient: patient.value
          })
        } else if (patient.value.id === '') {
          await createPatient({
            patient: patient.value
          })
        } else {
          await updatePatient({
            patient: patient.value
          })
        }
        page.value++
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    const complete = () => {
      clearPatient()
      dashboard()
    }

    return {
      today,
      page,
      setPageRef,
      title: inquiry.title,
      inquiryPages: inquiry.inquiryPages,
      maxPage,
      confirmItems,
      onPrev,
      onNext,
      onSubmit,
      yes,
      complete,
      confirmRef
    }
  }
})
