<template>
  <div class="tab-item" :class="[selected ? 'tab-selected' : 'tab-not-selected']">{{ title }}</div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    title: String,
    selected: Boolean
  }
}
</script>

<style lang="scss" scoped>
.tab-item {
  width: $tab_width;
  height: $tab_height;
  line-height: $tab_height;
  border-top-left-radius: $border_radius;
  border-top-right-radius: $border_radius;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;

  &.tab-selected {
    font-weight: bold;
    background: $base_color_water_light;
  }

  &.tab-not-selected {
    background: $base_color_gray_light;
    color: $base_color_gray_dark;
  }
}
</style>
