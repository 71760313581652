<template>
  <div :class="['alert-item', `alert-item-${type}`]">
    <fa-icon
      class="alert-item-close"
      @click="dismissAlert"
      icon="times"></fa-icon>
    <div>{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'alert-item',
  props: {
    type: {
      type: String,
      default: 'error',
      validator (value) {
        return [
          'notify',
          'warning',
          'error'
        ].indexOf(value) !== -1
      }
    },
    message: String
  },
  methods: {
    dismissAlert (event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-item {
  position: relative;
  width: 220px;
  height: 50px;
  margin-bottom: 5px;
  padding: 20px 15px 5px;
  opacity: 0.9;
  border-radius: $border_radius;
  font-size: $base_font_size * 0.8;
  font-weight: bold;
}

.alert-item-error {
  color: $base_color_red_dark;
  background: $base_color_red_light;
}

.alert-item-warning {
  color: $base_color_yellow_dark;
  background: $base_color_yellow_light;
}

.alert-item-notify {
  color: $base_color_blue_dark;
  background: $base_color_blue_light;
}

.alert-item-close {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: $base_font_size + 2px;
  cursor: pointer;
}

.fastest {
  animation-duration: 300ms;
}
</style>
