
import {
  defineComponent,
  computed
} from 'vue'

type MultiLineTitle = {
  names: string[]
  width: number
  center?: boolean
}

export default defineComponent({
  name: 'list-title-multiline',
  props: {
    titles: Array
  },
  setup (props) {
    const styles = computed(() => {
      return (props.titles as MultiLineTitle[]).map(title => {
        const s = [`flex: ${title.width}`]
        if (title.center) {
          s.push('text-align: center')
        }
        return s.join(';')
      })
    })
    return {
      styles
    }
  }
})
