
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import { v4 as uuid } from 'uuid'

type Props = {
  inputType: string
  title?: string | undefined
  placeholder?: string | undefined
  modelValue?: string | undefined
  readonly: boolean
  validation?: string | undefined
  required: boolean
}

export default defineComponent({
  name: 'validate-edit-box',
  props: {
    inputType: {
      type: String,
      default: 'text',
      validator (value: string) {
        return ['text', 'tel', 'number', 'password'].indexOf(value) !== -1
      }
    },
    title: String,
    placeholder: String,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    },
    validation: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  setup (props: Props) {
    const id = uuid()
    const focusRef = ref<HTMLElement|null>(null)

    const focus = () => {
      focusRef.value?.focus()
    }

    const inputClass = computed<string>(() => {
      return [
        'validate-edit-box-box',
        props.readonly ? 'edit-box-readonly' : '',
        props.validation !== undefined ? 'edit-box-validation-invalid' : ''
      ].join(' ')
    })

    return {
      id,
      focusRef,
      focus,
      inputClass
    }
  }
})
