
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import EditboxErrorMessage from '@/components/atoms/editboxErrorMessage.vue'

type Props = {
  inputType: string
  title?: string | undefined
  placeholder?: string | undefined
  modelValue?: string | undefined
  readonly: boolean
  validation?: string | undefined
}

export default defineComponent({
  name: 'no-label-edit-box',
  components: { EditboxErrorMessage },
  props: {
    inputType: {
      type: String,
      default: 'text',
      validator (value: string) {
        return ['text', 'tel', 'number', 'password'].includes(value)
      }
    },
    title: String,
    placeholder: String,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    },
    validation: String
  },
  setup (props: Props) {
    const focusRef = ref<HTMLElement | null>(null)

    const focus = () => {
      focusRef.value?.focus()
    }

    const inputClass = computed<string>(() => {
      return [
        'no-label-edit-box-box',
        props.readonly ? 'edit-box-readonly' : '',
        props.validation !== undefined ? 'edit-box-validation-invalid' : ''
      ].join(' ')
    })

    return {
      focusRef,
      focus,
      inputClass
    }
  }
})
