<template>
  <transition
    name="custom-classes-transition"
    :duration="100"
    enter-active-class="animated bounceIn"
    leave-active-class="animated bounceOut">
    <div>
      <h2>
        <slot name="title"></slot>
      </h2>
      <vue-content-item-frame>
        <key-value-frame :items="items">
          <slot name="message"></slot>
        </key-value-frame>
      </vue-content-item-frame>
    </div>
  </transition>
</template>

<script>
import KeyValueFrame from '@/components/molecules/keyValueFrame.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'

export default {
  name: 'complete-page',
  components: {
    VueContentItemFrame,
    KeyValueFrame
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
