import { ref } from 'vue'
import { API, graphqlOperation } from 'aws-amplify'
import { oneTimeToken } from '@/graphql/queries'
import { FetchStatus } from '@/composables/commonTypes'
import { TokenRefreshInterval } from '@/config'

interface OneTimeTokenResult {
  data? : {
    oneTimeToken: string
  }
}

// APIからone time tokenを取得する
// tokenは30分の有効期限であるが、更新は5分間隔で行う
export const useOneTimeToken = () => {
  const fetchStatus = ref<FetchStatus>(FetchStatus.BeforeFetch)
  const token = ref('')
  const inquiryId = ref('')

  let _fetchTimer: NodeJS.Timeout | null = null

  // tokenの定期取得を開始する。onMounted等から呼ぶ
  const startFetch = async (id: string) => {
    if (_fetchTimer !== null) {
      return
    }

    inquiryId.value = id
    await _fetchOneTimeToken()
    _fetchTimer = setInterval(async () => {
      await _fetchOneTimeToken()
    }, TokenRefreshInterval * 60 * 1000)
  }

  // tokenの定期取得を停止する。onUnmounted等から呼ぶ
  const stopFetch = () => {
    if (_fetchTimer === null) {
      return
    }
    clearTimeout(_fetchTimer)
    _fetchTimer = null
  }

  // 手動で強制的にtokenを更新したい場合に呼ぶ
  const forceRefresh = async () => {
    stopFetch()
    await startFetch(inquiryId.value)
  }

  const _fetchOneTimeToken = async () => {
    try {
      fetchStatus.value = FetchStatus.Fetching
      const res = await API.graphql(graphqlOperation(oneTimeToken, { inquiryId: inquiryId.value })) as OneTimeTokenResult
      token.value = res.data!.oneTimeToken
      fetchStatus.value = FetchStatus.Fetched
    } catch (e) {
      console.log(e)
      fetchStatus.value = FetchStatus.Failed
    }
  }

  return {
    fetchStatus,
    token,
    startFetch,
    stopFetch,
    forceRefresh
  }
}
