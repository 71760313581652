
import {
  defineComponent,
  inject,
  provide,
  ref,
  onMounted
} from 'vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import {
  UseAppointmentSubscriptionKey,
  UseAppointmentSubscriptionType
} from '@/composables/appointment/useAppointmentSubsucription'
import { usePatientList, UsePatientListKey } from '@/composables/patient/usePatientList'
import {
  useTemporaryPatientList,
  UseTemporaryPatientListKey
} from '@/composables/appointment/useTemporaryPatientList'
import { useAppointment, UseAppointmentKey } from '@/composables/appointment/useAppointment'
import {
  useAccountAppointment,
  UseAccountAppointmentKey
} from '@/composables/appointment/useAccountAppointment'
import { useAppointmentMutation } from '@/composables/appointment/useAppointmentMutation'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { AppointmentStatus, UpdateAppointmentInput } from '@/API'
import { formatHM } from '@/plugins/functions'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientTimeForm from '@/views/appointment/update/PatientTimeForm.vue'
import PatientTimeTable from '@/views/appointment/update/PatientTimeTable.vue'

export default defineComponent({
  name: 'CreateAppointment',
  components: {
    PatientTimeTable,
    PatientTimeForm,
    VueTwoColumnContent,
    VueContentRemain,
    Confirm,
    NormalButton,
    VueButtonFrame,
    VueTitle,
    VueFrame
  },
  setup () {
    const confirmDeleteRef = ref<InstanceType<typeof Confirm>>()
    const confirmUpdateRef = ref<InstanceType<typeof Confirm>>()

    const { append } = inject(UseErrorKey) as UseErrorType
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { getAppointment } = inject(UseAppointmentSubscriptionKey) as UseAppointmentSubscriptionType

    const patientList = usePatientList()
    const {
      selectPatientById,
      selectedPatient
    } = patientList
    provide(UsePatientListKey, patientList)

    const temporaryPatientList = useTemporaryPatientList()
    const {
      selectedTemporaryPatient,
      selectTemporaryPatientById
    } = temporaryPatientList
    provide(UseTemporaryPatientListKey, temporaryPatientList)

    const appointment = useAppointment(selectedPatient)
    const {
      patientType,
      appointmentId,
      baseDate,
      startTime,
      endTime,
      errorStartTime,
      errorEndTime,
      overlap
    } = appointment
    provide(UseAppointmentKey, appointment)

    const accountAppointment = useAccountAppointment()
    const {
      selectedAccount
    } = accountAppointment
    provide(UseAccountAppointmentKey, accountAppointment)

    const {
      updateAppointment,
      deleteAppointment,
      deleteTemporaryPatient
    } = useAppointmentMutation()

    onMounted(async () => {
      await accountAppointment.listAccountAppointment()
    })

    const onPrev = async () => {
      await back()
    }

    const onDelete = () => {
      confirmDeleteRef.value?.open()
    }

    const yesDelete = async () => {
      try {
        await deleteAppointment(appointmentId.value)
        if (patientType.value === 'tmp') {
          await deleteTemporaryPatient(selectedTemporaryPatient.value!.id)
        }

        await append('notify', '予約を削除しました')
        selectPatientById('')
        selectTemporaryPatientById('')
        appointmentId.value = ''
        startTime.value = ''
        endTime.value = ''
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    const onUpdate = () => {
      confirmUpdateRef.value?.open()
    }

    const yesUpdate = async () => {
      try {
        const currentAppointment = await getAppointment(appointmentId.value)

        const appointment: UpdateAppointmentInput = {
          ...currentAppointment!,
          appointmentStatus: currentAppointment!.appointmentStatus as AppointmentStatus,
          accountId: selectedAccount.value,
          date: baseDate.value,
          startTime: formatHM(startTime.value),
          endTime: formatHM(endTime.value)
        }
        await updateAppointment({ appointment })
        await append('notify', '予約を変更しました')
        selectPatientById('')
        selectTemporaryPatientById('')
        appointmentId.value = ''
        startTime.value = ''
        endTime.value = ''
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    return {
      patientType,
      appointmentId,
      errorStartTime,
      errorEndTime,
      overlap,
      onPrev,
      onDelete,
      yesDelete,
      onUpdate,
      yesUpdate,
      confirmDeleteRef,
      confirmUpdateRef
    }
  }
})
