<template>
  <div class="alert-message" :class="$mq">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'alert-message'
}
</script>

<style lang="scss" scoped>
.alert-message {
  position: relative;
  width: 95%;
  margin: 0 auto;
  text-align: right;
  color: $base_color_red;
  font-size: 0.9em;

  &.mobile {
    text-align: center;
  }
}
</style>
