<template>
  <div class="text-column">
    <div class="karte-row">
      <fa-icon class="karte-icon" icon="star"/>
      <div class="karte-number">{{ `No. ${value.number}` }}</div>
      <div class="karte-title" @click="value.cb(value)">{{ value.title }}</div>
      <div class="karte-account">{{ `担当: ${value.accountName}` }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-bookmark',
  props: {
    value: Object,
    columnId: String
  },
  emits: ['bookmark', 'up', 'down']
}
</script>

<style lang="scss" scoped>
.text-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .karte-row {
    display: grid;
    grid-template-columns: 40px 150px 250px 150px 30px 30px 30px;
    align-items: center;

    .karte-icon {
      color: $base_color_blue_light;
    }

    .karte-title {
      word-break: break-all;
      text-decoration: underline;
      cursor: pointer;
    }

    .karte-bookmark-marked {
      font-size: 1.2em;
      color: $base_color_blue_light;
      cursor: pointer;
    }

    .karte-bookmark {
      font-size: 1.2em;
      color: $base_color_gray_dark;
      cursor: pointer;
    }

    .karte-arrow-disable {
      font-size: 1.2em;
      color: $base_color_gray_light;
    }

    .karte-arrow {
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}

</style>
