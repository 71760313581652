<template>
  <div class="login-form">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-login-form'
}
</script>

<style lang="scss" scoped>
  .login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px;
    border: 5px solid $base_color_gray;
    border-radius: $border_radius;
    background: $base_color_white;
  }
</style>
