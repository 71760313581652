import { computed, ref, InjectionKey, reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Inquiry } from '@/composables/patient/types'
import {
  stringError,
  radioError,
  zipCodeError,
  numberError,
  emailError,
  numberArrayError
} from '@/composables/patient/validations'
import { toHalf } from '@/plugins/functions'
import PatientInquiry from '@/composables/patient/patient.json'
import { Display, Gender, SNSType, Patient } from '@/API'
import { TemporaryPatient as TemporaryPatientModel } from '@/models'

// 患者登録画面で入力した内容を保持するcomposable
// 入力した内容はvaluesに保存されるとともに、localStorageにも保存される。
// 画面のリロードと共にusePatient()を呼ぶと、localStorageからリロード前の値を復帰する。
// 患者登録の完了時、新規に患者登録画面に移動する際にはlocalStorageの内容を破棄するために clear()を呼ぶ
// 仮患者の登録を行う際は、事前にsetTemporaryを呼ぶことで必要な情報をlocalStorageに書き込む。
// また仮患者の登録ではurlクエリパラメータでp=temporaryPatientId&t=yの指定が必要。

export const usePatient = () => {
  const route = useRoute()
  const inquiry = reactive<Inquiry>(PatientInquiry as Inquiry)
  const values = ref<string[][]>([])
  const patientId = ref<string>(route.query.p as string || '')
  const temporary = ref<boolean>(route.query.t as string === 'y')

  const setPatient = (patient: Patient) => {
    values.value = []
    inquiry.inquiryPages.forEach(page => {
      values.value.push(page.inquiries.map(inq => {
        switch (inq.key) {
          case 'name':
            return patient.name
          case 'read':
            return patient.read
          case 'birthDate':
            return patient.birthDate
          case 'gender':
            return patient.gender
          case 'zipCode':
            return patient.private.zipCode!
          case 'pref':
            return patient.private.pref!
          case 'city':
            return patient.private.city!
          case 'address':
            return patient.private.address || ''
          case 'phoneNumbers':
            return patient.private.phoneNumber === null
              ? '[]'
              : '[' + patient.private.phoneNumber!.map(p => `"${p}"`).join(',') + ']'
          case 'email': {
            const email = patient.private.sns?.find(s => s.snsType === SNSType.email)
            return email ? email.accountId : ''
          }
          case 'comment':
            return patient.comment || ''
          default:
            return inq.valueType === 'numberArray' ? '[]' : ''
        }
      }))
    })
    const versionKey = 'ekarte-patient-version'
    localStorage.setItem(versionKey, inquiry.version)
  }

  const setTemporaryPatient = (temporary: TemporaryPatientModel) => {
    values.value = []
    inquiry.inquiryPages.forEach(page => {
      values.value.push(page.inquiries.map(inq => {
        switch (inq.key) {
          case 'name':
          case 'read':
            return temporary.name
          case 'gender':
            return temporary.gender
          case 'phoneNumbers':
            return temporary.phoneNumber && temporary.phoneNumber !== '' ? `["${temporary.phoneNumber}"]` : '[]'
          default:
            return inq.valueType === 'numberArray' ? '[]' : ''
        }
      }))
    })
    const versionKey = 'ekarte-patient-version'
    localStorage.setItem(versionKey, inquiry.version)
  }

  const clearPatient = () => {
    values.value = []
    inquiry.inquiryPages.forEach(page => {
      values.value.push(page.inquiries.map(inq => inq.valueType === 'numberArray' ? '[]' : ''))
    })
  }

  (() => {
    const versionKey = 'ekarte-patient-version'
    const lastVersion = localStorage.getItem(versionKey)
    if (lastVersion) {
      if (lastVersion === inquiry.version) {
        const dataKey = `ekarte-patient-${inquiry.version}`
        const lastData = localStorage.getItem(dataKey)
        if (lastData) {
          values.value = JSON.parse(lastData)
          return
        }
      }
    }
    // 新規作成
    clearPatient()
  })()

  watch(
    () => values.value,
    to => {
      const dataKey = `ekarte-patient-${inquiry.version}`
      localStorage.setItem(dataKey, JSON.stringify(to))
    },
    { deep: true }
  )

  const zipCode = computed(() => {
    for (let i = 0; i < inquiry.inquiryPages.length; i++) {
      for (let j = 0; j < inquiry.inquiryPages[i].inquiries.length; j++) {
        if (inquiry.inquiryPages[i].inquiries[j].valueType === 'zipCode') {
          return toHalf(values.value[i][j])
        }
      }
    }
    return ''
  })

  const setPref = (pref: string) => {
    for (let i = 0; i < inquiry.inquiryPages.length; i++) {
      for (let j = 0; j < inquiry.inquiryPages[i].inquiries.length; j++) {
        if (inquiry.inquiryPages[i].inquiries[j].key === 'pref') {
          values.value[i][j] = pref === ''
            ? inquiry.inquiryPages[i].inquiries[j].defaultValue || ''
            : pref
          return
        }
      }
    }
  }

  const setCity = (city: string) => {
    for (let i = 0; i < inquiry.inquiryPages.length; i++) {
      for (let j = 0; j < inquiry.inquiryPages[i].inquiries.length; j++) {
        if (inquiry.inquiryPages[i].inquiries[j].key === 'city') {
          values.value[i][j] = city
          return
        }
      }
    }
  }

  const setAddress = (address: string) => {
    for (let i = 0; i < inquiry.inquiryPages.length; i++) {
      for (let j = 0; j < inquiry.inquiryPages[i].inquiries.length; j++) {
        if (inquiry.inquiryPages[i].inquiries[j].key === 'address') {
          // 変更しようとしている住所が元の値に含まれていたら上書きしない
          if (!values.value[i][j].includes(address)) {
            values.value[i][j] = address
          }
          return
        }
      }
    }
  }

  const maxPage = computed(() => {
    return inquiry.inquiryPages.length
  })

  const validationErrors = computed(() => {
    return inquiry.inquiryPages.map((page, i) => {
      return page.inquiries.map((inq, j) => {
        switch (inq.valueType) {
          case 'date':
          case 'dateAndAge':
            return undefined
          case 'radio':
            return radioError(values.value[i][j], inq)
          case 'zipCode':
            return zipCodeError(values.value[i][j], inq)
          case 'addressSelector':
            return stringError(values.value[i][j], inq)
          case 'number':
            return numberError(values.value[i][j], inq)
          case 'numberArray':
            return numberArrayError(values.value[i][j], inq)
          case 'email':
            return emailError(values.value[i][j], inq)
          default:
            return stringError(values.value[i][j], inq)
        }
      })
    })
  })

  const confirmItems = computed(() => {
    return inquiry.inquiryPages.flatMap((page, i) => {
      return page.inquiries
        .filter(inq => inq.valueType !== 'addressSelector')
        .map((inq, j) => {
          const v = values.value[i][j]
          switch (inq.valueType) {
            case 'radio':
              return {
                key: inq.title,
                value: inq.options?.find(opt => opt.value === v)?.name || v
              }
            case 'numberArray':
              return {
                key: inq.title,
                value: JSON.parse(v).join(',')
              }
            default:
              return {
                key: inq.title,
                value: v
              }
          }
        })
    })
  })

  const patient = computed(() => {
    const keyValue:{[key:string]:string} = {}
    inquiry.inquiryPages.forEach((page, i) => {
      page.inquiries
        .filter(inq => inq.valueType !== 'addressSelector')
        .forEach((inq, j) => {
          keyValue[inq.key] = values.value[i][j]
        })
    })

    const phoneNumber = (JSON.parse(keyValue.phoneNumbers) as string[])
      .map(p => toHalf(p))

    const sns = keyValue.email !== ''
      ? [{
        snsType: SNSType.email,
        accountId: keyValue.email,
        access: false
      }]
      : []

    return {
      id: patientId.value,
      patientNumber: '',
      name: keyValue.name,
      read: keyValue.read,
      gender: keyValue.gender as Gender,
      birthDate: keyValue.birthDate,
      private: {
        zipCode: toHalf(keyValue.zipCode),
        pref: keyValue.pref,
        city: keyValue.city,
        address: keyValue.address,
        phoneNumber,
        sns
      },
      comment: keyValue.comment,
      display: Display.show
    }
  })

  return {
    inquiry: toRefs(inquiry),
    values,
    temporary,
    setPatient,
    setTemporaryPatient,
    clearPatient,
    zipCode,
    setPref,
    setCity,
    setAddress,
    maxPage,
    validationErrors,
    confirmItems,
    patient
  }
}

export type UsePatientType = ReturnType<typeof usePatient>
export const UsePatientKey: InjectionKey<UsePatientType> = Symbol('PatientRegister')
