<template>
  <div class="time-text-frame" :class="$mq">
    <fa-icon
      class="time-text-clock-icon"
      :icon="['fas', 'clock']"></fa-icon>
    <div class="time-text">{{ startTime }}-{{ endTime }}</div>
  </div>
</template>

<script>
export default {
  name: 'start-end-time-text',
  props: {
    startTime: String,
    endTime: String
  }
}
</script>

<style lang="scss" scoped>
.time-text-frame {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  height: 24px;
  line-height: 24px;
  font-size: 0.9em;

  &.mobile, &.tablet {
    font-size: 0.7em;
  }

  .time-text {
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
