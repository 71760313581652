import { computed, reactive } from 'vue'
import { API, graphqlOperation } from 'aws-amplify'
import { listZip } from '@/graphql/queries'
import { Zip } from '@/API.ts'
import { FetchStatus } from '@/composables/commonTypes'

interface ListZipResult {
  data?: {
    listZip: Zip[]
  }
}

interface ZipState {
  fetchStatus: FetchStatus
  listZip: Zip[]
}

export const useZipCodes = () => {
  const state = reactive<ZipState>({
    fetchStatus: FetchStatus.BeforeFetch,
    listZip: []
  })

  const fetchZipCodes = async (zipCode: string): Promise<Zip[]> => {
    try {
      state.fetchStatus = FetchStatus.Fetching
      const res = await API.graphql(graphqlOperation(listZip, { zipCode })) as ListZipResult
      state.fetchStatus = FetchStatus.Fetched
      state.listZip = res.data!.listZip
      return res.data!.listZip
    } catch (e) {
      state.fetchStatus = FetchStatus.Failed
      return Promise.reject(e)
    }
  }

  const addresses = computed(() => {
    return [
      ...state.listZip.map((zip, i) => {
        return {
          value: '' + i,
          name: `${zip.pref} ${zip.city} ${zip.address}`
        }
      }),
      {
        value: 'etc',
        name: '上記以外'
      }
    ]
  })

  return {
    state,
    fetchZipCodes,
    addresses
  }
}
