import { computed, ComputedRef, inject, InjectionKey, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  UseKarteSubscriptionKey,
  UseKarteSubscriptionType
} from '@/composables/karte/useKarteSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useKarte } from '@/composables/karte/useKarte'
import { DateKarte } from '@/composables/karte/types'
import { Karte as KarteModel } from '@/models'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import { getKarte } from '@/graphql/queries'
import {
  UseFavoriteKarteSubscriptionKey,
  UseFavoriteKarteSubscriptionType
} from '@/composables/karte/useFavoriteKarteSubscription'

interface GetKarteResult {
  data?: {
    getKarte: KarteModel
  }
}

export const useConsultationList = (oldDateKarteMap: ComputedRef<DateKarte>) => {
  const route = useRoute()
  const router = useRouter()
  const patientId = ref<string>(route.query.p as string || '')
  const { patientKarteMap } = inject(UseKarteSubscriptionKey) as UseKarteSubscriptionType
  const { favoriteMap } = inject(UseFavoriteKarteSubscriptionKey) as UseFavoriteKarteSubscriptionType
  const { clinicId } = inject(UseClinicKey) as UseClinicType
  const { setKarte } = useKarte()

  const consultationTitles = [
    { name: '診察日', width: 1 },
    { name: 'カルテ', width: 3 }
  ]

  const upKarte = async (karteDate: string, karteId: string) => {
    if (!mergedDateKarteMap.value[karteDate]) {
      return
    }
    const index = mergedDateKarteMap.value[karteDate].kartes.findIndex(k => k.id === karteId)
    if (index <= 0 || !(mergedDateKarteMap.value[karteDate].kartes[index] instanceof KarteModel)) {
      return
    }
    try {
      const { id: currId, displayOrder: currOrder } = mergedDateKarteMap.value[karteDate].kartes[index]
      const { id: prevId, displayOrder: prevOrder } = mergedDateKarteMap.value[karteDate].kartes[index - 1]
      await _updateOrder(currId, prevOrder)
      await _updateOrder(prevId, currOrder)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const downKarte = async (karteDate: string, karteId: string) => {
    if (!mergedDateKarteMap.value[karteDate]) {
      return
    }
    const index = mergedDateKarteMap.value[karteDate].kartes.findIndex(k => k.id === karteId)
    if (
      index < 0 ||
      index >= mergedDateKarteMap.value[karteDate].kartes.length - 1 ||
      !(mergedDateKarteMap.value[karteDate].kartes[index] instanceof KarteModel)
    ) {
      return
    }
    try {
      const { id: currId, displayOrder: currOrder } = mergedDateKarteMap.value[karteDate].kartes[index]
      const { id: nextId, displayOrder: nextOrder } = mergedDateKarteMap.value[karteDate].kartes[index + 1]
      await _updateOrder(currId, nextOrder)
      await _updateOrder(nextId, currOrder)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const mergedDateKarteMap = computed<DateKarte>(() => {
    if (patientId.value === '') {
      return {}
    }
    if (!patientKarteMap.value[patientId.value]) {
      return {}
    }

    const merged: DateKarte = {
      ...patientKarteMap.value[patientId.value]
    }

    Object.keys(oldDateKarteMap.value).forEach(karteDate => {
      if (Object.prototype.hasOwnProperty.call(merged, karteDate)) {
        const existIds = merged[karteDate].kartes.map(k => k.id)
        oldDateKarteMap.value[karteDate].kartes.forEach(simpleKarte => {
          if (!existIds.includes(simpleKarte.id)) {
            merged[karteDate].kartes = [...merged[karteDate].kartes, simpleKarte]
          }
        })
        merged[karteDate].kartes.sort((a, b) => a.displayOrder - b.displayOrder)
      } else {
        merged[karteDate] = { ...oldDateKarteMap.value[karteDate] }
      }
    })

    return merged
  })

  const consultationRows = computed(() => {
    return Object.keys(mergedDateKarteMap.value)
      .map(karteDate => {
        const dateString = mergedDateKarteMap.value[karteDate].dateString
        const karteTitles = mergedDateKarteMap.value[karteDate].kartes.map(karte => {
          return {
            id: karte.id,
            icon: karte instanceof KarteModel ? 'desktop' : 'cloud',
            number: karte.karteNumber,
            title: karte.title === '' ? 'タイトルなし' : karte.title,
            accountName: karte.accountName,
            bookmarked: favoriteMap.value[patientId.value]
              ? favoriteMap.value[patientId.value].some(favo => favo.karteId === karte.id)
              : false,
            karteDate,
            async cb () {
              if (karte instanceof KarteModel) {
                setKarte(karte)
              } else {
                try {
                  const res = await API.graphql(graphqlOperation(getKarte, { id: karte.id })) as GetKarteResult
                  setKarte(res.data!.getKarte)
                } catch (e) {
                  console.log(e)
                  return
                }
              }
              // setKarteによってlocalStorageに編集するカルテの情報が書き込まれるが、念のためurlでもpatientIdを通知する
              await router.push(`/w/${clinicId.value}/karte?p=${patientId.value}`)
            }
          }
        })

        return {
          id: karteDate,
          columns: [
            { value: dateString, width: 1, type: 'text' },
            { values: karteTitles, width: 3, type: 'kartes' }
          ]
        }
      })
      .sort((a, b) => a.id.localeCompare(b.id))
  })

  const _updateOrder = async (karteId: string, displayOrder: number) => {
    try {
      const original = await DataStore.query(KarteModel, k => k.id('eq', karteId))
      if (original.length === 0) {
        return Promise.reject(new Error('カルテ順の変更に失敗しました'))
      }
      await DataStore.save<KarteModel>(
        KarteModel.copyOf(original[0], updated => {
          updated.displayOrder = displayOrder
        })
      )
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    upKarte,
    downKarte,
    consultationTitles,
    consultationRows
  }
}

export type UseConsultationListType = ReturnType<typeof useConsultationList>
export const UseConsultationListKey: InjectionKey<UseConsultationListType> = Symbol('ConsultationList')
