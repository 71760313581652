import { InquiryItem } from '@/composables/patient/types'
import {
  zipCodeValidation,
  numberValidation,
  emailValidation
} from '@/plugins/validator'

export const stringError = (value: string, item: InquiryItem) => {
  return item.required
    ? value === '' ? `${item.title}を入力してください` : undefined
    : undefined
}

export const radioError = (value: string, item: InquiryItem) => {
  return item.required
    ? value === '' ? `${item.title}を選択してください` : undefined
    : undefined
}

export const zipCodeError = (value: string, item: InquiryItem) => {
  return item.required
    ? value === ''
      ? '郵便番号を入力してください'
      : zipCodeValidation(value)
    : value === ''
      ? undefined
      : zipCodeValidation(value)
}

export const numberError = (value: string, item: InquiryItem) => {
  return item.required
    ? value === ''
      ? `${item.title}を入力してください`
      : numberValidation(value)
    : value === ''
      ? undefined
      : numberValidation(value)
}

export const emailError = (value: string, item: InquiryItem) => {
  return item.required
    ? value === ''
      ? 'メールアドレスを入力してください'
      : emailValidation(value)
    : value === ''
      ? undefined
      : emailValidation(value)
}

export const numberArrayError = (value: string, item: InquiryItem) => {
  const values = JSON.parse(value) as string[]

  if (item.required && values.length === 0) {
    return `少なくとも１つの${item.title}を追加してください`
  }

  return values.map(v => v === '' ? `${item.title}を入力してください` : numberValidation(v))
}
