import { inject, InjectionKey } from 'vue'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { DataStore } from 'aws-amplify'
import {
  UseFavoriteKarteSubscriptionKey,
  UseFavoriteKarteSubscriptionType
} from '@/composables/karte/useFavoriteKarteSubscription'
import {
  FavoriteKarte as FavoriteKarteModel
} from '@/models'
import { KarteTitle } from '@/composables/karte/types'

export const useFavoriteKarteMutation = () => {
  const { favoriteMap } = inject(UseFavoriteKarteSubscriptionKey) as UseFavoriteKarteSubscriptionType

  const toggleBookmark = async (accountId: string, patientId: string, karte: KarteTitle) => {
    const exist = favoriteMap.value[patientId]
      ? favoriteMap.value[patientId].some(favo => favo.karteId === karte.id)
      : false

    try {
      if (exist) {
        await deleteFavoriteKarte(accountId, karte.id)
      } else {
        await registerFavoriteKarte(accountId, patientId, karte)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const registerFavoriteKarte = async (accountId: string, patientId: string, karte: KarteTitle) => {
    try {
      const newFavo = new FavoriteKarteModel({
        id: uuid(),
        accountId,
        karteId: karte.id,
        displayOrder: dayjs().unix(),
        karteAccountName: karte.accountName,
        karteNumber: karte.number,
        patientId: patientId,
        karteDate: karte.karteDate,
        title: karte.title
      })
      await DataStore.save<FavoriteKarteModel>(newFavo)
    } catch (e) {
      console.log(e)
      return Promise.reject(new Error('ブックマークの登録に失敗しました'))
    }
  }

  const deleteFavoriteKarte = async (accountId: string, karteId: string) => {
    try {
      const original = await DataStore.query(FavoriteKarteModel, k => k.karteId('eq', karteId).accountId('eq', accountId))
      if (original.length === 0) {
        return Promise.reject(new Error('ブックマークの削除に失敗しました'))
      }
      await DataStore.delete<FavoriteKarteModel>(original[0])
    } catch (e) {
      console.log(e)
      return Promise.reject(new Error('ブックマークの削除に失敗しました'))
    }
  }

  return {
    toggleBookmark
  }
}

export type UseFavoriteKarteMutationType = ReturnType<typeof useFavoriteKarteMutation>
export const UseFavoriteKarteMutationKey: InjectionKey<UseFavoriteKarteMutationType> = Symbol('FavoriteKarteMutation')
