<template>
  <div class="menu-item-frame" :class="[selected ? 'tab-selected' : 'tab-not-selected']">
    <fa-icon
      class="menu-item-icon"
      icon="angle-right"></fa-icon>
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'side-menu-item',
  props: {
    title: String,
    selected: Boolean
  }
}
</script>

<style lang="scss" scoped>
.menu-item-frame {
  width: 100%;
  height: $form_item_height;
  transition: 0.3s;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .menu-item-icon {
    margin-right: 8px;
  }

  &.tab-selected {
    border-left: 8px solid $base_color_water;
    background: $base_color_water_light;
    font-weight: bold;
  }

  &.tab-not-selected {
    border-left: 8px solid $base_color_water_lighter;
    color: $base_color_gray_dark;
  }
}
</style>
