export interface Base64Hash {
  b64: string
  hash: string
}

export interface CsvStruct {
  header: string[]
  data: string[][]
}

export const stringToCsv = (str: string): CsvStruct => {
  const split = str
    .replace('\r\n', '\n')
    .split('\n')
    .filter(s => s !== '')
    .map(line => line.split(',')
      .map(column => convertHalfKanaToFull(column))
    )
  const header = split[0]
  split.shift()
  const data = split.slice()

  return {
    header,
    data
  }
}

export const csvToString = (csv: CsvStruct): string => {
  const lines: string[] = []
  lines.push(csv.header.join(','))
  lines.push(...csv.data.map(columns => columns.join(',')))
  return lines.join('\n')
}

export const noHeaderCsvToString = (data: string[][]): string => {
  const lines: string[] = []
  lines.push(...data.map(columns => columns.join(',')))
  return lines.join('\n')
}

export const validateHeader = (csv: CsvStruct, headers: string[]): boolean => {
  if (csv.header.length !== headers.length) {
    return false
  }
  return csv.header.every((h, i) => h === headers[i])
}

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export const base64Decode = (b64: string): string => {
  if (b64.includes(',')) {
    const s = b64.split(',')
    b64 = s[1]
  }
  let escaped = atob(b64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join('')

  // BOMがあったら取り除く
  if (escaped.slice(0, 9) === '%ef%bb%bf') {
    escaped = escaped.substr(9)
  }

  return decodeURIComponent(escaped)
}

export const base64Encode = (str: string): string => {
  const escaped = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16))
  })
  return btoa(escaped)
}

export const sha256Hash = async (str: string): Promise<string> => {
  const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))
  return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2, '0')).join('') || ''
}

export const convertHalfKanaToFull = (str: string) => {
  const kanaMap: { [key:string]: string } = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｳﾞ: 'ヴ',
    ﾜﾞ: 'ヷ',
    ｦﾞ: 'ヺ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｯ: 'ッ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
    '｡': '。',
    '､': '、',
    ｰ: 'ー',
    '｢': '「',
    '｣': '」',
    '･': '・'
  }

  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g')
  return str
    .replace(reg, function (match) {
      return kanaMap[match]
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
}
