
import RadioButtonGroup from '@/components/molecules/radioButtonGroup.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'

export default {
  name: 'single-select-inquiry',
  components: {
    AlertMessage,
    RadioButtonGroup
  },
  props: {
    title: String,
    groupId: String,
    required: Boolean,
    radios: Array,
    modelValue: String,
    validation: String
  }
}
