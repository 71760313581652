export enum FetchStatus {
  BeforeFetch = 'beforeFetch',
  Fetching = 'fetching',
  Fetched = 'fetched',
  Failed = 'fetchFailed'
}

export enum RequestStatus {
  BeforeRequest = 'beforeRequest',
  Requesting = 'requesting',
  Success = 'success',
  Failed = 'failed'
}

export interface PageInfo {
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  startCursor: string,
  endCursor: string
}

export interface KeyValue {
  key: string
  value: string
}
