
import { defineComponent, inject, provide, ref } from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import PatientAnswerList from '@/views/patient/answer/PatientAnswerList.vue'
import VueTwoColumnContentEven from '@/components/layout/vueTwoColumnContentEven.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import TemporaryPatientList from '@/views/patient/answer/TemporaryPatientList.vue'
import Description from '@/components/atoms/Description.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import {
  UseInquiryAnswerSubscriptionKey,
  UseInquiryAnswerSubscriptionType
} from '@/composables/inquieryAnswer/useInquiryAnswerSubscription'
import {
  UseTemporaryPatientSubscriptionKey,
  UseTemporaryPatientSubscriptionType
} from '@/composables/appointment/useTemporaryPatientSubscription'
import { usePatientMatch, UsePatientMatchKey } from '@/composables/patient/usePatientMatch'
import { useInquiryAnswerMutation } from '@/composables/inquieryAnswer/useInquiryAnswerMutation'
import { usePatientMutation } from '@/composables/patient/usePatientMutation'
import { AnswerStatus } from '@/API'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { useAppointmentMutation } from '@/composables/appointment/useAppointmentMutation'

export default defineComponent({
  name: 'patient-answer',
  components: {
    Confirm,
    Description,
    TemporaryPatientList,
    NormalButton,
    VueButtonFrame,
    VueTwoColumnContentEven,
    PatientAnswerList,
    VueContentRemain,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const confirmNoMatchRef = ref<InstanceType<typeof Confirm>>()
    const confirmMatchRef = ref<InstanceType<typeof Confirm>>()
    const confirmDeleteRef = ref<InstanceType<typeof Confirm>>()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType

    const {
      patientAnswers
    } = inject(UseInquiryAnswerSubscriptionKey) as UseInquiryAnswerSubscriptionType
    const {
      temporaryPatients
    } = inject(UseTemporaryPatientSubscriptionKey) as UseTemporaryPatientSubscriptionType

    const match = usePatientMatch()
    const {
      selectedAnswerId,
      selectedTemporaryId,
      matched
    } = match
    provide(UsePatientMatchKey, match)

    const { updateInquiryStatus } = useInquiryAnswerMutation()
    const { createPatient } = usePatientMutation()
    const {
      getAppointmentByPatientId,
      clearAppointmentTemporary,
      deleteTemporaryPatient
    } = useAppointmentMutation()

    const onSubmit = () => {
      if (selectedTemporaryId.value === 'no-match') {
        confirmNoMatchRef.value?.open()
      } else {
        confirmMatchRef.value?.open()
      }
    }

    const yesNoMatch = async () => {
      const answer = patientAnswers.value.find(a => a.answer.id === selectedAnswerId.value)
      if (!answer) {
        return
      }

      try {
        await lockScreen()
        await createPatient({ patient: answer.patient })
        await updateInquiryStatus({
          id: selectedAnswerId.value,
          answerStatus: AnswerStatus.registered
        })
        selectedAnswerId.value = ''
        await append('notify', '着信した患者情報を登録しました')
      } catch (e) {
        console.log(e)
        await append('error', '患者の登録に失敗しました')
      } finally {
        await unlockScreen()
      }
    }

    const yesMatch = async () => {
      const answer = patientAnswers.value.find(a => a.answer.id === selectedAnswerId.value)
      if (!answer) {
        return
      }
      const temporary = temporaryPatients.value.find(t => t.id === selectedTemporaryId.value)
      if (!temporary) {
        return
      }

      try {
        await lockScreen()
        const appointments = await getAppointmentByPatientId(temporary.id)
        await Promise.all(appointments
          .filter(a => !a._deleted)
          .map(async a => {
            return await clearAppointmentTemporary(a.id)
          })
        )
        await deleteTemporaryPatient(temporary.id)
        await createPatient({
          patient: {
            ...answer.patient,
            id: temporary.id
          }
        })
        await updateInquiryStatus({
          id: selectedAnswerId.value,
          answerStatus: AnswerStatus.registered
        })
        selectedAnswerId.value = ''
        selectedTemporaryId.value = 'no-match'
        await append('notify', '着信した患者情報を登録しました')
      } catch (e) {
        console.log(e)
        await append('error', '患者の登録に失敗しました')
      } finally {
        await unlockScreen()
      }
    }

    const onDelete = () => {
      confirmDeleteRef.value?.open()
    }

    const yesDelete = async () => {
      try {
        await updateInquiryStatus({
          id: selectedAnswerId.value,
          answerStatus: AnswerStatus.toBeDeleted
        })
        selectedAnswerId.value = ''
        await append('notify', '着信情報を削除しました')
      } catch (e) {
        console.log(e)
        await append('error', '着信情報の削除に失敗しました')
      }
    }

    return {
      confirmNoMatchRef,
      confirmMatchRef,
      confirmDeleteRef,
      temporaryPatients,
      selectedAnswerId,
      matched,
      onSubmit,
      yesNoMatch,
      yesMatch,
      onDelete,
      yesDelete
    }
  }
})
