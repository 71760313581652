
import { defineComponent, inject } from 'vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import AlertItem from '@/components/atoms/alert/alertItem.vue'

export default defineComponent({
  name: 'alert',
  components: { AlertItem },
  setup () {
    const { errors, manualRemove } = inject(UseErrorKey) as UseErrorType

    return {
      errors,
      manualRemove
    }
  }
})
