<template>
  <div class="badge-frame">
    <slot></slot>
    <transition
      name="custom-classes-transition"
      :duration="500"
      enter-active-class="animated heartBeat"
      leave-active-class="animated heartBeat">
      <badge
        :key="`badge-${count}`"
        v-if="count>0"
        class="badge"
        :count="count"></badge>
    </transition>
  </div>
</template>

<script>
import Badge from '@/components/atoms/Badge.vue'
export default {
  name: 'badge-frame',
  components: {
    Badge
  },
  props: {
    count: Number
  }
}
</script>

<style lang="scss" scoped>
.badge-frame {
  position: relative;

  .badge {
    position: absolute;
    top: -$form_item_height_small / 2;
    right: -$form_item_height_small / 2;
  }
}
</style>
