
import {
  defineComponent, inject
} from 'vue'
import { useSubmit } from '@/composables/useSubmit'
import {
  UseTemporaryPatientKey,
  UseTemporaryPatientType
} from '@/composables/appointment/useTemporaryPatient'
import ValidateEditBox from '@/components/molecules/editbox/validateEditBox.vue'
import RadioButtonGroup from '@/components/molecules/radioButtonGroup.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import Description from '@/components/atoms/Description.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'

export default defineComponent({
  name: 'temporary-patient',
  components: {
    AlertMessage,
    Description,
    VueContentItemFrame,
    VueContent,
    RadioButtonGroup,
    ValidateEditBox
  },
  setup () {
    const { submitted, submit } = useSubmit()
    const {
      name,
      gender,
      phoneNumber,
      nameError,
      genderError,
      phoneNumberError,
      genders
    } = inject(UseTemporaryPatientKey) as UseTemporaryPatientType

    return {
      submitted,
      submit,
      name,
      gender,
      phoneNumber,
      nameError,
      genderError,
      phoneNumberError,
      genders
    }
  }
})
