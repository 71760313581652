
import { computed, defineComponent, inject, ref } from 'vue'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import VueContent from '@/components/layout/vueContent.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'

export default defineComponent({
  name: 'account-setting-name',
  components: {
    NormalButton,
    IconEditBox,
    VueContent
  },
  setup () {
    const {
      changeName,
      name: defaultName
    } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { append } = inject(UseErrorKey) as UseErrorType
    const requesting = ref(false)
    const name = ref(defaultName)

    const nameError = computed(() => {
      return name.value === '' ? '名前を入力してください' : undefined
    })

    const onSubmitName = async () => {
      if (nameError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await changeName({
          name: name.value
        })
        await append('notify', '名前を変更しました')
      } catch (e) {
        console.log(e)
        await append('error', '名前変更に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      changeName,
      requesting,
      name,
      nameError,
      onSubmitName
    }
  }
})
