<template>
  <div class="permission-text" :class="meta[0]">
    <span class="permission-icon">{{meta[1]}}</span>
    <span>
      <slot></slot>
    </span>
    <span>{{meta[2]}}</span>
  </div>
</template>

<script>
export default {
  name: 'text-permission',
  props: {
    permissions: Array,
    target: String
  },
  computed: {
    meta () {
      return this.permissions.some(p => p === `${this.target}Write`)
        ? ['read-write', '○', '変更可']
        : this.permissions.some(p => p === `${this.target}Read`)
          ? ['read-only', '△', '閲覧のみ']
          : ['can-not-access', '×', 'アクセス不可']
    }
  }
}
</script>

<style lang="scss" scoped>
  .permission-text {
    font-size: $base_font_size * 0.8;

    .permission-icon {
      font-size: $base_font_size * 1.2;
      line-height: $base_font_size;
      margin-right: 4px;
    }
  }

  .read-write {
    font-weight: bold;
  }

  .can-not-access {
    opacity: 0.5;
  }
</style>
