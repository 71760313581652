
import { defineComponent, inject } from 'vue'
import { useSubmit } from '@/composables/useSubmit'
import { UsePatientType, UsePatientKey } from '@/composables/patient/usePatient'
import ValidateEditBox from '@/components/molecules/editbox/validateEditBox.vue'
import DateAndAgeInquiry from '@/components/organisms/inquiry/DateAndAgeInquiry.vue'
import SingleSelectInquiry from '@/components/organisms/inquiry/SingleSelectInquiry.vue'
import AddressListInquiry from '@/components/organisms/inquiry/AddressListInquiry.vue'
import PrefInquiry from '@/components/organisms/inquiry/PrefInquiry.vue'
import NumberListInquiry from '@/components/organisms/inquiry/NumberListInquiry.vue'

interface Props {
  page: number
}

export default defineComponent({
  name: 'patient-page',
  components: {
    NumberListInquiry,
    PrefInquiry,
    AddressListInquiry,
    DateAndAgeInquiry,
    SingleSelectInquiry,
    ValidateEditBox
  },
  props: {
    page: {
      type: Number,
      required: true
    },
    show: Boolean,
    next: Function
  },
  setup (props: Props) {
    const { submitted, submit } = useSubmit()
    const {
      inquiry: { inquiryPages },
      zipCode,
      values,
      validationErrors,
      setPref,
      setCity,
      setAddress
    } = inject(UsePatientKey) as UsePatientType

    return {
      submitted,
      pageTitle: inquiryPages.value[props.page].pageTitle,
      inquiries: inquiryPages.value[props.page].inquiries,
      zipCode,
      values,
      validationErrors,
      setPref,
      setCity,
      setAddress,
      submit
    }
  }
})
