
import {
  computed,
  defineComponent, ref
} from 'vue'
import { useModal } from '@/composables/useModal'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'

export default defineComponent({
  name: 'floating',
  components: { WhiteBackdrop },
  setup () {
    const {
      show,
      open: openModel,
      dismiss
    } = useModal()
    const left = ref<number>(0)
    const top = ref<number>(0)

    const open = (x: number, y: number) => {
      left.value = x
      top.value = y
      openModel()
    }

    // TODO: SFC style CSS variable
    const pos = computed(() => {
      return {
        left: left.value + 'px',
        top: top.value + 'px'
      }
    })

    return {
      show,
      open,
      dismiss,
      pos
    }
  }
})
