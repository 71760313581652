import { InjectionKey, ref } from 'vue'
import { AcuItem as AcuItemModel } from '@/models'
import { BodyParts, Direction } from '@/API'
import { DataStore } from 'aws-amplify'

type AcuItemBodyPartsMap = {[key:string]: AcuItemModel | undefined}
type AcuItemDirectionMap = {[key:string]: AcuItemBodyPartsMap}

export const useAcuItemSubscription = () => {
  const acuItems = ref<AcuItemDirectionMap>({})
  const subscribing = ref(false)

  const fetchAcuItem = async () => {
    try {
      const items = await DataStore.query(AcuItemModel)
      const newItems:AcuItemDirectionMap = {
        [Direction.front]: _bodyParts(),
        [Direction.right]: _bodyParts(),
        [Direction.back]: _bodyParts(),
        [Direction.left]: _bodyParts()
      }
      items.forEach(item => {
        newItems[item.direction][item.bodyParts] = item
      })
      acuItems.value = newItems
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchAcuItem()
      const subscription = DataStore.observe(AcuItemModel).subscribe(async () => {
        await fetchAcuItem()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _bodyParts = () => {
    const parts: AcuItemBodyPartsMap = {}
    Object.entries(BodyParts).forEach(([, value]) => {
      parts[value] = undefined
    })
    return parts
  }

  return {
    acuItems,
    fetchAcuItem,
    subscribe
  }
}

export type UseAcuItemSubscriptionType = ReturnType<typeof useAcuItemSubscription>
export const UseAcuItemSubscriptionKey: InjectionKey<UseAcuItemSubscriptionType> = Symbol('AcuItemSubscription')
