
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import { v4 as uuid } from 'uuid'
import IconButtonLarge from '@/components/atoms/button/iconButtonLarge.vue'
import { toHalf } from '@/plugins/functions'

type Props = {
  title?: string | undefined
  placeholder?: string | undefined
  modelValue?: string | undefined
  readonly: boolean
  validation?: string | undefined
  required: boolean
  timeUnit: number
}

export default defineComponent({
  name: 'validate-edit-box-time',
  components: {
    IconButtonLarge
  },
  props: {
    title: String,
    placeholder: String,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    },
    validation: String,
    required: {
      type: Boolean,
      default: false
    },
    timeUnit: {
      type: Number,
      default: 30
    }
  },
  setup (props: Props, context) {
    const id = uuid()
    const focusRef = ref<HTMLElement|null>(null)

    const focus = () => {
      focusRef.value?.focus()
    }

    const inputClass = computed<string>(() => {
      return [
        'validate-edit-box-box',
        props.readonly ? 'edit-box-readonly' : '',
        props.validation !== undefined ? 'edit-box-validation-invalid' : ''
      ].join(' ')
    })

    const onIncreaseHour = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      let hour = parseInt(toHalf(props.modelValue!.substr(0, 2)))
      const minute = parseInt(toHalf(props.modelValue!.substr(2, 2)))
      hour++
      if (hour >= 24) {
        hour = 0
      }
      context.emit('update:modelValue', ('0' + hour).slice(-2) + ('0' + minute).slice(-2))
    }

    const onIncreaseMinute = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      let hour = parseInt(toHalf(props.modelValue!.substr(0, 2)))
      let minute = parseInt(toHalf(props.modelValue!.substr(2, 2)))
      minute = minute - minute % props.timeUnit + props.timeUnit
      if (minute >= 60) {
        minute -= 60
        hour++
      }
      if (hour >= 24) {
        hour = 0
      }
      context.emit('update:modelValue', ('0' + hour).slice(-2) + ('0' + minute).slice(-2))
    }

    const onDecreaseMinute = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      let hour = parseInt(toHalf(props.modelValue!.substr(0, 2)))
      let minute = parseInt(toHalf(props.modelValue!.substr(2, 2)))
      if (minute % props.timeUnit > 0) {
        minute -= minute % props.timeUnit
      } else {
        minute -= props.timeUnit
      }
      if (minute < 0) {
        minute += 60
        hour--
      }
      if (hour < 0) {
        hour = 23
      }
      context.emit('update:modelValue', ('0' + hour).slice(-2) + ('0' + minute).slice(-2))
    }

    const onDecreaseHour = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      let hour = parseInt(toHalf(props.modelValue!.substr(0, 2)))
      const minute = parseInt(toHalf(props.modelValue!.substr(2, 2)))
      hour--
      if (hour < 0) {
        hour = 23
      }
      context.emit('update:modelValue', ('0' + hour).slice(-2) + ('0' + minute).slice(-2))
    }

    return {
      id,
      focusRef,
      focus,
      inputClass,
      onIncreaseHour,
      onIncreaseMinute,
      onDecreaseMinute,
      onDecreaseHour
    }
  }
})
