import { computed, InjectionKey, ref } from 'vue'
import { API, graphqlOperation } from 'aws-amplify'
import { listInquiryAnswer } from '@/graphql/queries'
import { onAnswerInquiry } from '@/graphql/subscriptions'
import { Observable } from 'zen-observable-ts'
import { AnswerStatus, CreatePatientInput, Display, Gender, InquiryAnswer, ListInquiryAnswerResult } from '@/API'
import patientInquiry from '@/composables/inquieryAnswer/patientInquiry.json'

import { KeyValue } from '@/composables/commonTypes'
import { Inquiry } from '@/composables/patient/types'

interface ListResult {
  data?: {
    listInquiryAnswer: ListInquiryAnswerResult
  }
}

interface SubscribeResult {
  value: {
    data?: {
      onAnswerInquiry: InquiryAnswer
    }
  }
}

interface PatientInquiryAnswer {
  answer: InquiryAnswer
  patient: CreatePatientInput
  info: KeyValue[]
}

export const useInquiryAnswerSubscription = () => {
  const answers = ref<InquiryAnswer[]>([])
  const subscribing = ref(false)

  const _list = async () => {
    answers.value = []
    try {
      let nextToken: string | null = null
      while (true) {
        const res = await API.graphql(graphqlOperation(listInquiryAnswer, {
          filter: {
            answerStatus: AnswerStatus.unprocessed
          },
          nextToken
        })) as ListResult
        if (!res.data) {
          break
        }
        answers.value.push(...res.data.listInquiryAnswer.items)

        if (!res.data.listInquiryAnswer.nextToken) {
          break
        }
        nextToken = res.data.listInquiryAnswer.nextToken
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe inquiry without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('inquiry answer already subscribing'))
    }

    try {
      await _list()
      const subscription = (API.graphql(graphqlOperation(onAnswerInquiry, { clinicId: clinicId })) as Observable<SubscribeResult>)
        .subscribe({
          next: (value) => {
            if (value.value.data) {
              const newAnswer = value.value.data.onAnswerInquiry
              const index = answers.value.findIndex(a => a.id === newAnswer.id)
              if (index >= 0) {
                if ([AnswerStatus.toBeDeleted, AnswerStatus.toBeDeleted].includes(newAnswer.answerStatus)) {
                  answers.value = answers.value.filter(a => a.id !== newAnswer.id)
                } else {
                  const newValue = [...answers.value]
                  newValue.splice(index, 1, newAnswer)
                  answers.value = newValue
                }
              } else {
                answers.value.push(newAnswer)
              }
            }
          },
          error: (e) => {
            console.log(e)
          }
        })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const patientAnswers = computed<PatientInquiryAnswer[]>(() => {
    return answers.value
      .filter(a => a.inquiryId === 'patientInquiry' && a.answerStatus === AnswerStatus.unprocessed)
      .sort((a, b) => a.createdAt > b.createdAt
        ? 1
        : a.createdAt < b.createdAt
          ? -1
          : 0
      )
      .map(a => {
        return {
          answer: a,
          patient: _makePatient(a.answer),
          info: _makePatientInfo(a.answer)
        }
      })
  })

  const existUnprocessed = computed(() => {
    return answers.value.some(a => a.answerStatus === AnswerStatus.unprocessed)
  })

  const _makePatient = (answer: string): CreatePatientInput => {
    const answerJson = JSON.parse(answer) as {[key:string]: string}
    const phoneNumber: string[] = []
    if (answerJson.phoneNumber1 && answerJson.phoneNumber1 !== '') {
      phoneNumber.push(answerJson.phoneNumber1)
    }
    if (answerJson.phoneNumber2 && answerJson.phoneNumber2 !== '') {
      phoneNumber.push(answerJson.phoneNumber2)
    }

    return {
      patientNumber: '',
      name: answerJson.name,
      read: answerJson.read,
      gender: answerJson.gender as Gender,
      birthDate: answerJson.birthDate,
      private: {
        zipCode: answerJson.zipCode,
        pref: answerJson.pref,
        city: answerJson.city,
        address: answerJson.address,
        phoneNumber
      },
      display: Display.show
    }
  }

  const _makePatientInfo = (answer: string): KeyValue[] => {
    const answerJson = JSON.parse(answer)
    return (patientInquiry as Inquiry).inquiryPages.flatMap(page => {
      return page.inquiries
        .filter(inq => inq.key !== 'zipId')
        .map(inq => {
          if (inq.valueType === 'radio') {
            const option = inq.options!.find(o => o.value === answerJson[inq.key])
            return {
              key: inq.title,
              value: option!.name
            }
          } else {
            return {
              key: inq.title,
              value: answerJson[inq.key]
            }
          }
        })
    })
  }

  return {
    answers,
    patientAnswers,
    existUnprocessed,
    subscribe
  }
}

export type UseInquiryAnswerSubscriptionType = ReturnType<typeof useInquiryAnswerSubscription>
export const UseInquiryAnswerSubscriptionKey: InjectionKey<UseInquiryAnswerSubscriptionType> = Symbol('answer')
