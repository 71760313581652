<template>
  <div>
    <h2>
      <slot name="title"></slot>
    </h2>
    <vue-content-item-frame>
      <key-value-frame
        :items="items"
        :wide="$mq === 'mobile'">
        <slot name="message"></slot>
      </key-value-frame>
    </vue-content-item-frame>
  </div>
</template>

<script>
import KeyValueFrame from '@/components/molecules/keyValueFrame.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'

export default {
  name: 'confirm-page',
  components: {
    VueContentItemFrame,
    KeyValueFrame
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
