<template>
  <div class="title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-title'
}
</script>

<style lang="scss" scoped>
  .title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
</style>
