<template>
  <div class="list-row-item-button-frame">
    <template v-for="(button, index) in buttons">
      <normal-button
        class="list-row-item-button"
        v-if="button.value!==''"
        :key="columnId+'-'+index"
        :state="button.state"
        :disabled="!!button.disabled"
        @click="$emit('click', button.emitType)">{{ button.value }}
      </normal-button>
      <div
        class="list-row-item-button"
        v-if="button.value===''"
        :key="columnId+'-'+index"></div>
    </template>
  </div>
</template>

<script>
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default {
  name: 'list-row-item-multi-button',
  components: {
    NormalButton
  },
  props: {
    buttons: Array,
    columnId: String
  },
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.list-row-item-button-frame {
  display: flex;
  flex-direction: column;
}

.list-row-item-button {
  margin: 3px 0;
  height: $form_button_height;
}
</style>
