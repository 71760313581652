
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import { UsePatientListKey, UsePatientListType } from '@/composables/patient/usePatientList'
import {
  UseTemporaryPatientKey,
  UseTemporaryPatientType
} from '@/composables/appointment/useTemporaryPatient'
import { UseAppointmentKey, UseAppointmentType } from '@/composables/appointment/useAppointment'
import {
  UseAccountAppointmentKey,
  UseAccountAppointmentType
} from '@/composables/appointment/useAccountAppointment'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import SelectBox from '@/components/atoms/selectBox.vue'
import TimeEditBox from '@/components/molecules/timeEditBox.vue'
import VueContent from '@/components/layout/vueContent.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'

export default defineComponent({
  name: 'patient-time-form',
  components: {
    AlertMessage,
    VueContent,
    TimeEditBox,
    SelectBox
  },
  setup () {
    const { patientInfo: selectedPatientInfo } = inject(UsePatientListKey) as UsePatientListType
    const { patientInfo: temporaryPatientInfo } = inject(UseTemporaryPatientKey) as UseTemporaryPatientType
    const {
      patientType,
      baseDateString,
      startTime,
      endTime,
      overlap
    } = inject(UseAppointmentKey) as UseAppointmentType
    const {
      accounts,
      selectedAccount
    } = inject(UseAccountAppointmentKey) as UseAccountAppointmentType
    const {
      setting: { appointmentTimeUnit }
    } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

    const patientInfo = computed(() => {
      return patientType.value === 'sel' ? selectedPatientInfo.value : temporaryPatientInfo.value
    })

    return {
      baseDateString,
      startTime,
      endTime,
      overlap,
      patientInfo,
      accounts,
      selectedAccount,
      appointmentTimeUnit
    }
  }
})
