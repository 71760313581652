import { InjectionKey, ref } from 'vue'
import { ErrorDismissTime } from '@/config'
import { v4 as uuid } from 'uuid'

export const ErrorTypes = ['notify', 'warning', 'error'] as const
export type ErrorType = typeof ErrorTypes[number]

export interface ErrorMessage {
  id: string
  message: string
  errorType: ErrorType
  timer: NodeJS.Timeout | null
}

export const useError = () => {
  const errors = ref<ErrorMessage[]>([])

  const append = async (errorType: ErrorType, message: string, forever = false) => {
    const id = uuid()
    const timer = forever
      ? null
      : setTimeout(() => {
        _remove(id)
      }, ErrorDismissTime * 1000)

    errors.value.push({
      id,
      message,
      errorType,
      timer
    })
  }

  const manualRemove = async (id: string) => {
    const target = errors.value.find(err => err.id === id)
    if (!target) {
      return
    }
    if (target.timer) {
      clearTimeout(target.timer as NodeJS.Timeout)
    }
    _remove(id)
  }

  const _remove = (id: string) => {
    errors.value = errors.value.filter(err => err.id !== id)
  }

  return {
    errors,
    append,
    manualRemove
  }
}

export type UseErrorType = ReturnType<typeof useError>
export type AppendFunc = (errorType: ErrorType, message: string, forever?: boolean) => Promise<void>
export const UseErrorKey: InjectionKey<UseErrorType> = Symbol('errors')
