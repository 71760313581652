<template>
  <div
    class="time-table-no-hour"
    v-if="hourRange.length === 0">本日の営業時間はありません。(予約することは可能です。)
  </div>
  <div
    class="time-table"
    v-else>
    <time-range
      v-for="(h, i) in hourRange"
      :key="'time'+i"
      :hour="h"
      @hour="onHour">
    </time-range>
    <svg class="time-overlay">
      <rect
        v-for="(timeTable, index) in timeTableList"
        :key="'time-table'+index"
        v-show="timeTable.close"
        x="0"
        :y="timeTable.y"
        width="100%"
        :height="timeTable.height"
        class="time-rect-close">
      </rect>
      <g
        v-for="(timeTable, index) in appointmentTimeTableList"
        :key="'appointment-'+index"
        @click="$emit('appointment', appointmentList[index])">
        <rect
          :x="timeTable.x"
          :y="timeTable.y"
          :width="timeTable.width"
          :height="timeTable.height"
          rx="8"
          ry="8"
          :class="[timeTable.selected ? 'time-rect-register' : 'time-rect-appointment']"></rect>
        <text
          :x="timeTable.x"
          :y="timeTable.y"
          dx="4"
          dy="16"
          class="time-text-appointment">{{ timeTable.startTime }}-{{ timeTable.endTime }}
        </text>
        <text
          v-if="appointmentList[index].temporaryPatient"
          :x="timeTable.x"
          :y="timeTable.y"
          dx="4"
          dy="32"
          class="day-text-appointment">{{ timeTable.patientName }} 様 (仮登録患者)
        </text>
        <text
          v-else
          :x="timeTable.x"
          :y="timeTable.y"
          dx="4"
          dy="32"
          class="day-text-appointment">{{ timeTable.patientName }} 様
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import TimeRange from '@/components/atoms/timeRange.vue'

export default {
  name: 'time-table',
  components: { TimeRange },
  props: {
    hourRange: Array,
    hourList: Array,
    appointmentList: Array,
    startTime: String,
    endTime: String
  },
  methods: {
    onHour (hour) {
      this.$emit('update:startTime', ('0' + hour).slice(-2) + '00')
      this.$emit('update:endTime', ('0' + (hour + 1)).slice(-2) + '00')
    },
    hourValue (time) {
      const start = this.hourRange[0]
      const end = this.hourRange[this.hourRange.length - 1] + 1
      const h = parseInt(time.substr(0, 2))
      const m = parseInt(time.substr(3, 2))
      return (h + m / 60 - start) / (end - start) * 100
    },
    calcOverlap () {
      let maxColumn = 0
      this.appointmentList.forEach((appointment, index) => {
        let mask = 0
        this.appointmentList.forEach((appointmentComp, indexComp) => {
          if (index <= indexComp) {
            return
          }
          if (this.isOverlap(index, indexComp)) {
            mask |= (1 << appointmentComp.column)
          }
        })
        let column = 0
        while (true) {
          if ((mask & 1) === 0) {
            break
          }
          mask >>= 1
          column++
        }
        appointment.column = column
        if (maxColumn < column) {
          maxColumn = column
        }
      })

      return maxColumn + 1
    },
    isOverlap (i, j) {
      const si = this.appointmentList[i].appointment.startTime
      const ei = this.appointmentList[i].appointment.endTime
      const sj = this.appointmentList[j].appointment.startTime
      const ej = this.appointmentList[j].appointment.endTime

      return si < ej && ei > sj
    }
  },
  computed: {
    timeTableList () {
      if (this.hourList.length === 0) {
        return [{
          y: '0',
          height: '100%',
          close: true
        }]
      }

      const timeTableList = []
      this.hourList.reduce((lastHour, currentHour) => {
        if (lastHour === null) {
          timeTableList.push({
            y: '0',
            height: '' + this.hourValue(currentHour.businessHour.startTime).toFixed(3) + '%',
            close: true
          })
        } else {
          const start = this.hourValue(lastHour.businessHour.endTime)
          const end = this.hourValue(currentHour.businessHour.startTime)
          timeTableList.push({
            y: '' + start.toFixed(3) + '%',
            height: '' + (end - start).toFixed(3) + '%',
            close: true
          })
        }
        const start = this.hourValue(currentHour.businessHour.startTime)
        const end = this.hourValue(currentHour.businessHour.endTime)
        timeTableList.push({
          y: '' + start.toFixed(3) + '%',
          height: '' + (end - start).toFixed(3) + '%',
          close: currentHour.close
        })

        return currentHour
      }, null)
      const start = this.hourValue(this.hourList[this.hourList.length - 1].businessHour.endTime)
      timeTableList.push({
        y: '' + start.toFixed(3) + '%',
        height: '' + (100 - start).toFixed(3) + '%',
        close: true
      })

      return timeTableList
    },
    appointmentTimeTableList () {
      if (this.appointmentList.length === 0) {
        return []
      }

      const maxOverlap = this.calcOverlap()

      return this.appointmentList.map((appointment) => {
        const start = this.hourValue(appointment.appointment.startTime)
        const end = this.hourValue(appointment.appointment.endTime)
        return {
          id: appointment.appointment.id,
          startTime: appointment.appointment.startTime,
          endTime: appointment.appointment.endTime,
          patientName: appointment.patientName,
          selected: appointment.selected,
          x: (appointment.column / maxOverlap * 100 + 0.5).toFixed(3) + '%',
          y: '' + (start + 0.3).toFixed(3) + '%',
          width: (1 / maxOverlap * 100 - 1).toFixed(3) + '%',
          height: '' + (end - start - 0.6).toFixed(3) + '%'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-no-hour {
  height: 50vh;
  line-height: 50vh;
  text-align: center;
}

.time-table {
  position: relative;
  display: flex;
  flex-direction: column;
}

.time-overlay {
  position: absolute;
  top: 0;
  left: 8%;
  height: 100%;
  width: 92%;
}

.time-rect-close {
  fill: $base_color_gray_light;
  fill-opacity: .7;
}

.time-rect-register {
  fill: $base_color_red_light;
  fill-opacity: .7;
}

.time-rect-appointment {
  fill: $base_color_green_light;
  fill-opacity: .7;
}

.day-text-appointment {
  font-size: 0.9em;
}

.day-text-temporary {
  fill: none;
  stroke: $base_color_gray;
  stroke-width: 4px;
}
</style>
