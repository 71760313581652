import { InjectionKey, ref } from 'vue'
import { Holiday } from '@/API'
import { API, graphqlOperation } from 'aws-amplify'
import { listHoliday } from '@/graphql/queries'
import dayjs from 'dayjs'

type HolidayMap = { [key:string]: Holiday }

interface ListHolidayResult {
  data?: {
    listHoliday: Holiday[]
  }
}

export const useHoliday = () => {
  const holidayMap = ref<HolidayMap>({})
  const subscribing = ref(false)

  const fetchHoliday = async () => {
    try {
      const res = await API.graphql(graphqlOperation(listHoliday)) as ListHolidayResult
      const newMap: HolidayMap = {}
      res.data?.listHoliday.forEach(h => {
        newMap[h.date] = h
      })
      holidayMap.value = newMap
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // 祝日は購読するタイプのAPIではないが、施設ログイン時のみ取得するようにするため
  // 他と合わせてsubscribe関数を用意する
  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not fetch holiday without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('holiday already fetching'))
    }

    try {
      await fetchHoliday()
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const holidayName = (year: number, month: number, day: number): string => {
    const d = dayjs(new Date(year, month, day)).format('YYYY-MM-DD')
    return holidayMap.value[d] ? holidayMap.value[d].name : ''
  }

  return {
    holidayMap,
    fetchHoliday,
    subscribe,
    holidayName
  }
}

export type UseHolidayType = ReturnType<typeof useHoliday>
export const UseHolidayKey: InjectionKey<UseHolidayType> = Symbol('Holiday')
