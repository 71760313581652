<template>
  <div class="control-frame">
    <label class="control-label" :class="[$mq, required ? 'required' : '']">
      <slot name="label"></slot>
    </label>
    <div
      class="control-content"
      :class="$mq">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue-label',
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.control-frame {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.control-label {
  display: inline-block;
  position: relative;
  width: 25%;
  text-align: right;
  padding-right: 5px;

  &.required {
    &:before {
      position: relative;
      display: inline-block;
      line-height: $form_item_height / 2;
      top: $form_item_height / 4;
      left: -10px;
      content: '*';
      color: $base_color_red;
      font-size: 2em;
    }
  }

  &.mobile {
    width: 30%;
    font-size: 0.9em;
  }
}

.control-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  box-sizing: border-box;

  &.mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
  }
}
</style>
