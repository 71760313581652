
import {
  defineComponent
} from 'vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import { useModal } from '@/composables/useModal'

export default defineComponent({
  name: 'delete-business-hour',
  components: {
    WhiteBackdrop,
    NormalButton
  },
  props: {
    once: Boolean,
    close: Boolean,
    closeDay: Boolean
  },
  emits: ['delete', 'createTemporaryClose', 'deleteTemporaryClose', 'cancel'],
  setup (props, context) {
    const {
      show,
      open,
      dismiss
    } = useModal()

    const onDelete = () => {
      dismiss()
      context.emit('delete')
    }
    const onCreateTemporaryClose = () => {
      dismiss()
      context.emit('createTemporaryClose')
    }
    const onDeleteTemporaryClose = () => {
      dismiss()
      context.emit('deleteTemporaryClose')
    }
    const onCancel = () => {
      dismiss()
      context.emit('cancel')
    }

    return {
      show,
      open,
      dismiss,
      onDelete,
      onCreateTemporaryClose,
      onDeleteTemporaryClose,
      onCancel
    }
  }
})
