import { v4 as uuid } from 'uuid'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  BusinessHour as BusinessHourModel,
  TemporaryClose as TemporaryCloseModel
} from '@/models'
import { CreateBusinessHourInput } from '@/API'
import { createBusinessHour, createTemporaryClose } from '@/graphql/mutations'

export const useBusinessHourMutation = () => {
  const registerBusinessHour = async ({ businessHour }: {
    businessHour: CreateBusinessHourInput
  }) => {
    try {
      businessHour.id = uuid()
      await API.graphql(graphqlOperation(createBusinessHour, { input: businessHour }))
    } catch (e) {
      return Promise.reject(new Error('営業時間の登録に失敗しました'))
    }
  }

  const deleteBusinessHour = async (id: string) => {
    try {
      const original = await DataStore.query(BusinessHourModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('営業時間の削除に失敗しました'))
      }
      await DataStore.delete<BusinessHourModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const registerTemporaryClose = async (businessHourId: string, date: string) => {
    try {
      const input = {
        id: uuid(),
        businessHourId,
        date
      }
      await API.graphql(graphqlOperation(createTemporaryClose, { input }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const deleteTemporaryClose = async (id: string) => {
    try {
      const original = await DataStore.query(TemporaryCloseModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('営業日に戻すのに失敗しました'))
      }
      await DataStore.delete<TemporaryCloseModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerBusinessHour,
    deleteBusinessHour,
    registerTemporaryClose,
    deleteTemporaryClose
  }
}

export type UseBusinessHourMutationType = ReturnType<typeof useBusinessHourMutation>
