<template>
  <div class="text-column">
    <div
      class="text"
      v-for="(value, index) in values"
      :key="columnId+'-'+index">{{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-text-multiline',
  props: {
    values: Array,
    columnId: String
  }
}
</script>

<style lang="scss" scoped>
.text-column {
  display: flex;
  flex-direction: column;
}

.text {
  word-break: break-all;
}
</style>
