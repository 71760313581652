<template>
  <div :class="buttonClass">
    <fa-icon :icon="icon"></fa-icon>
  </div>
</template>

<script>
export default {
  name: 'icon-button',
  props: {
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      required: true,
      default: 'normal',
      validator (value) {
        return ['normal', 'normal-green', 'normal-gray', 'warning', 'delete', 'confirm'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    buttonClass () {
      return [
        'normal-button',
        this.disabled ? 'button-disable-' + this.state : 'button-enable-' + this.state
      ].join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.normal-button {
  height: $icon_button_size;
  width: $icon_button_size;
  border-radius: $border_radius;
  border: none;
  font-size: $base_font_size * 0.9;
  font-weight: bold;
  line-height: $icon_button_size;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  user-select: none;
}

.button-enable-normal {
  background-color: $base_color_water_dark;
  color: $base_color_water_darker;

  &:hover {
    background-color: $base_color_water;
  }
}

.button-enable-normal-green {
  background-color: $base_color_green;
  color: $base_color_green_dark;

  &:hover {
    background-color: $base_color_green_light;
  }
}

.button-enable-normal-gray {
  background-color: $base_color_gray;
  color: $base_color_gray_darker;

  &:hover {
    background-color: $base_color_gray_light;
  }
}

.button-enable-warning {
  background-color: $base_color_yellow;
  color: $base_color_yellow_dark;

  &:hover {
    background-color: $base_color_yellow_light;
  }
}

.button-enable-delete {
  background-color: $base_color_red;
  color: $base_color_red_dark;

  &:hover {
    background-color: $base_color_red_light;
  }
}

.button-enable-confirm {
  background-color: $base_color_gray_lighter;
  color: $base_color_black;
  border: 1px solid $base_color_dark;

  &:hover {
    box-shadow: 0 0 5px $base_color_dark;
    border-width: 2px;
    padding: 3px 9px;
    background-color: $base_color_gray_light;
  }
}

.button-disable-normal {
  background-color: $base_color_gray_light;
  color: $base_color_gray_dark;
  cursor: default;
}

.button-disable-normal-green {
  background-color: $base_color_gray_light;
  color: $base_color_green_dark;
  cursor: default;
}

.button-disable-warning {
  background-color: $base_color_gray_light;
  color: $base_color_gray_dark;
  cursor: default;
}

.button-disable-delete {
  background-color: $base_color_gray_light;
  color: $base_color_gray_dark;
  cursor: default;
}
</style>
