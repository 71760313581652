
import {
  defineComponent,
  ref,
  inject,
  onMounted
} from 'vue'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseInquiryAnswerSubscriptionKey, UseInquiryAnswerSubscriptionType } from '@/composables/inquieryAnswer/useInquiryAnswerSubscription'
import { today, thisYear, thisMonth } from '@/plugins/computed'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import BadgeFrame from '@/components/molecules/BadgeFrame.vue'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { useLogout } from '@/composables/useLogout'

export default defineComponent({
  name: 'dashboard',
  components: {
    BadgeFrame,
    VueTitle,
    VueFrame,
    NormalButton,
    Confirm
  },
  setup () {
    const confirmRef = ref<InstanceType<typeof Confirm>>()

    const {
      authenticated
    } = inject(UseAuthenticationKey) as UseAuthenticationType
    const {
      getClinicName,
      getAccessAppointment,
      clinicId,
      clinicOwner,
      accountWrite,
      patientWrite,
      patientRead,
      calendarWrite,
      calendarRead,
      dataWrite,
      dataRead,
      settingWrite,
      acceptAppointment,
      entering
    } = inject(UseClinicKey) as UseClinicType
    const { intoDashboard, logout } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { patientAnswers } = inject(UseInquiryAnswerSubscriptionKey) as UseInquiryAnswerSubscriptionType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType
    const { signOutAll } = useLogout()

    const onLogout = () => {
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        await lockScreen()
        await signOutAll()
        await logout()
      } catch (e) {
        console.log(e)
      } finally {
        await unlockScreen()
      }
    }

    onMounted(async () => {
      try {
        await getClinicName()
        await getAccessAppointment()
        intoDashboard()
      } catch (e) {
        console.log(e)
      }
    })

    return {
      authenticated,
      today,
      thisYear,
      thisMonth,
      clinicId,
      clinicOwner,
      accountWrite,
      patientWrite,
      patientRead,
      calendarWrite,
      calendarRead,
      dataWrite,
      dataRead,
      settingWrite,
      acceptAppointment,
      entering,
      patientAnswers,
      onLogout,
      yes,
      confirmRef
    }
  }
})
