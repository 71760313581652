<template>
  <div class="radio-group-frame">
    <label :class="['radio-group-title', required ? 'required' : '', $mq]">
      <slot></slot>
    </label>
    <div class="control-content" :class="$mq">
      <radio-button
        v-for="(radio, index) in radios"
        :key="'radio-group-'+groupId+'-'+index"
        :checked="radio.value===modelValue"
        @click="$emit('update:modelValue', radio.value)">{{ radio.name }}
      </radio-button>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/atoms/radioButton.vue'

export default {
  name: 'radio-button-group',
  components: {
    RadioButton
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    radios: Array,
    modelValue: String,
    required: Boolean
  }
}
</script>

<style lang="scss" scoped>
.radio-group-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-group-title {
  display: inline-block;
  position: relative;
  width: 25%;
  text-align: right;
  padding-right: 5px;

  &.required {
    top: -$form_item_height / 8;

    &:before {
      position: relative;
      display: inline-block;
      line-height: $form_item_height / 2;
      top: $form_item_height / 4;
      left: -10px;
      content: '*';
      color: $base_color_red;
      font-size: 2em;
    }
  }

  &.mobile {
    top: -$form_item_height / 8;
    align-items: flex-start;
    width: 30%;
    font-size: 0.9em;
  }
}

.control-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  box-sizing: border-box;

  &.mobile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
  }
}
</style>
