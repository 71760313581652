
import {
  computed,
  defineComponent, inject,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import { useSubmit } from '@/composables/useSubmit'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import VueLoginFrame from '@/components/layout/vueLoginFrame.vue'
import VueLoginForm from '@/components/layout/vueLoginForm.vue'
import Logo from '@/components/atoms/logo.vue'
import LinkText from '@/components/atoms/linkText.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { emailValidation, passwordValidation } from '@/plugins/validator'

export default defineComponent({
  name: 'login',
  components: {
    VueLoginFrame,
    VueLoginForm,
    Logo,
    LinkText,
    IconEditBox,
    NormalButton
  },
  setup () {
    const router = useRouter()
    const { submitted, submit } = useSubmit()
    const { signInAuth } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { signInClinic } = inject(UseClinicKey) as UseClinicType
    const { append } = inject(UseErrorKey) as UseErrorType

    const requesting = ref(false)
    const userId = ref('')
    const password = ref('')

    const userIdError = computed(() => {
      return emailValidation(userId.value)
    })

    const passwordError = computed(() => {
      return passwordValidation(password.value)
    })

    const onLogin = async () => {
      submit()
      if (userIdError.value !== undefined ||
        passwordError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await signInAuth({
          username: userId.value,
          password: password.value
        })
        await signInClinic()
        await router.push('/dashboard')
      } catch (e) {
        console.log(e)
        await append('error', 'ログインに失敗しました')
      }
    }

    return {
      submitted,
      requesting,
      userId,
      userIdError,
      password,
      passwordError,
      onLogin,
      userIdRef: ref(null),
      passwordRef: ref(null),
      submitRef: ref(null)
    }
  }
})
