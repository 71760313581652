import { InjectionKey, ref } from 'vue'
import { Gender, Patient as PatientModel } from '@/models'
import { DataStore } from 'aws-amplify'
import { genderString, PatientDetail } from '@/composables/patient/types'
import { age, formatYMD } from '@/plugins/functions'

export const usePatientSubscription = () => {
  const patients = ref<PatientDetail[]>([])
  const subscribing = ref(false)

  const fetchPatient = async () => {
    try {
      const data = await DataStore.query(PatientModel)
      patients.value = data.map(p => {
        return {
          patient: p,
          birthDateString: formatYMD(p.birthDate),
          age: age(p.birthDate),
          genderString: genderString(p.gender as Gender),
          address: p.private
            ? `${p.private.pref} ${p.private.city} ${p.private.address}`
            : ''
        }
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe patient without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('patient already subscribing'))
    }

    try {
      await fetchPatient()
      const subscription = DataStore.observe(PatientModel).subscribe(async () => {
        await fetchPatient()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const getPatient = (id: string) => {
    return patients.value.find(p => p.patient.id === id)
  }

  return {
    patients,
    fetchPatient,
    subscribe,
    getPatient
  }
}

export type UsePatientSubscriptionType = ReturnType<typeof usePatientSubscription>
export const UsePatientSubscriptionKey: InjectionKey<UsePatientSubscriptionType> = Symbol('PatientSubscription')
