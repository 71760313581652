
import {
  defineComponent,
  inject
} from 'vue'
import {
  RouterViewTransitionKey,
  RouterViewTransitionType
} from '@/composables/useRouterViewTransition'

export default defineComponent({
  name: 'back-button',
  setup () {
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    return {
      back
    }
  }
})
