<template>
  <div class="description">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'page-description'
}
</script>

<style lang="scss" scoped>
  .description {
    width: 90%;
    margin: 0 auto;
    font-size: 0.8em;
  }
</style>
