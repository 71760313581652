
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import ListTitleMultiline from '@/components/molecules/listTitleMultiline.vue'
import ListRow from '@/components/molecules/listRow.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import VueContent from '@/components/layout/vueContent.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import { VueMqType } from 'vue3-mq'
import { UsePatientListKey, UsePatientListType } from '@/composables/patient/usePatientList'
import { useDelay } from '@/composables/useDelay'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useRouter } from 'vue-router'
import { Patient as PatientModel } from '@/models'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'patient-list',
  components: {
    LoadingMessage,
    VueContent,
    VueYScrollArea,
    ListTitleMultiline,
    ListRow
  },
  setup () {
    const router = useRouter()
    const { clinicId } = inject(UseClinicKey) as UseClinicType
    const {
      patientItemMap,
      patientOrder,
      searchWord,
      show
    } = inject(UsePatientListKey) as UsePatientListType
    const mq = inject('mq') as VueMqType
    const { delayed } = useDelay(500)

    const onClick = async (emitType: string, patient: PatientModel) => {
      switch (emitType) {
        case 'karte': {
          const now = dayjs()
          const params = {
            y: now.year(),
            m: now.month(),
            d: now.date(),
            p: patient.id
          }
          const query = Object.entries(params)
            .map(([k, v]) => {
              return `${k}=${v}`
            })
            .join('&')
          await router.push(`/w/${clinicId.value}/karte_list?${query}`)
          break
        }
      }
    }

    const titles = computed(() => {
      return [
        {
          names: ['名前', 'ふりがな'],
          width: 2
        },
        {
          names: ['生年月日', '年齢'],
          width: 2
        },
        {
          names: ['性別'],
          width: 1
        },
        {
          names: ['郵便番号', '住所'],
          width: 2,
          hidden: mq.value !== 'desktop'
        },
        {
          names: ['電話番号', 'email'],
          width: 2
        },
        {
          names: ['操作'],
          width: 2,
          center: true
          // hidden: !this.permissionPatientAccess
        }
      ].filter(t => !t.hidden)
    })

    const rows = computed(() => {
      if (!delayed.value) {
        return []
      }

      return patientOrder.value
        .filter(id => show(patientItemMap.value[id].patient))
        .map(id => {
          const patientDetail = patientItemMap.value[id]
          const patient = patientDetail.patient
          return {
            patient,
            columns: [
              {
                values: [patient.name, patient.read],
                width: 2,
                type: 'emphasizedTextMultiline'
              },
              {
                values: [patientDetail.birthDateString, `${patientDetail.age} 歳`],
                width: 2,
                type: 'textMultiline'
              },
              {
                value: patientDetail.genderString,
                width: 1,
                type: 'text'
              },
              {
                values: [patient.private?.zipCode || '', patientDetail.address],
                width: 2,
                type: 'textMultiline',
                hidden: mq.value !== 'desktop'
              },
              {
                values: [
                  ...(patient.private?.phoneNumber || []),
                  ...(patient.private?.sns || []).map(sns => sns.accountId)
                ],
                width: 2,
                type: 'emphasizedTextMultiline'
              },
              {
                width: 2,
                type: 'buttons',
                buttons: [
                  {
                    value: 'カルテ一覧',
                    state: 'normal',
                    emitType: 'karte'
                  }
                ]
              // hidden: !this.permissionPatientAccess
              }
            ].filter(c => !c.hidden)
          }
        })
    })

    return {
      delayed,
      titles,
      rows,
      searchWord,
      onClick
    }
  }
})
