import { inject } from 'vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import PatientInquiry from '@/composables/patient/patient.json'
import { Inquiry } from '@/composables/patient/types'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'

// ログアウト、施設切替の際に呼ばなくてはいけないログアウト処理を以下にまとめておく
export const useLogout = () => {
  const {
    signOutClinic,
    exitClinic
  } = inject(UseClinicKey) as UseClinicType
  const {
    signOutAuth
  } = inject(UseAuthenticationKey) as UseAuthenticationType
  const { stopSync } = inject(UseFileDBKey) as UseFileDBType
  const { deleteDatabase } = inject(UseFileDBKey) as UseFileDBType

  const inquiry = PatientInquiry as Inquiry

  const exitClinicAll = async () => {
    try {
      await exitClinic()

      // db
      await stopSync()
      localStorage.removeItem('ekarte-file-que')
      await deleteDatabase()

      // karte
      localStorage.removeItem('ekarte-karte')

      // patient
      localStorage.removeItem('ekarte-patient-version')
      localStorage.removeItem(`ekarte-patient-${inquiry.version}`)

      // accountAppointment
      localStorage.removeItem('ekarte-account-appointment')
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const signOutAll = async () => {
    try {
      await signOutAuth()
      await signOutClinic()

      // db
      await stopSync()
      localStorage.removeItem('ekarte-file-que')
      await deleteDatabase()

      // karte
      localStorage.removeItem('ekarte-karte')

      // patient
      localStorage.removeItem('ekarte-patient-version')
      localStorage.removeItem(`ekarte-patient-${inquiry.version}`)

      // accountAppointment
      localStorage.removeItem('ekarte-account-appointment')
    } catch (e) {
      console.log(e)
      return Promise.reject(e)
    }
  }

  return {
    exitClinicAll,
    signOutAll
  }
}
