import { ref } from 'vue'

export const useFocus = () => {
  const focusRef = ref<HTMLElement|null>(null)

  const focus = () => {
    focusRef.value?.focus()
  }

  return {
    focusRef,
    focus
  }
}
