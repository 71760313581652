<template>
  <div class="error-message">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'editbox-error-message'
}
</script>

<style lang="scss" scoped>
  .error-message {
    color: $base_color_red_dark;
    font-size: 0.8em;
    padding: 0 10px;
  }
</style>
