import { InjectionKey, ref } from 'vue'
import { useRouter } from 'vue-router'

// ログアウト時はfadeトランジション
// ログイン時はslideInRightで、戻る動作のみslideInLeft
export const useRouterViewTransition = () => {
  const router = useRouter()
  const transition = ref('animated faster fadeIn')

  // ログイン直後にトランジションを変えるためにDashboard, ClinicDashboardから呼ぶ
  const intoDashboard = () => {
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  const logout = async () => {
    transition.value = 'animated faster fadeIn'
    await router.push('/login')
  }

  const timeout = async () => {
    transition.value = 'animated faster bounceIn'
    await router.push('/timeout')
  }

  const changeClinic = async () => {
    transition.value = 'animated faster fadeIn'
    setTimeout(async () => {
      await router.push('/dashboard')
    }, 0)
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  const root = async () => {
    transition.value = 'animated faster slideInLeft'
    setTimeout(async () => {
      await router.push('/')
    }, 0)
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  const dashboard = async () => {
    transition.value = 'animated faster slideInLeft'
    setTimeout(async () => {
      await router.push('/dashboard')
    }, 0)
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  const back = async () => {
    transition.value = 'animated faster slideInLeft'
    setTimeout(async () => {
      await router.go(-1)
    }, 0)
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  const onceFadeIn = async (to: string) => {
    transition.value = 'animated faster fadeIn'
    setTimeout(async () => {
      await router.replace(to)
    }, 0)
    setTimeout(() => {
      transition.value = 'animated faster slideInRight'
    }, 500)
  }

  return {
    transition,
    intoDashboard,
    logout,
    timeout,
    changeClinic,
    root,
    dashboard,
    back,
    onceFadeIn
  }
}

export type RouterViewTransitionType = ReturnType<typeof useRouterViewTransition>
export const RouterViewTransitionKey: InjectionKey<RouterViewTransitionType> = Symbol('RouterViewTransition')
