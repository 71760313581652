import { computed, InjectionKey, ref } from 'vue'
import dayjs from 'dayjs'
import { Gender, Appointment as AppointmentModel, TemporaryPatient } from '@/models'
import { DataStore, syncExpression } from 'aws-amplify'
import { YearAppointment } from '@/composables/appointment/types'
import { PatientDetail } from '@/composables/patient/types'

export const useAppointmentSubscription = ({ getPatient, getTemporaryPatient }: {
  getPatient: (id: string) => PatientDetail | undefined,
  getTemporaryPatient: (id: string) => TemporaryPatient | undefined
}) => {
  // subscriptionの段階でuserIdごとに予約データを持つようにする
  const appointments = ref<AppointmentModel[]>([])
  const subscribing = ref(false)

  const fetchAppointment = async () => {
    try {
      appointments.value = await DataStore.query(AppointmentModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const expression = () => {
    const lastMonth = dayjs().add(-2, 'months').format('YYYY-MM-DD')
    return syncExpression(AppointmentModel, () => {
      return a => a.date('gt', lastMonth)
    })
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchAppointment()
      const subscription = DataStore.observe(AppointmentModel).subscribe(async () => {
        await fetchAppointment()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      console.log(e)
      return Promise.reject(e)
    }
  }

  const appointmentMap = computed(() => {
    const newMap:YearAppointment = {}

    appointments.value.forEach(a => {
      const d = dayjs(a.date, 'YYYY-MM-DD')
      const year = d.format('YYYY')
      const month = d.format('MM')
      const day = d.format('DD')

      if (!Object.prototype.hasOwnProperty.call(newMap, year)) {
        newMap[year] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(newMap[year], month)) {
        newMap[year][month] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(newMap[year][month], day)) {
        newMap[year][month][day] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(newMap[year][month][day], a.accountId)) {
        newMap[year][month][day][a.accountId] = []
      }

      if (a.temporaryPatient) {
        const patient = getTemporaryPatient(a.patientId)
        newMap[year][month][day][a.accountId].push({
          patientName: patient?.name || '',
          patientGender: patient?.gender || Gender.OTHER,
          patientPhoneNumber: patient?.phoneNumber || '',
          selected: false,
          column: 0,
          appointment: a
        })
      } else {
        const patient = getPatient(a.patientId)?.patient
        newMap[year][month][day][a.accountId].push({
          patientName: patient?.name || '',
          patientGender: patient?.gender || Gender.OTHER,
          patientPhoneNumber: patient?.private!.phoneNumber && patient?.private!.phoneNumber.length > 0 ? patient?.private!.phoneNumber![0] : '',
          selected: false,
          column: 0,
          appointment: a
        })
      }
    })
    return newMap
  })

  const getAppointmentList = (year:number, month: number, day: number, accountId?: string) => {
    const y = `${year}`
    const m = ('0' + (month + 1)).slice(-2)
    const d = ('0' + day).slice(-2)

    if (accountId !== undefined) {
      return appointmentMap.value[y] && appointmentMap.value[y][m] && appointmentMap.value[y][m][d]
        ? appointmentMap.value[y][m][d][accountId] || []
        : []
    } else {
      return appointmentMap.value[y] && appointmentMap.value[y][m] && appointmentMap.value[y][m][d]
        ? Object.keys(appointmentMap.value[y][m][d]).flatMap(accountId => {
          return appointmentMap.value[y][m][d][accountId]
        })
        : []
    }
  }

  const getAppointment = (id: string) => {
    return appointments.value.find(a => a.id === id)
  }

  return {
    appointmentMap,
    fetchAppointment,
    expression,
    subscribe,
    getAppointmentList,
    getAppointment
  }
}

export type UseAppointmentSubscriptionType = ReturnType<typeof useAppointmentSubscription>
export const UseAppointmentSubscriptionKey: InjectionKey<UseAppointmentSubscriptionType> = Symbol('AppointmentSubscription')
