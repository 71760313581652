
import {
  defineComponent,
  inject,
  provide,
  ref,
  onMounted
} from 'vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useTemporaryPatient, UseTemporaryPatientKey } from '@/composables/appointment/useTemporaryPatient'
import { usePatientList, UsePatientListKey } from '@/composables/patient/usePatientList'
import { useAppointment, UseAppointmentKey } from '@/composables/appointment/useAppointment'
import {
  useAccountAppointment,
  UseAccountAppointmentKey
} from '@/composables/appointment/useAccountAppointment'
import { useAppointmentMutation } from '@/composables/appointment/useAppointmentMutation'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import TemporaryPatient from '@/views/appointment/register/TemporaryPatient.vue'
import SelectPatient from '@/views/appointment/register/SelectPatient.vue'
import Appointment from '@/views/appointment/register/Appointment.vue'
import { Patient as PatientModel } from '@/models'
import {
  CreateAppointmentInput,
  AppointmentStatus,
  CreateTemporaryPatientInput,
  Gender
} from '@/API'
import { formatHM } from '@/plugins/functions'

export default defineComponent({
  name: 'CreateAppointment',
  components: {
    Appointment,
    SelectPatient,
    TemporaryPatient,
    Confirm,
    NormalButton,
    VueButtonFrame,
    VueTitle,
    VueFrame
  },
  setup () {
    const page = ref<number>(0)
    const temporaryPatientRef = ref<InstanceType<typeof TemporaryPatient>>()
    const confirmRef = ref<InstanceType<typeof Confirm>>()

    const { append } = inject(UseErrorKey) as UseErrorType
    const { back, dashboard, onceFadeIn } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    const temporary = useTemporaryPatient()
    const {
      validTemporaryPatient,
      name: temporaryName,
      gender: temporaryGender,
      phoneNumber: temporaryPhoneNumber
    } = temporary
    provide(UseTemporaryPatientKey, temporary)

    const patientList = usePatientList()
    const {
      selectPatient,
      selectedPatient
    } = patientList
    provide(UsePatientListKey, patientList)

    const appointment = useAppointment(selectedPatient)
    const {
      patientType,
      baseDate,
      year,
      month,
      day,
      startTime,
      endTime,
      errorStartTime,
      errorEndTime,
      overlap
    } = appointment
    provide(UseAppointmentKey, appointment)

    const accountAppointment = useAccountAppointment()
    const {
      selectedAccount
    } = accountAppointment
    provide(UseAccountAppointmentKey, accountAppointment)

    const {
      registerAppointment,
      registerTemporaryPatient
    } = useAppointmentMutation()

    onMounted(async () => {
      await accountAppointment.listAccountAppointment()
    })

    const onPrev = async () => {
      if (page.value === 0) {
        await back()
      } else {
        page.value--
      }
    }

    const onNext = () => {
      if (page.value === 0 && patientType.value === 'tmp') {
        temporaryPatientRef.value?.submit()
        if (validTemporaryPatient()) {
          page.value++
        }
      }
    }

    const onAppointment = (patient:PatientModel) => {
      selectPatient(patient)
      page.value++
    }

    const onSubmit = () => {
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        let patientId: string
        if (patientType.value === 'sel') {
          patientId = selectedPatient.value!.id
        } else {
          const temporaryPatient: CreateTemporaryPatientInput = {
            name: temporaryName.value,
            gender: temporaryGender.value as Gender,
            phoneNumber: temporaryPhoneNumber.value
          }
          patientId = await registerTemporaryPatient({ temporaryPatient })
        }

        const appointment: CreateAppointmentInput = {
          patientId: patientId,
          temporaryPatient: patientType.value === 'tmp',
          accountId: selectedAccount.value,
          date: baseDate.value,
          startTime: formatHM(startTime.value),
          endTime: formatHM(endTime.value),
          appointmentStatus: AppointmentStatus.registered
        }
        await registerAppointment({ appointment })
        await append('notify', '予約登録しました。')
        await onceFadeIn(`/w/${clinicId.value}/update_appointment?y=${year.value}&m=${month.value}&d=${day.value}&a=${selectedAccount.value}`)
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    const complete = () => {
      dashboard()
    }

    return {
      patientType,
      page,
      errorStartTime,
      errorEndTime,
      overlap,
      onPrev,
      onNext,
      onAppointment,
      onSubmit,
      yes,
      complete,
      temporaryPatientRef,
      confirmRef
    }
  }
})
