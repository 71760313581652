<template>
  <div>
    <text-permission :permissions="permissions" target="account">アカウント</text-permission>
    <text-permission :permissions="permissions" target="patient">患者</text-permission>
    <text-permission :permissions="permissions" target="calendar">予約</text-permission>
    <text-permission :permissions="permissions" target="data">カルテ設定</text-permission>
    <text-permission :permissions="permissions" target="setting">設定</text-permission>
  </div>
</template>

<script>
import TextPermission from '@/components/molecules/textPermission.vue'

export default {
  name: 'list-row-item-permission',
  components: {
    TextPermission
  },
  props: {
    permissions: Array
  }
}
</script>

<style lang="scss" scoped>
  .list-row-item-permission-frame {
  }
</style>
