
import {
  defineComponent, inject, provide, ref
} from 'vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { useBusinessHour, UseBusinessHourKey } from '@/composables/businessHour/useBusinessHour'
import { useBusinessHourMutation } from '@/composables/businessHour/useBusinessHourMutation'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import VueButtonFrame from '@/components/layout/vueButtonFrame.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import Repeat from '@/views/businessHour/register/Repeat.vue'
import TimeRange from '@/views/businessHour/register/timeRange.vue'
import Confirm from '@/components/organisms/Confirm.vue'

export default defineComponent({
  name: 'Create-business-hour',
  components: {
    Confirm,
    VueButtonFrame,
    VueContentItemFrame,
    VueContent,
    VueTitle,
    VueFrame,
    Repeat,
    TimeRange,
    NormalButton
  },
  setup () {
    const { back } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const busi = useBusinessHour()
    const {
      submit,
      baseDateString,
      isError,
      businessHour
    } = busi
    provide(UseBusinessHourKey, busi)
    const { registerBusinessHour } = useBusinessHourMutation()
    const confirmRef = ref<InstanceType<typeof Confirm>>()

    const onSubmit = async () => {
      submit()
      if (isError.value) {
        return
      }
      confirmRef.value?.open()
    }

    const yes = async () => {
      try {
        await registerBusinessHour({
          businessHour: businessHour.value
        })
        await append('notify', '営業時間を追加しました')
        await back()
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      }
    }

    return {
      baseDateString,
      back,
      onSubmit,
      yes,
      confirmRef
    }
  }
})
