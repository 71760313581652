import { InjectionKey } from 'vue'
import { KarteItem as KarteItemModel } from '@/models'
import { KarteItem, CreateKarteItemInput, UpdateKarteItemInput } from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import { createKarteItem, updateKarteItem as updateKarteItemMutation } from '@/graphql/mutations'
import { getKarteItem as getKarteItemQuery } from '@/graphql/queries'

interface GetKarteItemResult {
  data?: {
    getKarteItem: KarteItem
  }
}

export const useKarteItemMutation = () => {
  const registerKarteItem = async ({ karteItem }: {
    karteItem: CreateKarteItemInput
  }) => {
    try {
      await API.graphql(graphqlOperation(createKarteItem, { input: karteItem }))
    } catch (e) {
      return Promise.reject(new Error('カルテ項目の登録に失敗しました'))
    }
  }

  const updateKarteItem = async ({ karteItem }: {
    karteItem: UpdateKarteItemInput
  }) => {
    try {
      const currentData = await _getCurrentKarteItem(karteItem.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバのカルテ項目の取得に失敗しました'))
      }
      const input: UpdateKarteItemInput = {
        id: karteItem.id,
        displayOrder: karteItem.displayOrder,
        title: karteItem.title,
        karteParts: karteItem.karteParts,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateKarteItemMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('カルテ項目の更新に失敗しました'))
    }
  }

  const deleteKarteItem = async (id: string) => {
    try {
      const original = await DataStore.query(KarteItemModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('カルテ項目の削除に失敗しました'))
      }
      await DataStore.delete<KarteItemModel>(original[0])
    } catch (e) {
      return Promise.reject(new Error('カルテ項目の削除に失敗しました'))
    }
  }

  const _getCurrentKarteItem = async (id: string): Promise<KarteItem|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getKarteItemQuery, { id })) as GetKarteItemResult
      return res.data?.getKarteItem
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerKarteItem,
    updateKarteItem,
    deleteKarteItem
  }
}

export type UseKarteItemMutationType = ReturnType<typeof useKarteItemMutation>
export const UseKarteItemMutationKey: InjectionKey<UseKarteItemMutationType> = Symbol('KarteItemMutation')
