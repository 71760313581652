import { InjectionKey, reactive, ref, toRefs, watch } from 'vue'
import { API, Auth, DataStore, graphqlOperation } from 'aws-amplify'
import { SyncExpression } from '@aws-amplify/datastore/src/types'
import {
  createClinic,
  enterClinic as enterClinicMutation,
  exitClinic as exitClinicMutation
} from '@/graphql/mutations'
import { getAcceptAppointment, getClinic } from '@/graphql/queries'
import { AccountClinic, Clinic, ClinicListData } from '@/API'
import { onUpdateAccount } from '@/graphql/subscriptions'
import { Observable } from 'zen-observable-ts'
import { useRouter } from 'vue-router'

interface CreateClinicResult {
  data : {
    createClinic: Clinic
  }
}

interface ClinicResult {
  data : {
    getClinic?: ClinicListData
  }
}

interface AcceptAppointmentResult {
  data? : {
    getAcceptAppointment: boolean
  }
}

interface SubscribeResult {
  value: {
    data?: {
      onUpdateAccount: AccountClinic
    }
  }
}

export interface ClinicState {
  clinicId: string
  clinicName: string
  accountClinicId: string
  acceptAppointment: boolean
}

export interface PermissionState {
  clinicOwner: boolean
  accountWrite: boolean
  patientWrite: boolean
  patientRead: boolean
  calendarWrite: boolean
  calendarRead: boolean
  dataWrite: boolean
  dataRead: boolean
  settingWrite: boolean
}
type PermissionStateKey = keyof PermissionState

export interface ClinicSubscription {
  syncExpression?: () => SyncExpression
  subscription: (clinicId: string) => Promise<() => void>
}

export const useClinic = (
  subscriptions: ClinicSubscription[],
  initializeDatabase?: () => Promise<boolean>
) => {
  const router = subscriptions.length > 0 ? useRouter() : undefined
  const state = reactive<ClinicState>({
    clinicName: '',
    clinicId: '',
    accountClinicId: '',
    acceptAppointment: false
  })
  const permission = reactive<PermissionState>({
    clinicOwner: false,
    accountWrite: false,
    patientWrite: false,
    patientRead: false,
    calendarWrite: false,
    calendarRead: false,
    dataWrite: false,
    dataRead: false,
    settingWrite: false
  })
  const subscribing = ref(false)
  const entering = ref(false)

  // localStorageから復帰
  const storageKey = 'ekarte-clinic'
  const lastData = localStorage.getItem(storageKey)
  if (lastData) {
    const { clinicName, clinicId, accountClinicId, acceptAppointment } = JSON.parse(lastData) as ClinicState
    state.clinicName = clinicName || ''
    state.clinicId = clinicId || ''
    state.accountClinicId = accountClinicId
    state.acceptAppointment = acceptAppointment || false
  }

  // localStorageに書き込み
  watch(
    () => state,
    to => {
      localStorage.setItem(storageKey, JSON.stringify(to))
    },
    { deep: true }
  )

  // clinicIdの変化でログイン、ログアウト、施設ログイン、ログアウトを判定して購読処理を切り替える
  watch(
    () => state.clinicId,
    async to => {
      if (to === '') {
        await _onExitClinic()
      } else {
        await _onEnterClinic()
      }
    }
  )

  const subscribeAccount = async () => {
    if (subscribing.value) {
      return Promise.reject(new Error('update account already subscribing'))
    }
    try {
      const subscription = (API.graphql(graphqlOperation(onUpdateAccount, { id: state.accountClinicId })) as Observable<SubscribeResult>)
        .subscribe({
          next: async () => {
            await enterClinic({ clinicId: state.clinicId })
            await router?.push('/dashboard')
          },
          error: (e) => {
            console.log('subscribeAccountError', e)
          }
        })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  let _unsubscription: (() => void)[] = []
  let _unsubscriptionAccount: (() => void)|undefined
  const _onEnterClinic = async () => {
    try {
      const expressions = subscriptions
        .filter(sub => sub.syncExpression !== undefined)
        .map(sub => sub.syncExpression!())
      DataStore.configure({
        syncExpressions: expressions
      })
      _unsubscription = await Promise.all(subscriptions.map(async sub => await sub.subscription(state.clinicId)))
      _unsubscriptionAccount = await subscribeAccount()
      await (initializeDatabase!)()
    } catch (e) {
      console.log(e)
    }
  }

  const _onExitClinic = async () => {
    try {
      await Promise.all(_unsubscription.map(unsub => unsub()))
      _unsubscription = []
      if (_unsubscriptionAccount) {
        await _unsubscriptionAccount()
        _unsubscriptionAccount = undefined
      }
    } catch (e) {
      console.log(e)
    }
  }

  const registerClinic = async ({ name, workSpace }: {
    name: string,
    workSpace: string
  }) => {
    try {
      const clinic = await API.graphql(graphqlOperation(createClinic, { name, workSpace })) as CreateClinicResult

      await enterClinic({ clinicId: clinic.data.createClinic.id })
      return true
    } catch (e) {
      if (e.errors.some((e: { message: string }) => e.message === 'The conditional request failed')) {
        return Promise.reject(new Error('そのワークスペース名は既に使われています'))
      }
      return Promise.reject(new Error('施設の登録に失敗しました'))
    }
  }

  const signInClinic = async () => {
    try {
      const info = await Auth.currentUserInfo()
      state.clinicId = info.attributes['custom:clinicId'] || ''
      state.accountClinicId = info.attributes['custom:accountClinicId'] || ''
      if (state.clinicId !== '') {
        await enterClinic({ clinicId: state.clinicId })
      }
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const getClinicName = async () => {
    if (state.clinicId === '') {
      return Promise.reject(new Error('account did not clinic login'))
    }

    try {
      const res = await API.graphql(graphqlOperation(getClinic)) as ClinicResult
      if (!res.data.getClinic) {
        return Promise.reject(new Error('can not get clinic'))
      }
      state.clinicName = res.data.getClinic.name
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const getAccessAppointment = async () => {
    if (state.clinicId === '') {
      return Promise.reject(new Error('account did not clinic login'))
    }

    try {
      const res = await API.graphql(graphqlOperation(getAcceptAppointment)) as AcceptAppointmentResult
      state.acceptAppointment = res.data!.getAcceptAppointment
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // TODO: enter中のclinicに変更(名前の変更、削除)があった場合に対応
  const enterClinic = async ({ clinicId }: {
    clinicId: string
  }) => {
    try {
      entering.value = true
      await API.graphql(graphqlOperation(enterClinicMutation, { clinicId }))

      // サーバ側のcognito user情報を書き換えた後、
      // ローカルで持っているtokenの情報を最新に書き換えるために
      // 強制的にtokenをrefreshする
      await _forceRefresh()
      await _refreshPermissions()

      const user = await Auth.currentAuthenticatedUser()
      const attributes = await Auth.userAttributes(user)
      {
        let clinicId = ''
        let accountClinicId = ''
        attributes.forEach(att => {
          if (att.Name === 'custom:clinicId') {
            clinicId = att.Value
          }
          if (att.Name === 'custom:accountClinicId') {
            accountClinicId = att.Value
          }
        })
        if (clinicId === '' || accountClinicId === '') {
          return Promise.reject(new Error('施設のログインに失敗しました'))
        }
        state.clinicId = clinicId
        state.accountClinicId = accountClinicId
      }
      return true
    } catch (e) {
      return Promise.reject(e)
    } finally {
      entering.value = false
    }
  }

  const exitClinic = async () => {
    try {
      await API.graphql(graphqlOperation(exitClinicMutation))
      await DataStore.clear()
      state.clinicName = ''
      state.clinicId = ''
      state.accountClinicId = ''
      state.acceptAppointment = false

      // サーバ側のcognito user情報を書き換えた後、
      // ローカルで持っているtokenの情報を最新に書き換えるために
      // 強制的にtokenをrefreshする
      await _forceRefresh()
      await _refreshPermissions()
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _forceRefresh = async () => {
    // TODO: 強制refreshが失敗したら、強制ログアウトにする
    try {
      const user = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()
      return new Promise((resolve) => {
        user.refreshSession(currentSession.getRefreshToken(), () => {
          resolve(true)
        })
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // permission情報はその時のtokenから読み取る
  const _refreshPermissions = async () => {
    permission.clinicOwner = false
    permission.accountWrite = false
    permission.patientWrite = false
    permission.patientRead = false
    permission.calendarWrite = false
    permission.calendarRead = false
    permission.dataWrite = false
    permission.dataRead = false
    permission.settingWrite = false
    try {
      const session = await Auth.currentSession()
      const idToken = session.getIdToken()
      const payload = idToken.decodePayload()
      if (Object.prototype.hasOwnProperty.call(payload, 'cognito:groups')) {
        const groups = payload['cognito:groups'] as string[]
        groups.forEach(g => { permission[g as PermissionStateKey] = true })
      }
    } catch (e) {
      // ログアウト時にもログがでてしまうので、以下はコメントアウト
      // console.log('can not get permissions', e)
    }
  }

  const signOutClinic = () => {
    state.clinicName = ''
    state.clinicId = ''
    state.accountClinicId = ''
    state.acceptAppointment = false
    permission.clinicOwner = false
    permission.accountWrite = false
    permission.patientWrite = false
    permission.patientRead = false
    permission.calendarWrite = false
    permission.calendarRead = false
    permission.dataWrite = false
    permission.dataRead = false
    permission.settingWrite = false
  }

  // 起動時、リロート直後(localStorageから復帰後)にclinicIdがセットされていたら施設データの購読開始
  const initialized = ref<Promise<boolean>>(
    state.clinicId !== '' && subscriptions.length > 0
      ? (async () => {
        // リロードの度に以下を呼ぶことで再ログインなしにcognitoのアクセス権グループを更新していたが
        // dynamoDBのコストがかかるので以下はコメントアウト
        // かわりに自分以外のアカウントのアクセス権を変更したら、そのアカウントを強制ログアウト(refreshToken無効にする)
        // 再ログインさせることでアクセス権の更新を行う。
        // await enterClinic({ clinicId: state.clinicId })
        await _forceRefresh()
        await _refreshPermissions()
        await _onEnterClinic()
        return true
      })()
      : Promise.resolve(true)
  )

  return {
    ...toRefs(state),
    ...toRefs(permission),
    registerClinic,
    signInClinic,
    getClinicName,
    getAccessAppointment,
    enterClinic,
    exitClinic,
    signOutClinic,
    entering,
    initialized
  }
}

export type UseClinicType = ReturnType<typeof useClinic>
export const UseClinicKey: InjectionKey<UseClinicType> = Symbol('clinic')
