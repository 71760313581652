
import {
  defineComponent,
  onMounted, inject
} from 'vue'
import { useClinicAccount } from '@/composables/root/useClinicAccount'
import { FetchStatus } from '@/composables/commonTypes'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContent from '@/components/layout/vueContent.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import ClinicRow from '@/components/molecules/ClinicRow.vue'

export default defineComponent({
  name: 'login-clinic',
  components: {
    ClinicRow,
    LoadingMessage,
    VueContent,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const { fetchStatus, clinicList, fetchClinicList } = useClinicAccount()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { enterClinic } = inject(UseClinicKey) as UseClinicType
    const { changeClinic } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType

    onMounted(async () => {
      try {
        await fetchClinicList()
      } catch (e) {
        console.log(e)
      }
    })

    const onLoginClinic = async (clinicId: string) => {
      try {
        await lockScreen()
        await enterClinic({ clinicId })
        await changeClinic()
      } catch (e) {
        console.log(e)
        await append('error', '施設にログインできませんでした')
      } finally {
        await unlockScreen()
      }
    }

    return {
      FetchStatus,
      fetchStatus,
      clinicList,
      onLoginClinic
    }
  }
})
