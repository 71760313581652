import { computed, inject, InjectionKey, ref } from 'vue'
import {
  UseTemporaryPatientSubscriptionKey,
  UseTemporaryPatientSubscriptionType
} from '@/composables/appointment/useTemporaryPatientSubscription'
import { TemporaryPatient as TemporaryPatientModel } from '@/models'

export const useTemporaryPatientList = () => {
  const selectedTemporaryPatient = ref<TemporaryPatientModel|undefined>()
  const { temporaryPatients } = inject(UseTemporaryPatientSubscriptionKey) as UseTemporaryPatientSubscriptionType

  const selectTemporaryPatientById = (patientId: string) => {
    selectedTemporaryPatient.value = temporaryPatients.value.find(p => p.id === patientId)
  }

  const patientInfo = computed(() => {
    if (!selectedTemporaryPatient.value) {
      return ''
    }

    let info = `${selectedTemporaryPatient.value.name} 様`
    if (selectedTemporaryPatient.value.phoneNumber !== '') {
      info += ` ☎${selectedTemporaryPatient.value.phoneNumber}`
    }
    return info
  })

  return {
    selectedTemporaryPatient,
    selectTemporaryPatientById,
    patientInfo
  }
}

export type UseTemporaryPatientListType = ReturnType<typeof useTemporaryPatientList>
export const UseTemporaryPatientListKey: InjectionKey<UseTemporaryPatientListType> = Symbol('TemporaryPatientList')
