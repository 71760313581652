
import {
  defineComponent,
  ref,
  computed
} from 'vue'
import { v4 as uuid } from 'uuid'
import IconButtonLarge from '@/components/atoms/button/iconButtonLarge.vue'

type Props = {
  title?: string | undefined
  placeholder?: string | undefined
  modelValue?: string | undefined
  readonly: boolean
  validation?: string | undefined
  required: boolean,
  minValue: number,
  maxValue: number
}

export default defineComponent({
  name: 'validate-edit-box-number',
  components: {
    IconButtonLarge
  },
  props: {
    title: String,
    placeholder: String,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    },
    validation: String,
    required: {
      type: Boolean,
      default: false
    },
    minValue: {
      type: Number,
      default: 1
    },
    maxValue: {
      type: Number,
      default: 99
    }
  },
  setup (props: Props, context) {
    const id = uuid()
    const focusRef = ref<HTMLElement|null>(null)

    const focus = () => {
      focusRef.value?.focus()
    }

    const inputClass = computed<string>(() => {
      return [
        'validate-edit-box-box',
        props.readonly ? 'edit-box-readonly' : '',
        props.validation !== undefined ? 'edit-box-validation-invalid' : ''
      ].join(' ')
    })

    const onIncrease = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      if (current + 1 > props.maxValue) {
        return
      } else if (current + 1 < props.minValue) {
        context.emit('update:modelValue', `${props.minValue}`)
        return
      }
      context.emit('update:modelValue', `${current + 1}`)
    }

    const onDecrease = () => {
      const current = parseInt(props.modelValue!)
      if (isNaN(current)) {
        return
      }
      if (current - 1 < props.minValue) {
        return
      } else if (current - 1 > props.maxValue) {
        context.emit('update:modelValue', `${props.maxValue}`)
        return
      }
      context.emit('update:modelValue', `${current - 1}`)
    }

    return {
      id,
      focusRef,
      focus,
      inputClass,
      onIncrease,
      onDecrease
    }
  }
})
