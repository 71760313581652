import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_app_footer = _resolveComponent("app-footer")
  const _component_alert = _resolveComponent("alert")
  const _component_screen_lock = _resolveComponent("screen-lock")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_app_header, {
      class: "header",
      syncStatus: _ctx.syncStatus
    }, null, 8, ["syncStatus"]),
    _createVNode(_component_router_view, { class: "app-router-view" }, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          name: "custom-classes-transition",
          "enter-active-class": _ctx.transition
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["enter-active-class"])
      ]),
      _: 1
    }),
    _createVNode(_component_app_footer),
    _createVNode(_component_alert),
    _createVNode(_component_screen_lock, {
      lock: _ctx.lock || _ctx.entering
    }, null, 8, ["lock"])
  ]))
}