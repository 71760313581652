
import {
  defineComponent, inject, ref
} from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import TimeEditBox from '@/components/molecules/timeEditBox.vue'
import IconButtonMiddle from '@/components/atoms/button/iconButtonMiddle.vue'
import ToggleRadioSwitch from '@/components/molecules/ToggleRadioSwitch.vue'
import ListBox from '@/components/atoms/listBox.vue'
import { useModal } from '@/composables/useModal'
import { UseConsultationInfoKey, UseConsultationInfoType } from '@/composables/karte/useConsultationInfo'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import Floating from '@/components/molecules/Floting.vue'
import SmallCalendar from '@/components/organisms/smallCalendar.vue'

export default defineComponent({
  name: 'karte-template-modal',
  components: {
    SmallCalendar,
    Floating,
    ListBox,
    ToggleRadioSwitch,
    IconButtonMiddle,
    TimeEditBox,
    NormalButton,
    WhiteBackdrop
  },
  emits: ['yes'],
  setup (props, context) {
    const {
      show,
      open,
      dismiss
    } = useModal()
    const floatingRef = ref<InstanceType<typeof Floating>>()
    const calendarRef = ref<InstanceType<typeof SmallCalendar>>()

    const {
      appointmentId,
      enableTime,
      editYear,
      editMonth,
      editDay,
      editStartTime,
      errorEditStartTime,
      editEndTime,
      errorEditEndTime,
      selectedTemplateId,
      editDateString,
      editDate,
      karteTemplateListItems
    } = inject(UseConsultationInfoKey) as UseConsultationInfoType

    const {
      setting: {
        appointmentTimeUnit
      },
      calendarStartDayNumber
    } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

    const onCalendar = (event: MouseEvent) => {
      calendarRef.value?.init(editDate.value, calendarStartDayNumber.value)
      floatingRef.value?.open(event.pageX - event.offsetX, event.pageY - event.offsetY + 55)
    }

    const onSelectDate = (date: {year: number, month: number, day: number}) => {
      editYear.value = date.year
      editMonth.value = date.month
      editDay.value = date.day
      floatingRef.value?.dismiss()
    }

    const onYes = () => {
      dismiss()
      context.emit('yes')
    }

    return {
      floatingRef,
      calendarRef,
      show,
      open,
      dismiss,
      onCalendar,
      onSelectDate,
      onYes,
      appointmentId,
      enableTime,
      editYear,
      editMonth,
      editDay,
      editStartTime,
      errorEditStartTime,
      editEndTime,
      errorEditEndTime,
      selectedTemplateId,
      editDateString,
      karteTemplateListItems,
      appointmentTimeUnit
    }
  }
})
