import { computed, InjectionKey, ref, watch } from 'vue'
import { API, graphqlOperation } from 'aws-amplify'
import { AccountAppointment, ListAccountAppointmentResult } from '@/API'
import { listAccountAppointment as listAccountAppointmentQuery } from '@/graphql/queries'
import { useRoute, useRouter } from 'vue-router'

interface ListAccountAppointmentQueryResult {
  data?: {
    listAccountAppointment: ListAccountAppointmentResult
  }
}

export const useAccountAppointment = () => {
  const route = useRoute()
  const router = useRouter()
  const accountAppointmentList = ref<AccountAppointment[]>([])
  const selectedAccount = ref<string>(route.query.a as string || '')

  // offlineでもアカウント名が取得できるようにlocalStorageに保存しておく
  const storageKey = 'ekarte-account-appointment'
  const lastData = localStorage.getItem(storageKey)
  if (lastData) {
    accountAppointmentList.value = JSON.parse(lastData) as AccountAppointment[]
  }

  watch(
    () => accountAppointmentList,
    to => {
      const data = to.value.map(t => {
        return {
          userId: t.userId,
          name: t.name
        }
      })
      localStorage.setItem(storageKey, JSON.stringify(data))
    },
    { deep: true }
  )

  watch(
    () => selectedAccount,
    async to => {
      const currentQuery = router.currentRoute.value.query
      const newQuery = {
        ...currentQuery,
        a: to.value
      }
      await router.replace({ path: route.path, query: newQuery })
    },
    { deep: true }
  )

  const listAccountAppointment = async () => {
    let newAccountAppointmentList: AccountAppointment[] = []

    let nextToken: string|null|undefined
    while (true) {
      try {
        const params = {
          limit: 5,
          nextToken: nextToken
        }
        const res = await API.graphql(graphqlOperation(listAccountAppointmentQuery, params)) as ListAccountAppointmentQueryResult
        if (res.data) {
          newAccountAppointmentList = [
            ...newAccountAppointmentList,
            ...(res.data.listAccountAppointment.items as AccountAppointment[])
          ]
          nextToken = res.data.listAccountAppointment.nextToken
        }
        if (!nextToken) {
          break
        }
      } catch (e) {
        return Promise.reject(e)
      }
    }

    accountAppointmentList.value = newAccountAppointmentList
    if (newAccountAppointmentList.length > 0 && selectedAccount.value === '') {
      selectedAccount.value = newAccountAppointmentList[0].userId
    }
  }

  // select box用アイテム
  const accounts = computed(() => {
    return accountAppointmentList.value.map(a => {
      return {
        name: a.name,
        value: a.userId
      }
    })
  })

  const selectedAccountName = computed(() => {
    return selectedAccount.value === ''
      ? ''
      : accountAppointmentList.value.find(a => a.userId === selectedAccount.value)?.name || ''
  })

  const accountName = (userId: string): string => {
    const account = accountAppointmentList.value.find(a => a.userId === userId)
    return account ? account.name : ''
  }

  return {
    accountAppointmentList,
    selectedAccount,
    listAccountAppointment,
    accounts,
    selectedAccountName,
    accountName
  }
}

export type UseAccountAppointmentType = ReturnType<typeof useAccountAppointment>
export const UseAccountAppointmentKey: InjectionKey<UseAccountAppointmentType> = Symbol('AccountAppointment')
