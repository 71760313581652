/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClinic = /* GraphQL */ `
  query GetClinic {
    getClinic {
      clinicId
      name
      owner
      invitation
    }
  }
`;
export const listAccountClinics = /* GraphQL */ `
  query ListAccountClinics {
    listAccountClinics {
      clinicId
      name
      owner
      invitation
    }
  }
`;
export const listAccount = /* GraphQL */ `
  query ListAccount($limit: Int, $nextToken: String) {
    listAccount(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        acceptAppointment
        myself
      }
      nextToken
    }
  }
`;
export const listAccountInvitation = /* GraphQL */ `
  query ListAccountInvitation($limit: Int, $nextToken: String) {
    listAccountInvitation(limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        ttl
        permissions
        acceptAppointment
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      permissions
      acceptAppointment
      myself
    }
  }
`;
export const listAccountAppointment = /* GraphQL */ `
  query ListAccountAppointment($limit: Int, $nextToken: String) {
    listAccountAppointment(limit: $limit, nextToken: $nextToken) {
      items {
        userId
        name
      }
      nextToken
    }
  }
`;
export const getAcceptAppointment = /* GraphQL */ `
  query GetAcceptAppointment {
    getAcceptAppointment
  }
`;
export const oneTimeToken = /* GraphQL */ `
  query OneTimeToken($inquiryId: String!) {
    oneTimeToken(inquiryId: $inquiryId)
  }
`;
export const listInquiryAnswer = /* GraphQL */ `
  query ListInquiryAnswer(
    $filter: InquiryAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInquiryAnswer(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        owner
        inquiryId
        patientId
        answer
        answerStatus
        createdAt
      }
      nextToken
    }
  }
`;
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        patientNumber
        name
        read
        gender
        birthDate
        private {
          zipCode
          pref
          city
          address
          phoneNumber
        }
        comment
        display
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        patientNumber
        name
        read
        gender
        birthDate
        private {
          zipCode
          pref
          city
          address
          phoneNumber
        }
        comment
        display
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemporaryPatients = /* GraphQL */ `
  query SyncTemporaryPatients(
    $filter: ModelTemporaryPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemporaryPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        name
        gender
        phoneNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemporaryPatient = /* GraphQL */ `
  query GetTemporaryPatient($id: ID!) {
    getTemporaryPatient(id: $id) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemporaryPatients = /* GraphQL */ `
  query ListTemporaryPatients(
    $filter: ModelTemporaryPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemporaryPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicId
        name
        gender
        phoneNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatientNumbers = /* GraphQL */ `
  query SyncPatientNumbers(
    $filter: ModelPatientNumberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatientNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        patientNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatientNumber = /* GraphQL */ `
  query GetPatientNumber($id: ID!) {
    getPatientNumber(id: $id) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPatientNumbers = /* GraphQL */ `
  query ListPatientNumbers(
    $filter: ModelPatientNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        patientNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBusinessHours = /* GraphQL */ `
  query SyncBusinessHours(
    $filter: ModelBusinessHourFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinessHours(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        userId
        originDate
        intervalType
        interval
        startTime
        endTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBusinessHour = /* GraphQL */ `
  query GetBusinessHour($id: ID!) {
    getBusinessHour(id: $id) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessHours = /* GraphQL */ `
  query ListBusinessHours(
    $filter: ModelBusinessHourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessHours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        userId
        originDate
        intervalType
        interval
        startTime
        endTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemporaryCloses = /* GraphQL */ `
  query SyncTemporaryCloses(
    $filter: ModelTemporaryCloseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemporaryCloses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        businessHourId
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemporaryClose = /* GraphQL */ `
  query GetTemporaryClose($id: ID!) {
    getTemporaryClose(id: $id) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemporaryCloses = /* GraphQL */ `
  query ListTemporaryCloses(
    $filter: ModelTemporaryCloseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemporaryCloses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        businessHourId
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppointments = /* GraphQL */ `
  query SyncAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        patientId
        temporaryPatient
        accountId
        date
        startTime
        endTime
        appointmentStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        patientId
        temporaryPatient
        accountId
        date
        startTime
        endTime
        appointmentStatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKarteItems = /* GraphQL */ `
  query SyncKarteItems(
    $filter: ModelKarteItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKarteItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        displayOrder
        title
        karteParts {
          id
          partsName
          title
          options
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKarteItem = /* GraphQL */ `
  query GetKarteItem($id: ID!) {
    getKarteItem(id: $id) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listKarteItems = /* GraphQL */ `
  query ListKarteItems(
    $filter: ModelKarteItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKarteItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        displayOrder
        title
        karteParts {
          id
          partsName
          title
          options
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAcuItems = /* GraphQL */ `
  query SyncAcuItems(
    $filter: ModelAcuItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAcuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        direction
        bodyParts
        parts {
          id
          x
          y
          name
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAcuItem = /* GraphQL */ `
  query GetAcuItem($id: ID!) {
    getAcuItem(id: $id) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAcuItems = /* GraphQL */ `
  query ListAcuItems(
    $filter: ModelAcuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        direction
        bodyParts
        parts {
          id
          x
          y
          name
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKarteTemplates = /* GraphQL */ `
  query SyncKarteTemplates(
    $filter: ModelKarteTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKarteTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        displayOrder
        title
        karteItemIds {
          id
          karteItemId
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKarteTemplate = /* GraphQL */ `
  query GetKarteTemplate($id: ID!) {
    getKarteTemplate(id: $id) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listKarteTemplates = /* GraphQL */ `
  query ListKarteTemplates(
    $filter: ModelKarteTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKarteTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        displayOrder
        title
        karteItemIds {
          id
          karteItemId
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKartes = /* GraphQL */ `
  query SyncKartes(
    $filter: ModelKarteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKartes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        accountId
        accountName
        karteNumber
        patientId
        karteDate
        displayOrder
        title
        karteResults {
          id
          karteItemId
          title
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKarte = /* GraphQL */ `
  query GetKarte($id: ID!) {
    getKarte(id: $id) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listKartes = /* GraphQL */ `
  query ListKartes(
    $filter: ModelKarteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKartes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        accountId
        accountName
        karteNumber
        patientId
        karteDate
        displayOrder
        title
        karteResults {
          id
          karteItemId
          title
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKarteNumbers = /* GraphQL */ `
  query SyncKarteNumbers(
    $filter: ModelKarteNumberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKarteNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        karteNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKarteNumber = /* GraphQL */ `
  query GetKarteNumber($id: ID!) {
    getKarteNumber(id: $id) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listKarteNumbers = /* GraphQL */ `
  query ListKarteNumbers(
    $filter: ModelKarteNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKarteNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        karteNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFavoriteKartes = /* GraphQL */ `
  query SyncFavoriteKartes(
    $filter: ModelFavoriteKarteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavoriteKartes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        accountId
        karteId
        displayOrder
        karteAccountId
        karteAccountName
        karteNumber
        patientId
        karteDate
        title
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFavoriteKarte = /* GraphQL */ `
  query GetFavoriteKarte($id: ID!) {
    getFavoriteKarte(id: $id) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFavoriteKartes = /* GraphQL */ `
  query ListFavoriteKartes(
    $filter: ModelFavoriteKarteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoriteKartes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        accountId
        karteId
        displayOrder
        karteAccountId
        karteAccountName
        karteNumber
        patientId
        karteDate
        title
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInquiryItems = /* GraphQL */ `
  query SyncInquiryItems(
    $filter: ModelInquiryItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInquiryItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        displayOrder
        version
        title
        inquiryPages {
          id
          pageTitle
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInquiryItem = /* GraphQL */ `
  query GetInquiryItem($id: ID!) {
    getInquiryItem(id: $id) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listInquiryItems = /* GraphQL */ `
  query ListInquiryItems(
    $filter: ModelInquiryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInquiryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        displayOrder
        version
        title
        inquiryPages {
          id
          pageTitle
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSettings = /* GraphQL */ `
  query SyncSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        calendarStartDay
        appointmentTimeUnit
        holidayAutoClose
        holidayAutoCloseSat
        holidayAutoCloseSun
        holidayAutoCloseSub
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        calendarStartDay
        appointmentTimeUnit
        holidayAutoClose
        holidayAutoCloseSat
        holidayAutoCloseSun
        holidayAutoCloseSub
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFileHashes = /* GraphQL */ `
  query SyncFileHashes(
    $filter: ModelFileHashFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFileHashes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clinicId
        filename
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFileHash = /* GraphQL */ `
  query GetFileHash($id: ID!) {
    getFileHash(id: $id) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listFileHashs = /* GraphQL */ `
  query ListFileHashs(
    $filter: ModelFileHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileHashs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clinicId
        filename
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listKarteByOrder = /* GraphQL */ `
  query ListKarteByOrder(
    $patientId: String
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKarteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKarteByOrder(
      patientId: $patientId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clinicId
        accountId
        accountName
        karteNumber
        patientId
        karteDate
        displayOrder
        title
        karteResults {
          id
          karteItemId
          title
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInquiry = /* GraphQL */ `
  query GetInquiry($inquiryId: String!) {
    getInquiry(inquiryId: $inquiryId)
  }
`;
export const listHoliday = /* GraphQL */ `
  query ListHoliday {
    listHoliday {
      date
      name
      substitute
    }
  }
`;
export const listZip = /* GraphQL */ `
  query ListZip($zipCode: String!) {
    listZip(zipCode: $zipCode) {
      id
      zipCode
      pref
      city
      address
    }
  }
`;
