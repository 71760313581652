import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const useTab = (v: string[]) => {
  const route = useRoute()
  const router = useRouter()
  const titles = ref<string[]>(v)
  const selectedIndex = ref<number>(route.query.t ? parseInt(route.query.t as string) : 0)

  const appendTab = (...v: string[]) => {
    titles.value.push(...v)
  }

  const selectTab = async (index: number) => {
    selectedIndex.value = index
    const currentQuery = router.currentRoute.value.query
    const newQuery = {
      ...currentQuery,
      t: `${index}`
    }
    await router.replace({ path: route.path, query: newQuery })
  }

  const tabs = computed(() => {
    return titles.value.map((t, i) => {
      return {
        title: t,
        selected: i === selectedIndex.value
      }
    })
  })

  return {
    appendTab,
    selectTab,
    selectedIndex,
    tabs
  }
}
