<template>
  <div class="tab-frame">
    <div class="tabs">
      <tab
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        v-bind="tab"
        @click="$emit('tab', index)"
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tab from '@/components/atoms/Tab.vue'

export default {
  name: 'tabs',
  components: {
    Tab
  },
  props: {
    tabs: Array
  },
  emits: ['tab']
}
</script>

<style lang="scss" scoped>
.tab-frame {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
}
</style>
