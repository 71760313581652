<template>
  <div
    class="drop-area"
    @dragover.prevent
    @drop="onDrop">
    <slot></slot>
    <normal-button
      class="drop-button"
      state="normal"
      @click="onFileSelect">ファイル選択
    </normal-button>
    <input
      class="hidden-input"
      ref="selectFileDialog"
      type="file"
      multiple
      @change="onChangeFiles">
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { sha256Hash } from '@/plugins/csvUtils'

export default {
  name: 'drop-area',
  components: { NormalButton },
  props: {
    fileTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {}
  },
  emits: ['file', 'error'],
  methods: {
    onFileSelect () {
      this.$refs.selectFileDialog.value = ''
      this.$refs.selectFileDialog.click()
    },
    onChangeFiles (event) {
      event.preventDefault()
      this.emitFiles(event.target.files)
    },
    onDrop (event) {
      event.stopPropagation()
      event.preventDefault()
      this.emitFiles(event.dataTransfer.files)
    },
    emitFiles (files) {
      Array.from(files).forEach(f => {
        const {
          type: fileType,
          name
        } = f

        if (this.fileTypes.findIndex(ft => f.type.match(ft)) < 0) {
          this.$emit('error', 'ファイルタイプが違います')
          return
        }
        const reader = new FileReader()
        reader.onload = async (e) => {
          const dropDownFile = {
            id: uuid(),
            fileType,
            name,
            base64: e.target.result,
            hash: await sha256Hash(e.target.result)
          }
          this.$emit('file', dropDownFile)
        }
        reader.readAsDataURL(f)
      })
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.drop-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 2px dashed $base_color_gray_dark;
  border-radius: $border_radius;
  text-align: center;
  color: $base_color_gray_dark;
  font-size: 0.8em;

  .drop-button {
    width: 180px;
    height: $form_item_height;
  }
}

.hidden-input {
  display: none;
}
</style>
