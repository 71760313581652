import { computed, inject, InjectionKey, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { addTime, formatHM } from '@/plugins/functions'
import {
  UseAppointmentSubscriptionKey,
  UseAppointmentSubscriptionType
} from '@/composables/appointment/useAppointmentSubsucription'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import { hhmmValidation } from '@/plugins/validator'
import { Patient as PatientModel } from '@/models'

export const useAppointment = (selectedPatient: Ref<PatientModel|undefined>) => {
  const route = useRoute()
  const patientType = ref<string>((route.query.t as string|null) || 'sel') // tmp:患者仮登録、sel:患者選択
  const appointmentId = ref<string>('')
  const year = ref<number>(parseInt(route.query.y as string))
  const month = ref<number>(parseInt(route.query.m as string))
  const day = ref<number>(parseInt(route.query.d as string))
  const startTime = ref<string>('')
  const endTime = ref<string>('')

  const { getAppointmentList } = inject(UseAppointmentSubscriptionKey) as UseAppointmentSubscriptionType
  const { setting: { appointmentTimeUnit } } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

  const baseDateString = computed(() => {
    const d = dayjs(new Date(year.value, month.value, day.value))
    return d.format('YYYY年M月D日(ddd)')
  })

  const baseDate = computed(() => {
    const d = dayjs(new Date(year.value, month.value, day.value))
    return d.format('YYYY-MM-DD')
  })

  const errorStartTime = computed(() => {
    return startTime.value === ''
      ? '開始時刻が設定されていません'
      : hhmmValidation(startTime.value)
  })

  const errorEndTime = computed(() => {
    return endTime.value === ''
      ? '終了時刻が設定されていません'
      : hhmmValidation(endTime.value)
  })

  const overlap = computed<boolean>(() => {
    if (patientType.value === 'tmp') {
      return false
    }
    if (!selectedPatient.value) {
      return false
    }
    if (errorStartTime.value !== undefined || errorEndTime.value !== undefined) {
      return false
    }

    const appointmentList = getAppointmentList(year.value, month.value, day.value)
    return appointmentList.some(appointment => {
      return appointment.appointment.patientId === selectedPatient.value!.id &&
        appointment.appointment.id !== appointmentId.value &&
        appointment.appointment.startTime < formatHM(endTime.value) &&
        appointment.appointment.endTime > formatHM(startTime.value)
    })
  })

  watch(
    () => startTime.value,
    to => {
      if (to.length === 4 && to >= endTime.value) {
        endTime.value = addTime(to, appointmentTimeUnit.value)
      }
    },
    { deep: true }
  )

  watch(
    () => endTime.value,
    to => {
      if (to.length === 4 && to <= startTime.value) {
        startTime.value = addTime(to, -appointmentTimeUnit.value)
      }
    },
    { deep: true }
  )

  return {
    patientType,
    appointmentId,
    year,
    month,
    day,
    startTime,
    endTime,
    errorStartTime,
    errorEndTime,
    overlap,
    baseDateString,
    baseDate
  }
}

export type UseAppointmentType = ReturnType<typeof useAppointment>
export const UseAppointmentKey: InjectionKey<UseAppointmentType> = Symbol('Appointment')
