import { computed, inject, InjectionKey, ref } from 'vue'
import {
  UsePatientSubscriptionKey,
  UsePatientSubscriptionType
} from '@/composables/patient/usePatientSubscription'
import { Display, Patient } from '@/models'
import { age, includes } from '@/plugins/functions'
import { PatientDetail } from '@/composables/patient/types'

export const usePatientList = () => {
  const searchWord = ref<string>('')
  const male = ref<boolean>(true)
  const female = ref<boolean>(true)
  const other = ref<boolean>(true)
  const onlyHidden = ref<boolean>(false)
  const selectedPatient = ref<Patient|undefined>()
  const { patients } = inject(UsePatientSubscriptionKey) as UsePatientSubscriptionType

  const selectPatient = (patient: Patient) => {
    selectedPatient.value = patient
  }

  const selectPatientById = (patientId: string) => {
    selectedPatient.value = patients.value.find(p => p.patient.id === patientId)?.patient
  }

  const show = (patient: Patient): boolean => {
    if (!male.value && patient.gender === 'male') {
      return false
    }
    if (!female.value && patient.gender === 'female') {
      return false
    }
    if ((!onlyHidden.value && patient.display === Display.HIDDEN) ||
      (onlyHidden.value && patient.display === Display.SHOW)) {
      return false
    }

    if (searchWord.value === '') {
      return true
    }

    return (
      includes(patient.name, searchWord.value) ||
      includes(patient.read, searchWord.value) ||
      (patient.private?.phoneNumber || []).some(phoneNumber => includes(phoneNumber, searchWord.value)) ||
      (patient.private?.sns || []).some(s => includes(s.accountId, searchWord.value))
    )
  }

  const patientOrder = computed(() => {
    return patients.value
      .sort((a, b) => {
        return a.patient.read > b.patient.read
          ? 1
          : a.patient.read < b.patient.read
            ? -1
            : 0
      })
      .map(p => p.patient.id)
  })

  const patientItemMap = computed(() => {
    const newMap: { [p: string]: PatientDetail } = {}
    patients.value.forEach(patient => {
      newMap[patient.patient.id] = patient
    })
    return newMap
  })

  const patientInfo = computed(() => {
    if (!selectedPatient.value) {
      return ''
    }
    let info = `${selectedPatient.value.name}(${selectedPatient.value.read}) 様 (${age(selectedPatient.value?.birthDate)}歳)`
    if (selectedPatient.value.private?.phoneNumber && selectedPatient.value.private?.phoneNumber.length > 0) {
      info += ` ☎${selectedPatient.value.private?.phoneNumber[0]}`
    }
    return info
  })

  return {
    searchWord,
    male,
    female,
    other,
    onlyHidden,
    selectedPatient,
    selectPatient,
    selectPatientById,
    show,
    patientItemMap,
    patientOrder,
    patientInfo
  }
}

export type UsePatientListType = ReturnType<typeof usePatientList>
export const UsePatientListKey: InjectionKey<UsePatientListType> = Symbol('PatientList')
