<template>
  <div class="qrcode-error">コード表示エラー<br>(ページを戻ってもう一度試してみてください)</div>
</template>

<script>

export default {
  name: 'qrcode-error'
}
</script>

<style lang="scss" scoped>
.qrcode-error {
  width: 300px;
  height: 300px;
  background: #ffffff;
  padding: 10px;
  border-radius: $border-radius;
  border: 3px solid $font_color_dark;
}
</style>
