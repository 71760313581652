
import { defineComponent, inject } from 'vue'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import { useModal } from '@/composables/useModal'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ListBox from '@/components/atoms/listBox.vue'
import { UseInquiryInfoKey, UseInquiryInfoType } from '@/composables/karte/useInquiryInfo'

export default defineComponent({
  name: 'inquiry-model',
  components: {
    ListBox,
    NormalButton,
    WhiteBackdrop
  },
  emits: ['yes'],
  setup (props, context) {
    const {
      show,
      open,
      dismiss
    } = useModal()
    const {
      selectedInquiryId,
      inquiryListItems
    } = inject(UseInquiryInfoKey) as UseInquiryInfoType

    const onYes = () => {
      dismiss()
      context.emit('yes')
    }

    return {
      show,
      open,
      dismiss,
      onYes,
      selectedInquiryId,
      inquiryListItems
    }
  }
})
