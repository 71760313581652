
import {
  defineComponent,
  inject
} from 'vue'
import { UsePatientListKey, UsePatientListType } from '@/composables/patient/usePatientList'
import TopLabelEditBox from '@/components/molecules/editbox/topLabelEditBox.vue'
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'
import VueContent from '@/components/layout/vueContent.vue'

export default defineComponent({
  name: 'patient-search-form',
  components: {
    VueContent,
    ToggleSwitch,
    TopLabelEditBox
  },
  setup () {
    const {
      searchWord,
      male,
      female,
      other
    } = inject(UsePatientListKey) as UsePatientListType

    return {
      searchWord,
      male,
      female,
      other
    }
  }
})
