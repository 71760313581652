
import {
  computed,
  defineComponent,
  ref
} from 'vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'small-calendar',
  setup (props, context) {
    const selectedDate = ref<string>('')
    const calendarStartDayNumber = ref<number>(0)
    const year = ref<number>(0)
    const month = ref<number>(0)

    const init = (date: string, startDayNumber: number) => {
      selectedDate.value = date
      calendarStartDayNumber.value = startDayNumber

      const d = dayjs(selectedDate.value, 'YYYY-MM-DD')
      year.value = d.year()
      month.value = d.month()
    }

    const addMonth = (m: number) => {
      const newDate = dayjs(new Date(year.value, month.value, 1)).add(m, 'month')
      year.value = newDate.year()
      month.value = newDate.month()
    }

    const onSelectDate = (day: number) => {
      selectedDate.value = dayjs(new Date(year.value, month.value, day)).format('YYYY-MM-DD')
      context.emit('select', {
        year: year.value,
        month: month.value,
        day
      })
    }

    const dayClass = (day: number) => {
      const d = dayjs(new Date(year.value, month.value, day))
      const classes = ['calendar-number-item-valid']

      if (d.diff(dayjs().startOf('day'), 'days') === 0) {
        classes.push('calendar-number-item-today')
      }
      if (d.day() === 0) {
        classes.push('calendar-number-item-sunday')
      } else if (d.day() === 6) {
        classes.push('calendar-number-item-saturday')
      }
      if (d.format('YYYY-MM-DD') === selectedDate.value) {
        classes.push('calendar-number-item-selected')
      }
      return classes
    }

    const calendarHeader = computed(() => {
      const d = '日月火水木金土日月火水木金'
      return d.slice(calendarStartDayNumber.value, calendarStartDayNumber.value + 7).split('')
    })

    const calendar = computed(() => {
      const target = dayjs(new Date(year.value, month.value, 1))
      const endDay = target.endOf('month').date()
      const firstDayOfWeek = target.startOf('month').day()

      const monthList = []
      const padding = firstDayOfWeek - calendarStartDayNumber.value >= 0
        ? firstDayOfWeek - calendarStartDayNumber.value
        : 7 + firstDayOfWeek - calendarStartDayNumber.value
      let weekList = Array(padding)
      for (let d = 1; d <= endDay; d++) {
        weekList.push(d)
        if (weekList.length === 7) {
          monthList.push(weekList)
          weekList = []
        }
      }
      if (weekList.length !== 0) {
        weekList.push(...Array(7 - weekList.length))
        monthList.push(weekList)
      }
      if (monthList.length === 5) {
        monthList.push(Array(7))
      }
      return monthList
    })

    return {
      year,
      month,
      init,
      addMonth,
      dayClass,
      onSelectDate,
      calendarHeader,
      calendar
    }
  }
})
