import { computed, InjectionKey, reactive, ref, toRefs } from 'vue'
import { Setting as SettingModel } from '@/models'
import { SettingState } from '@/composables/setting/useSetting'
import { DataStore } from 'aws-amplify'
import { WeekDayShort } from '@/API'

const WeeKDatMap = {
  [WeekDayShort.sun]: 0,
  [WeekDayShort.mon]: 1,
  [WeekDayShort.tue]: 2,
  [WeekDayShort.wed]: 3,
  [WeekDayShort.thu]: 4,
  [WeekDayShort.fri]: 5,
  [WeekDayShort.sat]: 6
}

export const useSettingSubscription = () => {
  const state = reactive<SettingState>({
    calendarStartDay: WeekDayShort.sun,
    appointmentTimeUnit: 30,
    holidayAutoClose: false,
    holidayAutoCloseSat: false,
    holidayAutoCloseSun: false,
    holidayAutoCloseSub: false
  })
  const subscribing = ref(false)

  const fetchSetting = async (clinicId: string) => {
    try {
      const setting = await DataStore.query(SettingModel, s => s.id('eq', clinicId)) as SettingModel[]
      if (setting.length === 0) {
        return
      }

      state.calendarStartDay = setting[0].calendarStartDay as WeekDayShort
      state.appointmentTimeUnit = setting[0].appointmentTimeUnit
      state.holidayAutoClose = setting[0].holidayAutoClose
      state.holidayAutoCloseSat = setting[0].holidayAutoCloseSat
      state.holidayAutoCloseSun = setting[0].holidayAutoCloseSun
      state.holidayAutoCloseSub = setting[0].holidayAutoCloseSub
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe setting without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('setting already subscribing'))
    }

    try {
      await fetchSetting(clinicId)
      const subscription = DataStore.observe(SettingModel).subscribe(async () => {
        await fetchSetting(clinicId)
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const calendarStartDayNumber = computed(() => {
    return WeeKDatMap[state.calendarStartDay]
  })

  return {
    setting: toRefs(state),
    rowSetting: state,
    fetchSetting,
    subscribe,
    calendarStartDayNumber
  }
}

export type UseSettingSubscriptionType = ReturnType<typeof useSettingSubscription>
export const UseSettingSubscriptionKey: InjectionKey<UseSettingSubscriptionType> = Symbol('SettingSubscription')
