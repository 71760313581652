import { InjectionKey, ref } from 'vue'
import { InquiryItem as InquiryItemModel } from '@/models'
import { DataStore } from 'aws-amplify'

export const useInquiryItemSubscription = () => {
  const inquiryItems = ref<InquiryItemModel[]>([])
  const subscribing = ref(false)

  const fetchInquiryItem = async () => {
    try {
      inquiryItems.value = await DataStore.query(InquiryItemModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchInquiryItem()
      const subscription = DataStore.observe(InquiryItemModel).subscribe(async () => {
        await fetchInquiryItem()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    inquiryItems,
    fetchInquiryItem,
    subscribe
  }
}

export type UseInquiryItemSubscriptionType = ReturnType<typeof useInquiryItemSubscription>
export const UseInquiryItemSubscriptionKey: InjectionKey<UseInquiryItemSubscriptionType> = Symbol('InquiryItemSubscription')
