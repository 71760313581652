<template>
  <div :class="dayNumberFrameClass">
    <div class="calendar-day-header">
      <div :class="dayNumberClass">{{ day }}</div>
      <div
        class="holiday-name"
        v-if="holidayName !== ''">{{ holidayName }}
      </div>
      <icon-button
        v-if="!disableAdd && (!disableAfterToday || !feature)"
        icon="plus"
        state="normal"
        @click="$emit('add')"></icon-button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import IconButton from '@/components/atoms/button/iconButton.vue'

export default {
  name: 'calendar-day-frame',
  components: {
    IconButton
  },
  props: {
    year: Number,
    month: Number,
    day: Number,
    disableAfterToday: {
      type: Boolean,
      default: true
    },
    disableAdd: {
      type: Boolean,
      default: false
    },
    holidayName: String
  },
  emits: ['add'],
  computed: {
    today () {
      return dayjs(new Date(this.year, this.month, this.day)).diff(dayjs().startOf('day'), 'days') === 0
    },
    feature () {
      return dayjs(new Date(this.year, this.month, this.day)).diff(dayjs().startOf('day'), 'days') > 0
    },
    sunday () {
      return dayjs(new Date(this.year, this.month, this.day)).day() === 0
    },
    saturday () {
      return dayjs(new Date(this.year, this.month, this.day)).day() === 6
    },
    dayClass () {
      const classes = []

      if (this.today) {
        classes.push('today')
      }
      if (this.feature) {
        if (this.disableAfterToday) {
          classes.push('feature-disable')
        } else {
          classes.push('feature')
        }
      }
      if (this.sunday) {
        classes.push('sunday')
      }
      if (this.saturday) {
        classes.push('saturday')
      }
      if (this.holidayName !== '') {
        classes.push('holiday')
      }

      return classes
    },
    dayNumberFrameClass () {
      return [
        ...this.dayClass.map(c => {
          return 'day-number-frame-' + c
        }),
        'day-number-frame'
      ].join(' ')
    },
    dayNumberClass () {
      return this.dayClass.map(c => {
        return 'day-number-' + c
      }).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.day-number-frame {
  min-height: 12vh;
  margin: 2px;
  border-radius: $border_radius;
  flex-grow: 1;
  flex-basis: 0;
  transition: 0.2s;
  background: $base_color_water_light;
}

.day-number-frame-sunday {
  color: $base_color_red_dark;
  background: $base_color_red_lighter;
}

.day-number-frame-saturday {
  color: $base_color_blue;
  background: $base_color_blue_lighter;
}

.day-number-frame-holiday {
  color: $base_color_red_dark;
  background: $base_color_red_lighter;
}

.day-number-frame-feature-disable {
  opacity: 0.3;
}

.calendar-day-header {
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 4px;

  .day-number-today {
    width: $icon_button_size;
    height: $icon_button_size;
    line-height: $icon_button_size;
    padding-right: 2px;
    border-radius: $icon_button_size;
    text-align: center;
    color: $base_color_water;
    background: $base_color_blue;

    &.day-number-sunday {
      color: $base_color_red_lighter;
      background: $base_color_red_dark;
    }

    &.day-number-saturday {
      color: $base_color_blue_lighter;
      background: $base_color_blue;
    }

    &.day-number-holiday {
      color: $base_color_red_lighter;
      background: $base_color_red_dark;
    }
  }

  .holiday-name {
    font-size: 0.7em;
    margin-left: 5px;
    margin-right: auto;
    white-space: nowrap;
  }
}
</style>
