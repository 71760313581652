
import {
  defineComponent, inject, onMounted
} from 'vue'
import { useRouter } from 'vue-router'
import { usePatient } from '@/composables/patient/usePatient'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseInquiryAnswerSubscriptionKey, UseInquiryAnswerSubscriptionType } from '@/composables/inquieryAnswer/useInquiryAnswerSubscription'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import BadgeFrame from '@/components/molecules/BadgeFrame.vue'

export default defineComponent({
  name: 'PatientDashboard',
  components: {
    BadgeFrame,
    NormalButton,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const router = useRouter()
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType
    const { patientAnswers } = inject(UseInquiryAnswerSubscriptionKey) as UseInquiryAnswerSubscriptionType

    onMounted(() => {
      intoDashboard()
    })

    const { clearPatient } = usePatient()
    const registerPatient = () => {
      clearPatient()
      router.push(`/w/${clinicId.value}/register_patient`)
    }

    return {
      clinicId,
      patientAnswers,
      registerPatient
    }
  }
})
