
import {
  defineComponent, inject, provide
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import PatientInfo from '@/views/karte/KarteList/PatientInfo.vue'
import ConsultationList from '@/views/karte/KarteList/ConsultationList.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import Tabs from '@/components/molecules/Tabs.vue'
import BookmarkList from '@/views/karte/KarteList/BookmarkList.vue'
import { useKartePatient, UseKartePatientKey } from '@/composables/karte/useKartePatient'
import { useConsultationInfo, UseConsultationInfoKey } from '@/composables/karte/useConsultationInfo'
import { useConsultationList, UseConsultationListKey } from '@/composables/karte/useConsultationList'
import { useOldPatientKarte, UseOldPatientKarteKey } from '@/composables/karte/useOldPatientKarte'
import { useFavoriteList, UseFavoriteListKey } from '@/composables/karte/useFavoriteList'
import { useTab } from '@/composables/useTab'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useInquiryInfo, UseInquiryInfoKey } from '@/composables/karte/useInquiryInfo'

export default defineComponent({
  name: 'KarteList',
  components: {
    BookmarkList,
    Tabs,
    VueContentRemain,
    ConsultationList,
    PatientInfo,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const kartePatient = useKartePatient()
    provide(UseKartePatientKey, kartePatient)
    const consultationInfo = useConsultationInfo()
    provide(UseConsultationInfoKey, consultationInfo)
    const inquiryInfo = useInquiryInfo()
    provide(UseInquiryInfoKey, inquiryInfo)
    const oldPatientKarte = useOldPatientKarte()
    const { dateKarteMap } = oldPatientKarte
    provide(UseOldPatientKarteKey, oldPatientKarte)
    const consultationList = useConsultationList(dateKarteMap)
    provide(UseConsultationListKey, consultationList)
    const favoriteList = useFavoriteList()
    provide(UseFavoriteListKey, favoriteList)

    const { patientWrite } = inject(UseClinicKey) as UseClinicType
    const { selectTab, selectedIndex, tabs } = useTab(['診察日一覧', 'ブックマーク'])

    return {
      patientWrite,
      selectTab,
      selectedIndex,
      tabs
    }
  }
})
