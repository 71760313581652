import {
  Karte as KarteModel,
  BodyParts,
  Direction,
  PartsName,
  AcuParts,
  FavoriteKarte as FavoriteKarteModel
} from '@/models'

export type SimpleKarte = {
  id: string,
  patientId: string,
  karteDate: string,
  displayOrder: number,
}

export type SimpleKarteByOrder = {
  id: string,
  accountName: string
  title: string
  karteNumber: string
  patientId: string,
  karteDate: string,
  displayOrder: number,
}

export type DayKarteDetail = {
  kartes: (KarteModel | SimpleKarteByOrder)[]
  date: string
  dateString: string
}
export type DateKarte = { [date: string]: DayKarteDetail }
export type PatientKarte = { [patientId: string]: DateKarte }

export type KarteResultType = {
  karteItemId: string[]
  title: string[]
  resultId: string[][]
  partsName: PartsName[][]
  resultTitle: string[][]
  startTime: string[][]
  endTime: string[][]
  text: string[][]
  fileId: string[][][]
  fileFileId: string[][][]
  fileName: string[][][]
  fileFileType: string[][][]
  fileHash: string[][][]
  options: string[][][]
  choices: string[][][]
  direction: Direction[][]
  bodyParts: BodyParts[][]
  acuId: string[][][]
  x: (number | undefined)[][][]
  y: (number | undefined)[][][]
  acuName: string[][][]
}

export type KarteResultOrder = {
  id: string
  arrayIndex: number
}

export type KarteType = {
  id: string
  accountId: string
  accountName: string
  karteNumber: string
  patientId: string
  karteDate: string
  displayOrder: number
  title: string
}

export type ResultControl = {
  history: boolean
  up: boolean
  down: boolean
  delete: boolean
}

export const SyncStatusTypes = {
  NotFoundOnCloud: 'notFoundOnCloud',
  Stored: 'stored',
  Synced: 'synced',
  Syncing: 'syncing',
  Retrying: 'retrying'
} as const
export type SyncStatusType = typeof SyncStatusTypes[keyof typeof SyncStatusTypes]

export type DBKarteFileType = {
  id: string
  fileType: string
  name: string
  base64: string
  hash: string
  syncStatus: SyncStatusType
}

export type AcuPartsSelection = AcuParts & {
  selected: boolean
  deprecated: boolean
}

// カルテ一覧の行情報
export type KarteTitle = {
  id: string
  icon: string
  number: string
  title: string
  accountName: string
  karteDate: string
  bookmarked: boolean
  cb: () => Promise<void>
}

export type PatientFavoriteKarteMap = {[patientId: string]: FavoriteKarteModel[]}
