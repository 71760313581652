<template>
  <div class="normal-edit-box">
    <label class="edit-box-label" :class="[$mq, required ? 'required' : '']" :for="id">
      <slot></slot>
    </label>
    <input
            :id="id"
            v-bind="$props"
            :class="['normal-edit-box-box', readonly ? 'edit-box-readonly' : '', $mq]"
            :disabled="readonly"
            ref="focus"
            @input="$emit('input', $event.target.value)">
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'

export default {
  name: 'small-edit-box',
  props: {
    type: {
      type: String,
      default: 'text',
      validator (value) {
        return ['text', 'tel', 'number', 'password'].indexOf(value) !== -1
      }
    },
    title: String,
    placeholder: String,
    value: String,
    pattern: String,
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    focus () {
      this.$refs.focus.focus()
    }
  },
  computed: {
    inputClass () {
      return [
        'normal-edit-box-box',
        this.readonly ? 'edit-box-readonly' : '',
        this.$mq
      ].join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
  .normal-edit-box {
    position: relative;
  }

  .edit-box-label {
    display: inline-block;
    position: relative;
    width: 25%;
    text-align: right;
    padding-right: 5px;

    &.required {
      &:before {
        position: relative;
        display: inline-block;
        line-height: $form_item_height / 2;
        top: $form_item_height / 4;
        left: -10px;
        content: '*';
        color: $base_color_red;
        font-size: 2em;
      }
    }

    &.mobile {
      width: 30%;
      font-size: 0.9em;
    }
  }

  .normal-edit-box-box {
    height: $form_item_height;
    width: 15%;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 3px 11px;
    border: 0;
    border-radius: $border_radius;
    background: $base_color_white;
    font-size: $base_font_size;
    outline: none;

    &::placeholder {
      color: $base_color_gray;
    }

    &:focus {
      border: 2px solid $base_color_water_dark;
      padding: 0 9px;
      transition: 0.2s;
    }

    &.mobile {
      width: 60%;
    }
  }

  .edit-box-readonly{
    background: $base_color_gray_light;
    color: $base_color_gray_dark;
  }
</style>
