
import {
  defineComponent
} from 'vue'
import { useModal } from '@/composables/useModal'
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default defineComponent({
  name: 'confirm',
  components: {
    WhiteBackdrop,
    NormalButton
  },
  props: {
    yesOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['yes', 'no'],
  setup (props, context) {
    const { show, open, dismiss } = useModal()

    const onYes = () => {
      dismiss()
      context.emit('yes')
    }
    const onNo = () => {
      dismiss()
      context.emit('no')
    }

    return {
      show,
      open,
      dismiss,
      onYes,
      onNo
    }
  }
})
