
import {
  defineComponent,
  computed,
  ref, inject
} from 'vue'
import { useRouter } from 'vue-router'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useBuiltInKarteItem } from '@/composables/karteData/builtInKarteItem'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { workSpaceValidation } from '@/plugins/validator'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import VueContent from '@/components/layout/vueContent.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NoLabelEditBox from '@/components/molecules/editbox/noLabelEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { Domain } from '@/config'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'

export default defineComponent({
  name: 'register-clinic',
  components: {
    NormalButton,
    NoLabelEditBox,
    IconEditBox,
    VueContentItemFrame,
    VueContent,
    PageDescription,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const router = useRouter()
    const isSubmit = ref(false) // 一度でもボタンを押したか(ボタンを押した後のみエラー表示するため)
    const requesting = ref(false)
    const name = ref('')
    const workSpace = ref('')

    const { registerClinic } = inject(UseClinicKey) as UseClinicType
    const {
      registerBuiltInKarteItems,
      registerBuiltInKarteTemplate
    } = useBuiltInKarteItem()
    const { append } = inject(UseErrorKey) as UseErrorType
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType

    const nameError = computed(() => {
      return name.value === '' ? '施設名を入力してください' : undefined
    })

    const workSpaceError = computed(() => {
      return workSpaceValidation(workSpace.value)
    })

    const onSubmit = async () => {
      isSubmit.value = true
      if (nameError.value !== undefined || workSpaceError.value !== undefined) {
        return
      }

      try {
        await lockScreen()
        await registerClinic({
          name: name.value,
          workSpace: workSpace.value
        })
        await registerBuiltInKarteItems()
        await registerBuiltInKarteTemplate()
        await router.push('/dashboard')
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    return {
      Domain,
      isSubmit,
      requesting,
      name,
      nameError,
      workSpace,
      workSpaceError,
      onSubmit,
      nameRef: ref(null),
      workSpaceRef: ref(null),
      submitRef: ref(null)
    }
  }
})
