import { InjectionKey, ref } from 'vue'
import { KarteTemplate as KarteTemplateModel } from '@/models'
import { DataStore } from 'aws-amplify'

export const useKarteTemplateSubscription = () => {
  const karteTemplates = ref<KarteTemplateModel[]>([])
  const subscribing = ref(false)

  const fetchKarteTemplate = async () => {
    try {
      karteTemplates.value = await DataStore.query(KarteTemplateModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchKarteTemplate()
      const subscription = DataStore.observe(KarteTemplateModel).subscribe(async () => {
        await fetchKarteTemplate()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    karteTemplates,
    fetchKarteTemplate,
    subscribe
  }
}

export type UseKarteTemplateSubscriptionType = ReturnType<typeof useKarteTemplateSubscription>
export const UseKarteTemplateSubscriptionKey: InjectionKey<UseKarteTemplateSubscriptionType> = Symbol('KarteTemplateSubscription')
