
import {
  defineComponent, inject
} from 'vue'
import { UseCalendarKey, UseCalendarType } from '@/composables/useCalendar'
import { UseHolidayKey, UseHolidayType } from '@/composables/useHoliday'
import CalendarDayFrame from '@/components/molecules/calendarDayFrame.vue'
import ArrowButton from '@/components/atoms/button/arrowButton.vue'

export default defineComponent({
  name: 'calendar',
  components: {
    CalendarDayFrame,
    ArrowButton
  },
  props: {
    disableAfterToday: {
      type: Boolean,
      default: true
    },
    disableAdd: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const {
      year,
      month,
      calendarHeader,
      calendar,
      addMonth
    } = inject(UseCalendarKey) as UseCalendarType
    const {
      holidayName
    } = inject(UseHolidayKey) as UseHolidayType

    return {
      year,
      month,
      calendarHeader,
      calendar,
      addMonth,
      holidayName
    }
  }
})
