<template>
  <div class="button-frame">
    <div class="button-sub-frame">
      <slot name="left"></slot>
    </div>
    <div class="button-sub-frame">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue-button-frame'
}
</script>

<style lang="scss" scoped>
.button-frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  margin: 0 auto;
}

.button-sub-frame {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
</style>
