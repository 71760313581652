
import { defineComponent, inject } from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueContent from '@/components/layout/vueContent.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import Description from '@/components/atoms/Description.vue'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import VueSubContent from '@/components/layout/vueSubContent.vue'
import FileNameText from '@/components/atoms/fileNameText.vue'
import DropArea from '@/components/molecules/dropArea.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import { DropAreaFileType } from '@/composables/types'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { useBulkPatient } from '@/composables/patient/useBulkPatient'
import { usePatientMutation } from '@/composables/patient/usePatientMutation'
import { useFileHash } from '@/composables/useFileHash'

export default defineComponent({
  name: 'BulkRegisterPatient',
  components: {
    VueYScrollArea,
    VueContentRemain,
    DropArea,
    FileNameText,
    VueSubContent,
    VueContentItemFrame,
    Description,
    BackButton,
    VueContent,
    VueTitle,
    VueFrame
  },
  setup () {
    const { lockScreen, unlockScreen } = inject(UseLockScreenKey) as UseLockScreenType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      registerFileHash,
      existFileHash
    } = useFileHash()
    const {
      patients,
      errors,
      parseCsv
    } = useBulkPatient()
    const { createPatient } = usePatientMutation()

    const onDropCsv = async (dropFileType: DropAreaFileType) => {
      if (dropFileType.name.slice(-4) !== '.csv') {
        return
      }
      try {
        await lockScreen()
        const exist = await existFileHash(dropFileType.base64)
        if (exist) {
          await append('warning', '同じ内容のファイルを既に登録済みです')
          return
        }
        await parseCsv(dropFileType)
        if (patients.value.length === 0) {
          await append('warning', 'ファイルが空のため患者の登録は行いませんでした。')
        } else {
          for (const patient of patients.value) {
            await createPatient({ patient })
          }
          await registerFileHash({
            filename: dropFileType.name,
            body: dropFileType.base64
          })
          await append('notify', `${patients.value.length}件の患者を登録しました`)
        }
      } catch (e) {
        console.log(e)
        await append('error', e.message)
      } finally {
        await unlockScreen()
      }
    }

    const onDropError = async (err: string) => {
      await append('error', err)
    }

    return {
      onDropCsv,
      onDropError,
      errors
    }
  }
})
