
import {
  computed,
  defineComponent,
  inject,
  ref,
  onMounted
} from 'vue'
import { useRouter } from 'vue-router'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import VueContent from '@/components/layout/vueContent.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import ListRow from '@/components/molecules/listRow.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import {
  UseTemporaryPatientSubscriptionKey,
  UseTemporaryPatientSubscriptionType
} from '@/composables/appointment/useTemporaryPatientSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseLockScreenKey, UseLockScreenType } from '@/composables/useLockScreen'
import { useAppointmentMutation } from '@/composables/appointment/useAppointmentMutation'
import { usePatient } from '@/composables/patient/usePatient'
import { GenderKey, genderString } from '@/composables/patient/types'

export default defineComponent({
  name: 'TemporaryPatientList',
  components: {
    Confirm,
    ListRow,
    VueYScrollArea,
    ListTitle,
    VueContent,
    VueContentRemain,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const router = useRouter()
    const deleteConfirmRef = ref<InstanceType<typeof Confirm>>()
    const selectedId = ref<string>('')

    const {
      clinicId,
      initialized
    } = inject(UseClinicKey) as UseClinicType
    const { temporaryPatients } = inject(UseTemporaryPatientSubscriptionKey) as UseTemporaryPatientSubscriptionType
    const { append } = inject(UseErrorKey) as UseErrorType
    const {
      lockScreen,
      unlockScreen
    } = inject(UseLockScreenKey) as UseLockScreenType

    const {
      getAppointmentByPatientId,
      deleteAppointment,
      deleteTemporaryPatient
    } = useAppointmentMutation()

    const { setTemporaryPatient } = usePatient()

    const onClick = async (emitType: string, id: string) => {
      switch (emitType) {
        case 'register': {
          const temp = temporaryPatients.value.find(t => t.id === id)
          if (!temp) {
            return
          }
          setTemporaryPatient(temp!)
          await router.push(`/w/${clinicId.value}/register_patient?p=${id}&t=y`)
          break
        }
        case 'match':
          await router.replace(`/w/${clinicId.value}/patient_answer?t=${id}`)
          break
        case 'delete':
          selectedId.value = id
          deleteConfirmRef.value?.open()
          break
      }
    }

    const yesDelete = async () => {
      try {
        const appointments = await getAppointmentByPatientId(selectedId.value)
        await Promise.all(appointments
          .filter(a => !a._deleted)
          .map(async a => {
            return await deleteAppointment(a.id)
          })
        )
        await deleteTemporaryPatient(selectedId.value)
        await append('notify', '仮登録患者様を削除しました')
      } catch (e) {
        console.log(e)
        await append('error', '仮登録患者様の削除に失敗しました')
      }
    }

    const titles = computed(() => {
      return [
        { name: '名前', width: 3 },
        { name: '性別', width: 1 },
        { name: '電話番号', width: 3 },
        { name: '操作', width: 3 }
      ]
    })

    const rows = computed(() => {
      return temporaryPatients.value
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(tmp => {
          return {
            id: tmp.id,
            columns: [
              { type: 'text', width: 3, value: tmp.name },
              { type: 'text', width: 1, value: genderString(tmp.gender as GenderKey) },
              { type: 'text', width: 3, value: tmp.phoneNumber },
              {
                type: 'buttons',
                width: 3,
                buttons: [
                  { value: '本登録', state: 'normal', emitType: 'register' },
                  { value: '着信一覧から登録', state: 'warning', emitType: 'match' },
                  { value: '削除', state: 'delete', emitType: 'delete' }
                ]
              }
            ]
          }
        })
    })

    onMounted(async () => {
      await lockScreen()
      await initialized.value
      await unlockScreen()
    })

    return {
      deleteConfirmRef,
      onClick,
      yesDelete,
      titles,
      rows,
      temporaryPatients
    }
  }
})
