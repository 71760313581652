import { computed, inject, InjectionKey, ref } from 'vue'
import {
  UseInquiryItemSubscriptionKey,
  UseInquiryItemSubscriptionType
} from '@/composables/karteData/useInquiryItemSubscription'
import { ListBoxItemType } from '@/composables/types'

export const useInquiryInfo = () => {
  const { inquiryItems } = inject(UseInquiryItemSubscriptionKey) as UseInquiryItemSubscriptionType
  const selectedInquiryId = ref<string>(inquiryItems.value.length > 0 ? inquiryItems.value[0].id : '')

  const resetInquiry = () => {
    selectedInquiryId.value = inquiryItems.value.length > 0 ? inquiryItems.value[0].id : ''
  }

  const inquiryListItems = computed<ListBoxItemType[]>(() => {
    // 先頭に選択肢なしを追加している
    return [
      ...inquiryItems.value
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(inq => {
          return {
            name: inq.title,
            value: inq.id
          }
        })
    ]
  })

  return {
    selectedInquiryId,
    resetInquiry,
    inquiryListItems
  }
}

export type UseInquiryInfoType = ReturnType<typeof useInquiryInfo>
export const UseInquiryInfoKey: InjectionKey<UseInquiryInfoType> = Symbol('InquiryInfo')
