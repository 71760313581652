<template>
  <div :class="['tag-frame', appointmentList.length === 0 ? 'patient-zero' : '']">
    <div :class="['doctor', appointmentList.length === 0 ? 'patient-zero' : '', $mq]">
      <div>{{ doctorName }}</div>
      <div>{{ appointmentList.length }}人</div>
    </div>
    <div class="appointment-list">
      <patient-hour-text
        v-for="(appointment, index) in sortedAppointmentList"
        :key="'appointment'+index"
        class="appointment"
        :appointment="appointment"/>
    </div>
  </div>
</template>

<script>
import PatientHourText from '@/components/molecules/PatientHourText.vue'

export default {
  name: 'doctor-tag',
  components: {
    PatientHourText
  },
  props: {
    doctorName: String,
    appointmentList: Array
  },
  computed: {
    sortedAppointmentList () {
      return [...this.appointmentList].sort((i, j) => {
        return i.appointment.startTime > j.appointment.startTime
          ? 1
          : i.appointment.startTime < j.appointment.startTime
            ? -1
            : 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-frame {
  border-radius: $border_radius;
  background: $base_color_gray_lighter;
  padding: 5px;

  &:hover {
    background: $base_color_gray_light;
  }

  &.patient-zero {
    &:hover {
      background: $base_color_gray_light;
    }
  }
}

.doctor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;

  &.patient-zero {
    opacity: 0.3;
  }

  &.laptop, &.tablet, &.mobile {
    font-size: 0.8em;
  }
}

.appointment-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}
</style>
