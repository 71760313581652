<template>
  <div class="frame" :class="$mq">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-content-item-frame'
}
</script>

<style lang="scss" scoped>
.frame {
  width: 80%;
  margin: 0 auto;

  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 12px;

  &.mobile {
    width: 95%;
  }
}
</style>
