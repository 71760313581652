import { Patient as PatientModel } from '@/models'

export const InquiryValueTypes = [
  'string',
  'number',
  'numberArray',
  'email',
  'date',
  'dateAndAge',
  'radio',
  'zipCode',
  'addressSelector',
  'pref',
  'city'
] as const
export type InquiryType = typeof InquiryValueTypes[number]

export interface InquiryOption {
  name: string
  value: string
}

export interface InquiryItem {
  key: string
  valueType: InquiryType
  required: boolean
  title: string
  placeholder?: string
  options?: InquiryOption[]
  defaultValue?: string
}

export interface InquiryPage {
  pageTitle: string
  inquiries: InquiryItem[]
}

export interface Inquiry {
  id: string
  version: string
  title: string
  inquiryPages: InquiryPage[]
}

export const Genders = {
  male: '男性',
  female: '女性',
  other: 'その他'
} as const
export type GenderKey = keyof typeof Genders

export const genderString = (value: GenderKey | null):string => {
  if (value === null) {
    return ''
  }
  return Genders[value]
}

// 表示の都度計算しないように、誕生日、年齢、性別、住所の表示文字列をデータの取得時に計算しておく
export type PatientDetail = {
  patient: PatientModel
  birthDateString: string
  age: number
  genderString: string
  address: string
}
