<template>
  <div class="list-row-item-button-frame">
    <normal-button
      class="list-row-item-button"
      :state="state"
      :disabled="disabled"
      @click="$emit('click', $event)">{{ value }}
    </normal-button>
  </div>
</template>

<script>
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default {
  name: 'list-row-item-button',
  components: {
    NormalButton
  },
  props: {
    value: String,
    state: String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.list-row-item-button-frame {
  display: flex;
  flex-direction: column;
}

.list-row-item-button {
  height: $form_button_height;
}
</style>
