<template>
  <div class="vue-flex-row file-name-frame">
    <fa-icon icon="file" class="file-icon"></fa-icon>
    <div class="file-name">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'file-name-text'
}
</script>

<style lang="scss" scoped>
  .file-name-frame {
    cursor: pointer;

    .file-icon {
      margin-right: 5px;
      color: $base_color_blue;
    }
    .file-name {
      text-decoration: underline;
    }
  }
</style>
