<template>
  <vue-content-remain>
    <vue-two-column-content>
      <template v-slot:left>
        <patient-search-form/>
      </template>
      <template v-slot:right>
        <patient-list @appointment="$emit('appointment', $event)"></patient-list>
      </template>
    </vue-two-column-content>
  </vue-content-remain>
</template>

<script>
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientSearchForm from '@/views/appointment/register/PatientSearchForm.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import PatientList from '@/views/appointment/register/PatientList.vue'

export default {
  name: 'select-patient',
  components: {
    PatientList,
    VueContentRemain,
    PatientSearchForm,
    VueTwoColumnContent
  }
}
</script>

<style lang="scss" scoped>

</style>
