<template>
  <span class="logo">電カル</span>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style lang="scss" scoped>
  .logo {
    display: inline-block;
    transform: scale(0.7, 1);
    color: $base_color_green_light;
  }
</style>
