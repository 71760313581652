export const AppName = 'eKarte'
export const Domain = 'https://den-karu.com'
export const InquirySubDomain = 'https://inquiry.den-karu.com'

export const AccountNumberPerPage = 10
export const ErrorDismissTime = 3 // sec
export const DefaultStartTime = '0900'
export const MaxAttachFileMega = 10
export const LoadingTimeout = 10000 // msec
export const TokenRefreshInterval = 5 // min
export const WheelUpThreshold = 18 // マウスホイールのY方向のマイナス変化量の回数合計がこの値を超えたら、新しいコンテンツをfetchする
export const FetchKarteNumber = 100 // あまり小さい値だと、削除済みのデータばかりがヒットして空の応答が返る。
export const FileSyncFailedIntervalSec = 60 * 1000

export const partsButtonPallet = [
  { id: '1', color: '#2f3432', bgColor: '#9fcaee', markColor: '#6caf65' },
  { id: '2', color: '#f0f0f0', bgColor: '#00597d', markColor: '#ecd864' },
  { id: '3', color: '#2f3432', bgColor: '#ecd864', markColor: '#6caf65' },
  { id: '4', color: '#2f3432', bgColor: '#ff8c89', markColor: '#6caf65' },
  { id: '5', color: '#f0f0f0', bgColor: '#6caf65', markColor: '#ecd864' },
  { id: '6', color: '#f0f0f0', bgColor: '#0a0a0e', markColor: '#ecd864' }
]

export const mqConfig = {
  breakpoints: {
    mobile: 821,
    tablet: 1025,
    laptop: 1280,
    desktop: Infinity
  }
}
