<template>
  <div class="text-column">
    <div
      class="text"
      v-for="(value, index) in values"
      :key="columnId+'-'+index"
      @click="value.cb(value)">{{ value.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-text-multiline-link',
  props: {
    values: Array,
    columnId: String
  }
}
</script>

<style lang="scss" scoped>
.text-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text {
  word-break: break-all;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 4px;
}
</style>
