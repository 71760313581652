
import { defineComponent } from 'vue'
import { useTab } from '@/composables/useTab'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import SideMenu from '@/components/molecules/SideMenu.vue'
import AccountSettingName from '@/views/root/accountSetting/AccountSettingName.vue'
import AccountSettingPassword from '@/views/root/accountSetting/AccountSettingPassword.vue'

export default defineComponent({
  name: 'account-setting',
  components: {
    AccountSettingPassword,
    AccountSettingName,
    SideMenu,
    VueTwoColumnContent,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const { tabs, selectedIndex, selectTab } = useTab(['名前変更', 'パスワード変更'])

    return {
      tabs,
      selectedIndex,
      selectTab
    }
  }
})
