import dayjs from 'dayjs'
import { computed, inject, InjectionKey, reactive, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useSubmit } from '@/composables/useSubmit'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { CreateBusinessHourInput, IntervalType } from '@/API'
import { hhmmValidation, numberValidation } from '@/plugins/validator'
import { formatHM } from '@/plugins/functions'

interface BusinessHourState {
  intervalType: string
  interval: string
  startTime: string
  endTime: string
}

export const useBusinessHour = () => {
  const route = useRoute()
  const { submitted, submit } = useSubmit()
  const { id: userId } = inject(UseAuthenticationKey) as UseAuthenticationType

  const year = ref<number>(parseInt(route.query.y as string))
  const month = ref<number>(parseInt(route.query.m as string))
  const day = ref<number>(parseInt(route.query.d as string))
  const id = ref<string>('')
  const state = reactive<BusinessHourState>({
    intervalType: 'week',
    interval: '1',
    startTime: '0900',
    endTime: '1700'
  })

  const storageKey = 'ekarte-business-hour'
  const lastData = localStorage.getItem(storageKey)
  if (lastData) {
    const json = JSON.parse(lastData) as BusinessHourState
    state.intervalType = json.intervalType
    state.interval = json.interval
    state.startTime = json.startTime
    state.endTime = json.endTime
  }

  watch(
    () => state,
    to => {
      localStorage.setItem(storageKey, JSON.stringify(to))
    },
    { deep: true }
  )

  const intervalError = computed(() => {
    if (state.intervalType === 'once') {
      return undefined
    }
    return state.interval === ''
      ? '繰返し間隔を入力してください'
      : numberValidation(state.interval)
  })

  const startTimeError = computed(() => {
    return state.startTime === ''
      ? '開始時刻を入力してください'
      : hhmmValidation(state.startTime)
  })

  const endTimeError = computed(() => {
    return state.endTime === ''
      ? '終了時刻を入力してください'
      : hhmmValidation(state.endTime)
  })

  const timeOrderError = computed(() => {
    return startTimeError.value !== undefined || endTimeError.value !== undefined
      ? undefined
      : state.startTime >= state.endTime
        ? '終了時刻が開始時刻より前か同じです'
        : undefined
  })

  const isError = computed(() => {
    return ![intervalError, startTimeError, endTimeError, timeOrderError].every(e => e.value === undefined)
  })

  const baseDateString = computed(() => {
    const d = dayjs(new Date(year.value, month.value, day.value))
    return d.format('YYYY年M月D日(ddd)')
  })

  const intervalTypes = computed(() => {
    const d = dayjs(new Date(year.value, month.value, day.value))
    const weekNum = Math.floor((d.date() - 1) / 7) + 1
    const weekDayName = d.format('ddd')
    return [
      {
        value: 'day',
        name: '日'
      },
      {
        value: 'week',
        name: '週'
      },
      {
        value: 'month',
        name: '月'
      },
      {
        value: 'year',
        name: '年'
      },
      {
        value: 'nthWeekDay',
        name: `第${weekNum}${weekDayName}曜日`
      },
      {
        value: 'once',
        name: '一回のみ'
      }
    ]
  })

  const businessHour = computed<CreateBusinessHourInput>(() => {
    const originDate = dayjs(new Date(year.value, month.value, day.value)).format('YYYY-MM-DD')

    return {
      id: id.value,
      userId: userId.value,
      originDate,
      intervalType: state.intervalType as IntervalType,
      interval: parseInt(state.interval),
      startTime: formatHM(state.startTime),
      endTime: formatHM(state.endTime)
    }
  })

  return {
    submit,
    submitted,
    year,
    month,
    day,
    ...toRefs(state),
    intervalError,
    startTimeError,
    endTimeError,
    timeOrderError,
    isError,
    baseDateString,
    intervalTypes,
    businessHour
  }
}

export type UseBusinessHourType = ReturnType<typeof useBusinessHour>
export const UseBusinessHourKey: InjectionKey<UseBusinessHourType> = Symbol('BusinessHour')
