<template>
  <div class="frame">
    <div class="label">{{label}}</div>
    <div class="value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'label-text',
  props: {
    label: String
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;

    .label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
</style>
