
import {
  defineComponent,
  inject
} from 'vue'
import { UseBusinessHourKey, UseBusinessHourType } from '@/composables/businessHour/useBusinessHour'
import VueLabel from '@/components/layout/vueLabel.vue'
import SelectBox from '@/components/atoms/selectBox.vue'
import ValidateEditBoxNumber from '@/components/molecules/editbox/validateEditBoxNumber.vue'

export default defineComponent({
  name: 'repeat',
  components: {
    ValidateEditBoxNumber,
    VueLabel,
    SelectBox
  },
  setup () {
    const {
      submitted,
      intervalType,
      interval,
      intervalTypes,
      intervalError
    } = inject(UseBusinessHourKey) as UseBusinessHourType

    return {
      submitted,
      intervalType,
      interval,
      intervalTypes,
      intervalError
    }
  }
})
