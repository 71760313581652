
import { defineComponent, inject } from 'vue'
import { UseFavoriteListKey, UseFavoriteListType } from '@/composables/karte/useFavoriteList'
import VueContent from '@/components/layout/vueContent.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import ListRow from '@/components/molecules/listRow.vue'

export default defineComponent({
  name: 'bookmark-list',
  components: {
    ListRow,
    VueYScrollArea,
    VueContent
  },
  setup () {
    const { favoriteRows } = inject(UseFavoriteListKey) as UseFavoriteListType

    return {
      favoriteRows
    }
  }
})
