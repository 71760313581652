
import { defineComponent, watchEffect } from 'vue'
import { useDateAndAge } from '@/composables/useDateAndAge'
import SelectBox from '@/components/atoms/selectBox.vue'
import VueLabel from '@/components/layout/vueLabel.vue'
import SmallEditBox from '@/components/molecules/editbox/smallEditBox.vue'

export default defineComponent({
  name: 'date-and-age-inquiry',
  components: {
    SmallEditBox,
    VueLabel,
    SelectBox
  },
  props: {
    title: String,
    keyValue: String,
    required: Boolean,
    modelValue: String
  },
  setup (props, content) {
    const {
      years,
      months,
      days,
      year,
      month,
      day,
      age
    } = useDateAndAge()

    year.value = props.modelValue?.substr(0, 4) || '2000'
    month.value = props.modelValue?.substr(5, 2) || '01'
    day.value = props.modelValue?.substr(8, 2) || '01'

    watchEffect(() => {
      const d = `${year.value}-${month.value}-${day.value}`
      content.emit('update:modelValue', d)
    })

    return {
      years,
      months,
      days,
      year,
      month,
      day,
      age
    }
  }
})
