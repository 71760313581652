
import {
  defineComponent,
  computed
} from 'vue'
import { useFocus } from '@/composables/useFocus'
import { v4 as uuid } from 'uuid'

type Props = {
  inputType: string
  title?: string | undefined
  placeholder?: string | undefined
  modelValue?: string | undefined
  readonly: boolean
  required: boolean
}

export default defineComponent({
  name: 'top-label-edit-box',
  props: {
    inputType: {
      type: String,
      default: 'text',
      validator (value: string) {
        return ['text', 'tel', 'number', 'password'].indexOf(value) !== -1
      }
    },
    title: String,
    placeholder: String,
    modelValue: String,
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup (props: Props) {
    const id = uuid()
    const { focusRef, focus } = useFocus()

    const inputClass = computed(() => {
      return [
        'top-label-edit-box-box',
        props.readonly ? 'edit-box-readonly' : ''
      ].join(' ')
    })

    return {
      id,
      focusRef,
      focus,
      inputClass
    }
  }
})
