
import { computed, defineComponent, inject, ref } from 'vue'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { useSubmit } from '@/composables/useSubmit'
import { passwordValidation } from '@/plugins/validator'
import VueContent from '@/components/layout/vueContent.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default defineComponent({
  name: 'AccountSettingPassword',
  components: {
    NormalButton,
    IconEditBox,
    VueContent
  },
  setup () {
    const {
      changePassword
    } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { append } = inject(UseErrorKey) as UseErrorType
    const { submitted, submit } = useSubmit()

    const requesting = ref(false)
    const oldPassword = ref('')
    const newPassword = ref('')
    const confirmedNewPassword = ref('')

    const oldPasswordError = computed(() => {
      return passwordValidation(oldPassword.value)
    })

    const newPasswordError = computed(() => {
      return passwordValidation(newPassword.value)
    })

    const confirmedNewPasswordError = computed(() => {
      if (newPassword.value !== confirmedNewPassword.value) {
        return '確認用のパスワードが異なっています'
      }
      return undefined
    })

    const onSubmitPassword = async () => {
      submit()
      if (oldPasswordError.value !== undefined ||
        newPasswordError.value !== undefined ||
        confirmedNewPasswordError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await changePassword({
          oldPassword: oldPassword.value,
          newPassword: newPassword.value
        })
        await append('notify', 'パスワードを変更しました')
      } catch (e) {
        console.log(e)
        await append('error', 'パスワード変更に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      submitted,
      requesting,
      oldPassword,
      oldPasswordError,
      newPassword,
      newPasswordError,
      confirmedNewPassword,
      confirmedNewPasswordError,
      onSubmitPassword
    }
  }
})
