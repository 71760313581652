import { InjectionKey, ref } from 'vue'
import { KarteItem as KarteItemModel } from '@/models'
import { DataStore } from 'aws-amplify'

export const useKarteItemSubscription = () => {
  const karteItems = ref<KarteItemModel[]>([])
  const subscribing = ref(false)

  const fetchKarteItem = async () => {
    try {
      karteItems.value = await DataStore.query(KarteItemModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchKarteItem()
      const subscription = DataStore.observe(KarteItemModel).subscribe(async () => {
        await fetchKarteItem()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    karteItems,
    fetchKarteItem,
    subscribe
  }
}

export type UseKarteItemSubscriptionType = ReturnType<typeof useKarteItemSubscription>
export const UseKarteItemSubscriptionKey: InjectionKey<UseKarteItemSubscriptionType> = Symbol('KarteItemSubscription')
