import { computed, inject, InjectionKey, ref, Ref } from 'vue'
import dayjs from 'dayjs'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import {
  UseBusinessHourSubscriptionKey,
  UseBusinessHourSubscriptionType
} from '@/composables/businessHour/useBusinessHourSubscription'
import { Day, SelectedHour } from '@/composables/businessHour/types'
import { useMakeCalender } from '@/composables/businessHour/makeCalendar'

export const useBusinessHourMyList = (year: Ref<number>, month: Ref<number>) => {
  const {
    id: userId,
    name
  } = inject(UseAuthenticationKey) as UseAuthenticationType
  const {
    businessHourMap
  } = inject(UseBusinessHourSubscriptionKey) as UseBusinessHourSubscriptionType
  const { makeCalendar } = useMakeCalender()

  const dayList = computed<Day[]>(() => {
    if (!businessHourMap.value[userId.value]) {
      return []
    }
    return makeCalendar(year.value, month.value, businessHourMap.value[userId.value])
  })

  const selectedHour = ref<SelectedHour|undefined>()

  const selectHour = async (year:number, month: number, dayIndex: number, hourIndex: number) => {
    if (dayList.value.length <= dayIndex) {
      selectedHour.value = undefined
      return
    }
    if (dayList.value[dayIndex].hourList.length <= hourIndex) {
      selectedHour.value = undefined
      return
    }
    selectedHour.value = {
      date: dayjs(new Date(year, month, dayList.value[dayIndex].day)).format('YYYY-MM-DD'),
      businessHour: dayList.value[dayIndex].hourList[hourIndex].businessHour,
      temporaryClose: dayList.value[dayIndex].hourList[hourIndex].temporaryClose,
      closeDay: dayList.value[dayIndex].closeDay
    }
  }

  return {
    name,
    dayList,
    selectedHour,
    selectHour
  }
}

export type UseBusinessHourMyListType = ReturnType<typeof useBusinessHourMyList>
export const UseBusinessHourMyListKey: InjectionKey<UseBusinessHourMyListType> = Symbol('BusinessHourMyList')
