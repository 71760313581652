<template>
  <div
    :class="['tag-frame',focused ? 'tag-frame-focused' : '']"
    @click="$emit('click')">
    <div class="content-frame">
      <div :class="close || closeDay ? 'content-closed' : ''">
        <slot name="title"></slot>
      </div>
      <div :class="close || closeDay ? 'content-closed' : ''">
        <slot name="content"></slot>
      </div>
      <div
        class="content-temporary-close"
        v-if="close">臨時休業
      </div>
      <div
        class="content-close"
        v-if="closeDay">休業日
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hour-tag',
  props: {
    focused: Boolean,
    close: Boolean,
    closeDay: Boolean
  }
}
</script>

<style lang="scss" scoped>
.tag-frame {
  border-radius: $border_radius;
  background: $base_color_gray_light;
  padding: 5px;
  transition: 0.3s;
}

.tag-frame-focused {
  background: $base_color_gray;
}

.content-frame {
  display: flex;
  flex-direction: column;

  .content-closed {
    opacity: 0.3;
  }

  .content-temporary-close {
    color: $base_color_red_dark;
    font-weight: bold;
  }

  .content-close {
    color: $base_color_red_dark;
    font-weight: bold;
  }
}
</style>
