import { computed, InjectionKey, ref } from 'vue'
import { Karte as KarteModel } from '@/models'
import { DataStore, syncExpression } from 'aws-amplify'
import dayjs from 'dayjs'
import { PatientKarte } from '@/composables/karte/types'

export const useKarteSubscription = () => {
  const lastMonth = ref<string>(dayjs().add(-2, 'months').format('YYYY-MM-DD'))
  const kartes = ref<KarteModel[]>([])
  const subscribing = ref(false)

  const fetchKarte = async () => {
    try {
      kartes.value = await DataStore.query(KarteModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const expression = () => {
    return syncExpression(KarteModel, () => {
      return a => a.karteDate('ge', lastMonth.value)
    })
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchKarte()
      const subscription = DataStore.observe(KarteModel).subscribe(async () => {
        await fetchKarte()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const patientKarteMap = computed(() => {
    const newMap: PatientKarte = {}

    kartes.value.forEach(k => {
      const karteDate = k.karteDate
      const patientId = k.patientId

      if (!Object.prototype.hasOwnProperty.call(newMap, patientId)) {
        newMap[patientId] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(newMap[patientId], karteDate)) {
        newMap[patientId][karteDate] = {
          kartes: [],
          date: karteDate,
          dateString: dayjs(karteDate, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
        }
      }
      newMap[patientId][karteDate].kartes.push(k)
    })

    Object.keys(newMap).forEach(patientId => {
      Object.keys(newMap[patientId]).forEach(karteDate => {
        newMap[patientId][karteDate].kartes.sort((a, b) => a.displayOrder - b.displayOrder)
      })
    })
    return newMap
  })

  return {
    lastMonth,
    fetchKarte,
    expression,
    subscribe,
    patientKarteMap
  }
}

export type UseKarteSubscriptionType = ReturnType<typeof useKarteSubscription>
export const UseKarteSubscriptionKey: InjectionKey<UseKarteSubscriptionType> = Symbol('KarteSubscription')
