
import { defineComponent, inject } from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import KeyValueFrame from '@/components/molecules/keyValueFrame.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import RadioButtonCard from '@/components/molecules/radioButtonCard.vue'
import {
  UseInquiryAnswerSubscriptionKey,
  UseInquiryAnswerSubscriptionType
} from '@/composables/inquieryAnswer/useInquiryAnswerSubscription'
import { UsePatientMatchKey, UsePatientMatchType } from '@/composables/patient/usePatientMatch'

export default defineComponent({
  name: 'patient-answer-list',
  components: { RadioButtonCard, VueYScrollArea, KeyValueFrame, VueContent },
  setup () {
    const {
      patientAnswers
    } = inject(UseInquiryAnswerSubscriptionKey) as UseInquiryAnswerSubscriptionType
    const {
      selectedAnswerId,
      selectTemporaryByPhoneNumbers
    } = inject(UsePatientMatchKey) as UsePatientMatchType

    const onSelectAnswer = (id: string, phoneNumbers: string[]) => {
      selectedAnswerId.value = id
      selectTemporaryByPhoneNumbers(phoneNumbers)
    }

    return {
      patientAnswers,
      selectedAnswerId,
      onSelectAnswer
    }
  }
})
