import { computed, inject, InjectionKey, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  UsePatientSubscriptionKey,
  UsePatientSubscriptionType
} from '@/composables/patient/usePatientSubscription'
import { API, graphqlOperation } from 'aws-amplify'
import { listKarteByOrder } from '@/graphql/queries'
import { ModelSortDirection } from '@/API'
import dayjs from 'dayjs'
import { SimpleKarteByOrder } from '@/composables/karte/types'

interface ListSimpleKarteByOrderResult {
  data?: {
    listKarteByOrder: {
      items: SimpleKarteByOrder[]
    }
  }
}

// カルテ一覧画面の患者情報
export const useKartePatient = () => {
  const route = useRoute()
  const patientId = ref<string>(route.query.p as string || '')
  const firstData = ref<string|null>(null)
  const { patients } = inject(UsePatientSubscriptionKey) as UsePatientSubscriptionType

  const fetchFirstDate = async () => {
    try {
      const res = await API.graphql(graphqlOperation(listKarteByOrder, {
        patientId: patientId.value,
        sortDirection: ModelSortDirection.ASC,
        limit: 1
      })) as ListSimpleKarteByOrderResult
      if (res.data?.listKarteByOrder.items && res.data!.listKarteByOrder.items.length > 0) {
        firstData.value = res.data!.listKarteByOrder.items[0].karteDate
      }
    } catch (e) {
      console.log(e)
    }
  }

  onMounted(async () => {
    await fetchFirstDate()
  })

  const patientDetail = computed(() => {
    return patients.value.find(p => p.patient.id === patientId.value)
  })

  const patientInfo = computed(() => {
    if (!patientDetail.value) {
      return ''
    }
    const patient = patientDetail.value.patient
    const info = []
    info.push(`${patient.name}(${patient.read}) 様`)
    info.push(`${patientDetail.value.age}歳`)
    info.push(`性別 ${patientDetail.value.genderString}`)

    return info.join(' / ')
  })

  const phoneNumber = computed(() => {
    if (!patientDetail.value) {
      return ''
    }
    const patient = patientDetail.value.patient
    const numbers = patient.private?.phoneNumber?.join(' / ')
    return `電話番号： ${numbers}`
  })

  const firstDateString = computed(() => {
    if (firstData.value === null) {
      return '???'
    }
    return dayjs(firstData.value, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
  })

  return {
    patientId,
    patientDetail,
    patientInfo,
    phoneNumber,
    firstDateString
  }
}

export type UseKartePatientType = ReturnType<typeof useKartePatient>
export const UseKartePatientKey: InjectionKey<UseKartePatientType> = Symbol('KartePatient')
