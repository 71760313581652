import { computed, InjectionKey, ref } from 'vue'
import { GenderKey } from '@/composables/patient/types'
import { numberValidation } from '@/plugins/validator'

export const useTemporaryPatient = () => {
  const name = ref<string>('')
  const gender = ref<GenderKey|null>(null)
  const phoneNumber = ref<string>('')

  const genders = [
    { value: 'male', name: '男性' },
    { value: 'female', name: '女性' },
    { value: 'other', name: 'その他' }
  ]

  const nameError = computed(() => {
    return name.value === '' ? '名前を入力して下さい' : undefined
  })

  const genderError = computed(() => {
    return gender.value === null ? '性別を選択して下さい' : undefined
  })

  const phoneNumberError = computed(() => {
    return phoneNumber.value === ''
      ? undefined
      : numberValidation(phoneNumber.value)
  })

  const patientInfo = computed(() => {
    let info = `${name.value} 様`
    if (phoneNumber.value !== '') {
      info += ` ☎${phoneNumber.value}`
    }
    return info
  })

  const validTemporaryPatient = () => {
    return nameError.value === undefined &&
      genderError.value === undefined &&
      phoneNumberError.value === undefined
  }

  return {
    name,
    gender,
    phoneNumber,
    genders,
    nameError,
    genderError,
    phoneNumberError,
    patientInfo,
    validTemporaryPatient
  }
}

export type UseTemporaryPatientType = ReturnType<typeof useTemporaryPatient>
export const UseTemporaryPatientKey: InjectionKey<UseTemporaryPatientType> = Symbol('TemporaryPatient')
