import { InjectionKey } from 'vue'
import { CreateFileHashInput, FileHash } from '@/API'
import { sha256Hash } from '@/plugins/csvUtils'
import { API, graphqlOperation } from 'aws-amplify'
import { createFileHash } from '@/graphql/mutations'

import { getFileHash } from '@/graphql/queries'

interface GetFileHashResult {
  data?: {
    getFileHash: FileHash | null
  }
}

export const useFileHash = () => {
  const registerFileHash = async ({ filename, body }: {
    filename: string,
    body: string
  }) => {
    try {
      const input: CreateFileHashInput = {
        id: await sha256Hash(body),
        filename: filename
      }
      await API.graphql(graphqlOperation(createFileHash, { input }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const existFileHash = async (body: string): Promise<boolean> => {
    try {
      const hash = await sha256Hash(body)
      const res = await API.graphql(graphqlOperation(getFileHash, { id: hash })) as GetFileHashResult
      return res.data?.getFileHash !== null
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerFileHash,
    existFileHash
  }
}

export type UseFileHashType = ReturnType<typeof useFileHash>
export const UseFileHashKey: InjectionKey<UseFileHashType> = Symbol('FileHash')
