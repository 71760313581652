<template>
  <div class="time-edit-box">
    <no-label-edit-box
      title="time"
      type="number"
      :placeholder="placeholder"
      :model-value="modelValue"
      :readonly="readonly"
      @update:modelValue="onInput"></no-label-edit-box>
    <icon-button-large
      v-if="!readonly"
      class="time-edit-button"
      icon="minus"
      state="normal"
      @click="onDown"></icon-button-large>
    <icon-button-large
      v-if="!readonly"
      class="time-edit-button"
      icon="plus"
      state="normal"
      @click="onUp"></icon-button-large>
  </div>
</template>

<script>
import * as config from '@/config'
import NoLabelEditBox from './editbox/noLabelEditBox.vue'
import IconButtonLarge from '@/components/atoms/button/iconButtonLarge.vue'
import { timeRound, addTime } from '@/plugins/functions'

export default {
  name: 'time-edit-box',
  components: {
    IconButtonLarge,
    NoLabelEditBox
  },
  props: {
    modelValue: String,
    interval: Number,
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput (value) {
      if (value.length >= 4) {
        const newTime = timeRound(value.substr(0, 4), this.interval)
        this.$emit('update:modelValue', value)
        this.$emit('update:modelValue', newTime)
      } else {
        this.$emit('update:modelValue', value)
      }
    },
    onUp () {
      if (this.modelValue.length < 4) {
        this.$emit('update:modelValue', config.DefaultStartTime)
      } else {
        const newTime = addTime(this.modelValue, this.interval)
        this.$emit('update:modelValue', newTime)
      }
    },
    onDown () {
      if (this.modelValue.length < 4) {
        this.$emit('update:modelValue', config.DefaultStartTime)
      } else {
        const newTime = addTime(this.modelValue, -this.interval)
        this.$emit('update:modelValue', newTime)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.time-edit-box {
  display: flex;
}

.time-edit-button {
  flex-shrink: 0;
  margin: 0 2px;
}
</style>
