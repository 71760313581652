import isURL from 'validator/lib/isURL'
import { toHalf } from '@/plugins/functions'
import { email, required } from '@vee-validate/rules'
import dayjs from 'dayjs'

export const urlValidation = (value: string): (string | undefined) => {
  return isURL(value, { require_tld: false }) ? undefined : 'URLが正しくありません'
}

export const emailValidation = (value: string): (string | undefined) => {
  if (!required(value)) {
    return 'Eメールアドレスが未入力です'
  }
  if (!email(value)) {
    return 'Eメールアドレスが正しくありません'
  }
  return undefined
}

export const passwordValidation = (value:string): (string | undefined) => {
  if (value === undefined || value.length < 8 || value.length > 32) {
    return 'パスワードは8文字以上、32文字以下で設定して下さい'
  }

  const passRegex = new RegExp('^[A-Za-z0-9$@!%*#?&]{8,32}$')
  return passRegex.test(value) ? undefined : 'パスワードはa-zA-Z0-9$@!%*#?&が使用可能です'
}

export const numberValidation = (value: string): (string | undefined) => {
  const numberRegex = new RegExp('^[0-9０１２３４５６７８９]+$')
  return numberRegex.test(value) ? undefined : '数字を入力してください'
}

export const zipCodeValidation = (value: string): (string | undefined) => {
  const numberRegex = new RegExp('^[0-9０１２３４５６７８９]{7}$')
  return numberRegex.test(value) ? undefined : '７桁の数字を入力してください'
}

export const workSpaceValidation = (value: string): (string | undefined) => {
  const reg = new RegExp('^[A-Za-z][A-Za-z0-9]{2,32}$')
  if (!reg.test(value)) {
    return 'ワークスペースは3から32文字の英数字のみで入力してください(1文字目は英字にしてください)'
  }
  if (!required(value)) {
    return 'ワークスペースが未入力です'
  }
  return undefined
}

export const hhmmValidation = (value: string): (string | undefined) => {
  const numberRegex = new RegExp('^[0-9０１２３４５６７８９]{4}$')
  if (!numberRegex.test(value)) {
    return '時刻(4桁)を入力してください'
  }

  const hour = parseInt(toHalf(value.substr(0, 2)))
  const minute = parseInt(toHalf(value.substr(2, 2)))
  return ((hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) || (hour === 24 && minute === 0)) ? undefined : '時刻(4桁)を入力してください'
}

export const yyyymmddValidation = (value: string): (string | undefined) => {
  const numberRegex = new RegExp('^[0-9０１２３４５６７８９]{8}$')
  if (!numberRegex.test(value)) {
    return '年月日(8桁)を入力してください'
  }

  return dayjs(toHalf(value), 'YYYYMMDD', true).isValid()
    ? undefined
    : '日付が不正です'
}
