
import { defineComponent, ref, onMounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import VueLoginFrame from '@/components/layout/vueLoginFrame.vue'
import VueLoginForm from '@/components/layout/vueLoginForm.vue'
import Logo from '@/components/atoms/logo.vue'
import SixDigits from '@/components/molecules/SixDigits.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import LinkText from '@/components/atoms/linkText.vue'
import Confirm from '@/components/organisms/Confirm.vue'

export default defineComponent({
  name: 'confirm-sign-up',
  components: {
    Confirm,
    LinkText,
    PageDescription,
    SixDigits,
    Logo,
    VueLoginForm,
    VueLoginFrame
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { confirmSignUp } = inject(UseAuthenticationKey) as UseAuthenticationType

    const digits = ref<string[]>(['', '', '', '', '', ''])
    const sixDigitRef = ref<InstanceType<typeof SixDigits>>()
    const confirmRef = ref<InstanceType<typeof Confirm>>()

    const onEnter = async () => {
      if (!digits.value.every(v => v.length === 1)) {
        return
      }

      try {
        const userId = route.query.uid as string
        await confirmSignUp({
          username: userId,
          code: digits.value.join('')
        })
        confirmRef.value?.open()
      } catch (e) {
        console.log(e)
      }
    }

    const yes = async () => {
      await router.push('/login')
    }

    onMounted(() => {
      sixDigitRef.value?.focus(0)
    })

    return {
      digits,
      onEnter,
      yes,
      sixDigitRef,
      confirmRef
    }
  }
})
