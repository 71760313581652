<template>
  <div>
    <no-label-edit-box
      class="list-row-item-button"
      :readonly="readonly"
      :modelValue="value"
      @update:modelValue="$emit('update:modelValue', $event)"></no-label-edit-box>
  </div>
</template>

<script>
import NoLabelEditBox from '@/components/molecules/editbox/noLabelEditBox.vue'

export default {
  name: 'list-row-item-edit-box',
  components: {
    NoLabelEditBox
  },
  props: {
    value: String,
    readonly: Boolean
  }
}
</script>

<style lang="scss" scoped>
.list-row-item-button {
  height: $form_button_height;
}
</style>
