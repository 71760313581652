<template>
  <div>
    <span
      v-for="(c, i) in chars"
      :key="'c'+i"
      :class="c.emphasized ? 'text-emphasized' : ''">{{ c.char }}</span>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-emphasized-text',
  props: {
    value: {
      type: String,
      default: ''
    },
    searchWord: String
  },
  computed: {
    chars () {
      if (this.searchWord.length === 0) {
        return [
          {
            char: this.value,
            emphasized: false
          }
        ]
      }

      let index = 0
      const searchChars = this.searchWord.split('')
      return this.value.split('').map(c => {
        if (index >= searchChars.length) {
          return {
            char: c,
            emphasized: false
          }
        }

        if (c === searchChars[index]) {
          index++
          return {
            char: c,
            emphasized: true
          }
        }
        return {
          char: c,
          emphasized: false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-emphasized {
  color: $base_color_blue_dark;
  font-weight: bold;
}
</style>
