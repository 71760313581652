<template>
  <div>{{value}}</div>
</template>

<script>
export default {
  name: 'list-row-item-text',
  props: {
    value: String
  }
}
</script>
