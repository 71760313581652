<template>
  <div class="clinic-row">
    <fa-icon
      class="clinic-icon"
      icon="clinic-medical"></fa-icon>
    <div>{{ name }}</div>
    <circle-mark v-if="owner" class="clinic-owner">管理者</circle-mark>
    <circle-mark v-else-if="invitation" class="clinic-invitation">招待中</circle-mark>
    <circle-mark v-else class="clinic-belongs">所属</circle-mark>
    <normal-button
      class="clinic-button"
      state="normal"
      @click="$emit('click')">ログイン</normal-button>
  </div>
</template>

<script>
import NormalButton from '@/components/atoms/button/normalButton.vue'
import CircleMark from '@/components/atoms/circleMark.vue'

export default {
  name: 'clinic-row',
  components: {
    CircleMark,
    NormalButton
  },
  props: {
    name: String,
    owner: Boolean,
    invitation: Boolean
  },
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.clinic-row {
  background: $base_color_water_light8;
  border-radius: $border_radius;
  padding: 8px 48px;

  display: grid;
  grid-template-columns: $form_item_height 2fr 1fr 1fr;
  align-items: center;
  grid-gap: 8px;

  .clinic-icon {
    justify-self: center;
    font-size: $base_font_size * 1.5;
  }

  .clinic-owner {
    color: $base_color_green;
    border-color: $base_color_green;
  }

  .clinic-invitation {
    color: $base_color_water_dark;
    border-color: $base_color_water_dark;
  }

  .clinic-belongs {
    color: $base_color_blue;
    border-color: $base_color_blue;
  }

  .clinic-button {
    height: $form_button_height;
  }
}
</style>
