import { computed, InjectionKey, Ref, ref } from 'vue'
import { FavoriteKarte as FavoriteKarteModel } from '@/models'
import { DataStore, syncExpression } from 'aws-amplify'
import { PatientFavoriteKarteMap } from '@/composables/karte/types'

export const useFavoriteKarteSubscription = (accountId: Ref<string>) => {
  const favorites = ref<FavoriteKarteModel[]>([])
  const subscribing = ref(false)

  const fetchFavoriteKarte = async () => {
    try {
      favorites.value = await DataStore.query(FavoriteKarteModel)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const expression = () => {
    return syncExpression(FavoriteKarteModel, () => {
      return a => a.accountId('eq', accountId.value)
    })
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchFavoriteKarte()
      const subscription = DataStore.observe(FavoriteKarteModel).subscribe(async () => {
        await fetchFavoriteKarte()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const favoriteMap = computed<PatientFavoriteKarteMap>(() => {
    const result: PatientFavoriteKarteMap = {}
    favorites.value.forEach(favo => {
      if (!Object.prototype.hasOwnProperty.call(result, favo.patientId)) {
        result[favo.patientId] = []
      }
      result[favo.patientId].push(favo)
    })
    Object.keys(result).forEach(patientId => {
      result[patientId] = result[patientId].sort((a, b) => a.displayOrder - b.displayOrder)
    })
    return result
  })

  return {
    favorites,
    fetchFavoriteKarte,
    expression,
    subscribe,
    favoriteMap
  }
}

export type UseFavoriteKarteSubscriptionType = ReturnType<typeof useFavoriteKarteSubscription>
export const UseFavoriteKarteSubscriptionKey: InjectionKey<UseFavoriteKarteSubscriptionType> = Symbol('FavoriteKarteSubscription')
