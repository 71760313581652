<template>
  <div class="time-range">
    <div
      class="time-range-label"
      @click="$emit('hour', hour)">{{ hour }}
    </div>
    <div class="time-range-schedule"></div>
  </div>
</template>

<script>
export default {
  name: 'time-range',
  props: {
    hour: Number
  }
}
</script>

<style lang="scss" scoped>
.time-range {
  display: flex;
  flex-direction: row;
}

.time-range-label {
  width: 8%;
  height: 80px;
  line-height: 80px;
  background: $base_color_gray_light;
  border-top: 1px solid $base_color_gray;
  border-bottom: 1px solid $base_color_gray;
  text-align: center;

  &:hover {
    background: $base_color_gray_lighter;
  }
}

.time-range-schedule {
  width: 92%;
  height: 80px;
  border-top: 1px solid $base_color_gray;
  border-bottom: 1px solid $base_color_gray;
  background: $base_color_white;
}
</style>
