<template>
  <div class="outer" :class="$mq">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-content'
}
</script>

<style lang="scss" scoped>
  .outer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    background-color: $base_color_water_light;
    border-radius: $border_radius;
    box-sizing: border-box;
  }
</style>
