import { computed, inject, InjectionKey, ref, watch } from 'vue'
import {
  UseTemporaryPatientSubscriptionKey,
  UseTemporaryPatientSubscriptionType
} from '@/composables/appointment/useTemporaryPatientSubscription'
import { useRoute, useRouter } from 'vue-router'

export const usePatientMatch = () => {
  const route = useRoute()
  const router = useRouter()
  const selectedAnswerId = ref<string>(route.query.a as string || '')
  const selectedTemporaryId = ref<string>(route.query.t as string || 'no-match')

  const { temporaryPatients } = inject(UseTemporaryPatientSubscriptionKey) as UseTemporaryPatientSubscriptionType

  watch(
    () => selectedAnswerId.value,
    async to => {
      const currentQuery = router.currentRoute.value.query
      const newQuery = {
        ...currentQuery,
        a: to,
        t: selectedTemporaryId.value
      }
      await router.replace({ path: route.path, query: newQuery })
    },
    { deep: true }
  )

  watch(
    () => selectedTemporaryId.value,
    async to => {
      const currentQuery = router.currentRoute.value.query
      const newQuery = {
        ...currentQuery,
        a: selectedAnswerId.value,
        t: to
      }
      await router.replace({ path: route.path, query: newQuery })
    },
    { deep: true }
  )

  // 着信一覧が選択された場合に、同じ電話番号の仮登録患者登録があった場合、それを選択する
  const selectTemporaryByPhoneNumbers = (phoneNumbers: string[]) => {
    const temporary = temporaryPatients.value.find(temp => temp.phoneNumber && phoneNumbers.includes(temp.phoneNumber))
    if (temporary) {
      selectedTemporaryId.value = temporary.id
    } else {
      selectedTemporaryId.value = 'no-match'
    }
  }

  const matched = computed(() => {
    return selectedAnswerId.value !== '' && selectedTemporaryId.value !== ''
  })

  return {
    selectedAnswerId,
    selectedTemporaryId,
    selectTemporaryByPhoneNumbers,
    matched
  }
}

export type UsePatientMatchType = ReturnType<typeof usePatientMatch>
export const UsePatientMatchKey: InjectionKey<UsePatientMatchType> = Symbol('PatientMatch')
