import { computed, ref, watchEffect } from 'vue'
import dayjs from 'dayjs'

export const useDateAndAge = () => {
  const year = ref('2000')
  const month = ref('01')
  const day = ref('01')

  const thisYear = new Date().getFullYear()
  const years = []
  for (let y = 1900; y <= thisYear; y++) {
    years.push({
      value: '' + y,
      name: '' + y
    })
  }

  const months = []
  for (let m = 1; m <= 12; m++) {
    months.push({
      value: m < 10 ? '0' + m : '' + m,
      name: '' + m
    })
  }

  const days = computed(() => {
    const days = []
    const maxDays = dayjs(`${year.value}-${month.value}-01`).daysInMonth()
    for (let d = 1; d <= maxDays; d++) {
      days.push({
        value: d < 10 ? '0' + d : '' + d,
        name: '' + d
      })
    }
    return days
  })

  const age = computed(() => {
    return '' + dayjs().diff(`${year.value}-${month.value}-${day.value}`, 'years')
  })

  watchEffect(() => {
    const maxDays = dayjs(`${year.value}-${month.value}-01`).daysInMonth()
    if (parseInt(day.value!) > maxDays) {
      day.value = '' + maxDays
    }
  })

  return {
    year,
    month,
    day,
    years,
    months,
    days,
    age
  }
}
