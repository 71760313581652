import { InjectionKey } from 'vue'
import { KarteTemplate as KarteTemplateModel } from '@/models'
import { KarteTemplate, CreateKarteTemplateInput, UpdateKarteTemplateInput } from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  createKarteTemplate as createKarteTemplateMutation,
  updateKarteTemplate as updateKarteTemplateMutation
} from '@/graphql/mutations'
import { getKarteTemplate as getKarteTemplateQuery } from '@/graphql/queries'

interface GetKarteTemplateResult {
  data?: {
    getKarteTemplate: KarteTemplate
  }
}

export const useKarteTemplateMutation = () => {
  const registerKarteTemplate = async ({ karteTemplate }: {
    karteTemplate: CreateKarteTemplateInput
  }) => {
    try {
      await API.graphql(graphqlOperation(createKarteTemplateMutation, { input: karteTemplate }))
    } catch (e) {
      return Promise.reject(new Error('カルテテンプレートの登録に失敗しました'))
    }
  }

  const updateKarteTemplate = async ({ karteTemplate }: {
    karteTemplate: UpdateKarteTemplateInput
  }) => {
    try {
      const currentData = await _getCurrentKarteTemplate(karteTemplate.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバのカルテテンプレートの取得に失敗しました'))
      }
      const input: UpdateKarteTemplateInput = {
        id: karteTemplate.id,
        displayOrder: karteTemplate.displayOrder,
        title: karteTemplate.title,
        karteItemIds: karteTemplate.karteItemIds,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateKarteTemplateMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('カルテテンプレートの更新に失敗しました'))
    }
  }

  const deleteKarteTemplate = async (id: string) => {
    try {
      const original = await DataStore.query(KarteTemplateModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('カルテテンプレートの削除に失敗しました'))
      }
      await DataStore.delete<KarteTemplateModel>(original[0])
    } catch (e) {
      return Promise.reject(new Error('カルテテンプレートの削除に失敗しました'))
    }
  }

  const _getCurrentKarteTemplate = async (id: string): Promise<KarteTemplate|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getKarteTemplateQuery, { id })) as GetKarteTemplateResult
      return res.data?.getKarteTemplate
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerKarteTemplate,
    updateKarteTemplate,
    deleteKarteTemplate
  }
}

export type UseKarteTemplateMutationType = ReturnType<typeof useKarteTemplateMutation>
export const UseKarteTemplateMutationKey: InjectionKey<UseKarteTemplateMutationType> = Symbol('KarteTemplateMutation')
