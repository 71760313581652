/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClinic = /* GraphQL */ `
  mutation CreateClinic($name: String!, $workSpace: String!) {
    createClinic(name: $name, workSpace: $workSpace) {
      id
      name
      owner
    }
  }
`;
export const enterClinic = /* GraphQL */ `
  mutation EnterClinic($clinicId: String!) {
    enterClinic(clinicId: $clinicId)
  }
`;
export const exitClinic = /* GraphQL */ `
  mutation ExitClinic {
    exitClinic
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      name
      permissions
      acceptAppointment
      myself
    }
  }
`;
export const createAccountInvitation = /* GraphQL */ `
  mutation CreateAccountInvitation($input: CreateAccountInvitationInput!) {
    createAccountInvitation(input: $input)
  }
`;
export const deleteAccountInvitation = /* GraphQL */ `
  mutation DeleteAccountInvitation($id: ID!) {
    deleteAccountInvitation(id: $id)
  }
`;
export const updateInquiryStatus = /* GraphQL */ `
  mutation UpdateInquiryStatus($id: ID!, $answerStatus: AnswerStatus!) {
    updateInquiryStatus(id: $id, answerStatus: $answerStatus) {
      id
      clinicId
      owner
      inquiryId
      patientId
      answer
      answerStatus
      createdAt
    }
  }
`;
export const uploadFile = /* GraphQL */ `
  mutation UploadFile($input: UploadFileInput!) {
    uploadFile(input: $input)
  }
`;
export const downloadFile = /* GraphQL */ `
  mutation DownloadFile($id: ID!) {
    downloadFile(id: $id) {
      id
      name
      fileType
      base64
      hash
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id)
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      name
      read
      gender
      birthDate
      private {
        zipCode
        pref
        city
        address
        phoneNumber
        sns {
          snsType
          accountId
          access
        }
      }
      comment
      display
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTemporaryPatient = /* GraphQL */ `
  mutation CreateTemporaryPatient(
    $input: CreateTemporaryPatientInput!
    $condition: ModelTemporaryPatientConditionInput
  ) {
    createTemporaryPatient(input: $input, condition: $condition) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemporaryPatient = /* GraphQL */ `
  mutation UpdateTemporaryPatient(
    $input: UpdateTemporaryPatientInput!
    $condition: ModelTemporaryPatientConditionInput
  ) {
    updateTemporaryPatient(input: $input, condition: $condition) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemporaryPatient = /* GraphQL */ `
  mutation DeleteTemporaryPatient(
    $input: DeleteTemporaryPatientInput!
    $condition: ModelTemporaryPatientConditionInput
  ) {
    deleteTemporaryPatient(input: $input, condition: $condition) {
      id
      clinicId
      name
      gender
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPatientNumber = /* GraphQL */ `
  mutation CreatePatientNumber(
    $input: CreatePatientNumberInput!
    $condition: ModelPatientNumberConditionInput
  ) {
    createPatientNumber(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePatientNumber = /* GraphQL */ `
  mutation UpdatePatientNumber(
    $input: UpdatePatientNumberInput!
    $condition: ModelPatientNumberConditionInput
  ) {
    updatePatientNumber(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePatientNumber = /* GraphQL */ `
  mutation DeletePatientNumber(
    $input: DeletePatientNumberInput!
    $condition: ModelPatientNumberConditionInput
  ) {
    deletePatientNumber(input: $input, condition: $condition) {
      id
      clinicId
      patientNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessHour = /* GraphQL */ `
  mutation CreateBusinessHour(
    $input: CreateBusinessHourInput!
    $condition: ModelBusinessHourConditionInput
  ) {
    createBusinessHour(input: $input, condition: $condition) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessHour = /* GraphQL */ `
  mutation UpdateBusinessHour(
    $input: UpdateBusinessHourInput!
    $condition: ModelBusinessHourConditionInput
  ) {
    updateBusinessHour(input: $input, condition: $condition) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessHour = /* GraphQL */ `
  mutation DeleteBusinessHour(
    $input: DeleteBusinessHourInput!
    $condition: ModelBusinessHourConditionInput
  ) {
    deleteBusinessHour(input: $input, condition: $condition) {
      id
      clinicId
      userId
      originDate
      intervalType
      interval
      startTime
      endTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTemporaryClose = /* GraphQL */ `
  mutation CreateTemporaryClose(
    $input: CreateTemporaryCloseInput!
    $condition: ModelTemporaryCloseConditionInput
  ) {
    createTemporaryClose(input: $input, condition: $condition) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemporaryClose = /* GraphQL */ `
  mutation UpdateTemporaryClose(
    $input: UpdateTemporaryCloseInput!
    $condition: ModelTemporaryCloseConditionInput
  ) {
    updateTemporaryClose(input: $input, condition: $condition) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemporaryClose = /* GraphQL */ `
  mutation DeleteTemporaryClose(
    $input: DeleteTemporaryCloseInput!
    $condition: ModelTemporaryCloseConditionInput
  ) {
    deleteTemporaryClose(input: $input, condition: $condition) {
      id
      clinicId
      businessHourId
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      clinicId
      patientId
      temporaryPatient
      accountId
      date
      startTime
      endTime
      appointmentStatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKarteItem = /* GraphQL */ `
  mutation CreateKarteItem(
    $input: CreateKarteItemInput!
    $condition: ModelKarteItemConditionInput
  ) {
    createKarteItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKarteItem = /* GraphQL */ `
  mutation UpdateKarteItem(
    $input: UpdateKarteItemInput!
    $condition: ModelKarteItemConditionInput
  ) {
    updateKarteItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKarteItem = /* GraphQL */ `
  mutation DeleteKarteItem(
    $input: DeleteKarteItemInput!
    $condition: ModelKarteItemConditionInput
  ) {
    deleteKarteItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteParts {
        id
        partsName
        title
        options
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAcuItem = /* GraphQL */ `
  mutation CreateAcuItem(
    $input: CreateAcuItemInput!
    $condition: ModelAcuItemConditionInput
  ) {
    createAcuItem(input: $input, condition: $condition) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAcuItem = /* GraphQL */ `
  mutation UpdateAcuItem(
    $input: UpdateAcuItemInput!
    $condition: ModelAcuItemConditionInput
  ) {
    updateAcuItem(input: $input, condition: $condition) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAcuItem = /* GraphQL */ `
  mutation DeleteAcuItem(
    $input: DeleteAcuItemInput!
    $condition: ModelAcuItemConditionInput
  ) {
    deleteAcuItem(input: $input, condition: $condition) {
      id
      clinicId
      direction
      bodyParts
      parts {
        id
        x
        y
        name
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKarteTemplate = /* GraphQL */ `
  mutation CreateKarteTemplate(
    $input: CreateKarteTemplateInput!
    $condition: ModelKarteTemplateConditionInput
  ) {
    createKarteTemplate(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKarteTemplate = /* GraphQL */ `
  mutation UpdateKarteTemplate(
    $input: UpdateKarteTemplateInput!
    $condition: ModelKarteTemplateConditionInput
  ) {
    updateKarteTemplate(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKarteTemplate = /* GraphQL */ `
  mutation DeleteKarteTemplate(
    $input: DeleteKarteTemplateInput!
    $condition: ModelKarteTemplateConditionInput
  ) {
    deleteKarteTemplate(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      title
      karteItemIds {
        id
        karteItemId
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKarte = /* GraphQL */ `
  mutation CreateKarte(
    $input: CreateKarteInput!
    $condition: ModelKarteConditionInput
  ) {
    createKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKarte = /* GraphQL */ `
  mutation UpdateKarte(
    $input: UpdateKarteInput!
    $condition: ModelKarteConditionInput
  ) {
    updateKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKarte = /* GraphQL */ `
  mutation DeleteKarte(
    $input: DeleteKarteInput!
    $condition: ModelKarteConditionInput
  ) {
    deleteKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      accountName
      karteNumber
      patientId
      karteDate
      displayOrder
      title
      karteResults {
        id
        karteItemId
        title
        kartePartsResult {
          id
          partsName
          title
          startTime
          endTime
          text
          options
          choices
          direction
          bodyParts
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKarteNumber = /* GraphQL */ `
  mutation CreateKarteNumber(
    $input: CreateKarteNumberInput!
    $condition: ModelKarteNumberConditionInput
  ) {
    createKarteNumber(input: $input, condition: $condition) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKarteNumber = /* GraphQL */ `
  mutation UpdateKarteNumber(
    $input: UpdateKarteNumberInput!
    $condition: ModelKarteNumberConditionInput
  ) {
    updateKarteNumber(input: $input, condition: $condition) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKarteNumber = /* GraphQL */ `
  mutation DeleteKarteNumber(
    $input: DeleteKarteNumberInput!
    $condition: ModelKarteNumberConditionInput
  ) {
    deleteKarteNumber(input: $input, condition: $condition) {
      id
      clinicId
      karteNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFavoriteKarte = /* GraphQL */ `
  mutation CreateFavoriteKarte(
    $input: CreateFavoriteKarteInput!
    $condition: ModelFavoriteKarteConditionInput
  ) {
    createFavoriteKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFavoriteKarte = /* GraphQL */ `
  mutation UpdateFavoriteKarte(
    $input: UpdateFavoriteKarteInput!
    $condition: ModelFavoriteKarteConditionInput
  ) {
    updateFavoriteKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavoriteKarte = /* GraphQL */ `
  mutation DeleteFavoriteKarte(
    $input: DeleteFavoriteKarteInput!
    $condition: ModelFavoriteKarteConditionInput
  ) {
    deleteFavoriteKarte(input: $input, condition: $condition) {
      id
      clinicId
      accountId
      karteId
      displayOrder
      karteAccountId
      karteAccountName
      karteNumber
      patientId
      karteDate
      title
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createInquiryItem = /* GraphQL */ `
  mutation CreateInquiryItem(
    $input: CreateInquiryItemInput!
    $condition: ModelInquiryItemConditionInput
  ) {
    createInquiryItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInquiryItem = /* GraphQL */ `
  mutation UpdateInquiryItem(
    $input: UpdateInquiryItemInput!
    $condition: ModelInquiryItemConditionInput
  ) {
    updateInquiryItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInquiryItem = /* GraphQL */ `
  mutation DeleteInquiryItem(
    $input: DeleteInquiryItemInput!
    $condition: ModelInquiryItemConditionInput
  ) {
    deleteInquiryItem(input: $input, condition: $condition) {
      id
      clinicId
      displayOrder
      version
      title
      inquiryPages {
        id
        pageTitle
        inquiries {
          id
          valueType
          required
          title
          options
        }
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      clinicId
      calendarStartDay
      appointmentTimeUnit
      holidayAutoClose
      holidayAutoCloseSat
      holidayAutoCloseSun
      holidayAutoCloseSub
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createFileHash = /* GraphQL */ `
  mutation CreateFileHash(
    $input: CreateFileHashInput!
    $condition: ModelFileHashConditionInput
  ) {
    createFileHash(input: $input, condition: $condition) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateFileHash = /* GraphQL */ `
  mutation UpdateFileHash(
    $input: UpdateFileHashInput!
    $condition: ModelFileHashConditionInput
  ) {
    updateFileHash(input: $input, condition: $condition) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteFileHash = /* GraphQL */ `
  mutation DeleteFileHash(
    $input: DeleteFileHashInput!
    $condition: ModelFileHashConditionInput
  ) {
    deleteFileHash(input: $input, condition: $condition) {
      id
      clinicId
      filename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const answerInquiry = /* GraphQL */ `
  mutation AnswerInquiry($answer: String!) {
    answerInquiry(answer: $answer) {
      id
      clinicId
      owner
      inquiryId
      patientId
      answer
      answerStatus
      createdAt
    }
  }
`;
