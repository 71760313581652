import { InjectionKey, reactive, toRefs, watch } from 'vue'
import { Auth, DataStore } from 'aws-amplify'

export interface AuthState {
  id: string
  authenticated: boolean
  name: string
}

export const useAuthentication = () => {
  const state = reactive<AuthState>({
    id: '',
    authenticated: false,
    name: ''
  })

  const storageKey = 'ekarte-auth'
  const lastData = localStorage.getItem(storageKey)
  if (lastData) {
    const { id, authenticated, name } = JSON.parse(lastData) as AuthState
    state.id = id || ''
    state.authenticated = authenticated || false
    state.name = name || ''
  }

  watch(
    () => state,
    to => {
      localStorage.setItem(storageKey, JSON.stringify(to))
    },
    { deep: true }
  )

  const signUp = async ({ username, password, name }: {
    username: string,
    password: string,
    name: string
  }) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          name
        }
      })
      return true
    } catch (e) {
      if (e.code !== 'UsernameExistsException') {
        return Promise.reject(e)
      }
    }

    // resendSignUpは既に認証コードが確認済みの場合エラーになる。
    // 認証済みのeメールを何度もresendSignUpするとブロックされる。
    try {
      await Auth.resendSignUp(username)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const confirmSignUp = async ({ username, code }: {
    username: string,
    code: string
  }) => {
    try {
      await Auth.confirmSignUp(username, code)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const forgetPassword = async ({ userId }: { userId: string }) => {
    try {
      await Auth.forgotPassword(userId)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const forgetPasswordSubmit = async ({ userId, verificationCode, newPassword }:{
    userId: string,
    verificationCode: string,
    newPassword: string
  }) => {
    try {
      await Auth.forgotPasswordSubmit(userId, verificationCode, newPassword)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const signInAuth = async ({ username, password }: {
    username: string,
    password: string
  }) => {
    try {
      await Auth.signIn(username, password)
      const info = await Auth.currentUserInfo()
      const { sub: id, name } = info.attributes

      state.id = id
      state.authenticated = true
      state.name = name
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const signOutAuth = async () => {
    try {
      await DataStore.clear()
      await Auth.signOut()
      state.id = ''
      state.authenticated = false
      state.name = ''

      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }
  const validSession = async () => {
    try {
      const session = await Auth.currentSession()
      return session.isValid()
    } catch (e) {
      return false
    }
  }

  const changeName = async ({ name }: {
    name: string
  }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, { name })

      state.name = name
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const changePassword = async ({ oldPassword, newPassword }: {
    oldPassword: string,
    newPassword: string
  }) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    ...toRefs(state),
    signUp,
    confirmSignUp,
    forgetPassword,
    forgetPasswordSubmit,
    signInAuth,
    signOutAuth,
    validSession,
    changeName,
    changePassword
  }
}

export type UseAuthenticationType = ReturnType<typeof useAuthentication>
export const UseAuthenticationKey: InjectionKey<UseAuthenticationType> = Symbol('authentication')
