<template>
  <footer class="footer">
    <div class="revision">system version : {{ revision }}</div>
    <div class="copyright">&copy; 2017 code house</div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  data () {
    return {
      revision: window.g_revision
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  height: $footer_height;
  width: 100vw;

  display: grid;
  grid-template-columns: 1fr 1fr;

  .revision {
    color: $base_color_gray;
    padding-left: 10px;
    font-size: $base_font_size * 0.7;
    line-height: $footer_height;
  }

  .copyright {
    color: $base_color_gray;
    padding-right: 10px;
    font-size: $base_font_size * 0.7;
    line-height: $footer_height;
    text-align: right;
  }
}
</style>
