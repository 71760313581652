
import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted
} from 'vue'
import { useOneTimeToken } from '@/composables/useOneTimeToken'
import { FetchStatus } from '@/composables/commonTypes'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import VueContent from '@/components/layout/vueContent.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import Qrcode from '@/components/atoms/QRCode.vue'
import QrcodeError from '@/components/atoms/QRCodeError.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { InquirySubDomain } from '@/config'
import ClipboardCopyButton from '@/components/molecules/ClipboardCopyButton.vue'

export default defineComponent({
  name: 'register-patient-qr',
  components: {
    ClipboardCopyButton,
    NormalButton,
    QrcodeError,
    LoadingMessage,
    Qrcode,
    VueContent,
    PageDescription,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const {
      fetchStatus,
      token,
      startFetch,
      stopFetch,
      forceRefresh
    } = useOneTimeToken()

    onMounted(async () => {
      await startFetch('patientInquiry')
    })

    onUnmounted(() => {
      stopFetch()
    })

    const patientUrl = computed(() => {
      if (token.value === '') {
        return ''
      }
      return `${InquirySubDomain}/patient?t=${token.value}`
    })

    return {
      fetchStatus,
      patientUrl,
      forceRefresh,
      FetchStatus
    }
  }
})
