import { InjectionKey, ref } from 'vue'

// 重いcomputed propertyの計算によって、初回の画面表示が送れるのを防ぐ
// delayTimeMSで指定する時間は、画面遷移のアニメーション時間以上を指定しないと動きがカクついてしまう
export const useDelay = (delayTimeMS: number) => {
  const delayed = ref<boolean>(false)

  setTimeout(() => {
    delayed.value = true
  }, delayTimeMS)

  return {
    delayed
  }
}

export type UseDelayType = ReturnType<typeof useDelay>
export const UseDelayKey: InjectionKey<UseDelayType> = Symbol('Delay')
