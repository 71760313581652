import { computed, inject, InjectionKey, reactive, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import { v4 as uuid } from 'uuid'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import {
  KarteResultOrder,
  KarteResultType,
  KarteType,
  ResultControl,
  SyncStatusTypes
} from '@/composables/karte/types'
import { BodyParts, Direction, Karte as KarteModel, KarteResult as KarteResultModel, PartsName } from '@/models'
import { DropAreaFileType } from '@/composables/types'
import { UseFileDBKey, UseFileDBType } from '@/composables/karte/useFileDB'

export const useKarte = () => {
  const route = useRoute()
  const karteOriginal = reactive<KarteType>({
    id: '',
    accountId: '',
    accountName: '',
    karteNumber: '',
    patientId: '',
    karteDate: '',
    displayOrder: 0,
    title: ''
  })
  const karteResultOriginal = reactive<KarteResultType>({
    karteItemId: [],
    title: [],
    resultId: [],
    partsName: [],
    resultTitle: [],
    startTime: [],
    endTime: [],
    text: [],
    fileId: [],
    fileFileId: [],
    fileName: [],
    fileFileType: [],
    fileHash: [],
    options: [],
    choices: [],
    direction: [],
    bodyParts: [],
    acuId: [],
    x: [],
    y: [],
    acuName: []
  })
  const orderOriginal = ref<KarteResultOrder[]>([])

  const karte = reactive<KarteType>({
    id: '',
    accountId: '',
    accountName: '',
    karteNumber: '',
    patientId: '',
    karteDate: '',
    displayOrder: 0,
    title: ''
  })
  const karteResult = reactive<KarteResultType>({
    karteItemId: [],
    title: [],
    resultId: [],
    partsName: [],
    resultTitle: [],
    startTime: [],
    endTime: [],
    text: [],
    fileId: [],
    fileFileId: [],
    fileName: [],
    fileFileType: [],
    fileHash: [],
    options: [],
    choices: [],
    direction: [],
    bodyParts: [],
    acuId: [],
    x: [],
    y: [],
    acuName: []
  })
  const order = ref<KarteResultOrder[]>([])

  const { id: accountId, name: accountName } = inject(UseAuthenticationKey) as UseAuthenticationType
  const { putFile, deleteFile } = inject(UseFileDBKey) as UseFileDBType

  const setKarte = (karteModel: KarteModel) => {
    const result = _convertKarteResultType(karteModel.karteResults)
    const ord: KarteResultOrder[] = karteModel.karteResults.map((r, i) => {
      return {
        id: r.id,
        arrayIndex: i
      }
    })

    karteOriginal.id = karteModel.id
    karteOriginal.accountId = karteModel.accountId
    karteOriginal.accountName = karteModel.accountName
    karteOriginal.karteNumber = karteModel.karteNumber
    karteOriginal.patientId = karteModel.patientId
    karteOriginal.karteDate = karteModel.karteDate
    karteOriginal.displayOrder = karteModel.displayOrder
    karteOriginal.title = karteModel.title
    karteResultOriginal.karteItemId = [...result.karteItemId]
    karteResultOriginal.title = [...result.title]
    karteResultOriginal.resultId = [...result.resultId]
    karteResultOriginal.partsName = [...result.partsName]
    karteResultOriginal.resultTitle = [...result.resultTitle]
    karteResultOriginal.startTime = [...result.startTime]
    karteResultOriginal.endTime = [...result.endTime]
    karteResultOriginal.text = [...result.text]
    karteResultOriginal.fileId = [...result.fileId]
    karteResultOriginal.fileFileId = [...result.fileFileId]
    karteResultOriginal.fileName = [...result.fileName]
    karteResultOriginal.fileFileType = [...result.fileFileType]
    karteResultOriginal.fileHash = [...result.fileHash]
    karteResultOriginal.options = [...result.options]
    karteResultOriginal.choices = [...result.choices]
    karteResultOriginal.direction = [...result.direction]
    karteResultOriginal.bodyParts = [...result.bodyParts]
    karteResultOriginal.acuId = [...result.acuId]
    karteResultOriginal.x = [...result.x]
    karteResultOriginal.y = [...result.y]
    karteResultOriginal.acuName = [...result.acuName]
    orderOriginal.value = [...ord]

    karte.id = karteModel.id
    karte.accountId = karteModel.accountId
    karte.accountName = karteModel.accountName
    karte.karteNumber = karteModel.karteNumber
    karte.patientId = karteModel.patientId
    karte.karteDate = karteModel.karteDate
    karte.displayOrder = karteModel.displayOrder
    karte.title = karteModel.title
    karteResult.karteItemId = [...result.karteItemId]
    karteResult.title = [...result.title]
    karteResult.resultId = [...result.resultId]
    karteResult.partsName = [...result.partsName]
    karteResult.resultTitle = [...result.resultTitle]
    karteResult.startTime = [...result.startTime]
    karteResult.endTime = [...result.endTime]
    karteResult.text = [...result.text]
    karteResult.fileId = [...result.fileId]
    karteResult.fileFileId = [...result.fileFileId]
    karteResult.fileName = [...result.fileName]
    karteResult.fileFileType = [...result.fileFileType]
    karteResult.fileHash = [...result.fileHash]
    karteResult.options = [...result.options]
    karteResult.choices = [...result.choices]
    karteResult.direction = [...result.direction]
    karteResult.bodyParts = [...result.bodyParts]
    karteResult.acuId = [...result.acuId]
    karteResult.x = [...result.x]
    karteResult.y = [...result.y]
    karteResult.acuName = [...result.acuName]
    order.value = [...ord]
  }

  const clearKarte = () => {
    karteOriginal.id = ''
    karteOriginal.accountId = ''
    karteOriginal.accountName = ''
    karteOriginal.karteNumber = ''
    karteOriginal.patientId = ''
    karteOriginal.karteDate = ''
    karteOriginal.displayOrder = 0
    karteOriginal.title = ''
    karteResultOriginal.karteItemId = []
    karteResultOriginal.title = []
    karteResultOriginal.resultId = []
    karteResultOriginal.partsName = []
    karteResultOriginal.resultTitle = []
    karteResultOriginal.startTime = []
    karteResultOriginal.endTime = []
    karteResultOriginal.text = []
    karteResultOriginal.fileId = []
    karteResultOriginal.fileFileId = []
    karteResultOriginal.fileName = []
    karteResultOriginal.fileFileType = []
    karteResultOriginal.fileHash = []
    karteResultOriginal.options = []
    karteResultOriginal.choices = []
    karteResultOriginal.direction = []
    karteResultOriginal.bodyParts = []
    karteResultOriginal.acuId = []
    karteResultOriginal.x = []
    karteResultOriginal.y = []
    karteResultOriginal.acuName = []
    orderOriginal.value = []

    karte.id = ''
    karte.accountId = ''
    karte.accountName = ''
    karte.karteNumber = ''
    karte.patientId = ''
    karte.karteDate = ''
    karte.displayOrder = 0
    karte.title = ''
    karteResult.karteItemId = []
    karteResult.title = []
    karteResult.resultId = []
    karteResult.partsName = []
    karteResult.resultTitle = []
    karteResult.startTime = []
    karteResult.endTime = []
    karteResult.text = []
    karteResult.fileId = []
    karteResult.fileFileId = []
    karteResult.fileName = []
    karteResult.fileFileType = []
    karteResult.fileHash = []
    karteResult.options = []
    karteResult.choices = []
    karteResult.direction = []
    karteResult.bodyParts = []
    karteResult.acuId = []
    karteResult.x = []
    karteResult.y = []
    karteResult.acuName = []
    order.value = []
  }

  const saved = (id: string, karteNumber: string, displayOrder: number) => {
    karte.id = id
    karte.karteNumber = karteNumber
    karte.displayOrder = displayOrder

    karteOriginal.id = karte.id
    karteOriginal.accountId = karte.accountId
    karteOriginal.accountName = karte.accountName
    karteOriginal.karteNumber = karte.karteNumber
    karteOriginal.patientId = karte.patientId
    karteOriginal.karteDate = karte.karteDate
    karteOriginal.displayOrder = karte.displayOrder
    karteOriginal.title = karte.title
    karteResultOriginal.karteItemId = [...karteResult.karteItemId]
    karteResultOriginal.title = [...karteResult.title]
    karteResultOriginal.resultId = [...karteResult.resultId]
    karteResultOriginal.partsName = [...karteResult.partsName]
    karteResultOriginal.resultTitle = [...karteResult.resultTitle]
    karteResultOriginal.startTime = [...karteResult.startTime]
    karteResultOriginal.endTime = [...karteResult.endTime]
    karteResultOriginal.text = [...karteResult.text]
    karteResultOriginal.fileId = [...karteResult.fileId]
    karteResultOriginal.fileFileId = [...karteResult.fileFileId]
    karteResultOriginal.fileName = [...karteResult.fileName]
    karteResultOriginal.fileFileType = [...karteResult.fileFileType]
    karteResultOriginal.fileHash = [...karteResult.fileHash]
    karteResultOriginal.options = [...karteResult.options]
    karteResultOriginal.choices = [...karteResult.choices]
    karteResultOriginal.direction = [...karteResult.direction]
    karteResultOriginal.bodyParts = [...karteResult.bodyParts]
    karteResultOriginal.acuId = [...karteResult.acuId]
    karteResultOriginal.x = [...karteResult.x]
    karteResultOriginal.y = [...karteResult.y]
    karteResultOriginal.acuName = [...karteResult.acuName]
    orderOriginal.value = [...order.value]
  }

  const addResult = (result: KarteResultModel) => {
    const resultType = _convertKarteResultType([result])
    karteResult.karteItemId = [...karteResult.karteItemId, ...resultType.karteItemId]
    karteResult.title = [...karteResult.title, ...resultType.title]
    karteResult.resultId = [...karteResult.resultId, ...resultType.resultId]
    karteResult.partsName = [...karteResult.partsName, ...resultType.partsName]
    karteResult.resultTitle = [...karteResult.resultTitle, ...resultType.resultTitle]
    karteResult.startTime = [...karteResult.startTime, ...resultType.startTime]
    karteResult.endTime = [...karteResult.endTime, ...resultType.endTime]
    karteResult.text = [...karteResult.text, ...resultType.text]
    karteResult.fileId = [...karteResult.fileId, ...resultType.fileId]
    karteResult.fileFileId = [...karteResult.fileFileId, ...resultType.fileFileId]
    karteResult.fileName = [...karteResult.fileName, ...resultType.fileName]
    karteResult.fileFileType = [...karteResult.fileFileType, ...resultType.fileFileType]
    karteResult.fileHash = [...karteResult.fileHash, ...resultType.fileHash]
    karteResult.options = [...karteResult.options, ...resultType.options]
    karteResult.choices = [...karteResult.choices, ...resultType.choices]
    karteResult.direction = [...karteResult.direction, ...resultType.direction]
    karteResult.bodyParts = [...karteResult.bodyParts, ...resultType.bodyParts]
    karteResult.acuId = [...karteResult.acuId, ...resultType.acuId]
    karteResult.x = [...karteResult.x, ...resultType.x]
    karteResult.y = [...karteResult.y, ...resultType.y]
    karteResult.acuName = [...karteResult.acuName, ...resultType.acuName]

    order.value = [...order.value, {
      id: result.id,
      arrayIndex: karteResult.karteItemId.length - 1
    }]
  }

  const upResult = (resultId: string) => {
    const targetIndex = order.value.findIndex(o => o.id === resultId)
    const newOrder = [...order.value]
    const tmp = newOrder[targetIndex - 1]
    newOrder.splice(targetIndex - 1, 1, newOrder[targetIndex])
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const downResult = (resultId: string) => {
    const targetIndex = order.value.findIndex(o => o.id === resultId)
    const newOrder = [...order.value]
    const tmp = newOrder[targetIndex + 1]
    newOrder.splice(targetIndex + 1, 1, newOrder[targetIndex])
    newOrder.splice(targetIndex, 1, tmp)
    order.value = newOrder
  }

  const deleteResult = async (resultId: string) => {
    // 消したリザルト行がファイルを含んでいた場合、不要なものをdbから消す
    const result = order.value.find(o => o.id === resultId)
    await Promise.all(karteResult.partsName[result!.arrayIndex].map(async (partsName, j) => {
      if (partsName === PartsName.IMAGES || partsName === PartsName.IMAGE_AND_TEXT || partsName === PartsName.ATTACH_FILES) {
        while (karteResult.fileFileId[result!.arrayIndex][j].length > 0) {
          try {
            await deleteKarteFile(result!.arrayIndex, j, 0)
          } catch (e) {
            return Promise.reject(e)
          }
        }
        return true
      } else {
        return true
      }
    }))

    order.value = [...order.value.filter(o => o.id !== resultId)]
  }

  const addKarteFile = async (resultIndex: number, kartePartsIndex: number, dropFile: DropAreaFileType) => {
    // 同一行の同一ハッシュは除外する
    const same = karteResult.fileHash[resultIndex][kartePartsIndex].some(hash => hash === dropFile.hash)
    if (same) {
      return Promise.reject(new Error('既に同じファイルが登録されています'))
    }

    // 他の箇所に同一ファイルがあった場合は、参照として登録する
    let sameFileId = ''
    order.value.find(o => {
      return karteResult.fileHash[o.arrayIndex].some((partsHash, j) => {
        return partsHash.some((hash, k) => {
          if (hash === dropFile.hash) {
            sameFileId = karteResult.fileFileId[o.arrayIndex][j][k]
            return true
          }
          return false
        })
      })
    })

    const fileId = uuid()
    karteResult.fileId[resultIndex][kartePartsIndex] = [
      ...karteResult.fileId[resultIndex][kartePartsIndex],
      dropFile.id
    ]
    karteResult.fileFileId[resultIndex][kartePartsIndex] = [
      ...karteResult.fileFileId[resultIndex][kartePartsIndex],
      sameFileId !== '' ? sameFileId : fileId
    ]
    karteResult.fileName[resultIndex][kartePartsIndex] = [
      ...karteResult.fileName[resultIndex][kartePartsIndex],
      dropFile.name
    ]
    karteResult.fileFileType[resultIndex][kartePartsIndex] = [
      ...karteResult.fileFileType[resultIndex][kartePartsIndex],
      dropFile.fileType
    ]
    karteResult.fileHash[resultIndex][kartePartsIndex] = [
      ...karteResult.fileHash[resultIndex][kartePartsIndex],
      dropFile.hash
    ]

    try {
      if (sameFileId === '') {
        await putFile({
          ...dropFile,
          id: fileId,
          syncStatus: SyncStatusTypes.Stored
        })
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const deleteKarteFile = async (resultIndex: number, kartePartsIndex: number, index: number) => {
    const deleteFileId = karteResult.fileFileId[resultIndex][kartePartsIndex][index]

    const newFileId = [...karteResult.fileId[resultIndex][kartePartsIndex]]
    newFileId.splice(index, 1)
    karteResult.fileId[resultIndex][kartePartsIndex] = newFileId
    const newFileFileId = [...karteResult.fileFileId[resultIndex][kartePartsIndex]]
    newFileFileId.splice(index, 1)
    karteResult.fileFileId[resultIndex][kartePartsIndex] = newFileFileId
    const newFileName = [...karteResult.fileName[resultIndex][kartePartsIndex]]
    newFileName.splice(index, 1)
    karteResult.fileName[resultIndex][kartePartsIndex] = newFileName
    const newFileFileType = [...karteResult.fileFileType[resultIndex][kartePartsIndex]]
    newFileFileType.splice(index, 1)
    karteResult.fileFileType[resultIndex][kartePartsIndex] = newFileFileType
    const newFileHash = [...karteResult.fileHash[resultIndex][kartePartsIndex]]
    newFileHash.splice(index, 1)
    karteResult.fileHash[resultIndex][kartePartsIndex] = newFileHash

    const allDeleted = karteResult.fileFileId.every(resultFileId => {
      return resultFileId.every(partsFileId => {
        return partsFileId.every(fileId => fileId !== deleteFileId)
      })
    })

    try {
      if (allDeleted) {
        await deleteFile(deleteFileId)
      }
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  // localStorageから編集中のカルテデータを復帰
  (() => {
    const patientId = route.query.p as string
    const data = localStorage.getItem('ekarte-karte')
    if (data) {
      const parsed = JSON.parse(data) as {
        karteOriginal: KarteType
        karteResultOriginal: KarteResultType
        orderOriginal: KarteResultOrder[]
        karte: KarteType
        karteResult: KarteResultType
        order: KarteResultOrder[]
      }
      if (parsed.karteOriginal.patientId === patientId && parsed.karte.patientId === patientId) {
        karteOriginal.id = parsed.karteOriginal.id
        karteOriginal.accountId = parsed.karteOriginal.accountId
        karteOriginal.accountName = parsed.karteOriginal.accountName
        karteOriginal.karteNumber = parsed.karteOriginal.karteNumber
        karteOriginal.patientId = parsed.karteOriginal.patientId
        karteOriginal.karteDate = parsed.karteOriginal.karteDate
        karteOriginal.title = parsed.karteOriginal.title
        karteResultOriginal.karteItemId = [...parsed.karteResultOriginal.karteItemId]
        karteResultOriginal.title = [...parsed.karteResultOriginal.title]
        karteResultOriginal.resultId = [...parsed.karteResultOriginal.resultId]
        karteResultOriginal.partsName = [...parsed.karteResultOriginal.partsName]
        karteResultOriginal.resultTitle = [...parsed.karteResultOriginal.resultTitle]
        karteResultOriginal.startTime = [...parsed.karteResultOriginal.startTime]
        karteResultOriginal.endTime = [...parsed.karteResultOriginal.endTime]
        karteResultOriginal.text = [...parsed.karteResultOriginal.text]
        karteResultOriginal.fileId = [...parsed.karteResultOriginal.fileId]
        karteResultOriginal.fileFileId = [...parsed.karteResultOriginal.fileFileId]
        karteResultOriginal.fileName = [...parsed.karteResultOriginal.fileName]
        karteResultOriginal.fileFileType = [...parsed.karteResultOriginal.fileFileType]
        karteResultOriginal.fileHash = [...parsed.karteResultOriginal.fileHash]
        karteResultOriginal.options = [...parsed.karteResultOriginal.options]
        karteResultOriginal.choices = [...parsed.karteResultOriginal.choices]
        karteResultOriginal.direction = [...parsed.karteResultOriginal.direction]
        karteResultOriginal.bodyParts = [...parsed.karteResultOriginal.bodyParts]
        karteResultOriginal.acuId = [...parsed.karteResultOriginal.acuId]
        karteResultOriginal.x = [...parsed.karteResultOriginal.x]
        karteResultOriginal.y = [...parsed.karteResultOriginal.y]
        karteResultOriginal.acuName = [...parsed.karteResultOriginal.acuName]
        orderOriginal.value = [...parsed.orderOriginal]

        karte.id = parsed.karte.id
        karte.accountId = parsed.karte.accountId
        karte.accountName = parsed.karte.accountName
        karte.karteNumber = parsed.karte.karteNumber
        karte.patientId = parsed.karte.patientId
        karte.karteDate = parsed.karte.karteDate
        karte.title = parsed.karte.title
        karteResult.karteItemId = [...parsed.karteResult.karteItemId]
        karteResult.title = [...parsed.karteResult.title]
        karteResult.resultId = [...parsed.karteResult.resultId]
        karteResult.partsName = [...parsed.karteResult.partsName]
        karteResult.resultTitle = [...parsed.karteResult.resultTitle]
        karteResult.startTime = [...parsed.karteResult.startTime]
        karteResult.endTime = [...parsed.karteResult.endTime]
        karteResult.text = [...parsed.karteResult.text]
        karteResult.fileId = [...parsed.karteResult.fileId]
        karteResult.fileFileId = [...parsed.karteResult.fileFileId]
        karteResult.fileName = [...parsed.karteResult.fileName]
        karteResult.fileFileType = [...parsed.karteResult.fileFileType]
        karteResult.fileHash = [...parsed.karteResult.fileHash]
        karteResult.options = [...parsed.karteResult.options]
        karteResult.choices = [...parsed.karteResult.choices]
        karteResult.direction = [...parsed.karteResult.direction]
        karteResult.bodyParts = [...parsed.karteResult.bodyParts]
        karteResult.acuId = [...parsed.karteResult.acuId]
        karteResult.x = [...parsed.karteResult.x]
        karteResult.y = [...parsed.karteResult.y]
        karteResult.acuName = [...parsed.karteResult.acuName]
        order.value = [...parsed.order]
        return
      }
    }
    // 新規作成
    clearKarte()
  })()

  // localStorageにカルテデータを保存
  watch(
    [karteOriginal, karteResultOriginal, orderOriginal, karte, karteResult, order],
    (curr) => {
      const data = {
        karteOriginal: curr[0],
        karteResultOriginal: curr[1],
        orderOriginal: curr[2],
        karte: curr[3],
        karteResult: curr[4],
        order: curr[5]
      }
      localStorage.setItem('ekarte-karte', JSON.stringify(data))
    },
    { deep: true }
  )

  // カルテ編集画面の項目上部のボタンの有効無効を計算して返す
  const karteResultControlMap = computed<{[resultId: string]: ResultControl}>(() => {
    const controlMap: {[resultId: string]: ResultControl} = {}
    order.value.forEach(({ id, arrayIndex }, i) => {
      // 項目が診察時間かどうか
      const isTime = karteResult.partsName[arrayIndex].every(partsName => partsName === PartsName.TIME_RANGE)
      controlMap[id] = {
        history: !isTime,
        up: i !== 0,
        down: i !== order.value.length - 1,
        delete: !isTime
      }
    })
    return controlMap
  })

  // 保存用のカルテデータ
  const newKarte = computed<KarteModel>(() => {
    const patientId = route.query.p as string

    return {
      id: karte.id,
      accountId: karte.accountId === '' ? accountId.value : karte.accountId,
      accountName: karte.accountName === '' ? accountName.value : karte.accountName,
      karteNumber: karte.karteNumber,
      patientId: patientId,
      karteDate: karte.karteDate,
      displayOrder: karte.displayOrder,
      title: karte.title,
      karteResults: [...order.value.map(({ id, arrayIndex }) => {
        return {
          id,
          karteItemId: karteResult.karteItemId[arrayIndex],
          title: karteResult.title[arrayIndex],
          kartePartsResult: karteResult.resultId[arrayIndex].map((resultId, j) => {
            return {
              id: resultId,
              partsName: karteResult.partsName[arrayIndex][j],
              title: karteResult.resultTitle[arrayIndex][j],
              startTime: karteResult.startTime[arrayIndex][j],
              endTime: karteResult.endTime[arrayIndex][j],
              text: karteResult.text[arrayIndex][j],
              files: karteResult.fileId[arrayIndex][j].map((fileId, k) => {
                return {
                  id: fileId,
                  fileId: karteResult.fileFileId[arrayIndex][j][k],
                  name: karteResult.fileName[arrayIndex][j][k],
                  fileType: karteResult.fileFileType[arrayIndex][j][k],
                  hash: karteResult.fileHash[arrayIndex][j][k]
                }
              }),
              options: karteResult.options[arrayIndex][j],
              choices: karteResult.choices[arrayIndex][j],
              direction: karteResult.direction[arrayIndex][j],
              bodyParts: karteResult.bodyParts[arrayIndex][j],
              parts: karteResult.acuId[arrayIndex][j].map((acuId, k) => {
                return {
                  id: acuId,
                  x: karteResult.x[arrayIndex][j][k],
                  y: karteResult.y[arrayIndex][j][k],
                  name: karteResult.acuName[arrayIndex][j][k]
                }
              })
            }
          })
        }
      })]
    }
  })

  const originalFileIds = computed(() => {
    return [...new Set(orderOriginal.value.flatMap(ord => {
      return karteResultOriginal.fileFileId[ord.arrayIndex].flatMap(partsFileId => {
        return partsFileId
      })
    }))]
  })

  const newFileIds = computed(() => {
    return [...new Set(order.value.flatMap(ord => {
      return karteResult.fileFileId[ord.arrayIndex].flatMap(partsFileId => {
        return partsFileId
      })
    }))]
  })

  const updateFileIds = computed(() => {
    return newFileIds.value.filter(fileId => !originalFileIds.value.includes(fileId))
  })

  const deleteFileIds = computed(() => {
    return originalFileIds.value.filter(fileId => !newFileIds.value.includes(fileId))
  })

  const karteDateString = computed(() => {
    return dayjs(karte.karteDate, 'YYYY-MM-DD').format('YYYY年M月D日(ddd)')
  })

  const editing = computed(() => {
    return (
      karteOriginal.title !== karte.title ||
      orderOriginal.value.length !== order.value.length ||
      orderOriginal.value.some(({ id: orgId, arrayIndex: orgArrayIndex }, i) => {
        const { id, arrayIndex } = order.value[i]
        return (
          orgId !== id ||
          karteResultOriginal.karteItemId[orgArrayIndex] !== karteResult.karteItemId[arrayIndex] ||
          karteResultOriginal.title[orgArrayIndex] !== karteResult.title[arrayIndex] ||
          karteResultOriginal.resultId[orgArrayIndex].length !== karteResult.resultId[arrayIndex].length ||
          karteResultOriginal.resultId[orgArrayIndex].some((_, j) => {
            return karteResultOriginal.resultId[orgArrayIndex][j] !== karteResult.resultId[arrayIndex][j] ||
              karteResultOriginal.resultId[orgArrayIndex][j] !== karteResult.resultId[arrayIndex][j] ||
              karteResultOriginal.partsName[orgArrayIndex][j] !== karteResult.partsName[arrayIndex][j] ||
              karteResultOriginal.resultTitle[orgArrayIndex][j] !== karteResult.resultTitle[arrayIndex][j] ||
              karteResultOriginal.startTime[orgArrayIndex][j] !== karteResult.startTime[arrayIndex][j] ||
              karteResultOriginal.endTime[orgArrayIndex][j] !== karteResult.endTime[arrayIndex][j] ||
              karteResultOriginal.text[orgArrayIndex][j] !== karteResult.text[arrayIndex][j] ||
              JSON.stringify(karteResultOriginal.fileId[orgArrayIndex][j]) !== JSON.stringify(karteResult.fileId[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.fileFileId[orgArrayIndex][j]) !== JSON.stringify(karteResult.fileFileId[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.fileName[orgArrayIndex][j]) !== JSON.stringify(karteResult.fileName[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.fileFileType[orgArrayIndex][j]) !== JSON.stringify(karteResult.fileFileType[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.fileHash[orgArrayIndex][j]) !== JSON.stringify(karteResult.fileHash[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.options[orgArrayIndex][j]) !== JSON.stringify(karteResult.options[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.choices[orgArrayIndex][j]) !== JSON.stringify(karteResult.choices[arrayIndex][j]) ||
              karteResultOriginal.direction[orgArrayIndex][j] !== karteResult.direction[arrayIndex][j] ||
              karteResultOriginal.bodyParts[orgArrayIndex][j] !== karteResult.bodyParts[arrayIndex][j] ||
              JSON.stringify(karteResultOriginal.acuId[orgArrayIndex][j]) !== JSON.stringify(karteResult.acuId[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.x[orgArrayIndex][j]) !== JSON.stringify(karteResult.x[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.y[orgArrayIndex][j]) !== JSON.stringify(karteResult.y[arrayIndex][j]) ||
              JSON.stringify(karteResultOriginal.acuName[orgArrayIndex][j]) !== JSON.stringify(karteResult.acuName[arrayIndex][j])
          })
        )
      })
    )
  })

  const _convertKarteResultType = (org: KarteResultModel[]) : KarteResultType => {
    const result: KarteResultType = {
      karteItemId: [],
      title: [],
      resultId: [],
      partsName: [],
      resultTitle: [],
      startTime: [],
      endTime: [],
      text: [],
      fileId: [],
      fileFileId: [],
      fileName: [],
      fileFileType: [],
      fileHash: [],
      options: [],
      choices: [],
      direction: [],
      bodyParts: [],
      acuId: [],
      x: [],
      y: [],
      acuName: []
    }

    org.forEach((o, i) => {
      result.karteItemId.push(o.karteItemId)
      result.title.push(o.title)
      result.resultId.push([])
      result.partsName.push([])
      result.resultTitle.push([])
      result.startTime.push([])
      result.endTime.push([])
      result.text.push([])
      result.fileId.push([])
      result.fileFileId.push([])
      result.fileName.push([])
      result.fileFileType.push([])
      result.fileHash.push([])
      result.options.push([])
      result.choices.push([])
      result.direction.push([])
      result.bodyParts.push([])
      result.acuId.push([])
      result.x.push([])
      result.y.push([])
      result.acuName.push([])
      o.kartePartsResult.forEach((p, j) => {
        result.resultId[i].push(p.id)
        result.partsName[i].push(p.partsName as PartsName)
        result.resultTitle[i].push(p.title || '')
        result.startTime[i].push(p.startTime || '')
        result.endTime[i].push(p.endTime || '')
        result.text[i].push(p.text || '')
        result.fileId[i].push([])
        result.fileFileId[i].push([])
        result.fileName[i].push([])
        result.fileFileType[i].push([])
        result.fileHash[i].push([])
        p.files?.forEach(f => {
          result.fileId[i][j].push(f.id)
          result.fileFileId[i][j].push(f.fileId)
          result.fileName[i][j].push(f.name)
          result.fileFileType[i][j].push(f.fileType)
          result.fileHash[i][j].push(f.hash)
        })
        result.options[i].push(p.options ? [...p.options] : [])
        result.choices[i].push(p.choices ? [...p.choices] : [])
        result.direction[i].push(p.direction as Direction)
        result.bodyParts[i].push(p.bodyParts as BodyParts)
        result.acuId[i].push([])
        result.x[i].push([])
        result.y[i].push([])
        result.acuName[i].push([])
        p.parts?.forEach(a => {
          result.acuId[i][j].push(a.id)
          result.x[i][j].push(a.x)
          result.y[i][j].push(a.y)
          result.acuName[i][j].push(a.name)
        })

        if (p.partsName === PartsName.SINGLE_CHOICE) {
          if (result.choices[i][j].length === 0) {
            result.choices[i][j].push('')
          }
        }
        if (p.partsName === PartsName.ACU) {
          if (!result.direction[i][j]) {
            result.direction[i][j] = Direction.FRONT
          }
          if (!result.bodyParts[i][j]) {
            result.bodyParts[i][j] = BodyParts.HEAD
          }
        }
      })
    })

    return result
  }

  return {
    karte: { ...toRefs(karte) },
    karteResult: { ...toRefs(karteResult) },
    order,
    setKarte,
    clearKarte,
    saved,
    addResult,
    upResult,
    downResult,
    deleteResult,
    addKarteFile,
    deleteKarteFile,
    karteResultControlMap,
    newKarte,
    newFileIds,
    updateFileIds,
    deleteFileIds,
    karteDateString,
    editing
  }
}

export type UseKarteType = ReturnType<typeof useKarte>
export const UseKarteKey: InjectionKey<UseKarteType> = Symbol('Karte')
