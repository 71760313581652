
import {
  defineComponent
} from 'vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientTimeForm from '@/views/appointment/register/PatientTimeForm.vue'
import PatientTimeTable from '@/views/appointment/register/PatientTimeTable.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'

export default defineComponent({
  name: 'appointment',
  components: {
    VueContentRemain,
    PatientTimeTable,
    PatientTimeForm,
    VueTwoColumnContent
  },
  setup () {
    return {
    }
  }
})
