import { v4 as uuid } from 'uuid'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  createPatient as createPatientMutation,
  updatePatient as updatePatientMutation,
  createPatientNumber,
  updatePatientNumber
} from '@/graphql/mutations'
import { getPatient as getPatientQuery, getPatientNumber } from '@/graphql/queries'
import { Patient as PatientModel } from '@/models'
import { Patient, Display, PatientNumber, CreatePatientInput, UpdatePatientInput } from '@/API'

import { inject } from 'vue'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'

interface GetPatientNumbersResult {
  data?: {
    getPatientNumber: PatientNumber
  }
}

interface GetPatientResult {
  data?: {
    getPatient: Patient
  }
}

export const usePatientMutation = () => {
  const { clinicId } = inject(UseClinicKey) as UseClinicType

  const createPatient = async ({ patient }: {
    patient: CreatePatientInput
  }) => {
    try {
      const patientNumber = await _nextPatientNumber()
      if (patient.id === '') {
        patient.id = uuid()
      }
      patient.patientNumber = ('000000' + patientNumber).substr(-7)

      await API.graphql(graphqlOperation(createPatientMutation, { input: patient }))
      return true
    } catch (e) {
      return Promise.reject(new Error('患者の登録に失敗しました'))
    }
  }

  const updatePatient = async ({ patient } : {
    patient: UpdatePatientInput
  }) => {
    try {
      const currentData = await _getCurrentPatient(patient.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバの患者データの取得に失敗しました'))
      }
      const input: UpdatePatientInput = {
        ...patient,
        patientNumber: currentData.patientNumber,
        display: currentData.display,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updatePatientMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('患者の更新に失敗しました'))
    }
  }

  const _nextPatientNumber = async () => {
    try {
      const res = await API.graphql(graphqlOperation(getPatientNumber, { id: clinicId.value })) as GetPatientNumbersResult
      if (res.data?.getPatientNumber !== null) {
        const param = {
          id: clinicId.value,
          patientNumber: res.data!.getPatientNumber.patientNumber + 1,
          _version: res.data!.getPatientNumber._version
        }
        await API.graphql(graphqlOperation(updatePatientNumber, { input: param }))
        return res.data!.getPatientNumber.patientNumber + 1
      } else {
        const param = {
          id: clinicId.value,
          patientNumber: 1
        }
        await API.graphql(graphqlOperation(createPatientNumber, { input: param }))
        return 1
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const changePatientDisplay = async ({ id, display }: {
    id: string,
    display: Display
  }) => {
    try {
      const original = await DataStore.query(PatientModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('患者の表示切替に失敗しました'))
      }
      await DataStore.save<PatientModel>(
        PatientModel.copyOf(original[0], updated => {
          updated.display = display
        })
      )
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const deletePatient = async (id: string) => {
    try {
      const original = await DataStore.query(PatientModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('患者の削除に失敗しました'))
      }
      await DataStore.delete<PatientModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _getCurrentPatient = async (id: string): Promise<Patient|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getPatientQuery, { id })) as GetPatientResult
      return res.data?.getPatient
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    createPatient,
    updatePatient,
    changePatientDisplay,
    deletePatient
  }
}

export type UsePatientMutationType = ReturnType<typeof usePatientMutation>
