
import {
  defineComponent, inject,
  ref
} from 'vue'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import IconButton from '@/components/atoms/button/iconButton.vue'

export default defineComponent({
  name: 'clipboard-copy-button',
  components: {
    IconButton
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const textRef = ref<HTMLTextAreaElement|null>(null)
    const { append } = inject(UseErrorKey) as UseErrorType

    // document.execCommand('copy') は以下の制約がある。
    // 1) クリックのハンドラで動作させる必要がある。
    // 2) 選択済みのテキストしかコピーできない。
    // そのため、透明化したtextareaに書き込まれた値を全選択してコピーしている。
    const onCopy = async () => {
      if (!textRef.value || props.text === '') {
        return
      }

      try {
        textRef.value.textContent = props.text
        textRef.value.select()
        document.execCommand('copy')
        await append('notify', 'URLをコピーしました')
      } catch (e) {
        console.log(e)
      }
    }

    return {
      textRef,
      onCopy
    }
  }
})
