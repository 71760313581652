<template>
  <div class="vue-flex-row">
    <template v-for="(button, index) in buttons">
      <icon-button-middle
        class="list-row-item-button"
        v-if="button.icon!==''"
        :key="columnId+'-'+index"
        :state="button.state"
        :disabled="!!button.disabled"
        :icon="button.icon"
        @click="$emit('click', button.emitType)"></icon-button-middle>
      <div
        class="list-row-item-button"
        v-if="button.icon===''"
        :key="columnId+'-'+index"></div>
    </template>
  </div>
</template>

<script>
import IconButtonMiddle from '@/components/atoms/button/iconButtonMiddle.vue'

export default {
  name: 'list-row-item-multi-icon-button',
  components: {
    IconButtonMiddle
  },
  props: {
    buttons: Array,
    columnId: String
  },
  emits: ['click']
}
</script>

<style lang="scss" scoped>
.list-row-item-button {
  margin: 0 3px;
}
</style>
