<template>
  <toggle-switch :modelValue="value"/>
</template>

<script>
import ToggleSwitch from '@/components/atoms/toggleSwitch.vue'
export default {
  name: 'list-row-item-checkbox',
  components: { ToggleSwitch },
  props: {
    value: Boolean
  }
}
</script>
