import { v4 as uuid } from 'uuid'
import { InjectionKey } from 'vue'
import { CreateKarteItemInput, CreateKarteTemplateInput, PartsName } from '@/API'
import { useKarteItemMutation } from '@/composables/karteData/useKarteItemMutation'
import { useKarteTemplateMutation } from '@/composables/karteData/useKarteTemplateMutation'

export const useBuiltInKarteItem = () => {
  const { registerKarteItem } = useKarteItemMutation()
  const { registerKarteTemplate } = useKarteTemplateMutation()

  const builtInItems: CreateKarteItemInput[] = [
    {
      id: uuid(),
      displayOrder: 1,
      title: '診察時間',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.timeRange
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 2,
      title: '現在受けている治療',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.multiLine
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 3,
      title: '既往歴',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.multiLine
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 4,
      title: '症状と経過',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.singleLine,
          title: '症状'
        },
        {
          id: uuid(),
          partsName: PartsName.multiLine,
          title: '経過'
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 5,
      title: '所見、治療方針',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.multiLine
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 6,
      title: '実証／中間証／虚証',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.multiLine,
          title: '実証'
        },
        {
          id: uuid(),
          partsName: PartsName.multiLine,
          title: '中間証'
        },
        {
          id: uuid(),
          partsName: PartsName.multiLine,
          title: '虚証'
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 7,
      title: '画像',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.images
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 8,
      title: '画像とテキスト',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.imageAndText
        }
      ]
    },
    {
      id: uuid(),
      displayOrder: 9,
      title: '添付ファイル',
      karteParts: [
        {
          id: uuid(),
          partsName: PartsName.attachFiles
        }
      ]
    }
  ]

  const builtInTemplates: CreateKarteTemplateInput[] = [
    {
      id: uuid(),
      displayOrder: 1,
      title: '初診',
      karteItemIds: [
        { id: uuid(), karteItemId: builtInItems[1].id! },
        { id: uuid(), karteItemId: builtInItems[2].id! }
      ]
    },
    {
      id: uuid(),
      displayOrder: 1,
      title: '診察',
      karteItemIds: [
        { id: uuid(), karteItemId: builtInItems[3].id! },
        { id: uuid(), karteItemId: builtInItems[4].id! },
        { id: uuid(), karteItemId: builtInItems[5].id! }
      ]
    }
  ]

  const registerBuiltInKarteItems = async () => {
    try {
      await Promise.all(builtInItems.map(async (karteItem) => {
        await registerKarteItem({ karteItem })
      }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const registerBuiltInKarteTemplate = async () => {
    try {
      await Promise.all(builtInTemplates.map(async (karteTemplate) => {
        await registerKarteTemplate({ karteTemplate })
      }))
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerBuiltInKarteItems,
    registerBuiltInKarteTemplate
  }
}

export type UseBuiltInKarteItemType = ReturnType<typeof useBuiltInKarteItem>
export const UseBuiltInKarteItemKey: InjectionKey<UseBuiltInKarteItemType> = Symbol('BuiltInKarteItem')
