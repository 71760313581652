<template>
  <div class="content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-sub-content'
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 8px 16px;
  background-color: $base_color_water_light8;
  border-radius: $border_radius;
}
</style>
