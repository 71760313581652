import { ref } from 'vue'

export const useSubmit = () => {
  const submitted = ref(false)

  const submit = ():void => {
    submitted.value = true
  }

  return {
    submitted,
    submit
  }
}
