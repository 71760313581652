<template>
  <div
    class="link-text"
    @click="$router.push(link)">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LinkText',
  props: {
    link: String
  }
}
</script>

<style lang="scss" scoped>
  .link-text {
    cursor: pointer;
    color: $base_color_blue;

    &:hover {
      font-weight: bold;
    }
  }
</style>
