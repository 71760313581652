
import {
  computed,
  defineComponent,
  inject
} from 'vue'
import { UseAppointmentKey, UseAppointmentType } from '@/composables/appointment/useAppointment'
import {
  UseAccountAppointmentKey,
  UseAccountAppointmentType
} from '@/composables/appointment/useAccountAppointment'
import {
  UseBusinessHourSubscriptionKey,
  UseBusinessHourSubscriptionType
} from '@/composables/businessHour/useBusinessHourSubscription'
import {
  UseAppointmentSubscriptionKey,
  UseAppointmentSubscriptionType
} from '@/composables/appointment/useAppointmentSubsucription'
import { UsePatientListKey, UsePatientListType } from '@/composables/patient/usePatientList'
import {
  UseTemporaryPatientKey,
  UseTemporaryPatientType
} from '@/composables/appointment/useTemporaryPatient'
import VueContent from '@/components/layout/vueContent.vue'
import DatePager from '@/components/organisms/datePager.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import TimeTable from '@/components/molecules/timeTable.vue'
import { useMakeCalender } from '@/composables/businessHour/makeCalendar'
import { expandHourRange, formatHM } from '@/plugins/functions'
import { Hour } from '@/composables/businessHour/types'
import { AppointmentPatient } from '@/composables/appointment/types'
import dayjs from 'dayjs'
import { AppointmentStatus, Gender } from '@/models'

export default defineComponent({
  name: 'patient-time-table',
  components: {
    TimeTable,
    VueContent,
    VueYScrollArea,
    DatePager
  },
  setup () {
    const { selectedPatient } = inject(UsePatientListKey) as UsePatientListType
    const {
      name: temporaryName,
      gender: temporaryGender,
      phoneNumber: temporaryPhoneNumber
    } = inject(UseTemporaryPatientKey) as UseTemporaryPatientType
    const {
      patientType,
      year,
      month,
      day,
      startTime,
      endTime,
      errorStartTime,
      errorEndTime
    } = inject(UseAppointmentKey) as UseAppointmentType
    const { selectedAccount } = inject(UseAccountAppointmentKey) as UseAccountAppointmentType
    const { businessHourMap } = inject(UseBusinessHourSubscriptionKey) as UseBusinessHourSubscriptionType
    const { getAppointmentList } = inject(UseAppointmentSubscriptionKey) as UseAppointmentSubscriptionType
    const { makeHourList } = useMakeCalender()

    const hourList = computed<Hour[]>(() => {
      if (!Object.prototype.hasOwnProperty.call(businessHourMap.value, selectedAccount.value)) {
        return []
      }
      const businessHour = businessHourMap.value[selectedAccount.value]
      const hours = makeHourList(year.value, month.value, day.value, businessHour)
      if (hours.closeDay) {
        return []
      }
      return hours.hourList
        .filter(h => h.temporaryClose === undefined)
    })

    const hourRange = computed<number[]>(() => {
      let minHour = 23
      let maxHour = 0.0

      hourList.value.forEach(h => {
        const s = parseInt(h.businessHour.startTime.substr(0, 2))
        const e = parseFloat(h.businessHour.endTime.substr(0, 2)) + parseFloat(h.businessHour.endTime.substr(3, 2)) / 60
        if (minHour > s) {
          minHour = s
        }
        if (maxHour < e) {
          maxHour = e
        }
      })

      getAppointmentList(year.value, month.value, day.value, selectedAccount.value)
        .forEach(appointment => {
          const s = parseInt(appointment.appointment.startTime.substr(0, 2))
          const e = parseFloat(appointment.appointment.endTime.substr(0, 2)) + parseFloat(appointment.appointment.endTime.substr(3, 2)) / 60
          if (minHour > s) {
            minHour = s
          }
          if (maxHour < e) {
            maxHour = e
          }
        })

      minHour = Math.max(minHour - 1, 0)
      maxHour = Math.min(Math.ceil(maxHour), 23)

      const range = []
      for (let h = minHour; h <= maxHour; h++) {
        range.push(h)
      }
      return expandHourRange(range, startTime.value, endTime.value)
    })

    const appointmentList = computed<AppointmentPatient[]>(() => {
      const list = [...getAppointmentList(year.value, month.value, day.value, selectedAccount.value)]

      if (errorStartTime.value === undefined && errorEndTime.value === undefined) {
        if (patientType.value === 'sel') {
          list.push({
            appointment: {
              id: '',
              patientId: selectedPatient.value!.id,
              accountId: selectedAccount.value,
              appointmentStatus: AppointmentStatus.REGISTERED,
              temporaryPatient: false,
              date: dayjs(new Date(year.value, month.value, day.value)).format('YYYY-MM-DD'),
              startTime: formatHM(startTime.value),
              endTime: formatHM(endTime.value)
            },
            patientName: selectedPatient.value!.name,
            patientRead: selectedPatient.value!.read,
            patientGender: selectedPatient.value!.gender,
            patientBirthDate: selectedPatient.value!.birthDate,
            patientPhoneNumber: selectedPatient.value!.private.phoneNumber && selectedPatient.value!.private.phoneNumber!.length > 0
              ? selectedPatient.value!.private.phoneNumber[0]
              : '',
            selected: true
          })
        } else {
          list.push({
            appointment: {
              id: '',
              patientId: '',
              accountId: selectedAccount.value,
              appointmentStatus: AppointmentStatus.REGISTERED,
              temporaryPatient: true,
              date: dayjs(new Date(year.value, month.value, day.value)).format('YYYY-MM-DD'),
              startTime: formatHM(startTime.value),
              endTime: formatHM(endTime.value)
            },
            patientName: temporaryName.value,
            patientGender: temporaryGender.value as Gender,
            patientPhoneNumber: temporaryPhoneNumber.value,
            selected: true
          })
        }
      }
      return list
    })

    return {
      year,
      month,
      day,
      hourList,
      hourRange,
      appointmentList,
      startTime,
      endTime
    }
  }
})
