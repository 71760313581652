<template>
  <no-label-radio-button-group
    :group-id="groupId"
    :radios="switchOptions"
    :modelValue="modelValue ? 'available' : 'none'"
    @update:modelValue="$emit('update:modelValue', $event === 'available')">{{ title }}
  </no-label-radio-button-group>
</template>

<script>

import NoLabelRadioButtonGroup from '@/components/molecules/NoLabelRadioButtonGroup.vue'
export default {
  name: 'ToggleRadioSwitch',
  components: { NoLabelRadioButtonGroup },
  props: {
    modelValue: Boolean,
    groupId: String
  },
  data () {
    return {
      switchOptions: [
        {
          value: 'available',
          name: 'あり'
        },
        {
          value: 'none',
          name: 'なし'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
