<template>
  <div>
    <div class="radio-group-title">
      <slot></slot>
    </div>
    <div class="radio-group-item-frame" :class="$mq">
      <radio-button
        v-for="(radio, index) in radios"
        :key="'radio-group-'+groupId+'-'+index"
        :checked="radio.value===modelValue"
        @click="$emit('update:modelValue', radio.value)">{{ radio.name }}
      </radio-button>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/atoms/radioButton.vue'

export default {
  name: 'radio-button-group-vertical',
  components: {
    RadioButton
  },
  props: {
    groupId: {
      type: String,
      required: true
    },
    radios: Array,
    modelValue: String
  }
}
</script>

<style lang="scss" scoped>
.radio-group-item-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 20px;

  &.mobile {
    margin-left: 12px;
  }
}
</style>
