import { inject, InjectionKey } from 'vue'
import dayjs from 'dayjs'
import { parseYMD } from '@/plugins/functions'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import {
  UseTemporaryCloseSubscriptionKey,
  UseTemporaryCloseSubscriptionType
} from '@/composables/businessHour/useTemporaryCloseSubscription'
import { UseHolidayKey, UseHolidayType } from '@/composables/useHoliday'
import { Day } from '@/composables/businessHour/types'
import { BusinessHour, IntervalType } from '@/models'

export const useMakeCalender = () => {
  const { setting } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType
  const { temporaryCloseMap } = inject(UseTemporaryCloseSubscriptionKey) as UseTemporaryCloseSubscriptionType
  const { holidayMap } = inject(UseHolidayKey) as UseHolidayType

  const makeCalendar = (
    year: number,
    month: number,
    businessHours: BusinessHour[]
  ): Day[] => {
    const startDate = dayjs(new Date(year, month, 1))
    const days = startDate.daysInMonth()

    const dayMap: { [key:string]: BusinessHour[] } = {}
    for (let i = 1; i <= days; i++) {
      dayMap[`${i}`] = []
    }

    businessHours.forEach(businessHour => {
      const {
        year: bYear,
        month: bMonth,
        day: bDay
      } = parseYMD(businessHour.originDate)
      const interval = businessHour.interval
      const diff = dayjs(businessHour.originDate, 'YYYY-MM-DD', true).diff(startDate, 'days')

      switch (businessHour.intervalType) {
        case IntervalType.DAY: {
          let startDay = diff % interval
          if (diff < 0) {
            startDay = interval + startDay
          }
          for (let i = startDay; i < days; i += interval) {
            dayMap[i + 1].push(businessHour)
          }
          break
        }
        case IntervalType.WEEK: {
          let startDay = diff % (interval * 7)
          if (diff < 0) {
            startDay = (interval * 7) + startDay
          }
          for (let i = startDay; i < days; i += interval * 7) {
            dayMap[i + 1].push(businessHour)
          }
          break
        }
        case IntervalType.MONTH: {
          if ((month - bMonth) % interval === 0) {
            dayMap[bDay].push(businessHour)
          }
          break
        }
        case IntervalType.YEAR: {
          if ((year - bYear) % interval === 0 && (month - bMonth) % 12 === 0) {
            dayMap[bDay].push(businessHour)
          }
          break
        }
        case IntervalType.NTH_WEEK_DAY: {
          if ((month - bMonth) % interval === 0) {
            let startDay = diff % 7
            if (diff < 0) {
              startDay = (7 + startDay) % 7
            }
            const week = Math.floor(bDay / 7)
            const d = startDay + week * 7
            if (d < days) {
              dayMap[d + 1].push(businessHour)
            }
          }
          break
        }
        case IntervalType.ONCE: {
          if (year === bYear && month === bMonth) {
            dayMap[bDay].push(businessHour)
          }
          break
        }
      }
    })

    return (Object.keys(dayMap) as string[])
      .filter(day => dayMap[day].length > 0)
      .sort()
      .map(day => {
        const d = dayjs(new Date(year, month, parseInt(day)))
        const formatDate = d.format('YYYY-MM-DD')
        const holiday = holidayMap.value[formatDate]
        const weekDay = d.day()
        let close = false
        if (holiday) {
          close = closeDay(holiday.substitute, weekDay)
        }
        return {
          day: parseInt(day),
          hourList: dayMap[day].map(dd => {
            const temporaryClose = temporaryCloseMap.value[dd.id]
            return {
              businessHour: dd,
              temporaryClose: temporaryClose && temporaryClose.date === formatDate
                ? temporaryClose
                : undefined
            }
          }),
          closeDay: close
        }
      })
  }

  const makeHourList = (
    year: number,
    month: number,
    day: number,
    businessHours: BusinessHour[]
  ): Day => {
    const startDate = dayjs(new Date(year, month, 1))
    const days = startDate.daysInMonth()

    const hourList: BusinessHour[] = []
    businessHours.forEach(businessHour => {
      const {
        year: bYear,
        month: bMonth,
        day: bDay
      } = parseYMD(businessHour.originDate)
      const interval = businessHour.interval
      const diff = dayjs(businessHour.originDate, 'YYYY-MM-DD', true).diff(startDate, 'days')

      switch (businessHour.intervalType) {
        case IntervalType.DAY: {
          let startDay = diff % interval
          if (diff < 0) {
            startDay = interval + startDay
          }
          if ((day - startDay - 1) % interval === 0) {
            hourList.push(businessHour)
          }
          break
        }
        case IntervalType.WEEK: {
          let startDay = diff % (interval * 7)
          if (diff < 0) {
            startDay = (interval * 7) + startDay
          }

          if ((day - startDay - 1) % (interval * 7) === 0) {
            hourList.push(businessHour)
          }
          break
        }
        case IntervalType.MONTH: {
          if ((month - bMonth) % interval === 0 && bDay === day) {
            hourList.push(businessHour)
          }
          break
        }
        case IntervalType.YEAR: {
          if ((year - bYear) % interval === 0 && (month - bMonth) % 12 === 0 && bDay === day) {
            hourList.push(businessHour)
          }
          break
        }
        case IntervalType.NTH_WEEK_DAY: {
          if ((month - bMonth) % interval === 0) {
            let startDay = diff % 7
            if (diff < 0) {
              startDay = (7 + startDay) % 7
            }
            const week = Math.floor(bDay / 7)
            const d = startDay + week * 7
            if (d < days && d + 1 === day) {
              hourList.push(businessHour)
            }
          }
          break
        }
        case IntervalType.ONCE: {
          if (year === bYear && month === bMonth && day === bDay) {
            hourList.push(businessHour)
          }
          break
        }
      }
    })

    const d = dayjs(new Date(year, month, day))
    const formatDate = d.format('YYYY-MM-DD')
    const holiday = holidayMap.value[formatDate]
    const weekDay = d.day()
    let close = false
    if (holiday) {
      close = closeDay(holiday.substitute, weekDay)
    }
    return {
      day: day,
      hourList: hourList.map(dd => {
        const temporaryClose = temporaryCloseMap.value[dd.id]
        return {
          businessHour: dd,
          temporaryClose: temporaryClose && temporaryClose.date === formatDate
            ? temporaryClose
            : undefined
        }
      }),
      closeDay: close
    }
  }

  const businessHourIntervalString = (businessHour: BusinessHour) => {
    switch (businessHour.intervalType) {
      case 'day':
      {
        return businessHour.interval === 1 ? '毎日' : `${businessHour.interval}日毎`
      }
      case 'week':
      {
        const intervalString = businessHour.interval === 1 ? '毎週' : `${businessHour.interval}週間毎`
        const dayName = dayjs(businessHour.originDate, 'YYYY-MM-DD', true).format('ddd')
        return `${intervalString}${dayName}曜日`
      }
      case 'month':
      {
        return businessHour.interval === 1 ? '毎月' : `${businessHour.interval}カ月毎`
      }
      case 'year':
      {
        return businessHour.interval === 1 ? '毎年' : `${businessHour.interval}年毎`
      }
      case 'nthWeekDay':
      {
        const intervalString = businessHour.interval === 1 ? '毎月' : `${businessHour.interval}カ月毎`
        const d = dayjs(businessHour.originDate, 'YYYY-MM-DD', true)
        const weekDayName = d.format('ddd')
        const weekNum = Math.floor((d.date() - 1) / 7) + 1
        return `${intervalString}第${weekNum}${weekDayName}曜`
      }
      case 'once':
      {
        return '一回のみ'
      }
    }
  }

  const closeDay = (substitute: boolean, weekDay: number): boolean => {
    if (substitute && setting.holidayAutoCloseSub.value) {
      return true
    }
    if (substitute) {
      return false
    }

    return (
      (weekDay === 0 && setting.holidayAutoCloseSun.value) ||
      (weekDay === 6 && setting.holidayAutoCloseSat.value) ||
      (![0, 6].includes(weekDay) && setting.holidayAutoClose.value)
    )
  }

  return {
    makeCalendar,
    makeHourList,
    businessHourIntervalString
  }
}

export type UseMakeCalenderType = ReturnType<typeof useMakeCalender>
export const UseMakeCalenderKey: InjectionKey<UseMakeCalenderType> = Symbol('MakeCalender')
