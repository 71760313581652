<template>
  <div class="menu-frame">
    <side-menu-item
      v-for="(menu, index) in menus"
      :key="`menu-${index}`"
      v-bind="menu"
      @click="$emit('menu', index)"
    />
  </div>
</template>

<script>
import SideMenuItem from '@/components/atoms/SideMenuItem.vue'

export default {
  name: 'side-menu',
  components: {
    SideMenuItem
  },
  props: {
    menus: Array
  },
  emits: ['menu']
}
</script>

<style lang="scss" scoped>
.menu-frame {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
</style>
