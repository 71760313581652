import { v4 as uuid } from 'uuid'
import {
  Appointment as AppointmentModel,
  TemporaryPatient as TemporaryPatientModel
} from '@/models'
import {
  Appointment,
  CreateAppointmentInput,
  CreateTemporaryPatientInput,
  UpdateAppointmentInput
} from '@/API'
import { API, DataStore, graphqlOperation } from 'aws-amplify'
import {
  createAppointment,
  updateAppointment as updateAppointmentMutation,
  createTemporaryPatient
} from '@/graphql/mutations'
import { getAppointment as getAppointmentQuery, listAppointments } from '@/graphql/queries'

interface GetAppointmentResult {
  data?: {
    getAppointment: Appointment
  }
}

interface ListAppointmentsResult {
  data?: {
    listAppointments: {
      items: Appointment[]
      nextToken: string | null
    }
  }
}

export const useAppointmentMutation = () => {
  const registerAppointment = async ({ appointment }: {
    appointment: CreateAppointmentInput
  }) => {
    try {
      appointment.id = uuid()
      await API.graphql(graphqlOperation(createAppointment, { input: appointment }))
      return appointment.id
    } catch (e) {
      return Promise.reject(new Error('予約の登録に失敗しました'))
    }
  }

  const updateAppointment = async ({ appointment }: {
    appointment: UpdateAppointmentInput
  }) => {
    try {
      const currentData = await _getCurrentAppointment(appointment.id)
      if (!currentData) {
        return Promise.reject(new Error('サーバの予約データの取得に失敗しました'))
      }
      const input: UpdateAppointmentInput = {
        id: appointment.id,
        accountId: appointment.accountId,
        date: appointment.date,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateAppointmentMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('予約の更新に失敗しました'))
    }
  }

  const clearAppointmentTemporary = async (id: string) => {
    try {
      const currentData = await _getCurrentAppointment(id)
      if (!currentData) {
        return Promise.reject(new Error('サーバの予約データの取得に失敗しました'))
      }
      const input: UpdateAppointmentInput = {
        id,
        temporaryPatient: false,
        _version: currentData._version
      }
      await API.graphql(graphqlOperation(updateAppointmentMutation, { input }))
    } catch (e) {
      return Promise.reject(new Error('予約の更新に失敗しました'))
    }
  }

  const deleteAppointment = async (id: string) => {
    try {
      // TODO: DataStoreの取得範囲にない古い予約の場合にも対応する
      const original = await DataStore.query(AppointmentModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('予約の削除に失敗しました'))
      }
      await DataStore.delete<AppointmentModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(new Error('予約の削除に失敗しました'))
    }
  }

  const registerTemporaryPatient = async ({ temporaryPatient }: {
    temporaryPatient: CreateTemporaryPatientInput
  }) => {
    try {
      temporaryPatient.id = uuid()
      await API.graphql(graphqlOperation(createTemporaryPatient, { input: temporaryPatient }))
      return temporaryPatient.id
    } catch (e) {
      return Promise.reject(new Error('患者の仮登録に失敗しました'))
    }
  }

  const deleteTemporaryPatient = async (id: string) => {
    try {
      const original = await DataStore.query(TemporaryPatientModel, p => p.id('eq', id))
      if (original.length === 0) {
        return Promise.reject(new Error('仮登録の患者の削除に失敗しました'))
      }
      await DataStore.delete<TemporaryPatientModel>(original[0])
      return true
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const getAppointmentByPatientId = async (patientId: string): Promise<Appointment[]> => {
    try {
      const filter = {
        patientId: {
          eq: patientId
        }
      }
      const res = await API.graphql(graphqlOperation(listAppointments, { filter })) as ListAppointmentsResult
      if (res.data?.listAppointments && res.data.listAppointments.items.length > 0) {
        return res.data.listAppointments.items
      }
      return []
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const _getCurrentAppointment = async (id: string): Promise<Appointment|undefined> => {
    try {
      const res = await API.graphql(graphqlOperation(getAppointmentQuery, { id })) as GetAppointmentResult
      return res.data?.getAppointment
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    registerAppointment,
    updateAppointment,
    clearAppointmentTemporary,
    deleteAppointment,
    registerTemporaryPatient,
    deleteTemporaryPatient,
    getAppointmentByPatientId
  }
}

export type UseAppointmentMutationType = ReturnType<typeof useAppointmentMutation>
