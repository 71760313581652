import { InjectionKey, ref } from 'vue'

export const useLockScreen = () => {
  const lock = ref<boolean>(false)

  const lockScreen = async () => {
    lock.value = true
    document.body.classList.add('open-modal')
  }

  const unlockScreen = async () => {
    lock.value = false
    document.body.classList.remove('open-modal')
  }

  return {
    lock,
    lockScreen,
    unlockScreen
  }
}

export type UseLockScreenType = ReturnType<typeof useLockScreen>
export const UseLockScreenKey: InjectionKey<UseLockScreenType> = Symbol('LockScreen')
