
import {
  defineComponent,
  inject
} from 'vue'
import { UseBusinessHourKey, UseBusinessHourType } from '@/composables/businessHour/useBusinessHour'
import { UseSettingSubscriptionKey, UseSettingSubscriptionType } from '@/composables/setting/useSettingSubscription'
import ValidateEditBoxTime from '@/components/molecules/editbox/validateEditBoxTime.vue'
import AlertMessage from '@/components/atoms/alert/alertMessage.vue'

export default defineComponent({
  name: 'time-range',
  components: {
    ValidateEditBoxTime,
    AlertMessage
  },
  setup () {
    const {
      submitted,
      startTime,
      endTime,
      startTimeError,
      endTimeError,
      timeOrderError
    } = inject(UseBusinessHourKey) as UseBusinessHourType
    const { setting: { appointmentTimeUnit } } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType

    return {
      submitted,
      startTime,
      endTime,
      startTimeError,
      endTimeError,
      timeOrderError,
      appointmentTimeUnit
    }
  }
})
