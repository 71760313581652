
import { defineComponent } from 'vue'
import RadioButton from '@/components/atoms/radioButton.vue'

export default defineComponent({
  name: 'radio-button-card',
  props: {
    selected: Boolean
  },
  components: { RadioButton },
  emits: ['click']
})
