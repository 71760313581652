<template>
  <div>
    <select
      class="select-box"
      :class="$mq"
      :value="selected"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.value)">
      <option
        v-for="(item, index) in items"
        :key="'select-box' + index"
        :value="item.value">{{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'list-row-item-select-box',
  props: {
    items: Array,
    selected: String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.select-box {
  height: $form_item_height;
  box-sizing: border-box;
  padding: 0 3px;
  border: 0;
  border-radius: $border_radius;
  background: $base_color_white;
  font-size: 0.9em;
  text-indent: 4px;
  outline: none;
  transition: 0.1s;

  &:focus {
    border: 3px solid $base_color_water;
    padding: 0;
    text-indent: 7px;
  }

  &.mobile {
    font-family: inherit;
    font-size: 0.7em;

    option {
      font-family: inherit;
      font-size: 1.2em;
    }
  }
}
</style>
