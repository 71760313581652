<template>
  <div class="content-frame">
    <div class="content-left-frame">
      <slot name="left"></slot>
    </div>
    <div class="content-right-frame">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vue-two-column-content-even'
}
</script>

<style lang="scss" scoped>
  .content-frame {
    display: flex;
    flex-direction: row;
    height: 100%;

    .content-left-frame {
      flex-basis: 50%;
      height: 100%;
    }

    .content-right-frame {
      flex-basis: 50%;
      margin-left: 8px;
      height: 100%;
    }
  }
</style>
