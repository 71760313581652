
import {
  defineComponent,
  onMounted
} from 'vue'
import { useZipCodes } from '@/composables/useZipCodes'
import { FetchStatus } from '@/composables/commonTypes'
import VueContentItemFrame from '@/components/layout/vueContentItemFrame.vue'
import LoadingMessage from '@/components/molecules/LoadingMessage.vue'
import RadioButtonGroupVertical from '@/components/molecules/radioButtonGroupVertical.vue'

export default defineComponent({
  name: 'address-list-inquiry',
  components: {
    RadioButtonGroupVertical,
    LoadingMessage,
    VueContentItemFrame
  },
  props: {
    keyValue: String,
    zipCode: String,
    setPref: Function,
    setCity: Function,
    setAddress: Function,
    modelValue: String,
    next: Function
  },
  setup (props, content) {
    const { state, fetchZipCodes, addresses } = useZipCodes()

    onMounted(async () => {
      if (!props.zipCode || props.zipCode === '') {
        content.emit('update:modelValue', 'etc')
        props.setPref && props.setPref('')
        props.setCity && props.setCity('')
        props.setAddress && props.setAddress('')
        props.next && props.next()
        return
      }

      try {
        const zipList = await fetchZipCodes(props.zipCode)
        if (zipList.length > 0) {
          const zip = state.listZip[0]
          props.setPref && props.setPref(zip.pref)
          props.setCity && props.setCity(zip.city)
          props.setAddress && props.setAddress(zip.address)
          content.emit('update:modelValue', '0')
        } else if (zipList.length === 0) {
          props.setPref && props.setPref('')
          props.setCity && props.setCity('')
          props.setAddress && props.setAddress('')
          content.emit('update:modelValue', 'etc')
        }
        if (zipList.length < 2) {
          props.next && props.next()
        }
      } catch (e) {
        console.log(e)
      }
    })

    const onSelect = (value: string) => {
      content.emit('update:modelValue', value)
      if (value === 'etc') {
        props.setPref && props.setPref(state.listZip[0].pref)
        props.setCity && props.setCity('')
        props.setAddress && props.setAddress('')
      } else {
        const zip = state.listZip[parseInt(value)]
        props.setPref && props.setPref(zip.pref)
        props.setCity && props.setCity(zip.city)
        props.setAddress && props.setAddress(zip.address)
      }
    }

    return {
      FetchStatus,
      state,
      addresses,
      onSelect
    }
  }
})
