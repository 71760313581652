
import { v4 as uuid } from 'uuid'
import { defineComponent, inject, ref } from 'vue'
import VueContent from '@/components/layout/vueContent.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import ListTitle from '@/components/molecules/listTitle.vue'
import VueYScrollArea from '@/components/layout/vueYScrollArea.vue'
import KarteTemplateModal from '@/views/karte/KarteList/KarteTemplateModal.vue'
import {
  UseConsultationListKey,
  UseConsultationListType
} from '@/composables/karte/useConsultationList'
import { UseKartePatientKey, UseKartePatientType } from '@/composables/karte/useKartePatient'
import { UseConsultationInfoKey, UseConsultationInfoType } from '@/composables/karte/useConsultationInfo'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { Karte as KarteModel, KarteResult, PartsName } from '@/models'
import { useKarte } from '@/composables/karte/useKarte'
import { useRouter } from 'vue-router'
import ListRow from '@/components/molecules/listRow.vue'
import {
  UseOldPatientKarteKey,
  UseOldPatientKarteType
} from '@/composables/karte/useOldPatientKarte'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { KarteTitle } from '@/composables/karte/types'
import { useFavoriteKarteMutation } from '@/composables/karte/useFavoriteKarteMutation'
import InquiryModel from '@/views/karte/KarteList/InquiryModel.vue'
import { UseInquiryInfoKey, UseInquiryInfoType } from '@/composables/karte/useInquiryInfo'

export default defineComponent({
  name: 'ConsultationList',
  components: {
    InquiryModel,
    ListRow,
    KarteTemplateModal,
    ListTitle,
    VueYScrollArea,
    NormalButton,
    VueContent
  },
  setup () {
    const router = useRouter()
    const karteTemplateRef = ref<InstanceType<typeof KarteTemplateModal>>()
    const inquiryRef = ref<InstanceType<typeof InquiryModel>>()
    const noDataNotified = ref<boolean>(false)

    const { append } = inject(UseErrorKey) as UseErrorType
    const { patientWrite, clinicId } = inject(UseClinicKey) as UseClinicType
    const { id: accountId, name: accountName } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { patientId } = inject(UseKartePatientKey) as UseKartePatientType
    const {
      editDate,
      enableTime,
      editStartTime,
      editEndTime,
      selectedTemplateItems,
      resetAppointment
    } = inject(UseConsultationInfoKey) as UseConsultationInfoType
    const { resetInquiry } = inject(UseInquiryInfoKey) as UseInquiryInfoType
    const {
      upKarte,
      downKarte,
      consultationTitles,
      consultationRows
    } = inject(UseConsultationListKey) as UseConsultationListType
    const {
      fetchOldKarte
    } = inject(UseOldPatientKarteKey) as UseOldPatientKarteType
    const { setKarte } = useKarte()
    const { toggleBookmark } = useFavoriteKarteMutation()

    const onAddKarte = () => {
      resetAppointment()
      karteTemplateRef.value?.open()
    }

    const yseTemplate = async () => {
      const karteResults: KarteResult[] = []
      if (enableTime.value) {
        karteResults.push({
          id: uuid(),
          karteItemId: '',
          title: '診察時間',
          kartePartsResult: [
            {
              id: uuid(),
              partsName: PartsName.TIME_RANGE,
              startTime: `${editStartTime.value.substr(0, 2)}${editStartTime.value.substr(2, 2)}`,
              endTime: `${editEndTime.value.substr(0, 2)}${editEndTime.value.substr(2, 2)}`
            }
          ]
        })
      }
      if (selectedTemplateItems.value.length > 0) {
        karteResults.push(...selectedTemplateItems.value)
      }

      const newKarte: KarteModel = {
        id: '',
        accountId: accountId.value,
        accountName: accountName.value,
        karteNumber: '',
        patientId: patientId.value,
        karteDate: editDate.value,
        displayOrder: 0,
        title: '',
        karteResults
      }
      setKarte(newKarte)

      // setKarteによってlocalStorageに編集するカルテの情報が書き込まれるが、念のためurlでもpatientIdを通知する
      await router.push(`/w/${clinicId.value}/karte?p=${patientId.value}`)
    }

    const onAddInquiry = () => {
      resetInquiry()
      inquiryRef.value?.open()
    }

    const yesInquiry = async () => {
      console.log('not implemented')
    }

    const onFetchOld = async () => {
      try {
        const noMoreData = await fetchOldKarte()
        if (noMoreData && !noDataNotified.value) {
          await append('warning', 'これ以上古いカルテはありません')
          noDataNotified.value = true
        }
      } catch (e) {
        console.log(e)
        await append('error', 'カルテ一覧の取得に失敗しました')
      }
    }

    const onBookmark = async (karteTitle: KarteTitle) => {
      try {
        await toggleBookmark(accountId.value, patientId.value, karteTitle)
      } catch (e) {
        await append('error', e.message)
      }
    }

    const onUp = async ({ id, karteDate }: KarteTitle) => {
      try {
        await upKarte(karteDate, id)
      } catch (e) {
        console.log(e)
        await append('error', 'カルテ順の変更に失敗しました')
      }
    }

    const onDown = async ({ id, karteDate }: KarteTitle) => {
      try {
        await downKarte(karteDate, id)
      } catch (e) {
        console.log(e)
        await append('error', 'カルテ順の変更に失敗しました')
      }
    }

    return {
      patientWrite,
      inquiryRef,
      karteTemplateRef,
      onAddKarte,
      yseTemplate,
      onAddInquiry,
      yesInquiry,
      onFetchOld,
      onBookmark,
      onUp,
      onDown,
      consultationTitles,
      consultationRows
    }
  }
})
