<template>
  <div class="login">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'vue-login-frame'
}
</script>

<style lang="scss" scoped>
  .login {
    width: 80vw;
    margin: 20px auto;
  }
</style>
