
import {
  computed,
  defineComponent, inject,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import { useSubmit } from '@/composables/useSubmit'
import { UseAuthenticationKey, UseAuthenticationType } from '@/composables/useAuthentication'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import VueLoginFrame from '@/components/layout/vueLoginFrame.vue'
import VueLoginForm from '@/components/layout/vueLoginForm.vue'
import Logo from '@/components/atoms/logo.vue'
import IconEditBox from '@/components/molecules/editbox/iconEditBox.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import LinkText from '@/components/atoms/linkText.vue'
import PageDescription from '@/components/atoms/pageDescription.vue'
import { emailValidation, passwordValidation } from '@/plugins/validator'

export default defineComponent({
  name: 'sign-up-form',
  components: {
    VueLoginFrame,
    VueLoginForm,
    PageDescription,
    Logo,
    LinkText,
    IconEditBox,
    NormalButton
  },
  setup () {
    const router = useRouter()
    const { submitted, submit } = useSubmit()
    const { signUp } = inject(UseAuthenticationKey) as UseAuthenticationType
    const { append } = inject(UseErrorKey) as UseErrorType

    const requesting = ref(false)
    const userId = ref('')
    const name = ref('')
    const password = ref('')
    const confirmedPassword = ref('')

    const userIdError = computed(() => {
      return emailValidation(userId.value)
    })

    const nameError = computed(() => {
      return name.value === '' ? '名前を入力してください' : undefined
    })

    const passwordError = computed(() => {
      return passwordValidation(password.value)
    })

    const confirmedPasswordError = computed(() => {
      if (password.value !== confirmedPassword.value) {
        return '確認用のパスワードが異なっています'
      }
      return undefined
    })

    const onSubmit = async () => {
      submit()
      if (userIdError.value !== undefined ||
        nameError.value !== undefined ||
        passwordError.value !== undefined ||
        confirmedPasswordError.value !== undefined) {
        return
      }

      try {
        requesting.value = true
        await signUp({
          username: userId.value,
          password: password.value,
          name: name.value
        })
        const encodeUserId = encodeURIComponent(userId.value)
        await router.push(`/confirm?uid=${encodeUserId}`)
      } catch (e) {
        console.log(e)
        await append('error', 'アカウントの登録に失敗しました')
      } finally {
        requesting.value = false
      }
    }

    return {
      submitted,
      requesting,
      userId,
      userIdError,
      name,
      nameError,
      password,
      passwordError,
      confirmedPassword,
      confirmedPasswordError,
      onSubmit,
      userIdRef: ref(null),
      nameRef: ref(null),
      passwordRef: ref(null),
      confirmedPasswordRef: ref(null),
      submitRef: ref(null)
    }
  }
})
