
import {
  defineComponent,
  inject,
  provide,
  onMounted,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import BackButton from '@/components/atoms/button/backButton.vue'
import Calendar from '@/components/organisms/calendar.vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import AppointmentTypeSelector from '@/views/appointment/calender/AppointmentTypeSelector.vue'
import DoctorTag from '@/components/molecules/doctorTag.vue'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import {
  UseSettingSubscriptionKey,
  UseSettingSubscriptionType
} from '@/composables/setting/useSettingSubscription'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { useCalendar, UseCalendarKey } from '@/composables/useCalendar'
import { useAppointmentList } from '@/composables/appointment/useAppintmentList'

export default defineComponent({
  name: 'appointment',
  components: {
    DoctorTag,
    AppointmentTypeSelector,
    VueTitle,
    VueFrame,
    BackButton,
    Calendar
  },
  setup () {
    const router = useRouter()
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { calendarStartDayNumber } = inject(UseSettingSubscriptionKey) as UseSettingSubscriptionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    const calendar = useCalendar(calendarStartDayNumber)
    const {
      year,
      month
    } = calendar
    provide(UseCalendarKey, calendar)

    const { appointmentList } = useAppointmentList(year, month)

    onMounted(async () => {
      intoDashboard()
    })

    const addDay = ref<number>(0)
    const selectorRef = ref<InstanceType<typeof AppointmentTypeSelector>>()

    const onAdd = async ({ day }: { day: number }) => {
      addDay.value = day
      selectorRef.value?.open()
    }

    const onTemporaryPatient = async () => {
      await router.push(`/w/${clinicId.value}/register_appointment?y=${year.value}&m=${month.value}&d=${addDay.value}&t=tmp`)
    }

    const onSelectPatient = async () => {
      await router.push(`/w/${clinicId.value}/register_appointment?y=${year.value}&m=${month.value}&d=${addDay.value}&t=sel`)
    }

    const onSelectAppointment = async (day:number, accountId: string, count: number) => {
      if (count === 0) {
        return
      }
      await router.push(`/w/${clinicId.value}/update_appointment?y=${year.value}&m=${month.value}&d=${day}&a=${accountId}`)
    }

    return {
      year,
      month,
      selectorRef,
      onAdd,
      onTemporaryPatient,
      onSelectPatient,
      onSelectAppointment,
      appointmentList
    }
  }
})
