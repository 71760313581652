import { ref } from 'vue'

export const useArrayValue = () => {
  const values = ref<string[]>([])

  const addValue = () => {
    values.value.push('')
  }

  const deleteValue = (i: number) => {
    values.value.splice(i, 1)
  }

  return {
    values,
    addValue,
    deleteValue
  }
}
