
import WhiteBackdrop from '@/components/atoms/whiteBackdrop.vue'

export default {
  name: 'ScreenLock',
  components: {
    WhiteBackdrop
  },
  props: {
    lock: Boolean
  }
}
