<template>
  <div class="pager" :class="$mq">
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(-1, 'months')">&lt;&lt;&lt;
    </normal-button>
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(-1, 'weeks')">&lt;&lt;
    </normal-button>
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(-1, 'days')">&lt;
    </normal-button>
    <div class="date">{{ date }}</div>
    <normal-button
      class="pager-button-today"
      state="normal"
      @click="onToday">今日
    </normal-button>
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(1, 'days')">&gt;
    </normal-button>
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(1, 'weeks')">&gt;&gt;
    </normal-button>
    <normal-button
      class="pager-button"
      state="normal"
      @click="onChangeDate(1, 'months')">&gt;&gt;&gt;
    </normal-button>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import NormalButton from '@/components/atoms/button/normalButton.vue'

export default {
  name: 'date-pager',
  components: {
    NormalButton
  },
  props: {
    year: Number,
    month: Number,
    day: Number
  },
  methods: {
    onChangeDate (value, unit) {
      const newDate = dayjs(new Date(this.year, this.month, this.day)).add(value, unit)
      this.$emit('update:year', newDate.year())
      this.$emit('update:month', newDate.month())
      this.$emit('update:day', newDate.date())
    },
    onToday () {
      const today = dayjs()
      this.$emit('update:year', today.year())
      this.$emit('update:month', today.month())
      this.$emit('update:day', today.date())
    }
  },
  computed: {
    date () {
      return dayjs(new Date(this.year, this.month, this.day)).format('M月/D日(ddd)')
    }
  }
}
</script>

<style lang="scss" scoped>
.pager {
  display: flex;
  justify-content: center;
}

.pager-button {
  height: $form_item_height;
  width: $form_item_height;
  margin: 0 5px;
}

.pager-button-today {
  height: $form_item_height;
  width: $form_item_height * 2;
  margin: 0 5px;
}

.date {
  height: $form_item_height;
  line-height: $form_item_height;
  margin: 0 30px;
}
</style>
