import { InjectionKey, ref } from 'vue'
import { BusinessHour as BusinessHourModel } from '@/models'
import { DataStore } from 'aws-amplify'

export const useBusinessHourSubscription = () => {
  // subscriptionの段階でuserIdごとに営業時間のデータを持つようにする
  const businessHourMap = ref<{[key:string]: BusinessHourModel[]}>({})
  const subscribing = ref(false)

  const fetchBusinessHour = async () => {
    try {
      const hours = await DataStore.query(BusinessHourModel)
      const newMap: { [key: string]: BusinessHourModel[] } = {}

      hours.forEach(h => {
        if (!Object.prototype.hasOwnProperty.call(newMap, h.userId)) {
          newMap[h.userId] = []
        }
        newMap[h.userId].push(h)
      })
      businessHourMap.value = newMap
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const subscribe = async (clinicId: string) => {
    if (clinicId === '') {
      return Promise.reject(new Error('can not subscribe business hour without clinic login'))
    }
    if (subscribing.value) {
      return Promise.reject(new Error('business hour already subscribing'))
    }

    try {
      await fetchBusinessHour()
      const subscription = DataStore.observe(BusinessHourModel).subscribe(async () => {
        await fetchBusinessHour()
      })
      subscribing.value = true
      return () => {
        if (!subscribing.value) {
          return
        }
        subscription.unsubscribe()
        subscribing.value = false
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    businessHourMap,
    fetchBusinessHour,
    subscribe
  }
}

export type UseBusinessHourSubscriptionType = ReturnType<typeof useBusinessHourSubscription>
export const UseBusinessHourSubscriptionKey: InjectionKey<UseBusinessHourSubscriptionType> = Symbol('BusinessHourSubscription')
