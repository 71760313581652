
import { defineComponent } from 'vue'
import Loading from '@/components/atoms/Loading.vue'

export default defineComponent({
  name: 'LoadingMessage',
  components: {
    Loading
  },
  setup () {
    //
  }
})
