<template>
  <div class="list-box">
    <select
      :id="id"
      :title="title"
      class="list-box-box"
      :class="$mq"
      :value="modelValue"
      :size="size"
      @change="$emit('update:modelValue', $event.target.value)">
      <option
        v-for="(item, index) in items"
        :key="'list-box' + index"
        :value="item.value">{{ item.name }}
      </option>
    </select>
    <label class="list-box-label" :class="$mq" :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'list-box',
  props: {
    id: String,
    title: String,
    items: {
      type: Array,
      required: true
    },
    modelValue: [String, Number],
    size: {
      type: Number,
      default: 10
    }
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  width: 100%;
}

.list-box-label {
  padding-left: 5px;

  &.mobile {
    font-size: 0.9em;
  }
}

.list-box-box {
  width: 100%;
  box-sizing: border-box;
  padding: 3px 11px;
  border: 0;
  border-radius: $border_radius;
  background: $base_color_white;
  font-size: $base_font_size;
  outline: none;

  &:focus {
    border: 3px solid $base_color_water;
    padding: 0 8px;
    transition: 0.1s;
  }

  &.mobile {
    font-family: inherit;
    font-size: 0.7em;

    option {
      font-family: inherit;
      font-size: 1.2em;
    }
  }
}
</style>
