<template>
  <div class="drop-down-menu">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'drop-down-item'
}
</script>

<style lang="scss">
  .drop-down-menu {
    display: block;
    width: 200px;
    height: $navbar_height;
    line-height: $navbar_height;
    padding: 0 15px;
    cursor: pointer;
    color: $font_color;
    text-decoration: none;

    &:hover {
      color: $font_color_light;
      background: $base_color_gray_light;
    }
  }
</style>
