
import {
  defineComponent,
  ref,
  provide,
  inject
} from 'vue'
import { useRouter } from 'vue-router'
import { usePatientMutation } from '@/composables/patient/usePatientMutation'
import { UsePatientListKey, usePatientList } from '@/composables/patient/usePatientList'
import { UseErrorKey, UseErrorType } from '@/composables/useError'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import VueTwoColumnContent from '@/components/layout/vueTwoColumnContent.vue'
import PatientSearchForm from '@/views/patient/list/PatientSearchForm.vue'
import PatientList from '@/views/patient/list/PatientList.vue'
import Confirm from '@/components/organisms/Confirm.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import VueContentRemain from '@/components/layout/vueContentRemain.vue'
import { Display, Patient } from '@/API'
import { usePatient } from '@/composables/patient/usePatient'

export default defineComponent({
  name: 'patient',
  components: {
    VueContentRemain,
    BackButton,
    PatientList,
    PatientSearchForm,
    VueTwoColumnContent,
    VueTitle,
    VueFrame,
    Confirm
  },
  setup () {
    const router = useRouter()
    const selectedPatientId = ref('')
    const selectedPatientName = ref('')
    const selectedPatientRead = ref('')
    const hideConfirmRef = ref<InstanceType<typeof Confirm>>()
    const showConfirmRef = ref<InstanceType<typeof Confirm>>()
    const deleteConfirmRef = ref<InstanceType<typeof Confirm>>()

    const { append } = inject(UseErrorKey) as UseErrorType
    const { clinicId } = inject(UseClinicKey) as UseClinicType
    const { changePatientDisplay, deletePatient } = usePatientMutation()
    const { setPatient } = usePatient()
    const patientList = usePatientList()
    provide(UsePatientListKey, patientList)

    const onChange = async (patient: Patient) => {
      setPatient(patient)
      await router.push(`/w/${clinicId.value}/register_patient?p=${patient.id}`)
    }
    const onHide = ({ id, name, read }:Patient) => {
      selectedPatientId.value = id
      selectedPatientName.value = name
      selectedPatientRead.value = read
      hideConfirmRef.value?.open()
    }

    const yesHide = async () => {
      try {
        await changePatientDisplay({
          id: selectedPatientId.value,
          display: Display.hidden
        })
        await append('notify', '患者を非表示にしました')
      } catch (e) {
        console.log(e)
        await append('error', '患者の非表示に失敗しました')
      }
    }

    const onShow = ({ id, name, read }:Patient) => {
      selectedPatientId.value = id
      selectedPatientName.value = name
      selectedPatientRead.value = read
      showConfirmRef.value?.open()
    }

    const yesShow = async () => {
      try {
        await changePatientDisplay({
          id: selectedPatientId.value,
          display: Display.show
        })
        await append('notify', '患者を表示しました')
      } catch (e) {
        console.log(e)
        await append('error', '患者の表示に失敗しました')
      }
    }

    const onDelete = ({ id, name, read }:Patient) => {
      selectedPatientId.value = id
      selectedPatientName.value = name
      selectedPatientRead.value = read
      deleteConfirmRef.value?.open()
    }

    const yesDelete = async () => {
      try {
        await deletePatient(selectedPatientId.value)
        await append('notify', '患者を削除しました')
      } catch (e) {
        console.log(e)
        await append('error', '患者の削除に失敗しました')
      }
    }

    return {
      selectedPatientName,
      selectedPatientRead,
      onChange,
      onHide,
      yesHide,
      onShow,
      yesShow,
      onDelete,
      yesDelete,
      hideConfirmRef,
      showConfirmRef,
      deleteConfirmRef
    }
  }
})
