<template>
  <div class="list-row-item-mark-frame">
    <circle-mark
      v-for="(mark, index) in marks"
      :key="columnId+'-'+index"
      class="mark"
      :style="markStyle[index]">{{ mark.title }}</circle-mark>
  </div>
</template>

<script>
import CircleMark from '@/components/atoms/circleMark.vue'

export default {
  name: 'list-row-item-circle-marks',
  components: {
    CircleMark
  },
  props: {
    marks: Array,
    columnId: String
  },
  computed: {
    markStyle () {
      return this.marks.map(m => {
        return {
          color: m.color,
          borderColor: m.color
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-row-item-mark-frame {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .mark {
    margin: 0 8px;
  }
}
</style>
