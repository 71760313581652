import { InjectionKey, ref, ComputedRef, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

export const useCalendar = (calendarStartDayNumber: ComputedRef<number>) => {
  const route = useRoute()
  const router = useRouter()
  const year = ref<number>(parseInt(route.query.y as string))
  const month = ref<number>(parseInt(route.query.m as string))

  const calendarHeader = computed(() => {
    const d = '日月火水木金土日月火水木金'
    return d.slice(calendarStartDayNumber.value, calendarStartDayNumber.value + 7).split('')
  })

  const calendar = computed(() => {
    const target = dayjs(new Date(year.value, month.value, 1))
    const endDay = target.endOf('month').date()
    const firstDayOfWeek = target.startOf('month').day()

    const monthList = []
    const padding = firstDayOfWeek - calendarStartDayNumber.value >= 0
      ? firstDayOfWeek - calendarStartDayNumber.value
      : 7 + firstDayOfWeek - calendarStartDayNumber.value
    let weekList = Array(padding)
    for (let d = 1; d <= endDay; d++) {
      weekList.push(d)
      if (weekList.length === 7) {
        monthList.push(weekList)
        weekList = []
      }
    }
    if (weekList.length !== 0) {
      weekList.push(...Array(7 - weekList.length))
      monthList.push(weekList)
    }
    if (monthList.length === 5) {
      monthList.push(Array(7))
    }
    return monthList
  })

  const addMonth = async (m: number) => {
    const newDate = dayjs(new Date(year.value, month.value, 1)).add(m, 'month')
    year.value = newDate.year()
    month.value = newDate.month()

    await router.replace(`${route.path}?y=${year.value}&m=${month.value}`)
  }

  return {
    year,
    month,
    calendarHeader,
    calendar,
    addMonth
  }
}

export type UseCalendarType = ReturnType<typeof useCalendar>
export const UseCalendarKey: InjectionKey<UseCalendarType> = Symbol('Calendar')
