<template>
  <div
    class="badge-circle"
    :class="[count>=100 ? 'large' : '']">{{ count }}</div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    count: Number
  }
}
</script>

<style lang="scss" scoped>
.badge-circle {
  color: $base_color_white;
  background: $base_color_red_dark;
  min-width: $form_item_height_small;
  height: $form_item_height_small;
  line-height: $form_item_height_small;
  border-radius: $form_item_height_small;
  border: 3px solid $base_color_white;
  text-align: center;
  @include shadow()
}

.large {
  padding: 0 8px;
}
</style>
