
import {
  defineComponent,
  inject,
  onMounted
} from 'vue'
import VueFrame from '@/components/layout/vueFrame.vue'
import VueTitle from '@/components/layout/vueTitle.vue'
import BackButton from '@/components/atoms/button/backButton.vue'
import NormalButton from '@/components/atoms/button/normalButton.vue'
import { today, thisYear, thisMonth } from '@/plugins/computed'
import { UseClinicKey, UseClinicType } from '@/composables/useClinic'
import { RouterViewTransitionKey, RouterViewTransitionType } from '@/composables/useRouterViewTransition'
import BadgeFrame from '@/components/molecules/BadgeFrame.vue'

export default defineComponent({
  name: 'KarteMenu',
  components: {
    BadgeFrame,
    NormalButton,
    BackButton,
    VueTitle,
    VueFrame
  },
  setup () {
    const { intoDashboard } = inject(RouterViewTransitionKey) as RouterViewTransitionType
    const { clinicId } = inject(UseClinicKey) as UseClinicType

    onMounted(async () => {
      intoDashboard()
    })

    return {
      today,
      thisYear,
      thisMonth,
      clinicId
    }
  }
})
