<template>
  <div
    class="arrow"
    :class="[disabled ? 'disabled' : '']">
    <fa-icon :icon="icon"/>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'arrow-button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  padding: 0 10px;
  cursor: pointer;
  color: $base_color_water_dark;
  user-select: none;

  &:hover {
    color: $base_color_water;
  }

  &.disabled {
    cursor: default;
    color: $base_color_gray;
  }
}
</style>
