
import { defineComponent } from 'vue'
import { useAddress } from '@/composables/useAddress'
import SelectBox from '@/components/atoms/selectBox.vue'
import VueLabel from '@/components/layout/vueLabel.vue'

export default defineComponent({
  name: 'pref-inquiry',
  components: {
    VueLabel,
    SelectBox
  },
  props: {
    title: String,
    keyValue: String,
    required: Boolean,
    modelValue: String
  },
  setup () {
    const { prefKeyValueList } = useAddress()

    return {
      prefKeyValueList
    }
  }
})
