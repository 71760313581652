<template>
  <div v-if="$mq !== 'mobile'">
    <div
      class="patient-frame"
      :class="$mq">
      <start-end-time-text
        :startTime="appointment.appointment.startTime"
        :endTime="appointment.appointment.endTime"/>
      <patient-name-text
        :patientName="appointment.patientName"
        :patientGender="appointment.patientGender"
        :temporaryPatient="appointment.appointment.temporaryPatient"/>
    </div>
  </div>
</template>

<script>
import StartEndTimeText from '@/components/atoms/StartEndTimeText.vue'
import PatientNameText from '@/components/atoms/PatientNameText.vue'

export default {
  name: 'Patient-hour-text',
  components: {
    PatientNameText,
    StartEndTimeText
  },
  props: {
    appointment: Object
  },
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
.patient-frame {
  display: flex;
  flex-direction: column;
  padding: 2px 4px;
  background: rgba($base_color_gray, 0.5);
  border-radius: $border_radius;
}
</style>
