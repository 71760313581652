/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Clinic = {
  __typename: "Clinic",
  id: string,
  name: string,
  owner: string,
};

export type UpdateAccountInput = {
  id: string,
  permissions: Array< Permission >,
  acceptAppointment: boolean,
};

export enum Permission {
  clinicOwner = "clinicOwner",
  accountWrite = "accountWrite",
  patientWrite = "patientWrite",
  patientRead = "patientRead",
  calendarWrite = "calendarWrite",
  calendarRead = "calendarRead",
  dataWrite = "dataWrite",
  dataRead = "dataRead",
  settingWrite = "settingWrite",
}


export type AccountClinic = {
  __typename: "AccountClinic",
  id: string,
  name: string,
  permissions: Array< Permission >,
  acceptAppointment: boolean,
  myself: boolean,
};

export type CreateAccountInvitationInput = {
  id: string,
  email: string,
  permissions: Array< Permission >,
  acceptAppointment: boolean,
};

export enum AnswerStatus {
  unprocessed = "unprocessed",
  registered = "registered",
  toBeDeleted = "toBeDeleted",
}


export type InquiryAnswer = {
  __typename: "InquiryAnswer",
  id: string,
  clinicId: string,
  owner: string,
  inquiryId: string,
  patientId?: string | null,
  answer: string,
  answerStatus: AnswerStatus,
  createdAt: string,
};

export type UploadFileInput = {
  id: string,
  name: string,
  fileType: string,
  base64: string,
  hash: string,
};

export type DownloadFile = {
  __typename: "DownloadFile",
  id: string,
  name: string,
  fileType: string,
  base64: string,
  hash: string,
};

export type CreatePatientInput = {
  id?: string | null,
  clinicId?: string | null,
  patientNumber: string,
  name: string,
  read: string,
  gender: Gender,
  birthDate: string,
  private: PatientPrivateInput,
  comment?: string | null,
  display: Display,
  _version?: number | null,
};

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
}


export type PatientPrivateInput = {
  zipCode?: string | null,
  pref?: string | null,
  city?: string | null,
  address?: string | null,
  phoneNumber?: Array< string > | null,
  sns?: Array< SNSInput > | null,
};

export type SNSInput = {
  snsType: SNSType,
  accountId: string,
  access: boolean,
};

export enum SNSType {
  email = "email",
  line = "line",
  facebook = "facebook",
}


export enum Display {
  show = "show",
  hidden = "hidden",
}


export type ModelPatientConditionInput = {
  patientNumber?: ModelStringInput | null,
  name?: ModelStringInput | null,
  read?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  birthDate?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  display?: ModelDisplayInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelDisplayInput = {
  eq?: Display | null,
  ne?: Display | null,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  clinicId?: string | null,
  patientNumber: string,
  name: string,
  read: string,
  gender: Gender,
  birthDate: string,
  private: PatientPrivate,
  comment?: string | null,
  display: Display,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type PatientPrivate = {
  __typename: "PatientPrivate",
  zipCode?: string | null,
  pref?: string | null,
  city?: string | null,
  address?: string | null,
  phoneNumber?: Array< string > | null,
  sns?:  Array<SNS > | null,
};

export type SNS = {
  __typename: "SNS",
  snsType: SNSType,
  accountId: string,
  access: boolean,
};

export type UpdatePatientInput = {
  id: string,
  clinicId?: string | null,
  patientNumber?: string | null,
  name?: string | null,
  read?: string | null,
  gender?: Gender | null,
  birthDate?: string | null,
  private?: PatientPrivateInput | null,
  comment?: string | null,
  display?: Display | null,
  _version?: number | null,
};

export type DeletePatientInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemporaryPatientInput = {
  id?: string | null,
  clinicId?: string | null,
  name: string,
  gender: Gender,
  phoneNumber?: string | null,
  _version?: number | null,
};

export type ModelTemporaryPatientConditionInput = {
  name?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phoneNumber?: ModelStringInput | null,
  and?: Array< ModelTemporaryPatientConditionInput | null > | null,
  or?: Array< ModelTemporaryPatientConditionInput | null > | null,
  not?: ModelTemporaryPatientConditionInput | null,
};

export type TemporaryPatient = {
  __typename: "TemporaryPatient",
  id: string,
  clinicId?: string | null,
  name: string,
  gender: Gender,
  phoneNumber?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTemporaryPatientInput = {
  id: string,
  clinicId?: string | null,
  name?: string | null,
  gender?: Gender | null,
  phoneNumber?: string | null,
  _version?: number | null,
};

export type DeleteTemporaryPatientInput = {
  id: string,
  _version?: number | null,
};

export type CreatePatientNumberInput = {
  id?: string | null,
  clinicId?: string | null,
  patientNumber: number,
  _version?: number | null,
};

export type ModelPatientNumberConditionInput = {
  patientNumber?: ModelIntInput | null,
  and?: Array< ModelPatientNumberConditionInput | null > | null,
  or?: Array< ModelPatientNumberConditionInput | null > | null,
  not?: ModelPatientNumberConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PatientNumber = {
  __typename: "PatientNumber",
  id: string,
  clinicId?: string | null,
  patientNumber: number,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePatientNumberInput = {
  id: string,
  clinicId?: string | null,
  patientNumber?: number | null,
  _version?: number | null,
};

export type DeletePatientNumberInput = {
  id: string,
  _version?: number | null,
};

export type CreateBusinessHourInput = {
  id?: string | null,
  clinicId?: string | null,
  userId: string,
  originDate: string,
  intervalType: IntervalType,
  interval: number,
  startTime: string,
  endTime: string,
  _version?: number | null,
};

export enum IntervalType {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  nthWeekDay = "nthWeekDay",
  once = "once",
}


export type ModelBusinessHourConditionInput = {
  userId?: ModelStringInput | null,
  originDate?: ModelStringInput | null,
  intervalType?: ModelIntervalTypeInput | null,
  interval?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  and?: Array< ModelBusinessHourConditionInput | null > | null,
  or?: Array< ModelBusinessHourConditionInput | null > | null,
  not?: ModelBusinessHourConditionInput | null,
};

export type ModelIntervalTypeInput = {
  eq?: IntervalType | null,
  ne?: IntervalType | null,
};

export type BusinessHour = {
  __typename: "BusinessHour",
  id: string,
  clinicId?: string | null,
  userId: string,
  originDate: string,
  intervalType: IntervalType,
  interval: number,
  startTime: string,
  endTime: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBusinessHourInput = {
  id: string,
  clinicId?: string | null,
  userId?: string | null,
  originDate?: string | null,
  intervalType?: IntervalType | null,
  interval?: number | null,
  startTime?: string | null,
  endTime?: string | null,
  _version?: number | null,
};

export type DeleteBusinessHourInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemporaryCloseInput = {
  id?: string | null,
  clinicId?: string | null,
  businessHourId: string,
  date: string,
  _version?: number | null,
};

export type ModelTemporaryCloseConditionInput = {
  businessHourId?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelTemporaryCloseConditionInput | null > | null,
  or?: Array< ModelTemporaryCloseConditionInput | null > | null,
  not?: ModelTemporaryCloseConditionInput | null,
};

export type TemporaryClose = {
  __typename: "TemporaryClose",
  id: string,
  clinicId?: string | null,
  businessHourId: string,
  date: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTemporaryCloseInput = {
  id: string,
  clinicId?: string | null,
  businessHourId?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type DeleteTemporaryCloseInput = {
  id: string,
  _version?: number | null,
};

export type CreateAppointmentInput = {
  id?: string | null,
  clinicId?: string | null,
  patientId: string,
  temporaryPatient: boolean,
  accountId: string,
  date: string,
  startTime: string,
  endTime: string,
  appointmentStatus: AppointmentStatus,
  _version?: number | null,
};

export enum AppointmentStatus {
  registered = "registered",
  canceled = "canceled",
  noShow = "noShow",
  consulted = "consulted",
}


export type ModelAppointmentConditionInput = {
  patientId?: ModelStringInput | null,
  temporaryPatient?: ModelBooleanInput | null,
  accountId?: ModelStringInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  appointmentStatus?: ModelAppointmentStatusInput | null,
  and?: Array< ModelAppointmentConditionInput | null > | null,
  or?: Array< ModelAppointmentConditionInput | null > | null,
  not?: ModelAppointmentConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAppointmentStatusInput = {
  eq?: AppointmentStatus | null,
  ne?: AppointmentStatus | null,
};

export type Appointment = {
  __typename: "Appointment",
  id: string,
  clinicId?: string | null,
  patientId: string,
  temporaryPatient: boolean,
  accountId: string,
  date: string,
  startTime: string,
  endTime: string,
  appointmentStatus: AppointmentStatus,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAppointmentInput = {
  id: string,
  clinicId?: string | null,
  patientId?: string | null,
  temporaryPatient?: boolean | null,
  accountId?: string | null,
  date?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  appointmentStatus?: AppointmentStatus | null,
  _version?: number | null,
};

export type DeleteAppointmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateKarteItemInput = {
  id?: string | null,
  clinicId?: string | null,
  displayOrder: number,
  title: string,
  karteParts: Array< KartePartsInput >,
  _version?: number | null,
};

export type KartePartsInput = {
  id: string,
  partsName: PartsName,
  title?: string | null,
  options?: Array< string > | null,
};

export enum PartsName {
  timeRange = "timeRange",
  singleLine = "singleLine",
  multiLine = "multiLine",
  images = "images",
  imageAndText = "imageAndText",
  attachFiles = "attachFiles",
  singleChoice = "singleChoice",
  multiChoice = "multiChoice",
  acu = "acu",
}


export type ModelKarteItemConditionInput = {
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteItemConditionInput | null > | null,
  or?: Array< ModelKarteItemConditionInput | null > | null,
  not?: ModelKarteItemConditionInput | null,
};

export type KarteItem = {
  __typename: "KarteItem",
  id: string,
  clinicId?: string | null,
  displayOrder: number,
  title: string,
  karteParts:  Array<KarteParts >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type KarteParts = {
  __typename: "KarteParts",
  id: string,
  partsName: PartsName,
  title?: string | null,
  options?: Array< string > | null,
};

export type UpdateKarteItemInput = {
  id: string,
  clinicId?: string | null,
  displayOrder?: number | null,
  title?: string | null,
  karteParts?: Array< KartePartsInput > | null,
  _version?: number | null,
};

export type DeleteKarteItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateAcuItemInput = {
  id?: string | null,
  clinicId?: string | null,
  direction: Direction,
  bodyParts: BodyParts,
  parts: Array< AcuPartsInput >,
  _version?: number | null,
};

export enum Direction {
  front = "front",
  left = "left",
  back = "back",
  right = "right",
}


export enum BodyParts {
  head = "head",
  body = "body",
  back = "back",
  rightUpperArm = "rightUpperArm",
  rightLowerArm = "rightLowerArm",
  leftUpperArm = "leftUpperArm",
  leftLowerArm = "leftLowerArm",
  rightUpperLeg = "rightUpperLeg",
  rightLowerLeg = "rightLowerLeg",
  leftUpperLeg = "leftUpperLeg",
  leftLowerLeg = "leftLowerLeg",
}


export type AcuPartsInput = {
  id: string,
  x?: number | null,
  y?: number | null,
  name: string,
};

export type ModelAcuItemConditionInput = {
  direction?: ModelDirectionInput | null,
  bodyParts?: ModelBodyPartsInput | null,
  and?: Array< ModelAcuItemConditionInput | null > | null,
  or?: Array< ModelAcuItemConditionInput | null > | null,
  not?: ModelAcuItemConditionInput | null,
};

export type ModelDirectionInput = {
  eq?: Direction | null,
  ne?: Direction | null,
};

export type ModelBodyPartsInput = {
  eq?: BodyParts | null,
  ne?: BodyParts | null,
};

export type AcuItem = {
  __typename: "AcuItem",
  id: string,
  clinicId?: string | null,
  direction: Direction,
  bodyParts: BodyParts,
  parts:  Array<AcuParts >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type AcuParts = {
  __typename: "AcuParts",
  id: string,
  x?: number | null,
  y?: number | null,
  name: string,
};

export type UpdateAcuItemInput = {
  id: string,
  clinicId?: string | null,
  direction?: Direction | null,
  bodyParts?: BodyParts | null,
  parts?: Array< AcuPartsInput > | null,
  _version?: number | null,
};

export type DeleteAcuItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateKarteTemplateInput = {
  id?: string | null,
  clinicId?: string | null,
  displayOrder: number,
  title: string,
  karteItemIds: Array< KarteItemIdInput >,
  _version?: number | null,
};

export type KarteItemIdInput = {
  id: string,
  karteItemId: string,
};

export type ModelKarteTemplateConditionInput = {
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteTemplateConditionInput | null > | null,
  or?: Array< ModelKarteTemplateConditionInput | null > | null,
  not?: ModelKarteTemplateConditionInput | null,
};

export type KarteTemplate = {
  __typename: "KarteTemplate",
  id: string,
  clinicId?: string | null,
  displayOrder: number,
  title: string,
  karteItemIds:  Array<KarteItemId >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type KarteItemId = {
  __typename: "KarteItemId",
  id: string,
  karteItemId: string,
};

export type UpdateKarteTemplateInput = {
  id: string,
  clinicId?: string | null,
  displayOrder?: number | null,
  title?: string | null,
  karteItemIds?: Array< KarteItemIdInput > | null,
  _version?: number | null,
};

export type DeleteKarteTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateKarteInput = {
  id?: string | null,
  clinicId?: string | null,
  accountId: string,
  accountName: string,
  karteNumber: string,
  patientId: string,
  karteDate: string,
  displayOrder: number,
  title: string,
  karteResults: Array< KarteResultInput >,
  _version?: number | null,
};

export type KarteResultInput = {
  id: string,
  karteItemId: string,
  title: string,
  kartePartsResult: Array< KartePartsResultInput >,
};

export type KartePartsResultInput = {
  id: string,
  partsName: PartsName,
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  text?: string | null,
  files?: Array< KarteFileTypeInput > | null,
  options?: Array< string > | null,
  choices?: Array< string > | null,
  direction?: Direction | null,
  bodyParts?: BodyParts | null,
  parts?: Array< AcuPartsInput > | null,
};

export type KarteFileTypeInput = {
  id: string,
  fileId: string,
  name: string,
  fileType: string,
  hash: string,
};

export type ModelKarteConditionInput = {
  accountId?: ModelStringInput | null,
  accountName?: ModelStringInput | null,
  karteNumber?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  karteDate?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteConditionInput | null > | null,
  or?: Array< ModelKarteConditionInput | null > | null,
  not?: ModelKarteConditionInput | null,
};

export type Karte = {
  __typename: "Karte",
  id: string,
  clinicId?: string | null,
  accountId: string,
  accountName: string,
  karteNumber: string,
  patientId: string,
  karteDate: string,
  displayOrder: number,
  title: string,
  karteResults:  Array<KarteResult >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type KarteResult = {
  __typename: "KarteResult",
  id: string,
  karteItemId: string,
  title: string,
  kartePartsResult:  Array<KartePartsResult >,
};

export type KartePartsResult = {
  __typename: "KartePartsResult",
  id: string,
  partsName: PartsName,
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  text?: string | null,
  files?:  Array<KarteFileType > | null,
  options?: Array< string > | null,
  choices?: Array< string > | null,
  direction?: Direction | null,
  bodyParts?: BodyParts | null,
  parts?:  Array<AcuParts > | null,
};

export type KarteFileType = {
  __typename: "KarteFileType",
  id: string,
  fileId: string,
  name: string,
  fileType: string,
  hash: string,
};

export type UpdateKarteInput = {
  id: string,
  clinicId?: string | null,
  accountId?: string | null,
  accountName?: string | null,
  karteNumber?: string | null,
  patientId?: string | null,
  karteDate?: string | null,
  displayOrder?: number | null,
  title?: string | null,
  karteResults?: Array< KarteResultInput > | null,
  _version?: number | null,
};

export type DeleteKarteInput = {
  id: string,
  _version?: number | null,
};

export type CreateKarteNumberInput = {
  id?: string | null,
  clinicId?: string | null,
  karteNumber: number,
  _version?: number | null,
};

export type ModelKarteNumberConditionInput = {
  karteNumber?: ModelIntInput | null,
  and?: Array< ModelKarteNumberConditionInput | null > | null,
  or?: Array< ModelKarteNumberConditionInput | null > | null,
  not?: ModelKarteNumberConditionInput | null,
};

export type KarteNumber = {
  __typename: "KarteNumber",
  id: string,
  clinicId?: string | null,
  karteNumber: number,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateKarteNumberInput = {
  id: string,
  clinicId?: string | null,
  karteNumber?: number | null,
  _version?: number | null,
};

export type DeleteKarteNumberInput = {
  id: string,
  _version?: number | null,
};

export type CreateFavoriteKarteInput = {
  id?: string | null,
  clinicId?: string | null,
  accountId: string,
  karteId: string,
  displayOrder: number,
  karteAccountId?: string | null,
  karteAccountName: string,
  karteNumber: string,
  patientId: string,
  karteDate: string,
  title: string,
  _version?: number | null,
};

export type ModelFavoriteKarteConditionInput = {
  accountId?: ModelStringInput | null,
  karteId?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  karteAccountId?: ModelStringInput | null,
  karteAccountName?: ModelStringInput | null,
  karteNumber?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  karteDate?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelFavoriteKarteConditionInput | null > | null,
  or?: Array< ModelFavoriteKarteConditionInput | null > | null,
  not?: ModelFavoriteKarteConditionInput | null,
};

export type FavoriteKarte = {
  __typename: "FavoriteKarte",
  id: string,
  clinicId?: string | null,
  accountId: string,
  karteId: string,
  displayOrder: number,
  karteAccountId?: string | null,
  karteAccountName: string,
  karteNumber: string,
  patientId: string,
  karteDate: string,
  title: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFavoriteKarteInput = {
  id: string,
  clinicId?: string | null,
  accountId?: string | null,
  karteId?: string | null,
  displayOrder?: number | null,
  karteAccountId?: string | null,
  karteAccountName?: string | null,
  karteNumber?: string | null,
  patientId?: string | null,
  karteDate?: string | null,
  title?: string | null,
  _version?: number | null,
};

export type DeleteFavoriteKarteInput = {
  id: string,
  _version?: number | null,
};

export type CreateInquiryItemInput = {
  id?: string | null,
  clinicId?: string | null,
  displayOrder: number,
  version: string,
  title: string,
  inquiryPages: Array< InquiryPageInput >,
  _version?: number | null,
};

export type InquiryPageInput = {
  id: string,
  pageTitle: string,
  inquiries: Array< InquiryPageItemInput >,
};

export type InquiryPageItemInput = {
  id: string,
  valueType: InquiryType,
  required: boolean,
  title: string,
  options?: Array< string > | null,
};

export enum InquiryType {
  text = "text",
  number = "number",
  numberArray = "numberArray",
  date = "date",
  time = "time",
  singleChoice = "singleChoice",
  multiChoice = "multiChoice",
}


export type ModelInquiryItemConditionInput = {
  displayOrder?: ModelIntInput | null,
  version?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelInquiryItemConditionInput | null > | null,
  or?: Array< ModelInquiryItemConditionInput | null > | null,
  not?: ModelInquiryItemConditionInput | null,
};

export type InquiryItem = {
  __typename: "InquiryItem",
  id: string,
  clinicId?: string | null,
  displayOrder: number,
  version: string,
  title: string,
  inquiryPages:  Array<InquiryPage >,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type InquiryPage = {
  __typename: "InquiryPage",
  id: string,
  pageTitle: string,
  inquiries:  Array<InquiryPageItem >,
};

export type InquiryPageItem = {
  __typename: "InquiryPageItem",
  id: string,
  valueType: InquiryType,
  required: boolean,
  title: string,
  options?: Array< string > | null,
};

export type UpdateInquiryItemInput = {
  id: string,
  clinicId?: string | null,
  displayOrder?: number | null,
  version?: string | null,
  title?: string | null,
  inquiryPages?: Array< InquiryPageInput > | null,
  _version?: number | null,
};

export type DeleteInquiryItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateSettingInput = {
  id?: string | null,
  clinicId?: string | null,
  calendarStartDay: WeekDayShort,
  appointmentTimeUnit: number,
  holidayAutoClose: boolean,
  holidayAutoCloseSat: boolean,
  holidayAutoCloseSun: boolean,
  holidayAutoCloseSub: boolean,
  _version?: number | null,
};

export enum WeekDayShort {
  sun = "sun",
  mon = "mon",
  tue = "tue",
  wed = "wed",
  thu = "thu",
  fri = "fri",
  sat = "sat",
}


export type ModelSettingConditionInput = {
  calendarStartDay?: ModelWeekDayShortInput | null,
  appointmentTimeUnit?: ModelIntInput | null,
  holidayAutoClose?: ModelBooleanInput | null,
  holidayAutoCloseSat?: ModelBooleanInput | null,
  holidayAutoCloseSun?: ModelBooleanInput | null,
  holidayAutoCloseSub?: ModelBooleanInput | null,
  and?: Array< ModelSettingConditionInput | null > | null,
  or?: Array< ModelSettingConditionInput | null > | null,
  not?: ModelSettingConditionInput | null,
};

export type ModelWeekDayShortInput = {
  eq?: WeekDayShort | null,
  ne?: WeekDayShort | null,
};

export type Setting = {
  __typename: "Setting",
  id: string,
  clinicId?: string | null,
  calendarStartDay: WeekDayShort,
  appointmentTimeUnit: number,
  holidayAutoClose: boolean,
  holidayAutoCloseSat: boolean,
  holidayAutoCloseSun: boolean,
  holidayAutoCloseSub: boolean,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSettingInput = {
  id: string,
  clinicId?: string | null,
  calendarStartDay?: WeekDayShort | null,
  appointmentTimeUnit?: number | null,
  holidayAutoClose?: boolean | null,
  holidayAutoCloseSat?: boolean | null,
  holidayAutoCloseSun?: boolean | null,
  holidayAutoCloseSub?: boolean | null,
  _version?: number | null,
};

export type DeleteSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateFileHashInput = {
  id?: string | null,
  clinicId?: string | null,
  filename: string,
  _version?: number | null,
};

export type ModelFileHashConditionInput = {
  filename?: ModelStringInput | null,
  and?: Array< ModelFileHashConditionInput | null > | null,
  or?: Array< ModelFileHashConditionInput | null > | null,
  not?: ModelFileHashConditionInput | null,
};

export type FileHash = {
  __typename: "FileHash",
  id: string,
  clinicId?: string | null,
  filename: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFileHashInput = {
  id: string,
  clinicId?: string | null,
  filename?: string | null,
  _version?: number | null,
};

export type DeleteFileHashInput = {
  id: string,
  _version?: number | null,
};

export type ClinicListData = {
  __typename: "ClinicListData",
  clinicId: string,
  name: string,
  owner: boolean,
  invitation: boolean,
};

export type ListAccountResult = {
  __typename: "ListAccountResult",
  items:  Array<AccountClinic >,
  nextToken?: string | null,
};

export type ListAccountInvitationResult = {
  __typename: "ListAccountInvitationResult",
  items:  Array<AccountInvitation >,
  nextToken?: string | null,
};

export type AccountInvitation = {
  __typename: "AccountInvitation",
  id: string,
  email: string,
  ttl: number,
  permissions: Array< Permission >,
  acceptAppointment: boolean,
};

export type ListAccountAppointmentResult = {
  __typename: "ListAccountAppointmentResult",
  items:  Array<AccountAppointment | null >,
  nextToken?: string | null,
};

export type AccountAppointment = {
  __typename: "AccountAppointment",
  userId: string,
  name: string,
};

export type InquiryAnswerFilterInput = {
  inquiryId?: string | null,
  answerStatus?: AnswerStatus | null,
};

export type ListInquiryAnswerResult = {
  __typename: "ListInquiryAnswerResult",
  items:  Array<InquiryAnswer >,
  nextToken?: string | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  patientNumber?: ModelStringInput | null,
  name?: ModelStringInput | null,
  read?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  birthDate?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  display?: ModelDisplayInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items?:  Array<Patient | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemporaryPatientFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phoneNumber?: ModelStringInput | null,
  and?: Array< ModelTemporaryPatientFilterInput | null > | null,
  or?: Array< ModelTemporaryPatientFilterInput | null > | null,
  not?: ModelTemporaryPatientFilterInput | null,
};

export type ModelTemporaryPatientConnection = {
  __typename: "ModelTemporaryPatientConnection",
  items?:  Array<TemporaryPatient | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPatientNumberFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  patientNumber?: ModelIntInput | null,
  and?: Array< ModelPatientNumberFilterInput | null > | null,
  or?: Array< ModelPatientNumberFilterInput | null > | null,
  not?: ModelPatientNumberFilterInput | null,
};

export type ModelPatientNumberConnection = {
  __typename: "ModelPatientNumberConnection",
  items?:  Array<PatientNumber | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBusinessHourFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  originDate?: ModelStringInput | null,
  intervalType?: ModelIntervalTypeInput | null,
  interval?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  and?: Array< ModelBusinessHourFilterInput | null > | null,
  or?: Array< ModelBusinessHourFilterInput | null > | null,
  not?: ModelBusinessHourFilterInput | null,
};

export type ModelBusinessHourConnection = {
  __typename: "ModelBusinessHourConnection",
  items?:  Array<BusinessHour | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemporaryCloseFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  businessHourId?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelTemporaryCloseFilterInput | null > | null,
  or?: Array< ModelTemporaryCloseFilterInput | null > | null,
  not?: ModelTemporaryCloseFilterInput | null,
};

export type ModelTemporaryCloseConnection = {
  __typename: "ModelTemporaryCloseConnection",
  items?:  Array<TemporaryClose | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAppointmentFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  temporaryPatient?: ModelBooleanInput | null,
  accountId?: ModelStringInput | null,
  date?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  appointmentStatus?: ModelAppointmentStatusInput | null,
  and?: Array< ModelAppointmentFilterInput | null > | null,
  or?: Array< ModelAppointmentFilterInput | null > | null,
  not?: ModelAppointmentFilterInput | null,
};

export type ModelAppointmentConnection = {
  __typename: "ModelAppointmentConnection",
  items?:  Array<Appointment | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelKarteItemFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteItemFilterInput | null > | null,
  or?: Array< ModelKarteItemFilterInput | null > | null,
  not?: ModelKarteItemFilterInput | null,
};

export type ModelKarteItemConnection = {
  __typename: "ModelKarteItemConnection",
  items?:  Array<KarteItem | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAcuItemFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  direction?: ModelDirectionInput | null,
  bodyParts?: ModelBodyPartsInput | null,
  and?: Array< ModelAcuItemFilterInput | null > | null,
  or?: Array< ModelAcuItemFilterInput | null > | null,
  not?: ModelAcuItemFilterInput | null,
};

export type ModelAcuItemConnection = {
  __typename: "ModelAcuItemConnection",
  items?:  Array<AcuItem | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelKarteTemplateFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteTemplateFilterInput | null > | null,
  or?: Array< ModelKarteTemplateFilterInput | null > | null,
  not?: ModelKarteTemplateFilterInput | null,
};

export type ModelKarteTemplateConnection = {
  __typename: "ModelKarteTemplateConnection",
  items?:  Array<KarteTemplate | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelKarteFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  accountName?: ModelStringInput | null,
  karteNumber?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  karteDate?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelKarteFilterInput | null > | null,
  or?: Array< ModelKarteFilterInput | null > | null,
  not?: ModelKarteFilterInput | null,
};

export type ModelKarteConnection = {
  __typename: "ModelKarteConnection",
  items?:  Array<Karte | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelKarteNumberFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  karteNumber?: ModelIntInput | null,
  and?: Array< ModelKarteNumberFilterInput | null > | null,
  or?: Array< ModelKarteNumberFilterInput | null > | null,
  not?: ModelKarteNumberFilterInput | null,
};

export type ModelKarteNumberConnection = {
  __typename: "ModelKarteNumberConnection",
  items?:  Array<KarteNumber | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFavoriteKarteFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  accountId?: ModelStringInput | null,
  karteId?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  karteAccountId?: ModelStringInput | null,
  karteAccountName?: ModelStringInput | null,
  karteNumber?: ModelStringInput | null,
  patientId?: ModelStringInput | null,
  karteDate?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelFavoriteKarteFilterInput | null > | null,
  or?: Array< ModelFavoriteKarteFilterInput | null > | null,
  not?: ModelFavoriteKarteFilterInput | null,
};

export type ModelFavoriteKarteConnection = {
  __typename: "ModelFavoriteKarteConnection",
  items?:  Array<FavoriteKarte | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInquiryItemFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  displayOrder?: ModelIntInput | null,
  version?: ModelStringInput | null,
  title?: ModelStringInput | null,
  and?: Array< ModelInquiryItemFilterInput | null > | null,
  or?: Array< ModelInquiryItemFilterInput | null > | null,
  not?: ModelInquiryItemFilterInput | null,
};

export type ModelInquiryItemConnection = {
  __typename: "ModelInquiryItemConnection",
  items?:  Array<InquiryItem | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSettingFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  calendarStartDay?: ModelWeekDayShortInput | null,
  appointmentTimeUnit?: ModelIntInput | null,
  holidayAutoClose?: ModelBooleanInput | null,
  holidayAutoCloseSat?: ModelBooleanInput | null,
  holidayAutoCloseSun?: ModelBooleanInput | null,
  holidayAutoCloseSub?: ModelBooleanInput | null,
  and?: Array< ModelSettingFilterInput | null > | null,
  or?: Array< ModelSettingFilterInput | null > | null,
  not?: ModelSettingFilterInput | null,
};

export type ModelSettingConnection = {
  __typename: "ModelSettingConnection",
  items?:  Array<Setting | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFileHashFilterInput = {
  id?: ModelIDInput | null,
  clinicId?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  and?: Array< ModelFileHashFilterInput | null > | null,
  or?: Array< ModelFileHashFilterInput | null > | null,
  not?: ModelFileHashFilterInput | null,
};

export type ModelFileHashConnection = {
  __typename: "ModelFileHashConnection",
  items?:  Array<FileHash | null > | null,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type Holiday = {
  __typename: "Holiday",
  date: string,
  name: string,
  substitute: boolean,
};

export type Zip = {
  __typename: "Zip",
  id: string,
  zipCode: string,
  pref: string,
  city: string,
  address: string,
};

export type CreateClinicMutationVariables = {
  name: string,
  workSpace: string,
};

export type CreateClinicMutation = {
  createClinic?:  {
    __typename: "Clinic",
    id: string,
    name: string,
    owner: string,
  } | null,
};

export type EnterClinicMutationVariables = {
  clinicId: string,
};

export type EnterClinicMutation = {
  enterClinic: boolean,
};

export type ExitClinicMutation = {
  exitClinic: boolean,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
};

export type UpdateAccountMutation = {
  updateAccount:  {
    __typename: "AccountClinic",
    id: string,
    name: string,
    permissions: Array< Permission >,
    acceptAppointment: boolean,
    myself: boolean,
  },
};

export type CreateAccountInvitationMutationVariables = {
  input: CreateAccountInvitationInput,
};

export type CreateAccountInvitationMutation = {
  createAccountInvitation: boolean,
};

export type DeleteAccountInvitationMutationVariables = {
  id: string,
};

export type DeleteAccountInvitationMutation = {
  deleteAccountInvitation: boolean,
};

export type UpdateInquiryStatusMutationVariables = {
  id: string,
  answerStatus: AnswerStatus,
};

export type UpdateInquiryStatusMutation = {
  updateInquiryStatus:  {
    __typename: "InquiryAnswer",
    id: string,
    clinicId: string,
    owner: string,
    inquiryId: string,
    patientId?: string | null,
    answer: string,
    answerStatus: AnswerStatus,
    createdAt: string,
  },
};

export type UploadFileMutationVariables = {
  input: UploadFileInput,
};

export type UploadFileMutation = {
  uploadFile: boolean,
};

export type DownloadFileMutationVariables = {
  id: string,
};

export type DownloadFileMutation = {
  downloadFile?:  {
    __typename: "DownloadFile",
    id: string,
    name: string,
    fileType: string,
    base64: string,
    hash: string,
  } | null,
};

export type DeleteFileMutationVariables = {
  id: string,
};

export type DeleteFileMutation = {
  deleteFile: boolean,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTemporaryPatientMutationVariables = {
  input: CreateTemporaryPatientInput,
  condition?: ModelTemporaryPatientConditionInput | null,
};

export type CreateTemporaryPatientMutation = {
  createTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTemporaryPatientMutationVariables = {
  input: UpdateTemporaryPatientInput,
  condition?: ModelTemporaryPatientConditionInput | null,
};

export type UpdateTemporaryPatientMutation = {
  updateTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTemporaryPatientMutationVariables = {
  input: DeleteTemporaryPatientInput,
  condition?: ModelTemporaryPatientConditionInput | null,
};

export type DeleteTemporaryPatientMutation = {
  deleteTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePatientNumberMutationVariables = {
  input: CreatePatientNumberInput,
  condition?: ModelPatientNumberConditionInput | null,
};

export type CreatePatientNumberMutation = {
  createPatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientNumberMutationVariables = {
  input: UpdatePatientNumberInput,
  condition?: ModelPatientNumberConditionInput | null,
};

export type UpdatePatientNumberMutation = {
  updatePatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientNumberMutationVariables = {
  input: DeletePatientNumberInput,
  condition?: ModelPatientNumberConditionInput | null,
};

export type DeletePatientNumberMutation = {
  deletePatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBusinessHourMutationVariables = {
  input: CreateBusinessHourInput,
  condition?: ModelBusinessHourConditionInput | null,
};

export type CreateBusinessHourMutation = {
  createBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessHourMutationVariables = {
  input: UpdateBusinessHourInput,
  condition?: ModelBusinessHourConditionInput | null,
};

export type UpdateBusinessHourMutation = {
  updateBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessHourMutationVariables = {
  input: DeleteBusinessHourInput,
  condition?: ModelBusinessHourConditionInput | null,
};

export type DeleteBusinessHourMutation = {
  deleteBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTemporaryCloseMutationVariables = {
  input: CreateTemporaryCloseInput,
  condition?: ModelTemporaryCloseConditionInput | null,
};

export type CreateTemporaryCloseMutation = {
  createTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTemporaryCloseMutationVariables = {
  input: UpdateTemporaryCloseInput,
  condition?: ModelTemporaryCloseConditionInput | null,
};

export type UpdateTemporaryCloseMutation = {
  updateTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTemporaryCloseMutationVariables = {
  input: DeleteTemporaryCloseInput,
  condition?: ModelTemporaryCloseConditionInput | null,
};

export type DeleteTemporaryCloseMutation = {
  deleteTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAppointmentMutationVariables = {
  input: CreateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type CreateAppointmentMutation = {
  createAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppointmentMutationVariables = {
  input: UpdateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type UpdateAppointmentMutation = {
  updateAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppointmentMutationVariables = {
  input: DeleteAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type DeleteAppointmentMutation = {
  deleteAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKarteItemMutationVariables = {
  input: CreateKarteItemInput,
  condition?: ModelKarteItemConditionInput | null,
};

export type CreateKarteItemMutation = {
  createKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKarteItemMutationVariables = {
  input: UpdateKarteItemInput,
  condition?: ModelKarteItemConditionInput | null,
};

export type UpdateKarteItemMutation = {
  updateKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKarteItemMutationVariables = {
  input: DeleteKarteItemInput,
  condition?: ModelKarteItemConditionInput | null,
};

export type DeleteKarteItemMutation = {
  deleteKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAcuItemMutationVariables = {
  input: CreateAcuItemInput,
  condition?: ModelAcuItemConditionInput | null,
};

export type CreateAcuItemMutation = {
  createAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAcuItemMutationVariables = {
  input: UpdateAcuItemInput,
  condition?: ModelAcuItemConditionInput | null,
};

export type UpdateAcuItemMutation = {
  updateAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAcuItemMutationVariables = {
  input: DeleteAcuItemInput,
  condition?: ModelAcuItemConditionInput | null,
};

export type DeleteAcuItemMutation = {
  deleteAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKarteTemplateMutationVariables = {
  input: CreateKarteTemplateInput,
  condition?: ModelKarteTemplateConditionInput | null,
};

export type CreateKarteTemplateMutation = {
  createKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKarteTemplateMutationVariables = {
  input: UpdateKarteTemplateInput,
  condition?: ModelKarteTemplateConditionInput | null,
};

export type UpdateKarteTemplateMutation = {
  updateKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKarteTemplateMutationVariables = {
  input: DeleteKarteTemplateInput,
  condition?: ModelKarteTemplateConditionInput | null,
};

export type DeleteKarteTemplateMutation = {
  deleteKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKarteMutationVariables = {
  input: CreateKarteInput,
  condition?: ModelKarteConditionInput | null,
};

export type CreateKarteMutation = {
  createKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKarteMutationVariables = {
  input: UpdateKarteInput,
  condition?: ModelKarteConditionInput | null,
};

export type UpdateKarteMutation = {
  updateKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKarteMutationVariables = {
  input: DeleteKarteInput,
  condition?: ModelKarteConditionInput | null,
};

export type DeleteKarteMutation = {
  deleteKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateKarteNumberMutationVariables = {
  input: CreateKarteNumberInput,
  condition?: ModelKarteNumberConditionInput | null,
};

export type CreateKarteNumberMutation = {
  createKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateKarteNumberMutationVariables = {
  input: UpdateKarteNumberInput,
  condition?: ModelKarteNumberConditionInput | null,
};

export type UpdateKarteNumberMutation = {
  updateKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteKarteNumberMutationVariables = {
  input: DeleteKarteNumberInput,
  condition?: ModelKarteNumberConditionInput | null,
};

export type DeleteKarteNumberMutation = {
  deleteKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFavoriteKarteMutationVariables = {
  input: CreateFavoriteKarteInput,
  condition?: ModelFavoriteKarteConditionInput | null,
};

export type CreateFavoriteKarteMutation = {
  createFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFavoriteKarteMutationVariables = {
  input: UpdateFavoriteKarteInput,
  condition?: ModelFavoriteKarteConditionInput | null,
};

export type UpdateFavoriteKarteMutation = {
  updateFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFavoriteKarteMutationVariables = {
  input: DeleteFavoriteKarteInput,
  condition?: ModelFavoriteKarteConditionInput | null,
};

export type DeleteFavoriteKarteMutation = {
  deleteFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInquiryItemMutationVariables = {
  input: CreateInquiryItemInput,
  condition?: ModelInquiryItemConditionInput | null,
};

export type CreateInquiryItemMutation = {
  createInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInquiryItemMutationVariables = {
  input: UpdateInquiryItemInput,
  condition?: ModelInquiryItemConditionInput | null,
};

export type UpdateInquiryItemMutation = {
  updateInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInquiryItemMutationVariables = {
  input: DeleteInquiryItemInput,
  condition?: ModelInquiryItemConditionInput | null,
};

export type DeleteInquiryItemMutation = {
  deleteInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSettingMutationVariables = {
  input: CreateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type CreateSettingMutation = {
  createSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSettingMutationVariables = {
  input: UpdateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type UpdateSettingMutation = {
  updateSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSettingMutationVariables = {
  input: DeleteSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type DeleteSettingMutation = {
  deleteSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFileHashMutationVariables = {
  input: CreateFileHashInput,
  condition?: ModelFileHashConditionInput | null,
};

export type CreateFileHashMutation = {
  createFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFileHashMutationVariables = {
  input: UpdateFileHashInput,
  condition?: ModelFileHashConditionInput | null,
};

export type UpdateFileHashMutation = {
  updateFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFileHashMutationVariables = {
  input: DeleteFileHashInput,
  condition?: ModelFileHashConditionInput | null,
};

export type DeleteFileHashMutation = {
  deleteFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type AnswerInquiryMutationVariables = {
  answer: string,
};

export type AnswerInquiryMutation = {
  answerInquiry:  {
    __typename: "InquiryAnswer",
    id: string,
    clinicId: string,
    owner: string,
    inquiryId: string,
    patientId?: string | null,
    answer: string,
    answerStatus: AnswerStatus,
    createdAt: string,
  },
};

export type GetClinicQuery = {
  getClinic?:  {
    __typename: "ClinicListData",
    clinicId: string,
    name: string,
    owner: boolean,
    invitation: boolean,
  } | null,
};

export type ListAccountClinicsQuery = {
  listAccountClinics:  Array< {
    __typename: "ClinicListData",
    clinicId: string,
    name: string,
    owner: boolean,
    invitation: boolean,
  } >,
};

export type ListAccountQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountQuery = {
  listAccount?:  {
    __typename: "ListAccountResult",
    items:  Array< {
      __typename: "AccountClinic",
      id: string,
      name: string,
      permissions: Array< Permission >,
      acceptAppointment: boolean,
      myself: boolean,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListAccountInvitationQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountInvitationQuery = {
  listAccountInvitation?:  {
    __typename: "ListAccountInvitationResult",
    items:  Array< {
      __typename: "AccountInvitation",
      id: string,
      email: string,
      ttl: number,
      permissions: Array< Permission >,
      acceptAppointment: boolean,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "AccountClinic",
    id: string,
    name: string,
    permissions: Array< Permission >,
    acceptAppointment: boolean,
    myself: boolean,
  } | null,
};

export type ListAccountAppointmentQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountAppointmentQuery = {
  listAccountAppointment?:  {
    __typename: "ListAccountAppointmentResult",
    items:  Array< {
      __typename: "AccountAppointment",
      userId: string,
      name: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAcceptAppointmentQuery = {
  getAcceptAppointment: boolean,
};

export type OneTimeTokenQueryVariables = {
  inquiryId: string,
};

export type OneTimeTokenQuery = {
  oneTimeToken: string,
};

export type ListInquiryAnswerQueryVariables = {
  filter?: InquiryAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryAnswerQuery = {
  listInquiryAnswer?:  {
    __typename: "ListInquiryAnswerResult",
    items:  Array< {
      __typename: "InquiryAnswer",
      id: string,
      clinicId: string,
      owner: string,
      inquiryId: string,
      patientId?: string | null,
      answer: string,
      answerStatus: AnswerStatus,
      createdAt: string,
    } >,
    nextToken?: string | null,
  } | null,
};

export type SyncPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientsQuery = {
  syncPatients?:  {
    __typename: "ModelPatientConnection",
    items?:  Array< {
      __typename: "Patient",
      id: string,
      clinicId?: string | null,
      patientNumber: string,
      name: string,
      read: string,
      gender: Gender,
      birthDate: string,
      private:  {
        __typename: "PatientPrivate",
        zipCode?: string | null,
        pref?: string | null,
        city?: string | null,
        address?: string | null,
        phoneNumber?: Array< string > | null,
      },
      comment?: string | null,
      display: Display,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items?:  Array< {
      __typename: "Patient",
      id: string,
      clinicId?: string | null,
      patientNumber: string,
      name: string,
      read: string,
      gender: Gender,
      birthDate: string,
      private:  {
        __typename: "PatientPrivate",
        zipCode?: string | null,
        pref?: string | null,
        city?: string | null,
        address?: string | null,
        phoneNumber?: Array< string > | null,
      },
      comment?: string | null,
      display: Display,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemporaryPatientsQueryVariables = {
  filter?: ModelTemporaryPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemporaryPatientsQuery = {
  syncTemporaryPatients?:  {
    __typename: "ModelTemporaryPatientConnection",
    items?:  Array< {
      __typename: "TemporaryPatient",
      id: string,
      clinicId?: string | null,
      name: string,
      gender: Gender,
      phoneNumber?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemporaryPatientQueryVariables = {
  id: string,
};

export type GetTemporaryPatientQuery = {
  getTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTemporaryPatientsQueryVariables = {
  filter?: ModelTemporaryPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemporaryPatientsQuery = {
  listTemporaryPatients?:  {
    __typename: "ModelTemporaryPatientConnection",
    items?:  Array< {
      __typename: "TemporaryPatient",
      id: string,
      clinicId?: string | null,
      name: string,
      gender: Gender,
      phoneNumber?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPatientNumbersQueryVariables = {
  filter?: ModelPatientNumberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientNumbersQuery = {
  syncPatientNumbers?:  {
    __typename: "ModelPatientNumberConnection",
    items?:  Array< {
      __typename: "PatientNumber",
      id: string,
      clinicId?: string | null,
      patientNumber: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientNumberQueryVariables = {
  id: string,
};

export type GetPatientNumberQuery = {
  getPatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientNumbersQueryVariables = {
  filter?: ModelPatientNumberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientNumbersQuery = {
  listPatientNumbers?:  {
    __typename: "ModelPatientNumberConnection",
    items?:  Array< {
      __typename: "PatientNumber",
      id: string,
      clinicId?: string | null,
      patientNumber: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBusinessHoursQueryVariables = {
  filter?: ModelBusinessHourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBusinessHoursQuery = {
  syncBusinessHours?:  {
    __typename: "ModelBusinessHourConnection",
    items?:  Array< {
      __typename: "BusinessHour",
      id: string,
      clinicId?: string | null,
      userId: string,
      originDate: string,
      intervalType: IntervalType,
      interval: number,
      startTime: string,
      endTime: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBusinessHourQueryVariables = {
  id: string,
};

export type GetBusinessHourQuery = {
  getBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessHoursQueryVariables = {
  filter?: ModelBusinessHourFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinessHoursQuery = {
  listBusinessHours?:  {
    __typename: "ModelBusinessHourConnection",
    items?:  Array< {
      __typename: "BusinessHour",
      id: string,
      clinicId?: string | null,
      userId: string,
      originDate: string,
      intervalType: IntervalType,
      interval: number,
      startTime: string,
      endTime: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemporaryClosesQueryVariables = {
  filter?: ModelTemporaryCloseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemporaryClosesQuery = {
  syncTemporaryCloses?:  {
    __typename: "ModelTemporaryCloseConnection",
    items?:  Array< {
      __typename: "TemporaryClose",
      id: string,
      clinicId?: string | null,
      businessHourId: string,
      date: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemporaryCloseQueryVariables = {
  id: string,
};

export type GetTemporaryCloseQuery = {
  getTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTemporaryClosesQueryVariables = {
  filter?: ModelTemporaryCloseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemporaryClosesQuery = {
  listTemporaryCloses?:  {
    __typename: "ModelTemporaryCloseConnection",
    items?:  Array< {
      __typename: "TemporaryClose",
      id: string,
      clinicId?: string | null,
      businessHourId: string,
      date: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAppointmentsQueryVariables = {
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAppointmentsQuery = {
  syncAppointments?:  {
    __typename: "ModelAppointmentConnection",
    items?:  Array< {
      __typename: "Appointment",
      id: string,
      clinicId?: string | null,
      patientId: string,
      temporaryPatient: boolean,
      accountId: string,
      date: string,
      startTime: string,
      endTime: string,
      appointmentStatus: AppointmentStatus,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAppointmentQueryVariables = {
  id: string,
};

export type GetAppointmentQuery = {
  getAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppointmentsQueryVariables = {
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsQuery = {
  listAppointments?:  {
    __typename: "ModelAppointmentConnection",
    items?:  Array< {
      __typename: "Appointment",
      id: string,
      clinicId?: string | null,
      patientId: string,
      temporaryPatient: boolean,
      accountId: string,
      date: string,
      startTime: string,
      endTime: string,
      appointmentStatus: AppointmentStatus,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncKarteItemsQueryVariables = {
  filter?: ModelKarteItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncKarteItemsQuery = {
  syncKarteItems?:  {
    __typename: "ModelKarteItemConnection",
    items?:  Array< {
      __typename: "KarteItem",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      title: string,
      karteParts:  Array< {
        __typename: "KarteParts",
        id: string,
        partsName: PartsName,
        title?: string | null,
        options?: Array< string > | null,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetKarteItemQueryVariables = {
  id: string,
};

export type GetKarteItemQuery = {
  getKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKarteItemsQueryVariables = {
  filter?: ModelKarteItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKarteItemsQuery = {
  listKarteItems?:  {
    __typename: "ModelKarteItemConnection",
    items?:  Array< {
      __typename: "KarteItem",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      title: string,
      karteParts:  Array< {
        __typename: "KarteParts",
        id: string,
        partsName: PartsName,
        title?: string | null,
        options?: Array< string > | null,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAcuItemsQueryVariables = {
  filter?: ModelAcuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAcuItemsQuery = {
  syncAcuItems?:  {
    __typename: "ModelAcuItemConnection",
    items?:  Array< {
      __typename: "AcuItem",
      id: string,
      clinicId?: string | null,
      direction: Direction,
      bodyParts: BodyParts,
      parts:  Array< {
        __typename: "AcuParts",
        id: string,
        x?: number | null,
        y?: number | null,
        name: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAcuItemQueryVariables = {
  id: string,
};

export type GetAcuItemQuery = {
  getAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAcuItemsQueryVariables = {
  filter?: ModelAcuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAcuItemsQuery = {
  listAcuItems?:  {
    __typename: "ModelAcuItemConnection",
    items?:  Array< {
      __typename: "AcuItem",
      id: string,
      clinicId?: string | null,
      direction: Direction,
      bodyParts: BodyParts,
      parts:  Array< {
        __typename: "AcuParts",
        id: string,
        x?: number | null,
        y?: number | null,
        name: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncKarteTemplatesQueryVariables = {
  filter?: ModelKarteTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncKarteTemplatesQuery = {
  syncKarteTemplates?:  {
    __typename: "ModelKarteTemplateConnection",
    items?:  Array< {
      __typename: "KarteTemplate",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      title: string,
      karteItemIds:  Array< {
        __typename: "KarteItemId",
        id: string,
        karteItemId: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetKarteTemplateQueryVariables = {
  id: string,
};

export type GetKarteTemplateQuery = {
  getKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKarteTemplatesQueryVariables = {
  filter?: ModelKarteTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKarteTemplatesQuery = {
  listKarteTemplates?:  {
    __typename: "ModelKarteTemplateConnection",
    items?:  Array< {
      __typename: "KarteTemplate",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      title: string,
      karteItemIds:  Array< {
        __typename: "KarteItemId",
        id: string,
        karteItemId: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncKartesQueryVariables = {
  filter?: ModelKarteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncKartesQuery = {
  syncKartes?:  {
    __typename: "ModelKarteConnection",
    items?:  Array< {
      __typename: "Karte",
      id: string,
      clinicId?: string | null,
      accountId: string,
      accountName: string,
      karteNumber: string,
      patientId: string,
      karteDate: string,
      displayOrder: number,
      title: string,
      karteResults:  Array< {
        __typename: "KarteResult",
        id: string,
        karteItemId: string,
        title: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetKarteQueryVariables = {
  id: string,
};

export type GetKarteQuery = {
  getKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKartesQueryVariables = {
  filter?: ModelKarteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKartesQuery = {
  listKartes?:  {
    __typename: "ModelKarteConnection",
    items?:  Array< {
      __typename: "Karte",
      id: string,
      clinicId?: string | null,
      accountId: string,
      accountName: string,
      karteNumber: string,
      patientId: string,
      karteDate: string,
      displayOrder: number,
      title: string,
      karteResults:  Array< {
        __typename: "KarteResult",
        id: string,
        karteItemId: string,
        title: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncKarteNumbersQueryVariables = {
  filter?: ModelKarteNumberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncKarteNumbersQuery = {
  syncKarteNumbers?:  {
    __typename: "ModelKarteNumberConnection",
    items?:  Array< {
      __typename: "KarteNumber",
      id: string,
      clinicId?: string | null,
      karteNumber: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetKarteNumberQueryVariables = {
  id: string,
};

export type GetKarteNumberQuery = {
  getKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListKarteNumbersQueryVariables = {
  filter?: ModelKarteNumberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKarteNumbersQuery = {
  listKarteNumbers?:  {
    __typename: "ModelKarteNumberConnection",
    items?:  Array< {
      __typename: "KarteNumber",
      id: string,
      clinicId?: string | null,
      karteNumber: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFavoriteKartesQueryVariables = {
  filter?: ModelFavoriteKarteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFavoriteKartesQuery = {
  syncFavoriteKartes?:  {
    __typename: "ModelFavoriteKarteConnection",
    items?:  Array< {
      __typename: "FavoriteKarte",
      id: string,
      clinicId?: string | null,
      accountId: string,
      karteId: string,
      displayOrder: number,
      karteAccountId?: string | null,
      karteAccountName: string,
      karteNumber: string,
      patientId: string,
      karteDate: string,
      title: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFavoriteKarteQueryVariables = {
  id: string,
};

export type GetFavoriteKarteQuery = {
  getFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFavoriteKartesQueryVariables = {
  filter?: ModelFavoriteKarteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteKartesQuery = {
  listFavoriteKartes?:  {
    __typename: "ModelFavoriteKarteConnection",
    items?:  Array< {
      __typename: "FavoriteKarte",
      id: string,
      clinicId?: string | null,
      accountId: string,
      karteId: string,
      displayOrder: number,
      karteAccountId?: string | null,
      karteAccountName: string,
      karteNumber: string,
      patientId: string,
      karteDate: string,
      title: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInquiryItemsQueryVariables = {
  filter?: ModelInquiryItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInquiryItemsQuery = {
  syncInquiryItems?:  {
    __typename: "ModelInquiryItemConnection",
    items?:  Array< {
      __typename: "InquiryItem",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      version: string,
      title: string,
      inquiryPages:  Array< {
        __typename: "InquiryPage",
        id: string,
        pageTitle: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInquiryItemQueryVariables = {
  id: string,
};

export type GetInquiryItemQuery = {
  getInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInquiryItemsQueryVariables = {
  filter?: ModelInquiryItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInquiryItemsQuery = {
  listInquiryItems?:  {
    __typename: "ModelInquiryItemConnection",
    items?:  Array< {
      __typename: "InquiryItem",
      id: string,
      clinicId?: string | null,
      displayOrder: number,
      version: string,
      title: string,
      inquiryPages:  Array< {
        __typename: "InquiryPage",
        id: string,
        pageTitle: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSettingsQuery = {
  syncSettings?:  {
    __typename: "ModelSettingConnection",
    items?:  Array< {
      __typename: "Setting",
      id: string,
      clinicId?: string | null,
      calendarStartDay: WeekDayShort,
      appointmentTimeUnit: number,
      holidayAutoClose: boolean,
      holidayAutoCloseSat: boolean,
      holidayAutoCloseSun: boolean,
      holidayAutoCloseSub: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSettingQueryVariables = {
  id: string,
};

export type GetSettingQuery = {
  getSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSettingsQuery = {
  listSettings?:  {
    __typename: "ModelSettingConnection",
    items?:  Array< {
      __typename: "Setting",
      id: string,
      clinicId?: string | null,
      calendarStartDay: WeekDayShort,
      appointmentTimeUnit: number,
      holidayAutoClose: boolean,
      holidayAutoCloseSat: boolean,
      holidayAutoCloseSun: boolean,
      holidayAutoCloseSub: boolean,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFileHashesQueryVariables = {
  filter?: ModelFileHashFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFileHashesQuery = {
  syncFileHashes?:  {
    __typename: "ModelFileHashConnection",
    items?:  Array< {
      __typename: "FileHash",
      id: string,
      clinicId?: string | null,
      filename: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFileHashQueryVariables = {
  id: string,
};

export type GetFileHashQuery = {
  getFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFileHashsQueryVariables = {
  filter?: ModelFileHashFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFileHashsQuery = {
  listFileHashs?:  {
    __typename: "ModelFileHashConnection",
    items?:  Array< {
      __typename: "FileHash",
      id: string,
      clinicId?: string | null,
      filename: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListKarteByOrderQueryVariables = {
  patientId?: string | null,
  displayOrder?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelKarteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKarteByOrderQuery = {
  listKarteByOrder?:  {
    __typename: "ModelKarteConnection",
    items?:  Array< {
      __typename: "Karte",
      id: string,
      clinicId?: string | null,
      accountId: string,
      accountName: string,
      karteNumber: string,
      patientId: string,
      karteDate: string,
      displayOrder: number,
      title: string,
      karteResults:  Array< {
        __typename: "KarteResult",
        id: string,
        karteItemId: string,
        title: string,
      } >,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInquiryQueryVariables = {
  inquiryId: string,
};

export type GetInquiryQuery = {
  getInquiry: string,
};

export type ListHolidayQuery = {
  listHoliday:  Array< {
    __typename: "Holiday",
    date: string,
    name: string,
    substitute: boolean,
  } >,
};

export type ListZipQueryVariables = {
  zipCode: string,
};

export type ListZipQuery = {
  listZip:  Array< {
    __typename: "Zip",
    id: string,
    zipCode: string,
    pref: string,
    city: string,
    address: string,
  } >,
};

export type OnAnswerInquirySubscriptionVariables = {
  clinicId: string,
};

export type OnAnswerInquirySubscription = {
  onAnswerInquiry?:  {
    __typename: "InquiryAnswer",
    id: string,
    clinicId: string,
    owner: string,
    inquiryId: string,
    patientId?: string | null,
    answer: string,
    answerStatus: AnswerStatus,
    createdAt: string,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  id: string,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "AccountClinic",
    id: string,
    name: string,
    permissions: Array< Permission >,
    acceptAppointment: boolean,
    myself: boolean,
  } | null,
};

export type OnCreatePatientSubscriptionVariables = {
  clinicId: string,
};

export type OnCreatePatientSubscription = {
  onCreatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePatientSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdatePatientSubscription = {
  onUpdatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePatientSubscriptionVariables = {
  clinicId: string,
};

export type OnDeletePatientSubscription = {
  onDeletePatient?:  {
    __typename: "Patient",
    id: string,
    clinicId?: string | null,
    patientNumber: string,
    name: string,
    read: string,
    gender: Gender,
    birthDate: string,
    private:  {
      __typename: "PatientPrivate",
      zipCode?: string | null,
      pref?: string | null,
      city?: string | null,
      address?: string | null,
      phoneNumber?: Array< string > | null,
      sns?:  Array< {
        __typename: "SNS",
        snsType: SNSType,
        accountId: string,
        access: boolean,
      } > | null,
    },
    comment?: string | null,
    display: Display,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTemporaryPatientSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateTemporaryPatientSubscription = {
  onCreateTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTemporaryPatientSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateTemporaryPatientSubscription = {
  onUpdateTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTemporaryPatientSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteTemporaryPatientSubscription = {
  onDeleteTemporaryPatient?:  {
    __typename: "TemporaryPatient",
    id: string,
    clinicId?: string | null,
    name: string,
    gender: Gender,
    phoneNumber?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePatientNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnCreatePatientNumberSubscription = {
  onCreatePatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePatientNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdatePatientNumberSubscription = {
  onUpdatePatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePatientNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnDeletePatientNumberSubscription = {
  onDeletePatientNumber?:  {
    __typename: "PatientNumber",
    id: string,
    clinicId?: string | null,
    patientNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBusinessHourSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateBusinessHourSubscription = {
  onCreateBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessHourSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateBusinessHourSubscription = {
  onUpdateBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessHourSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteBusinessHourSubscription = {
  onDeleteBusinessHour?:  {
    __typename: "BusinessHour",
    id: string,
    clinicId?: string | null,
    userId: string,
    originDate: string,
    intervalType: IntervalType,
    interval: number,
    startTime: string,
    endTime: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTemporaryCloseSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateTemporaryCloseSubscription = {
  onCreateTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTemporaryCloseSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateTemporaryCloseSubscription = {
  onUpdateTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTemporaryCloseSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteTemporaryCloseSubscription = {
  onDeleteTemporaryClose?:  {
    __typename: "TemporaryClose",
    id: string,
    clinicId?: string | null,
    businessHourId: string,
    date: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAppointmentSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateAppointmentSubscription = {
  onCreateAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppointmentSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateAppointmentSubscription = {
  onUpdateAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppointmentSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteAppointmentSubscription = {
  onDeleteAppointment?:  {
    __typename: "Appointment",
    id: string,
    clinicId?: string | null,
    patientId: string,
    temporaryPatient: boolean,
    accountId: string,
    date: string,
    startTime: string,
    endTime: string,
    appointmentStatus: AppointmentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKarteItemSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateKarteItemSubscription = {
  onCreateKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKarteItemSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateKarteItemSubscription = {
  onUpdateKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKarteItemSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteKarteItemSubscription = {
  onDeleteKarteItem?:  {
    __typename: "KarteItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteParts:  Array< {
      __typename: "KarteParts",
      id: string,
      partsName: PartsName,
      title?: string | null,
      options?: Array< string > | null,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAcuItemSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateAcuItemSubscription = {
  onCreateAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAcuItemSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateAcuItemSubscription = {
  onUpdateAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAcuItemSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteAcuItemSubscription = {
  onDeleteAcuItem?:  {
    __typename: "AcuItem",
    id: string,
    clinicId?: string | null,
    direction: Direction,
    bodyParts: BodyParts,
    parts:  Array< {
      __typename: "AcuParts",
      id: string,
      x?: number | null,
      y?: number | null,
      name: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKarteTemplateSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateKarteTemplateSubscription = {
  onCreateKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKarteTemplateSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateKarteTemplateSubscription = {
  onUpdateKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKarteTemplateSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteKarteTemplateSubscription = {
  onDeleteKarteTemplate?:  {
    __typename: "KarteTemplate",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    title: string,
    karteItemIds:  Array< {
      __typename: "KarteItemId",
      id: string,
      karteItemId: string,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateKarteSubscription = {
  onCreateKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateKarteSubscription = {
  onUpdateKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteKarteSubscription = {
  onDeleteKarte?:  {
    __typename: "Karte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    accountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    displayOrder: number,
    title: string,
    karteResults:  Array< {
      __typename: "KarteResult",
      id: string,
      karteItemId: string,
      title: string,
      kartePartsResult:  Array< {
        __typename: "KartePartsResult",
        id: string,
        partsName: PartsName,
        title?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        text?: string | null,
        options?: Array< string > | null,
        choices?: Array< string > | null,
        direction?: Direction | null,
        bodyParts?: BodyParts | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateKarteNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateKarteNumberSubscription = {
  onCreateKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateKarteNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateKarteNumberSubscription = {
  onUpdateKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteKarteNumberSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteKarteNumberSubscription = {
  onDeleteKarteNumber?:  {
    __typename: "KarteNumber",
    id: string,
    clinicId?: string | null,
    karteNumber: number,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFavoriteKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateFavoriteKarteSubscription = {
  onCreateFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFavoriteKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateFavoriteKarteSubscription = {
  onUpdateFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFavoriteKarteSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteFavoriteKarteSubscription = {
  onDeleteFavoriteKarte?:  {
    __typename: "FavoriteKarte",
    id: string,
    clinicId?: string | null,
    accountId: string,
    karteId: string,
    displayOrder: number,
    karteAccountId?: string | null,
    karteAccountName: string,
    karteNumber: string,
    patientId: string,
    karteDate: string,
    title: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInquiryItemSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateInquiryItemSubscription = {
  onCreateInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInquiryItemSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateInquiryItemSubscription = {
  onUpdateInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInquiryItemSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteInquiryItemSubscription = {
  onDeleteInquiryItem?:  {
    __typename: "InquiryItem",
    id: string,
    clinicId?: string | null,
    displayOrder: number,
    version: string,
    title: string,
    inquiryPages:  Array< {
      __typename: "InquiryPage",
      id: string,
      pageTitle: string,
      inquiries:  Array< {
        __typename: "InquiryPageItem",
        id: string,
        valueType: InquiryType,
        required: boolean,
        title: string,
        options?: Array< string > | null,
      } >,
    } >,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSettingSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateSettingSubscription = {
  onCreateSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSettingSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateSettingSubscription = {
  onUpdateSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSettingSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteSettingSubscription = {
  onDeleteSetting?:  {
    __typename: "Setting",
    id: string,
    clinicId?: string | null,
    calendarStartDay: WeekDayShort,
    appointmentTimeUnit: number,
    holidayAutoClose: boolean,
    holidayAutoCloseSat: boolean,
    holidayAutoCloseSun: boolean,
    holidayAutoCloseSub: boolean,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFileHashSubscriptionVariables = {
  clinicId: string,
};

export type OnCreateFileHashSubscription = {
  onCreateFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFileHashSubscriptionVariables = {
  clinicId: string,
};

export type OnUpdateFileHashSubscription = {
  onUpdateFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFileHashSubscriptionVariables = {
  clinicId: string,
};

export type OnDeleteFileHashSubscription = {
  onDeleteFileHash?:  {
    __typename: "FileHash",
    id: string,
    clinicId?: string | null,
    filename: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
